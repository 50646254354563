<template>
  <v-row justify="center" class="py-3">
    <v-col v-if="loading" cols="8" offset="2">
      <v-progress-linear class="mt-16" indeterminate />
    </v-col>
    <div v-else class="col-md-12">
      <v-breadcrumbs :items="items" large class="px-3 pt-0"></v-breadcrumbs>
      <p class="font-weight-medium px-3 fs-20">
        {{ $t('id_order') }}:
        {{
          detail_transaction && detail_transaction.order_number
            ? detail_transaction.order_number
            : ''
        }}
      </p>
      <v-stepper v-model="e1" class="transparent pb-4" elevation="0">
        <v-stepper-header v-model="quotation_stepper" class="box-shadow-none mb-3">
          <v-stepper-step
            :editable="quotation_stepper > 0"
            step="1"
            edit-icon="$complete"
            :complete="quotation_stepper > 1"
          >
            {{ $t('quotation') }}
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step
            :editable="quotation_stepper > 1"
            step="2"
            edit-icon="$complete"
            :complete="quotation_stepper > 2"
          >
            {{ $t('contract_payment') }}
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step
            :editable="quotation_stepper > 2"
            step="3"
            edit-icon="$complete"
            :complete="quotation_stepper > 3"
          >
            {{ $t('upload') }}
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step
            :editable="quotation_stepper > 3"
            step="4"
            edit-icon="$complete"
            :complete="quotation_stepper > 4"
          >
            {{ $t('activation') }}
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step
            :editable="quotation_stepper > 4"
            step="5"
            edit-icon="$complete"
            :complete="quotation_stepper > 5"
          >
            {{ $t('finish') }}
          </v-stepper-step>
        </v-stepper-header>
        <div class="col-md-12 mb-0 ml-0 mt-0">
          <div class="row">
            <div class="col-md-8">
              <v-card outlined class="mx-2 shadow-sm white rounded-8">
                <v-stepper-items>
                  <v-stepper-content step="1">
                    <v-card class="mb-12 transparent pa-3" elevation="0">
                      <v-alert
                        v-if="
                          detail_transaction && detail_transaction.status != 'waiting_quotation'
                        "
                        outlined
                        color="#C2C9D1"
                        class="rounded-8"
                      >
                        <div class="row">
                          <div class="col-md-8 d-flex align-center color-black">
                            <v-icon class="me-2 font-weight-light">ri-file-text-line</v-icon>
                            <p class="font-weight-medium me-2 mb-0">
                              {{
                                detail_transaction &&
                                detail_transaction.files &&
                                detail_transaction.files.quotation
                                  ? getFilename(
                                      $i18n.locale &&
                                        $i18n.locale == 'jp' &&
                                        detail_transaction.files.quotation_ja
                                        ? detail_transaction.files.quotation_ja
                                        : detail_transaction.files.quotation
                                    )
                                  : ''
                              }}
                            </p>
                            <!-- <p class="mb-0">150KB</p> -->
                          </div>
                          <div class="col-md-4 align-end center-text">
                            <v-btn
                              text
                              color="primary"
                              small
                              target="_blank"
                              :href="
                                detail_transaction &&
                                detail_transaction.files &&
                                detail_transaction.files.quotation
                                  ? $i18n.locale &&
                                    $i18n.locale == 'jp' &&
                                    detail_transaction.files.quotation_ja
                                    ? detail_transaction.files.quotation_ja
                                    : detail_transaction.files.quotation
                                  : ''
                              "
                              download
                              :disabled="detail_transaction.status === 'quotation_expired'"
                            >
                              {{ $t('download') }}
                              <v-icon class="ms-2">ri-download-cloud-fill</v-icon>
                            </v-btn>

                            <v-btn
                              v-if="detail_transaction.status === 'pending_quotation'"
                              :disabled="
                                btn_approve_quotation === false ||
                                detail_transaction.status === 'quotation_expired'
                              "
                              color="primary"
                              @click="approveQuotation()"
                              class="rounded-8"
                              small
                              :loading="loading_quotation"
                            >
                              {{ $t('approve') }}
                            </v-btn>
                          </div>
                        </div>
                      </v-alert>

                      <v-alert
                        v-if="detail_transaction.status === 'pending_quotation'"
                        color="blue-grey lighten-1"
                        outlined
                        text
                      >
                        {{ quotation_valid_until }}
                      </v-alert>

                      <template v-if="detail_transaction.status === 'quotation_expired'">
                        <v-btn
                          color="primary"
                          class="rounded-8 mb-4"
                          small
                          :to="{
                            name: 'detail-page',
                            params: {
                              id: media_produk._id,
                              slug: media_produk.title.replace(/ /g, '-').toLowerCase(),
                            },
                          }"
                          target="_blank"
                        >
                          {{ $t('requiest_quotation') }}
                        </v-btn>
                        <v-alert color="blue-grey lighten-1" outlined text>
                          {{ $t('quotation_no_longer_valid') }}
                        </v-alert>
                      </template>

                      <v-alert
                        v-if="detail_transaction.status == 'waiting_quotation'"
                        text
                        outlined
                        color="primary"
                        class="rounded-8"
                      >
                        <p class="color-black fs-18 font-weight-medium mb-0">
                          {{ $t('waiting_for_quotation') }}
                        </p>
                        <p class="color-black mb-0">
                          {{
                            $t(
                              'your_request_has_been_accepted_admin_will_send_you_quotation_here_please_waiting'
                            )
                          }}
                        </p>
                      </v-alert>
                    </v-card>
                  </v-stepper-content>
                  <v-stepper-content step="2">
                    <v-card class="mb-12 transparent pa-3" elevation="0">
                      <v-alert
                        v-if="
                          detail_transaction.status &&
                          ['waiting_contract', 'contract_rejected'].includes(
                            detail_transaction.status
                          )
                        "
                        text
                        outlined
                        color="primary"
                        class="rounded-8"
                      >
                        <!-- <p class="color-black fs-18 font-weight-medium mb-0">{{ $t('waiting_for_contract') }}</p> -->
                        <p class="color-black mb-0">
                          {{ $t('admin_will_send_you_contract_here_please_waiting') }}
                        </p>
                      </v-alert>

                      <v-alert v-else outlined color="#C2C9D1" class="rounded-8">
                        <div class="row">
                          <div class="col-md-8 d-flex align-center color-black">
                            <p class="font-weight-medium me-2 mb-0">
                              <v-icon class="me-2 font-weight-light">ri-file-text-line</v-icon>
                              {{
                                detail_transaction &&
                                detail_transaction.files &&
                                detail_transaction.files.contract_unsigned
                                  ? getFilename(
                                      $i18n.locale &&
                                        $i18n.locale == 'jp' &&
                                        detail_transaction.files.contract_unsigned_ja
                                        ? detail_transaction.files.contract_unsigned_ja
                                        : detail_transaction.files.contract_unsigned
                                    )
                                  : ''
                              }}
                            </p>
                          </div>
                          <div class="col-md-4 align-end center-text">
                            <v-btn
                              text
                              color="primary"
                              small
                              target="_blank"
                              :href="
                                detail_transaction &&
                                detail_transaction.files &&
                                detail_transaction.files.contract_unsigned
                                  ? $i18n.locale &&
                                    $i18n.locale == 'jp' &&
                                    detail_transaction.files.contract_unsigned_ja
                                    ? detail_transaction.files.contract_unsigned_ja
                                    : detail_transaction.files.contract_unsigned
                                  : ''
                              "
                              download
                            >
                              {{ $t('download') }}
                              <v-icon class="ms-2">ri-download-cloud-fill</v-icon>
                            </v-btn>
                          </div>
                        </div>
                      </v-alert>

                      <v-alert
                        v-if="
                          detail_transaction.status &&
                          detail_transaction.status == 'pending_contract'
                        "
                        text
                        outlined
                        color="primary"
                        class="rounded-8"
                      >
                        <p class="color-black mb-0">
                          {{
                            $t(
                              'please_download_the_pdf_file_above_then_give_authorized_manually_after_that_upload_the_contract_payment_receipt'
                            )
                          }}
                        </p>
                      </v-alert>

                      <!-- Contract signed -->
                      <v-col cols="12" class="px-0">
                        <!-- contract history -->
                        <div v-if="itemsData.length" class="font-weight-medium mb-4">
                          <span> {{ $t('transaction.your_signed_contract_history') }} </span>
                          <table class="table-history mt-2" style="width: 100%">
                            <tbody>
                              <template v-for="(item, index) in itemsData">
                                <tr :key="`item-${index}`" @click="handleClickRow(index)">
                                  <td class="">
                                    <v-row class="flex-nowrap" no-gutters align="center">
                                      <v-icon size="20" left>ri-file-text-line</v-icon>
                                      <span class="black--text text-h6">
                                        <a
                                          :href="item.file"
                                          target="_blank"
                                          class="text-body-1 black--text"
                                        >
                                          {{ getFilename(item.file) }}
                                        </a>
                                      </span>
                                    </v-row>
                                  </td>
                                  <td :class="item.status || 'text-right'">
                                    <span class="text--disabled">
                                      {{ getDate(item.timestamp) }}
                                    </span>
                                  </td>
                                  <td v-if="item.status">
                                    <v-row no-gutters justify="space-between">
                                      <span class="warning--text font-weight-bold">
                                        {{ $t('transaction.status.' + item.status) }}
                                      </span>
                                      <v-icon
                                        :style="{ rotate: show_status[index] ? '180deg' : 'none' }"
                                      >
                                        $dropdown
                                      </v-icon>
                                    </v-row>
                                  </td>
                                </tr>
                                <tr
                                  class="item.status"
                                  v-if="item.status && show_status[index]"
                                  :key="`desc-${index}`"
                                >
                                  <td :colspan="3">{{ item.description }}</td>
                                </tr>
                              </template>
                            </tbody>
                          </table>
                          <!-- <div v-if="itemsData.length > 1">
                            <a href="#" class="text--disabled font-weight-bold" @click="more = !more">
                              {{ !more ? 'show more' : 'less' }}
                            </a>
                          </div> -->
                        </div>

                        <!-- signed contract label -->
                        <div
                          v-if="
                            ['pending', 'rejected', 'approved'].includes(
                              this.approval_contract.status
                            ) || detail_transaction.status == 'pending_contract'
                          "
                          class="font-weight-medium mb-2"
                        >
                          <span> {{ $t('contract_authorized') }}</span>
                          <span
                            v-if="
                              ['approved', 'pending', 'expired'].includes(approval_contract.status)
                            "
                            :class="
                              detail_transaction &&
                              detail_transaction.approval &&
                              detail_transaction.approval.contract_signed &&
                              detail_transaction.approval.contract_signed.status &&
                              detail_transaction.approval.contract_signed.status == 'approved'
                                ? 'green--text'
                                : detail_transaction &&
                                  detail_transaction.approval &&
                                  detail_transaction.approval.contract_signed &&
                                  detail_transaction.approval.contract_signed.status &&
                                  detail_transaction.approval.contract_signed.status == 'rejected'
                                ? 'red--text'
                                : 'color-disable'
                            "
                            class="float-right text-decoration-none text-capitalize"
                            tabindex="-1"
                          >
                            <!-- {{approval_contract.status ? $t('contract_'+approval.status).replace(/_/g,' ') : $t('waiting_approval2')}} -->
                            {{ $t('transaction.status.' + approval_contract.status) }}
                          </span>
                        </div>

                        <!-- signed contract file -->
                        <v-alert
                          v-if="
                            ['approved', 'pending', 'expired'].includes(approval_contract.status)
                          "
                          outlined
                          color="#C2C9D1"
                          class="rounded-8"
                        >
                          <div class="row">
                            <div class="col-md-8 d-flex align-center color-black">
                              <p class="font-weight-medium me-2 mb-0 text-dots-1">
                                <v-icon class="me-2 font-weight-light">ri-file-text-line</v-icon>
                                {{
                                  detail_transaction &&
                                  detail_transaction.files &&
                                  detail_transaction.files.contract_signed
                                    ? getFilename(detail_transaction.files.contract_signed)
                                    : ''
                                }}
                              </p>
                            </div>
                            <div class="col-md-4 align-end center-text">
                              <v-btn
                                text
                                color="primary"
                                small
                                target="_blank"
                                :href="
                                  detail_transaction &&
                                  detail_transaction.files &&
                                  detail_transaction.files.contract_signed
                                    ? detail_transaction.files.contract_signed
                                    : ''
                                "
                                download
                              >
                                {{ $t('download') }}
                                <v-icon class="ms-2">ri-download-cloud-fill</v-icon>
                              </v-btn>
                            </div>
                          </div>
                        </v-alert>

                        <!-- hidden -->
                        <v-alert
                          v-if="false"
                          text
                          outlined
                          :color="
                            approval_contract.status == 'approved'
                              ? 'success'
                              : approval_contract.status == 'rejected'
                              ? 'error'
                              : 'primary'
                          "
                          class="rounded-8"
                        >
                          <p class="color-black mb-0">{{ approval_contract.note }}</p>
                        </v-alert>

                        <!-- alert payment rejected -->
                        <v-alert
                          v-if="approval_contract.status == 'rejected'"
                          text
                          outlined
                          color="primary"
                          class="rounded-8"
                          close-text="Close Alert"
                          dismissible
                        >
                          <p class="color-black mb-0">
                            {{ $t('transaction.your_signed_contract_rejected') }}
                          </p>
                        </v-alert>

                        <template
                          v-if="
                            detail_transaction.status == 'pending_contract' &&
                            !['pending', 'approved'].includes(approval_contract.status)
                          "
                        >
                          <file-pond
                            name="contract_signed"
                            credits="false"
                            class-name="my-pond"
                            ref="contract_signed"
                            :label-idle="
                              `<div><div class='text-center mb-2'><i aria-hidden='true' class='v-icon notranslate ri ri-download-cloud-fill theme--light' style='font-size: 40px;'></i></div>` +
                              $t('drag_drop_your_files_or') +
                              ` <span class='primary--text'><span class=''>` +
                              $t('browse') +
                              `</span></span></div>`
                            "
                            v-bind:allow-multiple="false"
                            v-bind:files="contract_signed"
                            v-on:init="handleFilePondInit"
                            accepted-file-types="application/pdf"
                          />
                          <div class="text-end mb-3 mt-3">
                            <v-btn
                              color="primary"
                              @click="submitContract()"
                              class="rounded-8"
                              small
                              :loading="loading_contract"
                            >
                              {{ $t('submit') }}
                            </v-btn>
                          </div>
                        </template>

                        <!-- hidden -->
                      </v-col>

                      <!-- payment proof -->
                      <v-col cols="12" class="px-0">
                        <!-- first payment history -->
                        <div v-if="itemsDataProf.length" class="font-weight-medium mb-4">
                          <span> {{ $t('transaction.payment_proof_history') }} </span>
                          <table class="table-history mt-2" style="width: 100%">
                            <tbody>
                              <template v-for="(item, index) in itemsDataProf">
                                <tr :key="`item-${index}`" @click="handleClickRowProf(index)">
                                  <td class="text-truncate">
                                    <v-row class="flex-nowrap" no-gutters align="center">
                                      <v-icon size="20" left>ri-file-text-line</v-icon>
                                      <span class="black--text text-h6 text-truncate">
                                        <a
                                          :href="item.file"
                                          target="_blank"
                                          class="text-body-1 black--text"
                                        >
                                          {{ getFilename(item.file) }}
                                        </a>
                                      </span>
                                    </v-row>
                                  </td>
                                  <td :class="item.status || 'text-right'">
                                    <span class="text--disabled">
                                      {{ getDate(item.timestamp) }}
                                    </span>
                                  </td>
                                  <td v-if="item.status">
                                    <v-row no-gutters justify="space-between">
                                      <span class="warning--text font-weight-bold">
                                        {{ $t('transaction.status.' + item.status) }}
                                      </span>
                                      <v-icon
                                        :style="{
                                          rotate: show_status_Prof[index] ? '180deg' : 'none',
                                        }"
                                      >
                                        $dropdown
                                      </v-icon>
                                    </v-row>
                                  </td>
                                </tr>
                                <tr
                                  class="item.status"
                                  v-if="item.status && show_status_Prof[index]"
                                  :key="`desc-${index}`"
                                >
                                  <td :colspan="3">{{ item.description }}</td>
                                </tr>
                              </template>
                            </tbody>
                          </table>
                        </div>

                        <!-- payment proof label -->
                        <div
                          v-if="
                            ['pending', 'approved', 'rejected'].includes(approval_payment.status) ||
                            detail_transaction.status == 'pending_contract'
                          "
                          class="font-weight-medium mb-2"
                        >
                          <span> {{ $t('payment_receipt') }}</span>
                          <span
                            v-if="
                              ['approved', 'pending', 'expired'].includes(approval_payment.status)
                            "
                            :class="
                              detail_transaction &&
                              detail_transaction.approval &&
                              detail_transaction.approval.payment_proof_first &&
                              detail_transaction.approval.payment_proof_first.status &&
                              detail_transaction.approval.payment_proof_first.status == 'approved'
                                ? 'green--text'
                                : detail_transaction &&
                                  detail_transaction.approval &&
                                  detail_transaction.approval.payment_proof_first &&
                                  detail_transaction.approval.payment_proof_first.status &&
                                  detail_transaction.approval.payment_proof_first.status ==
                                    'rejected'
                                ? 'red--text'
                                : 'color-disable'
                            "
                            class="float-right text-decoration-none text-capitalize"
                            tabindex="-1"
                          >
                            <!-- {{detail_transaction && detail_transaction.approval && detail_transaction.approval.payment_proof_first && detail_transaction.approval.payment_proof_first.status ? $t('first_payment_proof_'+detail_transaction.approval.payment_proof_first.status).replace(/_/g,' ') : $t('waiting_approval2')}} -->
                            {{
                              approval_payment.status
                                ? $t('transaction.status.' + approval_payment.status)
                                : $t('waiting_approval2')
                            }}
                          </span>
                        </div>

                        <!-- payment proof file -->
                        <v-alert
                          v-if="
                            ['approved', 'pending', 'expired'].includes(approval_payment.status)
                          "
                          outlined
                          color="#C2C9D1"
                          class="rounded-8 custom-alert"
                        >
                          <div class="row">
                            <div class="col-md-8 d-flex align-center color-black text-truncate">
                              <p class="font-weight-medium me-2 mb-0 text-truncate text-no-wrap">
                                <v-icon class="me-2 font-weight-light">ri-file-text-line</v-icon>
                                {{
                                  approval_payment.file ? getFilename(approval_payment.file) : ''
                                }}
                              </p>
                            </div>
                            <div class="col-md-4 align-end center-text">
                              <v-btn
                                text
                                color="primary"
                                small
                                target="_blank"
                                :href="approval_payment.file || ''"
                                download
                              >
                                {{ $t('download') }}
                                <v-icon class="ms-2">ri-download-cloud-fill</v-icon>
                              </v-btn>
                            </div>
                          </div>
                        </v-alert>
                        <v-alert
                          v-if="false"
                          text
                          outlined
                          :color="
                            approval_payment.status == 'approved'
                              ? 'success'
                              : approval_payment.status == 'rejected'
                              ? 'error'
                              : 'primary'
                          "
                          class="rounded-8"
                        >
                          <p class="color-black mb-0">{{ approval_payment.note }}</p>
                        </v-alert>

                        <!-- alert payment rejected -->
                        <v-alert
                          v-if="approval_payment.status == 'rejected'"
                          text
                          outlined
                          color="primary"
                          class="rounded-8"
                          close-text="Close Alert"
                          dismissible
                        >
                          <p class="color-black mb-0">
                            {{ $t('transaction.your_payment_rejected') }}
                          </p>
                        </v-alert>

                        <!-- PAYMENT ATTACHMENT -->
                        <template
                          v-if="
                            detail_transaction.status == 'pending_contract' &&
                            !['pending', 'approved'].includes(approval_payment.status)
                          "
                        >
                          <file-pond
                            name="payment_proof_first"
                            credits="false"
                            class-name="my-pond"
                            ref="payment_proof_first"
                            :label-idle="
                              `<div><div class='text-center mb-2'><i aria-hidden='true' class='v-icon notranslate ri ri-download-cloud-fill theme--light' style='font-size: 40px;'></i></div>` +
                              $t('drag_drop_your_files_or') +
                              ` <span class='primary--text'><span class=''>` +
                              $t('browse') +
                              `</span></span></div>`
                            "
                            v-bind:allow-multiple="false"
                            v-bind:files="payment_proof_first"
                            v-on:init="handleFilePondInit"
                            accepted-file-types="application/pdf"
                          />
                          <div class="text-end mb-3 mt-3">
                            <v-btn
                              color="primary"
                              @click="submitPaymentProf()"
                              class="rounded-8"
                              small
                              :loading="loading_payment_first"
                            >
                              {{ $t('submit') }}
                            </v-btn>
                          </div>
                        </template>

                        <!-- hidden -->
                      </v-col>
                    </v-card>
                  </v-stepper-content>
                  <v-stepper-content step="3">
                    <v-card class="mb-12 transparent pa-3" elevation="0">
                      <v-alert
                        v-if="
                          detail_transaction &&
                          detail_transaction.status &&
                          detail_transaction.status == 'pending_contract'
                        "
                        text
                        outlined
                        color="primary"
                        class="rounded-8"
                      >
                        <p class="color-black fs-18 font-weight-medium mb-0">
                          {{ $t('waiting_for_approval_contract_and_payment') }}
                        </p>
                        <p class="color-black mb-0">
                          {{
                            $t('upload_media_will_be_available_if_contract_and_payment_approved')
                          }}
                        </p>
                      </v-alert>

                      <v-alert
                        v-if="detail_transaction.status == 'contract_approved'"
                        text
                        outlined
                        color="primary"
                        class="rounded-8"
                      >
                        <p class="color-black mb-0">
                          {{ $t('please_attend_the_rule_before_you_upload_the_media') }}
                        </p>
                      </v-alert>

                      <!-- Attachment -->
                      <v-col cols="12" class="px-0">
                        <div class="font-weight-medium mb-2">
                          <div class="font-weight-medium mb-4">
                            <span v-if="itemsMedia"> {{ $t('your_media_history') }} </span>
                            <table v-if="itemsMedia" class="table-history mt-2" style="width: 100%">
                              <tbody>
                                <template v-for="(item, index) in itemsMedia">
                                  <tr :key="`item-${index}`" @click="handleClickRowMedia(index)">
                                    <td class="">
                                      <v-row class="flex-nowrap" no-gutters align="center">
                                        <v-icon size="20" left>ri-file-text-line</v-icon>
                                        <span class="black--text text-body-1">
                                          {{ item.file }}
                                        </span>
                                      </v-row>
                                    </td>
                                    <td :class="item.status || 'text-right'">
                                      <span class="text--disabled">
                                        {{ getDate(item.timestamp) }}
                                      </span>
                                    </td>
                                    <td v-if="item.status">
                                      <v-row no-gutters justify="space-between">
                                        <span class="warning--text font-weight-bold">
                                          {{ $t('transaction.status.' + item.status) }}
                                        </span>
                                        <v-icon
                                          :style="{
                                            rotate: show_status_Media[index] ? '180deg' : 'none',
                                          }"
                                        >
                                          $dropdown
                                        </v-icon>
                                      </v-row>
                                    </td>
                                  </tr>
                                  <tr
                                    class="item.status"
                                    v-if="item.status && show_status_Media[index]"
                                    :key="`desc-${index}`"
                                  >
                                    <td :colspan="3">{{ item.description }}</td>
                                  </tr>
                                </template>
                              </tbody>
                            </table>
                          </div>

                          <span> {{ $t('media') }} </span>
                          <span
                            :class="
                              detail_transaction &&
                              detail_transaction.approval &&
                              detail_transaction.approval.media &&
                              detail_transaction.approval.media.status &&
                              detail_transaction.approval.media.status == 'approved'
                                ? 'green--text'
                                : detail_transaction &&
                                  detail_transaction.approval &&
                                  detail_transaction.approval.media &&
                                  detail_transaction.approval.media.status &&
                                  detail_transaction.approval.media.status == 'rejected'
                                ? 'red--text'
                                : 'color-disable'
                            "
                            class="float-right text-decoration-none text-capitalize"
                            tabindex="-1"
                            v-if="detail_transaction.status != 'contract_approved'"
                          >
                            {{
                              detail_transaction &&
                              detail_transaction.approval &&
                              detail_transaction.approval.media &&
                              detail_transaction.approval.media.status
                                ? $t('media_' + detail_transaction.approval.media.status).replace(
                                    /_/g,
                                    ' '
                                  )
                                : $t('waiting_approval')
                            }}
                          </span>
                        </div>

                        <v-alert
                          outlined
                          color="#C2C9D1"
                          class="rounded-8 pa-0"
                          v-if="
                            !['contract_approved', 'waiting_approval_contract'].includes(
                              detail_transaction.status
                            )
                          "
                        >
                          <div
                            class="fs-14 d-flex align-center color-black"
                            v-for="(item, index) in detail_transaction.files
                              ? detail_transaction.files.media
                              : []"
                            :key="index"
                          >
                            <div
                              class="col-md-8 d-flex align-center color-black"
                              style="word-break: break-all; margin-bottom: -18px"
                            >
                              <p class="font-weight-medium me-2 mb-4">
                                <v-icon style="margin-top: -5px" class="me-2 font-weight-light"
                                  >ri-file-text-line
                                </v-icon>
                                {{ getFilename(item) }}
                              </p>
                            </div>

                            <!-- <p class="mb-0">150KB</p> -->
                            <div class="col-md-4 align-end center-text">
                              <v-btn
                                text
                                color="primary"
                                small
                                target="_blank"
                                :href="item"
                                download
                              >
                                {{ $t('download') }}
                                <v-icon class="ms-2">ri-download-cloud-fill</v-icon>
                              </v-btn>
                            </div>
                          </div>
                        </v-alert>

                        <!-- media alert: hidden -->
                        <v-alert
                          v-if="false"
                          text
                          outlined
                          :color="
                            detail_transaction &&
                            detail_transaction.approval &&
                            detail_transaction.approval.media &&
                            detail_transaction.approval.media.status &&
                            detail_transaction.approval.media.status == 'approved'
                              ? 'success'
                              : detail_transaction &&
                                detail_transaction.approval &&
                                detail_transaction.approval.media &&
                                detail_transaction.approval.media.status &&
                                detail_transaction.approval.media.status == 'rejected'
                              ? 'error'
                              : 'primary'
                          "
                          class="rounded-8"
                        >
                          <p class="color-black mb-0">
                            {{ detail_transaction.approval.media.note }}
                          </p>
                        </v-alert>

                        <v-alert
                          v-if="approval_media.status === 'rejected'"
                          text
                          outlined
                          color="primary"
                          class="rounded-8"
                          close-text="Close Alert"
                          dismissible
                        >
                          <p class="color-black mb-0">
                            {{ $t('transaction.media_file_has_been_rejected') }}
                          </p>
                        </v-alert>

                        <template v-if="detail_transaction.status == 'contract_approved'">
                          <file-pond
                            name="media"
                            credits="false"
                            class-name="my-pond"
                            ref="media"
                            :label-idle="
                              `<div><div class='text-center mb-2'><i aria-hidden='true' class='v-icon notranslate ri ri-download-cloud-fill theme--light' style='font-size: 40px;'></i></div>` +
                              $t('drag_drop_your_files_or') +
                              ` <span class='primary--text'><span class=''>` +
                              $t('browse') +
                              `</span></span></div>`
                            "
                            v-bind:allow-multiple="true"
                            v-bind:files="media"
                            v-on:init="handleFilePondInit"
                            accepted-file-types="image/gif,image/jpeg,image/png,image/bmp,image/jpg,video/mp4,video/x-m4v,video/*,audio/mp3,audio/*;capture=microphone"
                          />

                          <div class="text-end mb-3">
                            <v-btn
                              color="primary"
                              @click="submitMedia()"
                              class="rounded-8"
                              small
                              :loading="loading_media"
                            >
                              {{ $t('submit') }}
                            </v-btn>
                          </div>
                        </template>
                      </v-col>
                    </v-card>
                  </v-stepper-content>
                  <v-stepper-content step="4">
                    <v-card class="mb-12 transparent pa-3" elevation="0">
                      <!-- ini alert label nya -->
                      <v-alert
                        v-if="detail_transaction.status == 'media_approved'"
                        text
                        outlined
                        color="primary"
                        class="rounded-8"
                      >
                        <p class="color-black fs-18 font-weight-medium mb-0">
                          {{ $t('waiting_media_activation') }}
                        </p>
                      </v-alert>

                      <template v-else>
                        <!-- first payment history -->
                        <div v-if="itemsDataProf2.length" class="font-weight-medium mb-4">
                          <span> Your 2nd Payment History </span>
                          <table class="table-history mt-2" style="width: 100%">
                            <tbody>
                              <template v-for="(item, index) in itemsDataProf2">
                                <tr :key="`item-${index}`" @click="handleClickRowProf(index)">
                                  <td class="text-truncate">
                                    <v-row class="flex-nowrap" no-gutters align="center">
                                      <v-icon size="20" left>ri-file-text-line</v-icon>
                                      <span class="black--text text-h6 text-truncate">
                                        <a
                                          :href="item.file"
                                          target="_blank"
                                          class="text-body-1 black--text"
                                        >
                                          {{ getFilename(item.file) }}
                                        </a>
                                      </span>
                                    </v-row>
                                  </td>
                                  <td :class="item.status || 'text-right'">
                                    <span class="text--disabled">
                                      {{ getDate(item.timestamp) }}
                                    </span>
                                  </td>
                                  <td v-if="item.status">
                                    <v-row no-gutters justify="space-between">
                                      <span class="warning--text font-weight-bold">
                                        {{ $t('transaction.status.' + item.status) }}
                                      </span>
                                      <v-icon
                                        :style="{
                                          rotate: show_status_Prof[index] ? '180deg' : 'none',
                                        }"
                                      >
                                        $dropdown
                                      </v-icon>
                                    </v-row>
                                  </td>
                                </tr>
                                <tr
                                  class="item.status"
                                  v-if="item.status && show_status_Prof[index]"
                                  :key="`desc-${index}`"
                                >
                                  <td :colspan="3">{{ item.description }}</td>
                                </tr>
                              </template>
                            </tbody>
                          </table>
                        </div>

                        <v-alert
                          outlined
                          color="#C2C9D1"
                          class="rounded-8 pa-0"
                          v-if="
                            detail_transaction &&
                            detail_transaction.files &&
                            detail_transaction.files.media_proof &&
                            detail_transaction.status == 'media_activated'
                          "
                        >
                          <div class="fs-14 d-flex align-center color-black">
                            <div
                              class="col-md-8 d-flex align-center color-black"
                              style="word-break: break-all; margin-bottom: -18px"
                            >
                              <p class="font-weight-medium me-2 mb-4">
                                <v-icon style="margin-top: -5px" class="me-2 font-weight-light"
                                  >ri-file-text-line
                                </v-icon>
                                {{
                                  detail_transaction &&
                                  detail_transaction.files &&
                                  detail_transaction.files.media_proof
                                    ? getFilename(detail_transaction.files.media_proof)
                                    : ''
                                }}
                              </p>
                            </div>

                            <!-- <p class="mb-0">150KB</p> -->
                            <div class="col-md-4 align-end center-text">
                              <v-btn
                                text
                                color="primary"
                                small
                                target="_blank"
                                :href="detail_transaction.files.media_proof"
                                download
                              >
                                {{ $t('download') }}
                                <v-icon class="ms-2">ri-download-cloud-fill</v-icon>
                              </v-btn>
                            </div>
                          </div>
                        </v-alert>

                        <!-- Attachment -->
                        <v-col cols="12" class="px-0" v-if="isDoublePayment">
                          <div class="font-weight-medium mb-2">
                            <span> {{ $t('second_payment_receipt') }} </span>
                            <span
                              v-if="approval_payment_2.status != 'rejected'"
                              :class="
                                approval_payment_2.status == 'approved'
                                  ? 'green--text'
                                  : approval_payment_2.status == 'rejected'
                                  ? 'red--text'
                                  : 'color-disable'
                              "
                              class="float-right text-decoration-none text-capitalize"
                              tabindex="-1"
                            >
                              {{ $t('transaction.status.' + approval_payment_2.status) }}
                            </span>
                          </div>
                          <v-alert
                            v-if="
                              ['approved', 'pending', 'expired'].includes(approval_payment_2.status)
                            "
                            outlined
                            color="#C2C9D1"
                            class="rounded-8"
                          >
                            <div class="row">
                              <div class="col-md-8 d-flex align-center color-black">
                                <p class="font-weight-medium me-2 mb-0">
                                  <v-icon class="me-2 font-weight-light">ri-file-text-line</v-icon>
                                  {{ getFilename(approval_payment_2.file) }}
                                </p>
                                <!-- <p class="mb-0">150KB</p> -->
                              </div>
                              <div class="col-md-4 align-end center-text">
                                <v-btn
                                  text
                                  color="primary"
                                  small
                                  target="_blank"
                                  :href="approval_payment_2.file"
                                  download
                                >
                                  {{ $t('download') }}
                                  <v-icon class="ms-2">ri-download-cloud-fill</v-icon>
                                </v-btn>
                              </div>
                            </div>
                          </v-alert>
                          <file-pond
                            v-if="
                              isDoublePayment &&
                              !['pending', 'approved', 'expired'].includes(
                                approval_payment_2.status
                              )
                            "
                            name="payment_proof_second"
                            credits="false"
                            class-name="my-pond"
                            ref="payment_proof_second"
                            :label-idle="
                              `<div><div class='text-center mb-2'><i aria-hidden='true' class='v-icon notranslate ri ri-download-cloud-fill theme--light' style='font-size: 40px;'></i></div>` +
                              $t('drag_drop_your_files_or') +
                              ` <span class='primary--text'><span class=''>` +
                              $t('browse') +
                              `</span></span></div>`
                            "
                            v-bind:allow-multiple="false"
                            v-bind:files="payment_proof_second"
                            v-on:init="handleFilePondInit"
                            accepted-file-types="application/pdf"
                          />
                        </v-col>

                        <div
                          class="text-end mb-3"
                          v-if="
                            isDoublePayment &&
                            !['pending', 'approved', 'expired'].includes(approval_payment_2.status)
                          "
                        >
                          <v-btn
                            color="primary"
                            @click="submitPayment()"
                            class="rounded-8"
                            r
                            small
                            :disabled="btn_submit_payment"
                            :loading="loading_payment"
                          >
                            {{ $t('submit') }}
                          </v-btn>
                        </div>
                      </template>
                    </v-card>
                  </v-stepper-content>
                  <v-stepper-content step="5">
                    <v-card class="mb-12 transparent pa-3" elevation="0">
                      <v-alert
                        outlined
                        color="#C2C9D1"
                        class="rounded-8 pa-0"
                        v-if="
                          detail_transaction &&
                          detail_transaction.files &&
                          detail_transaction.files.media &&
                          detail_transaction.status != 'contract_approved' &&
                          detail_transaction.status != 'waiting_approval_contract'
                        "
                      >
                        <div class="fs-14 d-flex align-center color-black">
                          <div
                            class="col-md-8 d-flex align-center color-black"
                            style="word-break: break-all; margin-bottom: -18px"
                          >
                            <p class="font-weight-medium me-2 mb-4">
                              <v-icon style="margin-top: -5px" class="me-2 font-weight-light"
                                >ri-file-text-line
                              </v-icon>
                              {{
                                detail_transaction &&
                                detail_transaction.files &&
                                detail_transaction.files.media_proof
                                  ? getFilename(detail_transaction.files.media_proof)
                                  : ''
                              }}
                            </p>
                          </div>

                          <!-- <p class="mb-0">150KB</p> -->
                          <div class="col-md-4 align-end center-text">
                            <v-btn
                              text
                              color="primary"
                              small
                              target="_blank"
                              :href="detail_transaction.files.media_proof"
                              download
                            >
                              {{ $t('download') }}
                              <v-icon class="ms-2">ri-download-cloud-fill</v-icon>
                            </v-btn>
                          </div>
                        </div>
                      </v-alert>

                      <!-- Attachment -->
                      <v-col
                        cols="12"
                        class="px-0"
                        v-if="
                          detail_transaction &&
                          detail_transaction.files &&
                          detail_transaction.files.payment_proof_second &&
                          detail_transaction.files.payment_proof_second != ''
                        "
                      >
                        <div class="font-weight-medium mb-2">
                          <span> {{ $t('second_payment_receipt') }} </span>
                        </div>
                        <v-alert outlined color="#C2C9D1" class="rounded-8">
                          <div class="row">
                            <div class="col-md-8 d-flex align-center color-black">
                              <p class="font-weight-medium me-2 mb-0">
                                <v-icon class="me-2 font-weight-light">ri-file-text-line</v-icon>
                                {{
                                  detail_transaction &&
                                  detail_transaction.files &&
                                  detail_transaction.files.payment_proof_second
                                    ? getFilename(detail_transaction.files.payment_proof_second)
                                    : ''
                                }}
                              </p>
                              <!-- <p class="mb-0">150KB</p> -->
                            </div>
                            <div class="col-md-4 align-end center-text">
                              <v-btn
                                text
                                color="primary"
                                small
                                target="_blank"
                                :href="
                                  detail_transaction &&
                                  detail_transaction.files &&
                                  detail_transaction.files.payment_proof_second
                                    ? detail_transaction.files.payment_proof_second
                                    : ''
                                "
                                download
                              >
                                {{ $t('download') }}
                                <v-icon class="ms-2">ri-download-cloud-fill</v-icon>
                              </v-btn>
                            </div>
                          </div>
                        </v-alert>
                        <v-alert
                          v-if="
                            detail_transaction &&
                            detail_transaction.approval &&
                            detail_transaction.approval.payment_proof_second &&
                            detail_transaction.approval.payment_proof_second.note &&
                            detail_transaction.approval.payment_proof_second.note != ''
                          "
                          text
                          outlined
                          :color="
                            detail_transaction &&
                            detail_transaction.approval &&
                            detail_transaction.approval.payment_proof_second &&
                            detail_transaction.approval.payment_proof_second.status &&
                            detail_transaction.approval.payment_proof_second.status == 'approved'
                              ? 'success'
                              : detail_transaction &&
                                detail_transaction.approval &&
                                detail_transaction.approval.payment_proof_second &&
                                detail_transaction.approval.payment_proof_second.status &&
                                detail_transaction.approval.payment_proof_second.status ==
                                  'rejected'
                              ? 'error'
                              : 'primary'
                          "
                          class="rounded-8"
                        >
                          <p class="color-black mb-0">
                            {{ detail_transaction.approval.payment_proof_second.note }}
                          </p>
                        </v-alert>
                      </v-col>

                      <div class="text-end mb-3">
                        <v-btn
                          color="primary"
                          :to="{
                            name: 'detail-page',
                            params: {
                              id:
                                detail_transaction &&
                                detail_transaction.product &&
                                detail_transaction.product._id
                                  ? detail_transaction.product._id
                                  : '',
                            },
                          }"
                          class="rounded-8"
                          small
                        >
                          {{ $t('renewal') }}
                        </v-btn>
                      </div>
                    </v-card>
                  </v-stepper-content>
                </v-stepper-items>
              </v-card>
            </div>
            <div class="col-md-4">
              <v-card outlined class="mx-2 shadow-sm" @click="dialog = true">
                <v-img
                  height="140"
                  :src="
                    detail_transaction &&
                    detail_transaction.product &&
                    detail_transaction.product.photo[0]
                      ? detail_transaction.product.photo[0]
                      : ''
                  "
                >
                </v-img>
                <v-chip
                  :ripple="false"
                  class="pa-2 ma-2 mb-0 text-capitalize"
                  color="primary"
                  outlined
                  label
                  small
                >
                  {{ getStatusOrder(detail_transaction) }}
                </v-chip>
                <p v-if="$i18n.locale === 'en'" class="font-weight-medium fs-18 wb mb-0 pa-3">
                  {{
                    detail_transaction &&
                    detail_transaction.product &&
                    detail_transaction.product.media_type &&
                    detail_transaction.product.media_type.title
                      ? detail_transaction.product.media_type.title + ' - '
                      : ''
                  }}{{ detail_transaction.product && detail_transaction.product.title }}
                </p>
                <p v-else class="font-weight-medium fs-18 wb mb-0 pa-3">
                  {{
                    detail_transaction &&
                    detail_transaction.product &&
                    detail_transaction.product.media_type &&
                    detail_transaction.product.media_type.title
                      ? detail_transaction.product.media_type.title_ja + ' - '
                      : ''
                  }}{{ detail_transaction.product && detail_transaction.product.title }}
                </p>

                <v-card-text class="pt-0 px-3">
                  <p class="text-dots-1 mb-0">
                    {{
                      detail_transaction &&
                      detail_transaction.product &&
                      detail_transaction.product.description &&
                      detail_transaction.product.description
                        ? detail_transaction.product.description
                        : ''
                    }}
                  </p>
                  <p class="color-oranye mb-0">
                    {{ $t('id_order') }}:
                    {{
                      detail_transaction && detail_transaction.order_number
                        ? detail_transaction.order_number
                        : ''
                    }}
                  </p>
                  <p class="color-black mb-0">
                    {{ $t('duration') }}:
                    <!-- {{detail_transaction && detail_transaction.duration ? detail_transaction.duration : ''}} -->
                    {{
                      detail_transaction.duration == '1 Month'
                        ? $t('one_month')
                        : detail_transaction.duration == '3 Months'
                        ? $t('three_month')
                        : detail_transaction.duration == '6 Months'
                        ? $t('six_month')
                        : detail_transaction.duration == '12 Months'
                        ? $t('one_year')
                        : detail_transaction.duration
                    }}
                  </p>
                </v-card-text>
              </v-card>
            </div>
          </div>
        </div>

        <!-- </div>         -->
      </v-stepper>
    </div>
    <v-snackbar top v-model="snackbar.state" :color="snackbar.color" text>
      <div class="color-black font-weight-bold" v-html="snackbar.text"></div>
      <div class="color-black" v-html="snackbar.content"></div>
      <template v-slot:action="{ attrs }">
        <v-btn small icon color="primary" v-bind="attrs" @click="snackbar.state = false">
          <v-icon>$close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="dialog" scrollable max-width="900">
      <v-card>
        <v-toolbar class="transparent" fixed elevation="0">
          <v-toolbar-title>
            <v-chip
              :ripple="false"
              class="pa-2 ma-2 mb-0 text-capitalize"
              color="primary"
              outlined
              label
              small
            >
              {{
                detail_transaction && detail_transaction.status
                  ? detail_transaction.status == 'waiting_quotation'
                    ? $t('waiting_for_quotation')
                    : detail_transaction.status == 'waiting_approval_media'
                    ? $t('waiting_approval')
                    : detail_transaction.status == 'waiting_contract'
                    ? $t('waiting_for_contract')
                    : $t('transaction.status.' + detail_transaction.status)
                  : ''
              }}
            </v-chip>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon @click="dialog = false">
              <v-icon>ri-close-line</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <carousel
            v-if="media_produk.photo && media_produk.photo.length > 0 && media_produk.photo[0]"
            :key="refresh"
            :autoplay="true"
            :loop="false"
            :center="true"
            :items="1"
            :nav="false"
            :adjustableHeight="true"
            :margin="5"
          >
            <img
              v-for="item in media_produk.photo"
              :key="item"
              :src="item"
              class="img-detail-media"
            />
          </carousel>
          <p class="font-weight-medium fs-20 mt-3">
            {{
              media_produk.media_type && media_produk.media_type.title
                ? media_produk.media_type.title + ' - '
                : ''
            }}{{ media_produk.title }}
          </p>

          <v-alert
            v-if="media_produk.reason && media_produk.reason != ''"
            text
            outlined
            :class="
              media_produk.status && media_produk.status == 'activated'
                ? 'green--text'
                : media_produk.status && media_produk.status == 'rejected'
                ? 'red--text'
                : 'color-disable'
            "
            class="rounded-8"
          >
            <p
              v-if="media_produk.status && media_produk.status == 'rejected'"
              class="color-black fs-18 font-weight-medium mb-0"
            >
              Reason for rejected
            </p>
            <p class="color-black mb-0">{{ media_produk.reason }}</p>
          </v-alert>

          <p class="color-state" v-html="media_produk.description"></p>

          <div class="box-trafic py-3">
            <div class="row">
              <div
                class="col-3 col-md-2"
                :title="$t('category')"
                v-if="media_produk && media_produk.category && media_produk.category.title"
              >
                <p class="fs-14 font-weight-medium mb-0">{{ $t('category') }}</p>
                <p class="color-state">
                  {{ media_produk && media_produk.category && media_produk.category.title }}
                </p>
              </div>
              <div class="col-3 col-md-2" :title="$t('traffic')">
                <p class="fs-14 font-weight-medium mb-0">{{ $t('traffic') }}</p>
                <p class="color-state mb-0">{{ media_produk && media_produk.traffic }}</p>
              </div>
              <div class="col-12 col-md-12 mb-2">
                <p class="fs-14 font-weight-medium mb-0">{{ $t('transaction.link_video') }}</p>
                <a v-if="media_produk.video != ''" :href="media_produk.video" target="_blank">{{
                  media_produk && media_produk.video
                }}</a>
                <p v-else class="text-primary mb-0">-</p>
              </div>
            </div>
          </div>

          <div
            class="box-details mb-3"
            v-if="media_produk.media_type && media_produk.media_type.property"
          >
            <div class="row">
              <div
                v-for="(item, index) in media_produk.media_type.property"
                :key="index"
                class="col-3 col-md-2"
              >
                <!-- <p class="font-weight-bold">{{$t(item.title.toLowerCase().replace(/ /g,'_'))}}</p> -->
                <p class="font-weight-bold">{{ item.title }}</p>
                <p class="font-weight-normal">{{ item.value }}</p>
              </div>
            </div>
          </div>

          <div class="box-details mb-3">
            <div class="row">
              <div class="col-3 col-md-2 text-center" :title="$t('size')">
                <img src="@/assets/images/size.png" width="64" height="64" class="mb-1" />
                <p class="font-weight-medium">
                  {{
                    media_produk.unit_of_measurement && media_produk.unit_of_measurement.width
                      ? media_produk.unit_of_measurement.width
                      : ''
                  }}
                  x
                  {{
                    media_produk.unit_of_measurement && media_produk.unit_of_measurement.height
                      ? media_produk.unit_of_measurement.height
                      : ''
                  }}
                  {{
                    media_produk.unit_of_measurement && media_produk.unit_of_measurement.type
                      ? $t(media_produk.unit_of_measurement.type.toLowerCase())
                      : ''
                  }}
                </p>
              </div>
              <div class="col-3 col-md-2 text-center" :title="$t('orientation')">
                <img src="@/assets/images/window.png" width="64" height="64" class="mb-1" />
                <p class="font-weight-medium">
                  {{
                    $t(
                      media_produk && media_produk.orientation
                        ? media_produk.orientation.toLowerCase()
                        : ''
                    )
                  }}
                </p>
              </div>
              <div class="col-3 col-md-2 text-center" :title="$t('lighting')">
                <img src="@/assets/images/sun.png" width="64" height="64" class="mb-1" />
                <p class="font-weight-medium">
                  {{ media_produk.is_lighting === 'true' ? $t('available') : $t('not_available') }}
                </p>
              </div>
            </div>
          </div>

          <div class="box-map mb-3">
            <GmapMap
              :center="{
                lat:
                  media_produk && media_produk.location && media_produk.location.coordinates
                    ? media_produk.location.coordinates[1]
                    : '',
                lng:
                  media_produk && media_produk.location && media_produk.location.coordinates
                    ? media_produk.location.coordinates[0]
                    : '',
              }"
              :zoom="10"
              map-style-id="roadmap"
              :options="{
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: true,
                disableDefaultUi: false,
              }"
              style="width: 100%; height: 50vmin"
              ref="mapRef"
            >
              <GmapMarker
                :position="{
                  lat:
                    media_produk && media_produk.location && media_produk.location.coordinates
                      ? media_produk.location.coordinates[1]
                      : '',
                  lng:
                    media_produk && media_produk.location && media_produk.location.coordinates
                      ? media_produk.location.coordinates[0]
                      : '',
                }"
              />
            </GmapMap>
            <div class="row mt-2">
              <div class="col-md-10">
                <p class="color-state">
                  <v-icon>ri-map-pin-2-fill</v-icon>
                  {{
                    media_produk.location && media_produk.location.address
                      ? media_produk.location.address
                      : ''
                  }}
                </p>
              </div>
              <div class="col-md-2 align-self-end text-end">
                <v-btn
                  :href="
                    media_produk.location && media_produk.location.coordinates
                      ? 'https://www.google.com/maps/place/' +
                        media_produk.location.coordinates[1] +
                        ',' +
                        media_produk.location.coordinates[0]
                      : ''
                  "
                  target="_blank"
                  outlined
                  class="mb-3 color-state rounded-8"
                  x-small
                >
                  {{ $t('view_on_map') }}
                </v-btn>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
// import { mapGetters} from 'vuex'
// Import Vue FilePond
import vueFilePond from 'vue-filepond';
// import moment from "moment";

// Import FilePond styles
import 'filepond/dist/filepond.min.css';

// Import FilePond plugins
// Please note that you need to install these plugins separately

// Import image preview plugin styles
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';

// Import image preview and file type validation plugins
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import carousel from 'vue-owl-carousel';

// Create component
const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview);

export default {
  name: 'detail-trans',
  components: {
    FilePond,
    carousel,
  },
  data: () => ({
    dialog: false,
    itemsData: [],
    itemsDataProf: [],
    itemsMedia: [],
    itemsDataProf2: [],
    more: false,
    more_prof: false,
    more_med: false,
    show_status: [],
    show_status_Prof: [],
    show_status_Media: [],
    items: [
      {
        text: 'Transaction',
        disabled: false,
        href: '/transaction',
      },
      {
        text: '',
        disabled: true,
        href: '#',
      },
    ],
    btn_approve_quotation: true,
    btn_submit_contract: true,
    btn_submit_payment: true,
    btn_submit_media: true,
    isPaketEmpty: false,
    loading: false,
    loading_quotation: false,
    loading_contract: false,
    loading_payment_first: false,
    loading_payment: false,
    loading_media: false,
    contract_signed: [],
    payment_proof_first: [],
    payment_proof_second: [],
    media: [],
    e1: 1,
    quotation_stepper: 0,
    overlay: 0.1,
    overlay_color: 'secondary',
    detail_transaction: {},
    approval: {},
    data: {},
    data2: {},
    snackbar: {
      state: false,
      text: '',
      content: '',
      color: 'success',
    },
    refresh: 0,
    media_produk: {},
  }),
  computed: {
    current_lang() {
      try {
        return this.$i18n.locale;
      } catch (error) {
        return 'en';
      }
    },
    quotation_valid_until() {
      try {
        switch (this.current_lang) {
          case 'en':
            return (
              `The quotation above will be valid until ` +
              this.getDate(this.detail_transaction.quotation.expired)
            );
          case 'jp':
            return (
              `御見積書は` + this.getDate(this.detail_transaction.quotation.expired) + 'まで有効'
            );
          default:
            return '';
        }
      } catch (error) {
        console.log('error quotation valid', error.message);
        return '';
      }
    },
    isDoublePayment() {
      try {
        return this.detail_transaction.contract.payment.terms == 'double_payment';
      } catch (error) {
        return false;
      }
    },
    approval_contract() {
      try {
        if (this.isEmpty(this.detail_transaction.approval?.contract_signed)) {
          throw new Error('Contract empty');
        }
        const contract = this.detail_transaction.approval?.contract_signed;
        const status = contract && contract.status ? contract : { status: '' };
        return {
          file: this.detail_transaction.files.contract_signed,
          ...status,
        };
      } catch (error) {
        console.log('contract error', error.message);
        return {};
      }
    },
    approval_payment() {
      // return this.detail_transaction.approval.contract_signed || {};
      try {
        if (this.isEmpty(this.detail_transaction.approval.payment_proof_first)) {
          throw new Error('Payment empty');
        }
        const contract = this.detail_transaction.approval.payment_proof_first;
        const status = typeof contract == 'string' ? { status: contract } : contract;
        return {
          file: this.detail_transaction.files.payment_proof_first,
          ...status,
        };
      } catch (error) {
        return {};
      }
    },
    approval_payment_2() {
      // return this.detail_transaction.approval.contract_signed || {};
      try {
        if (this.isEmpty(this.detail_transaction.approval.payment_proof_second)) {
          throw new Error('Payment empty');
        }
        const contract = this.detail_transaction.approval.payment_proof_second;
        const status = typeof contract == 'string' ? { status: contract } : contract;
        return {
          file: this.detail_transaction.files?.payment_proof_second,
          ...status,
        };
      } catch (error) {
        return {};
      }
    },
    approval_media() {
      // return this.detail_transaction.approval.contract_signed || {};
      try {
        if (this.isEmpty(this.detail_transaction.approval.media)) {
          throw new Error('Media empty');
        }
        const contract = this.detail_transaction.approval.media;
        const status = typeof contract == 'string' ? { status: contract } : contract;
        return {
          file: this.detail_transaction.approval.media,
          ...status,
        };
      } catch (error) {
        return {};
      }
    },
    file() {
      if (this.data.file instanceof File) {
        const file = this.data.file;
        const type = ['application/pdf'].includes(file.type) ? 'document' : 'file';
        const url = URL.createObjectURL(file);
        return {
          url,
          type,
        };
      } else {
        return this.data.file;
      }
    },
    file2() {
      if (this.data2.file2 instanceof File) {
        const file = this.data2.file2;
        const type = ['application/pdf'].includes(file.type) ? 'document' : 'file';
        const url = URL.createObjectURL(file);
        return {
          url,
          type,
        };
      } else {
        return this.data2.file2;
      }
    },
  },
  async mounted() {
    await this.getDetailTransaction(this.$route.params.id);
    this.items[0].text = await this.$t('transaction');
    // this.$emit("page-changed", 2, {
    //   title: "Detail Transaction",
    //   link: this.$route.path,
    // });
  },
  created() {},
  methods: {
    getStatusOrder(item) {
      let status = item.status;
      if (item.approval?.contract_signed?.status == 'pending') {
        status = 'waiting_contract_approval';
      }
      return this.$t(`transaction.status.${status}`);
    },
    isEmpty(object) {
      return (
        (object &&
          Object.keys(object).length === 0 &&
          Object.getPrototypeOf(object) === Object.prototype) ||
        !object
      );
    },
    getDate(date) {
      // let currentDate = moment(a);
      // return moment(currentDate).format("MMMM D, YYYY");
      try {
        const locale = this.$i18n.locale == 'en' ? 'en-US' : 'ja-JP';
        if (!isNaN(date)) date = date * 1000;
        const localeDate = new Date(date);
        const d = localeDate.toLocaleDateString(locale, {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        });
        const t = localeDate.toLocaleTimeString(locale, {
          hour12: false,
          hour: '2-digit',
          minute: '2-digit',
        });

        return `${d} ${t}`;
      } catch (error) {
        return date;
      }
    },
    handleClickRow(index) {
      const status = this.show_status[index];
      this.$set(this.show_status, index, !status);
    },
    handleClickRowProf(index) {
      const status = this.show_status_Prof[index];
      this.$set(this.show_status_Prof, index, !status);
    },
    handleClickRowMedia(index) {
      const status = this.show_status_Media[index];
      this.$set(this.show_status_Media, index, !status);
    },
    handleFilePondInit: function () {
      // console.log("FilePond has initialized");
      // FilePond instance methods are available on `this.$refs.pond`
    },
    async upload(files) {
      return await Promise.all(
        files.map(async (file) => {
          let formData = new FormData();
          formData.append('file', file.file);
          return await this.$store.dispatch('upload', formData);
        })
      );
    },
    async submitContract() {
      this.loading_contract = true;
      if (this.$refs.contract_signed.getFiles().length == 0) {
        alert('Contract Signed Required!');
        this.loading_contract = false;
        return;
      }
      // if (this.$refs.payment_proof_first.getFiles().length == 0) {
      //   alert("Payment Proof Required!")
      //   this.loading_contract = false
      //   return
      // }
      const contract = await this.upload(this.$refs.contract_signed.getFiles());
      // const payment = await this.upload(this.$refs.payment_proof_first.getFiles());
      this.$store
        .dispatch('submitContract', {
          id: this.$route.params.id,
          contract: contract[0],
          // payment_proof: payment[0]
        })
        .then(() => {
          this.getDetailTransaction(this.$route.params.id);
          if (this.$i18n.locale === 'en') {
            this.snackbar.text = 'Success';
            this.snackbar.content = 'Contract has been submitted.';
          } else {
            this.snackbar.text = '送信されました';
            this.snackbar.content = 'ご契約書の送信に成功しました';
          }
          this.snackbar.state = true;
          this.snackbar.color = 'success';
          this.loading_contract = false;
          this.btn_submit_contract = true;
          // this.e1 = 3
        })
        .catch((error) => {
          console.log(error);
          this.snackbar.text = 'Failed';
          this.snackbar.content = error.response.data.message;
          this.snackbar.state = true;
          this.snackbar.color = 'error';
          this.loading_contract = false;
          // window.location.href = '/'
        });
    },
    async submitPaymentProf() {
      this.loading_payment_first = true;
      if (this.$refs.payment_proof_first.getFiles().length == 0) {
        alert('Payment Proof Required!');
        this.loading_payment_first = false;
        return;
      }
      const payment = await this.upload(this.$refs.payment_proof_first.getFiles());
      this.$store
        .dispatch('submitContractPay', {
          id: this.$route.params.id,
          payment_proof: payment[0],
        })
        .then(() => {
          this.getDetailTransaction(this.$route.params.id);
          if (this.$i18n.locale === 'en') {
            this.snackbar.text = 'Success';
            this.snackbar.content = 'Payment has been submitted.';
          } else {
            this.snackbar.text = '送信されました';
            this.snackbar.content = 'ご契約書の送信に成功しました';
          }
          this.snackbar.state = true;
          this.snackbar.color = 'success';
          this.loading_payment_first = false;
          this.btn_submit_contract = true;
          // this.e1 = 3
        })
        .catch((error) => {
          console.log(error);
          this.snackbar.text = 'Failed';
          this.snackbar.content = error.response.data.message;
          this.snackbar.state = true;
          this.snackbar.color = 'error';
          this.loading_payment_first = false;
          // window.location.href = '/'
        });
    },
    async submitPayment() {
      this.loading_payment = true;
      if (this.$refs.payment_proof_second.getFiles().length == 0) {
        alert('Payment Proof Required!');
        this.loading_payment = false;
        return;
      }
      const payment = await this.upload(this.$refs.payment_proof_second.getFiles());
      this.$store
        .dispatch('submitPayment', {
          id: this.$route.params.id,
          payment_proof: payment[0],
        })
        .then(() => {
          this.getDetailTransaction(this.$route.params.id);
          this.snackbar.text = 'Success';
          this.snackbar.content = 'Payment has been submitted.';
          this.snackbar.state = true;
          this.snackbar.color = 'success';
          this.loading_payment = false;
          this.btn_submit_payment = true;
          this.e1 = 5;
        })
        .catch((error) => {
          console.log(error);
          this.snackbar.text = 'Failed';
          this.snackbar.content = error.response.data.message;
          this.snackbar.state = true;
          this.snackbar.color = 'error';
          this.loading_payment = false;
          // window.location.href = '/'
        });
    },
    async submitMedia() {
      this.loading_media = true;
      if (this.$refs.media.getFiles().length == 0) {
        alert('Media Required!');
        this.loading_media = false;
        return;
      }
      const media = await this.upload(this.$refs.media.getFiles());
      this.$store
        .dispatch('uploadMedia', {
          id: this.$route.params.id,
          media: media,
        })
        .then(() => {
          this.getDetailTransaction(this.$route.params.id);
          if (this.$i18n.locale === 'en') {
            this.snackbar.text = 'Success';
            this.snackbar.content = 'Media has been submitted.';
          } else {
            this.snackbar.text = '成功しました';
            this.snackbar.content = 'デザインの送信に成功しました';
          }
          this.snackbar.state = true;
          this.snackbar.color = 'success';
          this.loading_media = false;
          this.btn_submit_contract = true;
          // this.e1 = 4
          this.e1 = 3;
        })
        .catch((error) => {
          console.log(error);
          this.snackbar.text = 'Failed';
          this.snackbar.content = error.response.data.message;
          this.snackbar.state = true;
          this.snackbar.color = 'error';
          this.loading_media = false;
          // window.location.href = '/'
        });
    },
    approveQuotation() {
      this.loading_quotation = true;
      this.$store
        .dispatch('approveQuotation', {
          id: this.$route.params.id,
        })
        .then(() => {
          this.getDetailTransaction(this.$route.params.id);
          if (this.$i18n.locale === 'en') {
            this.snackbar.text = 'Success';
            this.snackbar.content = 'Quotation has been approved.';
          } else {
            this.snackbar.text = '承認されました';
            this.snackbar.content = '見積書を承認しました';
          }
          this.snackbar.state = true;
          this.snackbar.color = 'success';
          this.loading_quotation = false;
          this.btn_approve_quotation = false;
          this.e1 = 2;
        })
        .catch((error) => {
          console.log(error);
          this.snackbar.text = 'Failed';
          this.snackbar.content = error.response.data.message;
          this.snackbar.state = true;
          this.snackbar.color = 'error';
          this.loading_quotation = false;
          // window.location.href = '/'
        });
    },
    getFilename(url) {
      try {
        const pathname = new URL(url).pathname;
        const index = pathname.lastIndexOf('/');
        return -1 !== index ? pathname.substring(index + 1) : pathname;
      } catch (error) {
        return url;
      }
    },
    getDetailTransaction(id) {
      this.loading = true;
      this.$store
        .dispatch('getDetailTransaction', id)
        .then((data) => {
          this.detail_transaction = data;
          this.media_produk = data.product;

          const statusFlow = [
            'waiting_quotation',
            'pending_quotation',
            'quotation_expired',
            'waiting_contract',
            'contract_rejected',
            'pending_contract',
            'contract_approved',
            'waiting_approval_media',
            'media_approved',
            'media_installed',
            'media_activated',
          ];
          const statusIndex = statusFlow.findIndex((v) => v == this.detail_transaction.status);

          switch (statusIndex) {
            case 0:
              this.btn_approve_quotation = true;
              break;
            case 1:
            case 2:
              this.quotation_stepper = this.e1 = 1;
              break;
            case 3:
            case 4:
            case 5:
              this.quotation_stepper = this.e1 = 2;
              this.btn_submit_contract = false;
              break;
            case 6:
            case 7:
              this.quotation_stepper = this.e1 = 3;
              this.btn_submit_payment = false;
              break;
            case 8:
            case 9:
              this.btn_submit_payment = false;
              this.quotation_stepper = this.e1 = 4;
              break;
            case 10:
              if (this.detail_transaction.contract?.payment?.terms == 'double_payment') {
                this.quotation_stepper = this.e1 = 4;
                if (this.detail_transaction.approval?.payment_proof_second?.status == 'approved') {
                  this.e1 = 5;
                  this.quotation_stepper = 6;
                }
              } else {
                this.e1 = 5;
                this.quotation_stepper = 6;
              }
              break;
            default:
              this.quotation_stepper = this.e1 = 1;
              break;
          }

          this.items[1].text = this.detail_transaction?.order_number;

          if (data.history) {
            this.itemsData = data.history?.contract_signed
              ? data.history?.contract_signed.sort((a, b) => b.timestamp - a.timestamp)
              : [];
            this.itemsDataProf = data.history?.payment_proof_first
              ? data.history?.payment_proof_first.sort((a, b) => b.timestamp - a.timestamp)
              : [];
            this.itemsMedia = data.history?.media
              ? data.history?.media.sort((a, b) => b.timestamp - a.timestamp)
              : [];
            this.itemsDataProf2 = data.history?.payment_proof_second
              ? data.history?.payment_proof_second.sort((a, b) => b.timestamp - a.timestamp)
              : [];
            // console.log('media',this.itemsMedia)
          }
          this.loading = false;
          // console.log('payment',this.approval_payment.status)
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          // window.location.href = '/'
        });
    },
    dropFile(event) {
      this.overlay = 0.1;
      this.$set(this.data, 'file', event.dataTransfer.files[0]);
      this.$set(this.data.file, 'src', URL.createObjectURL(this.data.file));
    },
    changeFile() {
      this.$set(this.data, 'file', this.$refs.file.files[0]);
      this.$set(this.data.file, 'src', URL.createObjectURL(this.data.file));
    },
    dropFile2(event) {
      this.overlay = 0.1;
      this.$set(this.data2, 'file2', event.dataTransfer.files[0]);
      this.$set(this.data2.file2, 'src', URL.createObjectURL(this.data2.file2));
    },
    changeFile2() {
      this.$set(this.data2, 'file2', this.$refs.file.files[0]);
      this.$set(this.data2.file2, 'src', URL.createObjectURL(this.data2.file2));
    },
  },
  watch: {},
};
</script>

<style>
.custom-alert .v-alert__content {
  width: 100%;
}
.center-text {
  text-align: end !important;
}

.v-stepper__step--active {
  font-weight: bold !important;
}

@media (max-width: 425px) {
  .center-text {
    text-align: center !important;
  }
}
</style>
