<template>
  <div class="">

    <!-- detail content -->
    <section class="detail-content white py-5">
      <div class="col-md-11 mx-auto">
        <div class="row">
          <div class="col-md-12">
            <v-breadcrumbs
              :items="items"
              large
              class="ps-0"
            ></v-breadcrumbs>
          </div>
          <div class="col-md-8">
            <img src="@/assets/images/tower.jpg" class="mb-3" style="width: 100%;height: auto;object-fit: cover;">
            <p class="font-weight-medium fs-20">Building - Nagoya TV Tower Naka Ward, Nagoya, Aichi 460-0003</p>

            <p class="color-state">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget consectetur eget dui sit. Non malesuada diam gravida eleifend lobortis.</p>

            <div class="box-trafic py-3">
              <p class="fs-12 font-weight-medium mb-0">Traffic</p>
              <p class="color-state">200 - 300 / minutes</p>
            </div>

            <div class="box-details mb-3">
              <div class="row">
                <div class="col-3 col-md-2 col-lg-1 text-center">
                  <img src="@/assets/images/size.png" width="64" height="64" class="mb-1">
                  <p class="font-weight-medium">10x5 M</p>
                </div>
                <div class="col-3 col-md-2 col-lg-1 text-center">
                  <img src="@/assets/images/window.png" width="64" height="64" class="mb-1">
                  <p class="font-weight-medium">Vertical</p>
                </div>
                <div class="col-3 col-md-2 col-lg-1 text-center">
                  <img src="@/assets/images/sun.png" width="64" height="64" class="mb-1">
                  <p class="font-weight-medium">Avaiable</p>
                </div>
              </div>
            </div>

            <div class="box-map mb-3">
              <img src="@/assets/images/maps.png" class="mb-2" style="width: 100%;height: auto;object-fit: cover;">
              <div class="row">
                <div class="col-md-6">
                  <p class="color-state">
                    <v-icon>ri-map-pin-2-fill</v-icon>
                    Nagoya TV Tower Naka Ward, Nagoya, Aichi 460-0003
                  </p>
                </div>  
                <div class="col-md-6 align-self-end text-end">
                  <v-btn
                  outlined
                  class="mb-3 color-state rounded-8"
                  small
                >
                  View on map
                </v-btn>
                </div>  
              </div>
            </div>
            <div class="d-lg-none d-md-block mb-5">
              <v-card
                elevation="0"
                outlined
                class="px-3 rounded-md"
              >
                <v-card-actions>
                  <v-list-item class="grow pl-0">                  
                    <v-list-item-content class="pl-0">
                      <p class="color-oranye font-weight-medium">Price Start from</p>
                    </v-list-item-content>

                    <v-row
                      align="center"
                      justify="end"
                    >                    
                      <p class="fs-20 color-oranye font-weight-medium">￥ {{ this.duration*500 }}</p>
                    </v-row>
                  </v-list-item>
                </v-card-actions>
                <v-form
                  ref="form"
                  class="px-2"
                >
                  <v-row>
                    <v-col class="pb-1">
                      <div class="mb-2">
                        <span class="font-weight-medium">Choose Duration</span>
                      </div>
                      <v-select
                        v-model="duration"
                        :items="month"
                        placeholder="Select Duration"
                        outlined
                        :item-text="'name'"
                        class="rounded-8"
                        :item-value="'value'"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row class="mt-0">
                    <v-col class="pb-0">
                        <div class="mb-2">
                          <span class="font-weight-medium">Choose Start Date</span>
                        </div>
                        <v-menu
                          ref="menu"
                          v-model="menu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="date"
                              append-icon="ri-calendar-line"
                              readonly
                              outlined
                              v-bind="attrs"
                              v-on="on"
                              class="rounded-8"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="date"
                            :active-picker.sync="activePicker"                          
                            :min="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                            @change="save"
                            class="rounded-8"
                          ></v-date-picker>
                          <!-- :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)" -->
                        </v-menu>
                        <!-- <v-menu
                          ref="menu"
                          v-model="menu"
                          :close-on-content-click="false"
                          elevation="0"
                          style="min-width:auto!important"
                        >
                          <v-date-picker
                            v-model="date"
                            ref="picker"
                            min="1950-01-01"
                            :max="(new Date()).toISOString()"
                            outlined
                            required
                          ></v-date-picker>
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="date"
                              append-icon="ri-calendar-line"
                              readonly
                              outlined
                              v-bind="attrs"
                              v-on="on"
                              class=""
                            ></v-text-field>
                          </template>
                        </v-menu> -->
                      </v-col> 
                  </v-row>
                  <div class="box-date py-3 mb-3 rounded-8" style="background: linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), #3E66FB;">
                    <div class="row">
                      <div class="col-md-6 text-center">
                        <p class="font-weight-medium">Start Date</p>
                        <p v-if="this.date !=null " class="primary--text">{{ dateStart(date) }}</p>
                      </div>
                      <div class="col-md-6 text-center">
                        <p class="font-weight-medium">End Date</p>
                        <p v-if="this.date !=null && this.duration == '1'" class="primary--text">{{ dateMonth(date) }}</p>
                        <p v-else-if="this.date !=null && this.duration == '3'" class="primary--text">{{ dateMonth3(date) }}</p>
                        <p v-else-if="this.date !=null && this.duration == '6'" class="primary--text">{{ dateMonth6(date) }}</p>
                        <p v-else-if="this.date !=null && this.duration == '12'" class="primary--text">{{ dateYear(date) }}</p>
                      </div>
                    </div>
                  </div>
                  <v-btn
                    color="primary"
                    class="mb-3 rounded-8"
                    small
                    block
                    :to="{ name: 'login' }"
                  >
                    Quotation Request
                  </v-btn>
                  <v-btn
                    outlined
                    class="mb-3 rounded-8"
                    small
                    block
                  >
                    Ask for Enquiries
                  </v-btn>
                  <v-btn
                    text
                    small
                    block
                    color="primary"
                    class="mb-3"
                  >
                    Print Factsheet
                  </v-btn>
                </v-form>
              </v-card>
            </div>
            <div class="box-nearby">
              <h2 class="font-weight-bold mb-3">Media Nearby</h2>              
              <div class="row">
                <div class="col-md-3">                  
                  <v-card
                    class=""
                  >
                    <v-img
                      height="140"
                      src="@/assets/images/img-detail.jpeg"
                    ></v-img>

                    <p class="font-weight-medium fs-18 wb mb-0 pa-2">Building - Nagoya TV Tower</p>
                    
                    <v-card-text class="pt-0 px-2">
                      <p class="color-oranye mb-0">
                        Start from ￥{{500}}
                      </p>
                      <p class="text-dots-2 mb-0">Nagoya TV Tower Naka Ward, Nagoya, Aichi ...</p>
                    </v-card-text>
                  </v-card>
                </div>
                <div class="col-md-3">                  
                  <v-card
                    class=""
                  >
                    <v-img
                      height="140"
                      src="@/assets/images/img-detail.jpeg"
                    ></v-img>

                    <p class="font-weight-medium fs-18 wb mb-0 pa-2">Building - Nagoya TV Tower</p>
                    
                    <v-card-text class="pt-0 px-2">
                      <p class="color-oranye mb-0">
                        Start from ￥{{500}}
                      </p>
                      <p class="text-dots-2 mb-0">Nagoya TV Tower Naka Ward, Nagoya, Aichi ...</p>
                    </v-card-text>
                  </v-card>
                </div>
                <div class="col-md-3">                  
                  <v-card
                    class=""
                  >
                    <v-img
                      height="140"
                      src="@/assets/images/img-detail.jpeg"
                    ></v-img>

                    <p class="font-weight-medium fs-18 wb mb-0 pa-2">Building - Nagoya TV Tower</p>
                    
                    <v-card-text class="pt-0 px-2">
                      <p class="color-oranye mb-0">
                        Start from ￥{{500}}
                      </p>
                      <p class="text-dots-2 mb-0">Nagoya TV Tower Naka Ward, Nagoya, Aichi ...</p>
                    </v-card-text>
                  </v-card>
                </div>
                <div class="col-md-3">                  
                  <v-card
                    class=""
                  >
                    <v-img
                      height="140"
                      src="@/assets/images/img-detail.jpeg"
                    ></v-img>

                    <p class="font-weight-medium fs-18 wb mb-0 pa-2">Building - Nagoya TV Tower</p>
                    
                    <v-card-text class="pt-0 px-2">
                      <p class="color-oranye mb-0">
                        Start from ￥{{500}}
                      </p>
                      <p class="text-dots-2 mb-0">Nagoya TV Tower Naka Ward, Nagoya, Aichi ...</p>
                    </v-card-text>
                  </v-card>
                </div>                
              </div>
            </div>
          </div>
          <div class="col-md-4 d-none d-lg-block">
            <v-card
              elevation="0"
              outlined
              class="px-3 rounded-md"
            >
              <v-card-actions>
                <v-list-item class="grow pl-0">                  
                  <v-list-item-content class="pl-0">
                    <p class="color-oranye font-weight-medium">Price Start from</p>
                  </v-list-item-content>

                  <v-row
                    align="center"
                    justify="end"
                  >                    
                    <p class="fs-20 color-oranye font-weight-medium">￥ {{ this.duration*500 }}</p>
                  </v-row>
                </v-list-item>
              </v-card-actions>
              <v-form
                ref="form"
                class="px-2"
              >
                <v-row>
                  <v-col class="pb-1">
                    <div class="mb-2">
                      <span class="font-weight-medium">Choose Duration</span>
                    </div>
                    <v-select
                      v-model="duration"
                      :items="month"
                      placeholder="Select Duration"
                      outlined
                      :item-text="'name'"
                      class="rounded-8"
                      :item-value="'value'"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row class="mt-0">
                  <v-col class="pb-0">
                      <div class="mb-2">
                        <span class="font-weight-medium">Choose Start Date</span>
                      </div>
                      <v-menu
                        ref="menu2"
                        v-model="menu2"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="date2"
                            append-icon="ri-calendar-line"
                            readonly
                            outlined
                            v-bind="attrs"
                            v-on="on"
                            class="rounded-8"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="date2"
                          :active-picker.sync="activePicker2"                          
                          :min="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                          @change="save2"
                          class="rounded-8"
                        ></v-date-picker>
                        <!-- :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)" -->
                      </v-menu>
                    </v-col> 
                </v-row>
                <div class="box-date py-3 mb-3 rounded-8" style="background: linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), #3E66FB;">
                  <div class="row">
                    <div class="col-md-6 text-center">
                      <p class="font-weight-medium">Start Date</p>
                      <p v-if="this.date2 !=null " class="primary--text">{{ dateStart(date2) }}</p>
                    </div>
                    <div class="col-md-6 text-center">
                      <p class="font-weight-medium">End Date</p>
                      <p v-if="this.date2 !=null && this.duration == '1'" class="primary--text">{{ dateMonth(date2) }}</p>
                      <p v-else-if="this.date2 !=null && this.duration == '3'" class="primary--text">{{ dateMonth3(date2) }}</p>
                      <p v-else-if="this.date2 !=null && this.duration == '6'" class="primary--text">{{ dateMonth6(date2) }}</p>
                      <p v-else-if="this.date2 !=null && this.duration == '12'" class="primary--text">{{ dateYear(date2) }}</p>
                    </div>
                  </div>
                </div>
                <v-btn
                  color="primary"
                  class="mb-3 rounded-8"
                  small
                  block
                  @click="send()"
                >
                  Quotation Request
                </v-btn>
                <v-btn
                  outlined
                  class="mb-3 rounded-8"
                  small
                  block
                >
                  Ask for Enquiries
                </v-btn>
                <v-btn
                  text
                  small
                  block
                  color="primary"
                  class="mb-3"
                >
                  Print Factsheet
                </v-btn>
              </v-form>
            </v-card>
          </div>        
        </div>
      </div>
      <v-snackbar top v-model="snackbar.state" color="primary" text>
        <!-- :timeout="timeout" -->
        <!-- <v-alert
              type="info"
              text
              dense                            
              >
                
        <div class="color-black font-weight-bold" v-html="snackbar.text"></div>
        <div class="color-black" v-html="snackbar.content"></div>
              </v-alert
            > -->
        <!-- <v-icon>ri-info</v-icon> -->
        <div class="color-black font-weight-bold" v-html="snackbar.text"></div>
        <div class="color-black" v-html="snackbar.content"></div>
        <template v-slot:action="{ attrs }">
          <v-btn small icon color="primary" v-bind="attrs" @click="snackbar.state = false">
            <v-icon>$close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <!-- <v-snackbar
      :timeout="-1"
      :value="true"
      absolute
      bottom
      color="primary"
      left
      text
    >
      Lorem ipsum dolor sit amet consectetur.
    </v-snackbar> -->
    </section>
  </div>
</template>

<script>
  import moment from "moment";
export default {
  data: () => ({
    items: [
        {
          text: 'Home',
          disabled: false,
          href: '/',
        },
        {
          text: 'Building',
          disabled: true,
          href: 'breadcrumbs_link_1',
        }
      ],
    month:[
     {
        id:'1',
        name:'1 Month',
        value:'1'
     },
     {
        id:'2',
        name:'3 Months',
        value:'3'
     },
     {
        id:'3',
        name:'6 Months',
        value:'6'
     },
     {
        id:'4',
        name:'12 Months',
        value:'12'
     }
    ],
    duration:'1',
    activePicker: null,
    date: null,
    dateEnd:null,
    menu: false,
    snackbar: {
        state: false,
        text: "",
        content: "",
      },
  activePicker2: null,
    date2: null,
    dateEnd2:null,
    menu2: false,
  }),
  watch: {
    menu (val) {
        val && setTimeout(() => (this.activePicker = 'YEAR'))
      },
    menu2 (val) {
        val && setTimeout(() => (this.activePicker2 = 'YEAR'))
      },
  },
  methods: {
    save (date) {
      this.$refs.menu.save(date)
    },
    save2 (date) {
      this.$refs.menu2.save(date)
    },
    dateMonth(a) {
      let currentDate = moment(a);
      let futureYear = moment(currentDate).add(1, 'M');
      return moment(futureYear).format("MMMM D, YYYY");
    },
    dateMonth3(a) {
      let currentDate = moment(a);
      let futureYear = moment(currentDate).add(3, 'M');
      return moment(futureYear).format("MMMM D, YYYY");
    },
    dateMonth6(a) {
      let currentDate = moment(a);
      let futureYear = moment(currentDate).add(6, 'M');
      return moment(futureYear).format("MMMM D, YYYY");
    },
    dateYear(a) {
      let currentDate = moment(a);
      let futureYear = moment(currentDate).add(1, 'Y');
      return moment(futureYear).format("MMMM D, YYYY");
    },
    dateStart(a) {
      return moment(a).format("MMMM D, YYYY");
    },
    send(){
      this.snackbar.text = 'Request sent';
      this.snackbar.content = 'Your request has been accepted';      
      this.snackbar.state = true;
    }
  },
};
</script>