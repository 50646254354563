require('./lib');
import './assets/style.css';

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import i18n from './lang';
import store from './store';
import vuetify from './lib/vuetify';
import * as VueGoogleMaps from 'vue2-google-maps';
import VueLazyload from 'vue-lazyload';
import VueCookies from 'vue-cookies-reactive';
import firebaseMessaging from './firebase';
import VueDrift from 'vue-drift';

Vue.use(VueDrift, {
  appId: '6iwftwxtrf6i',
  // development: true,
});

if (firebaseMessaging.messaging.isSupported()) {
  const messaging = firebaseMessaging.messaging();

  messaging.usePublicVapidKey(
    'BNLhtk7YbjIaN6rw9Vee84ANPbpE0rtYGatxEW6xtfq62kYJ9b-SVtz9aWdfZ2CPE_mFclUs3knHKHj6lQLtmAA'
  );
  Vue.prototype.$firebaseIsSupported = true;
  Vue.prototype.$messaging = messaging;
  // Vue.prototype.$registration = []
  navigator.serviceWorker
    .getRegistrations()
    .then((registrations) => {
      // console.log("registrations", registrations);
      if (registrations.length == 0) {
        navigator.serviceWorker
          .register('/firebase-messaging-sw.js')
          .then(async (registration) => {
            // await messaging.usePublicVapidKey(
            //   "BNLhtk7YbjIaN6rw9Vee84ANPbpE0rtYGatxEW6xtfq62kYJ9b-SVtz9aWdfZ2CPE_mFclUs3knHKHj6lQLtmAA"
            // );
            /* messaging.subscribeTokenToTopic(registration, "test")
              .then((response) => {
                // See the MessagingTopicManagementResponse reference documentation
                // for the contents of response.
                console.log('Successfully subscribed to topic:', response);
              })
              .catch((error) => {
                console.log('Error subscribing to topic:', error);
              }); */
            await messaging.useServiceWorker(registration);
            Vue.prototype.$registration = registration;
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        Vue.prototype.$registration = registrations;
      }
    })
    .catch((err) => {
      console.log(err);
    });
  /* navigator.serviceWorker.addEventListener('message', event => {
      console.log('Push Notification Recieved', event) // refresh code goes here  
    }) */
  /* navigator.serviceWorker
      .register("firebase-messaging-sw.js")
      .then((registration) => {
        console.log(registration);
        const messaging = messaging;
        messaging.useServiceWorker(registration);
      })
      .catch((err) => {
        console.log(err);
      }); */
} else {
  Vue.prototype.$firebaseIsSupported = false;
}

Vue.config.productionTip = false;
Vue.use(require('vue-moment'));
Vue.use(VueCookies);
// Vue.$cookies.set('kinsan_lang','en');
Vue.use(VueLazyload);
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBNQK9KCjbq3MyGrJCMdu1_vbLNWy91osY',
    libraries: 'places',
  },
  installComponents: true,
});

Vue.prototype.$tData = (data, key) => {
  if (i18n.locale == 'jp') {
    return data[key + '_ja'] || data[key];
  }
  return data[key];
};

(async () => {
  await store.dispatch('get_user');
  new Vue({
    i18n,
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount('#app');
})();
