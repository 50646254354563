import axios from "axios";
import url from "./api";

// const offline = false

const state = () => ({
  // user: null,
  status: "",
});

const mutations = {
  /* SET_USER(state, user) {
    state.user = user;
  },
  RESET_USER(state) {
    state.user = null;
  }, */
  SET_STATUS(state, status) {
    state.status = status;
  },
};

const actions = {
  async getHomepage({ commit }, data) {
    /* let user = JSON.parse(localStorage.getItem("ytc_kinsan_auth"));

    if (!user) {
      localStorage.removeItem("ytc_kinsan_auth");
      return;
    }

    let token = user.token;
    // let bearer = `Bearer ${token}`;

    axios.defaults.headers.common["Authorization"] = token; */

    return axios
      .get(url.homepage, {params: data})
      .then((res) => res.data)
      .then((res) => {
        commit("SET_STATUS", "success");
        return res.data
      })
      .catch((error) => {
        console.error(error);
      });
  },
  async getLocation({ commit }, data) {
    return axios
      .get(url.location, {params: data})
      .then((res) => res.data)
      .then((res) => {
        commit("SET_STATUS", "success");
        return res.data
      })
      .catch((error) => {
        console.error(error);
      });
  },
  async getMediatype({ commit }, data) {
    return axios
      .get(url.media_type, {params: data})
      .then((res) => res.data)
      .then((res) => {
        commit("SET_STATUS", "success");
        return res.data
      })
      .catch((error) => {
        console.error(error);
      });
  },
  async getTargetmarket({ commit }, data) {
    return axios
      .get(url.target_market, {params: data})
      .then((res) => res.data)
      .then((res) => {
        commit("SET_STATUS", "success");
        return res.data
      })
      .catch((error) => {
        console.error(error);
      });
  },
  async getCategory({ commit }, data) {
    return axios
      .get(url.category, {params: data})
      .then((res) => res.data)
      .then((res) => {
        commit("SET_STATUS", "success");
        return res.data
      })
      .catch((error) => {
        console.error(error);
      });
  },
  async getMedia({ commit }, data) {
    return axios
      .get(url.media, {params: data})
      .then((res) => res.data)
      .then((res) => {
        commit("SET_STATUS", "success");
        return res.data
      })
      .catch((error) => {
        console.error(error);
      });
  },
  async getMediaNearby({ commit }, data) {
    return axios
      .get(url.media_nearby, {params: data})
      .then((res) => res.data)
      .then((res) => {
        commit("SET_STATUS", "success");
        return res.data
      })
      .catch((error) => {
        console.error(error);
      });
  },
  async inquiry({ commit }, data) {
    return axios
      .post(url.inquiry, data)
      .then((res) => res.data)
      .then(() => {
        commit("SET_STATUS", "success");
        return "success";
      }) 
  },
};

const getters = {
  /* user: (state) => state.user,
  isLoggedIn: (state) => !!state.user && !!state.user.token, */
};

export default { state, mutations, actions, getters };
