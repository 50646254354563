export default {
  add_new: "Add New",
  approve: "Approve",
  cancel: "Cancel",
  close: "Close",
  delete: "Delete",
  download: "Download",
  login: "Login",
  logout: "Logout",
  ok: "OK",
  reject: "Reject",
  reject_2: "Reject",
  remove: "Remove",
  replace: "Replace",
  save: "Save",
  search: "Search",
  signup: "Signup",
  submit: "Submit",
  take_down: "Take Down",
  upload: "Upload",
  update: "Update",
  view: "View",
};
