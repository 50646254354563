<template>
  <v-row align-content="center">
    <v-col class="" md="12" style="min-height: 80vh">
      <v-row>
        <v-col class="bg-transparent mx-auto" md="4">
          <v-spacer class="py-5"></v-spacer>
          <v-spacer class="py-5"></v-spacer>
          <v-form
            class="px-8 mb-3"
            @submit.prevent="submit"
            lazy-validation
            ref="form"
            v-model="valid"
          >
            <div class="fs-32 font-weight-bold text-center">{{ $t('new_password') }}</div>
            <p class="text-center mb-5">{{ $t('enter_new_password_to_continue') }}</p>
            <v-alert
              type="error"
              text
              dense
              prominent
              v-show="responseFail !== null"
              >{{ responseFail }}</v-alert
            >
            <v-alert
              type="success"
              text
              dense
              prominent
              v-show="responseSuccess !== null"
              v-html="responseSuccess"
              ></v-alert
            >
            <v-row>
              <v-col>
                <div class="mb-2">
                  <span class="font-weight-bold">{{ $t('new_password') }}</span>                  
                </div>
                <v-text-field
                  v-model="password"
                  :rules="pswRules"
                  class="rounded-8"                  
                  outlined
                  hide-details="auto"
                  prepend-inner-icon="ri-key-fill"
                  required
                  :type="show ? 'text' : 'password'"
                  :append-icon="show ? 'ri-eye-fill' : 'ri-eye-off-fill'"
                  @click:append="show = !show"
                ></v-text-field>
              </v-col>
            </v-row>
            
            <v-row>
              <v-col cols="12" class="">
                <v-btn
                  
                  elevation="0"
                  type="submit"
                  color="primary"
                  :loading="submiting"
                  class="rounded-8"
                  block
                >
                  {{ $t('reset_password') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
          
          <v-spacer class="py-3"></v-spacer>
          <v-spacer class="py-5"></v-spacer>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "login",
  data() {
    return {
      show: false,
      errors: [],
      valid: true,
      email: "",
      password: "",
      emailRules: [
        v => !!v || this.$t('email_is_required'),
        v => /.+@.+\..+/.test(v) || this.$t('email_must_be_valid'),
      ],
      pswRules: [
        (v) => !!v || this.$t('password_is_required'),
        (v) =>  (v && /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#^$!%*?&])[A-Za-z\d@#^$!%*?&]{8,}$/.test(v)) || this.$t('password_must_has_at_least_8_characters_that_include_at_least_1_lowercase_character_1_uppercase_character_1_number_and_1_special_character'),
      ],
      submiting: false,
      responseFail: null,
      responseSuccess: null,
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  mounted() {
    if (!this.$route.query.token) {
      this.$router.push("/");
    }
  },
  methods: {
    submit() {
      this.submiting = true;
      this.responseFail = null;
      this.responseSuccess = null;
      if (this.$refs.form.validate()) {
        let token = this.$route.query.token;
        let password = this.password;
        this.$store
          .dispatch("resetPassword", { token, password })
          .then(async (result) => {
            this.check_postal_code = false;
            if (result) {
              this.password = "";
              this.responseSuccess = "Success! Click <a href='/login'>here</a> to login.";
            }
            this.submiting = false;
          })
          .catch((error) => {
            this.responseFail = (error.response.data.message).replace("body should have ", "").replace("body.", "");
            this.submiting = false;
          });
        // this.$router.push("/reset-password");
      } else{
        this.submiting=false;
      }
    },
    
  },
};
</script>

<style>

</style>
