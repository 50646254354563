import axios from "axios";
import url from "./api";

// const offline = false

const state = () => ({
  // user: null,
  status: "",
});

const mutations = {
  /* SET_USER(state, user) {
    state.user = user;
  },
  RESET_USER(state) {
    state.user = null;
  }, */
  SET_STATUS(state, status) {
    state.status = status;
  },
};

const actions = {
  /* async login({ commit }, user) {
    if (offline) {
        if (user.email == 'user@gmail.com' && user.password == 'user') {
          const token = 'Bearer ' + 'token'
          const user = user
          const status ='member'
          sessionStorage.setItem('token', token)
          axios.defaults.headers.common['Authorization'] = token
          commit('SET_USER', { token: token, user: user , status: status})
        }
        else if (user.email == 'owner@gmail.com' && user.password == 'owner') {
          const token = 'Bearer ' + 'token'
          const user = user
          const status ='owner'          
          sessionStorage.setItem('token', token)
          axios.defaults.headers.common['Authorization'] = token
          commit('SET_USER', { token: token, user: user, status: status })
        }
      } 
      else {
        // user.access_key = process.env.VUE_APP_ACCESS_KEY;
        return axios
          .post(url.user_login, user)
          .then((res) => res.data)
          .then((res) => {
            let user = res.data;
            // let token = `Bearer ${user.token}`;
            let token = user.token;

            axios.defaults.headers.common["Authorization"] = token;
            localStorage.setItem("ytc_kinsan_auth", JSON.stringify(user));
            commit("SET_USER", user);
          })
      }
    // user.access_key = process.env.VUE_APP_ACCESS_KEY;
    // user.provider = "whatsapp";

    // return axios
    //   .post(url.user_login, user, {
    //     headers: { "Content-Type": "application/json" },
    //   })
    //   .then((res) => res.data)
    //   .then((res) => {
    //     let user = res.data;
    //     let token = `Bearer ${user.token}`;

    //     axios.defaults.headers.common["Authorization"] = token;
    //     localStorage.setItem("ytc_kinsan_auth", JSON.stringify(user));
    //     commit("SET_USER", user);
    //     return "success";
    //   });
  }, */

  /* async register({ commit }, data) {
    return axios
      .post(url.user_register, data)
      .then((res) => res.data)
      .then(() => {
        commit("SET_STATUS", "success");
        return "success";
      }) 
  },

  async updateProfile({ commit }, data) {
    return axios
      .post(url.user_update, data)
      .then((res) => res.data)
      .then(() => {
        commit("SET_STATUS", "success");
        return "success";
      }) 
  },

  async changePassword({ commit }, data) {
    return axios
      .post(url.change_password, data)
      .then((res) => res.data)
      .then(() => {
        commit("SET_STATUS", "success");
        return "success";
      }) 
  },

  async forgotPassword({ commit }, data) {
    return axios
      .post(url.forgot_password, data)
      .then((res) => res.data)
      .then(() => {
        commit("SET_STATUS", "success");
        return "success";
      }) 
  },

  async resetPassword({ commit }, data) {
    return axios
      .post(url.reset_password, data)
      .then((res) => res.data)
      .then(() => {
        commit("SET_STATUS", "success");
        return "success";
      }) 
  },

  async postCode({ commit }, data) {
    return axios
      .post(url.postcode, data)
      .then((res) => res.data)
      .then((res) => {
        commit("SET_STATUS", "success");
        return res;
      }) 
  }, */

  async createMedia({ commit }, data) {
    let user = JSON.parse(localStorage.getItem("ytc_kinsan_auth"));

    if (!user) {
      localStorage.removeItem("ytc_kinsan_auth");
      return;
    }

    let token = user.token;
    // let bearer = `Bearer ${token}`;

    axios.defaults.headers.common["Authorization"] = token;
    
    return axios
      .post(url.create_media, data)
      .then((res) => res.data)
      .then(() => {
        commit("SET_STATUS", "success");
        return "success";
      }) 
  },

  async updateMedia({ commit }, data) {
    return axios
      .put(url.update_media+"/"+data, {})
      .then((res) => res.data)
      .then((res) => {
        commit("SET_STATUS", "success");
        return res.data
      })
      .catch((error) => {
        console.error(error);
        // this.$router.push("/");
        // window.location.href = '/'
      });
  },

  async getTargetMarketOwner({ commit }, data) {
    let user = JSON.parse(localStorage.getItem("ytc_kinsan_auth"));

    if (!user) {
      localStorage.removeItem("ytc_kinsan_auth");
      return;
    }

    let token = user.token;
    // let bearer = `Bearer ${token}`;

    axios.defaults.headers.common["Authorization"] = token;

    return axios
      .get(url.target_market_owner, {params: data})
      .then((res) => res.data)
      .then((res) => {
        commit("SET_STATUS", "success");
        return res.data
      })
      .catch((error) => {
        console.error(error);
      });
  },

  async getMediaTypeOwner({ commit }, data) {
    let user = JSON.parse(localStorage.getItem("ytc_kinsan_auth"));

    if (!user) {
      localStorage.removeItem("ytc_kinsan_auth");
      return;
    }

    let token = user.token;
    // let bearer = `Bearer ${token}`;

    axios.defaults.headers.common["Authorization"] = token;

    return axios
      .get(url.media_type_owner, {params: data})
      .then((res) => res.data)
      .then((res) => {
        commit("SET_STATUS", "success");
        return res.data
      })
      .catch((error) => {
        console.error(error);
      });
  },

  async getMediaOwner({ commit }, data) {
    let user = JSON.parse(localStorage.getItem("ytc_kinsan_auth"));

    if (!user) {
      localStorage.removeItem("ytc_kinsan_auth");
      return;
    }

    let token = user.token;
    // let bearer = `Bearer ${token}`;

    axios.defaults.headers.common["Authorization"] = token;

    return axios
      .get(url.media_owner, {params: data})
      .then((res) => res.data)
      .then((res) => {
        commit("SET_STATUS", "success");
        return res.data
      })
      .catch((error) => {
        console.error(error);
      });
  },

  async getDetailMediaOwner({ commit }, data) {
    let user = JSON.parse(localStorage.getItem("ytc_kinsan_auth"));

    if (!user) {
      localStorage.removeItem("ytc_kinsan_auth");
      return;
    }

    let token = user.token;
    // let bearer = `Bearer ${token}`;

    axios.defaults.headers.common["Authorization"] = token;

    return axios
      .get(url.detail_media_owner+"/"+data, {})
      .then((res) => res.data)
      .then((res) => {
        commit("SET_STATUS", "success");
        return res.data
      })
      .catch((error) => {
        console.error(error);
      });
  },

  async getDetailMedia({ commit }, data) {
    return axios
      .get(url.detail_media+"/"+data, {})
      .then((res) => res.data)
      .then((res) => {
        commit("SET_STATUS", "success");
        return res.data
      })
      .catch((error) => {
        console.error(error);
        // this.$router.push("/");
        window.location.href = '/'
      });
  },
};

const getters = {
  /* user: (state) => state.user,
  isLoggedIn: (state) => !!state.user && !!state.user.token, */
};

export default { state, mutations, actions, getters };
