export default {
  title: 'Media',
  all_media: 'All Media',
  activated: 'Activated Media',
  pending: 'Waiting for Review',
  rejected: 'Rejected Media',
  deactivated: 'Media Deactivated',
  search: 'Search Media',
  blank_title: 'No Media',
  reason_rejected: 'Reason for rejected',
  minimum_price: 'Start from {price}',
  activated_feedback_title: 'Media Activated',
  activated_feedback_subtitle: 'The media has been activated successfully',
  rejected_feedback_title: 'Media Rejected',
  rejected_feedback_subtitle: 'The media has been rejected successfully',
  deactivated_feedback_title: 'Success deactivate media',
  deactivated_feedback_subtitle: 'The media has been deactivated successfully',
  category_updated_feedback_title: "Media Category's Updated",
  category_updated_feedback_subtitle: "The media category's has been updated successfully",
};
