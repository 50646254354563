<template>
  <!-- <v-row> -->
  <v-col md="12" class="mx-auto">
    <v-row>
      <v-col cols="12" class="bg-transparent" md="12">
        <div class="row">
          <div class="col-md-12 pb-0">
            <v-breadcrumbs :items="items" large class="px-0 pt-0"></v-breadcrumbs>
          </div>
          <div class="col-md-12 pt-0">
            <!-- <div class="fs-20 font-weight-bold">{{ $t('update_media') }}</div> -->
            <div class="fs-20 font-weight-bold">{{ $t('update_media') }}</div>
          </div>
        </div>
      </v-col>
      <v-col cols="12" class="bg-transparent py-0" md="12">
        <v-spacer class="py-3"></v-spacer>

        <v-alert type="error" text dense prominent v-if="response" v-html="response"></v-alert>
        <v-alert type="success" text dense prominent v-if="responsesukses">
          {{ $t('success_update_product') }}
        </v-alert>
        <v-form
          id="create_media"
          lazy-validation
          class="mb-3"
          @submit.prevent="submit"
          ref="form"
          v-model="valid"
        >
          <div class="row mb-5">
            <div class="col-md-4">
              <p class="fs-20 font-weight-medium mb-2">{{ $t('basic_info') }}</p>
              <p class="color-state">{{ $t('information_related_to_the_specification') }}</p>
            </div>
            <div class="col-md-8">
              <v-row>
                <v-col class="pb-1">
                  <div class="mb-2">
                    <span class="font-weight-medium">{{ $t('media_type') }}</span>
                  </div>
                  <v-autocomplete
                    @change="mediaTypeChild"
                    :rules="[...rules('Media Type', 'required'), validate]"
                    required
                    :items="list_media_type"
                    :item-text="(v) => $tData(v, 'title')"
                    item-value="_id"
                    :placeholder="$t('choose_media_type')"
                    v-model="detail_media.media_type"
                    outlined
                    dense
                    class="rounded-8 mediaType"
                  ></v-autocomplete>
                  <v-text-field
                    outlined
                    hide-details="auto"
                    class="rounded-8 mb-5"
                    v-for="(item, index) in detail_media.media_type.property"
                    v-model="detail_media.media_type.property[index].value"
                    :rules="[
                      ...rules(
                        $i18n.locale == 'jp' ? item.title_ja : item.title,
                        item.is_required ? 'required' : ''
                      ),
                      validate,
                    ]"
                    :key="index"
                    :required="item.is_required"
                    :placeholder="$i18n.locale == 'jp' ? item.title_ja : item.title"
                  ></v-text-field>

                  <v-text-field
                    outlined
                    hide-details="auto"
                    class="rounded-8 mb-5"
                    v-for="(item, index) in media_type_child"
                    v-model="media_type_child_value[index]"
                    :rules="[
                      ...rules(
                        $i18n.locale == 'jp' ? item.title_ja : item.title,
                        item.is_required ? 'required' : ''
                      ),
                      validate,
                    ]"
                    :key="index"
                    :required="item.is_required"
                    :placeholder="$i18n.locale == 'jp' ? item.title_ja : item.title"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-1">
                  <div class="mb-2">
                    <span class="font-weight-medium">{{ $t('media_name') }}</span>
                  </div>
                  <v-text-field
                    :rules="[...rules('Media Name', 'required'), validate]"
                    required
                    class="rounded-8"
                    v-model="detail_media.title"
                    placeholder=""
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-1">
                  <div class="mb-2">
                    <span class="font-weight-medium">{{ $t('orientation') }}</span>
                  </div>
                  <v-select
                    :rules="[...rules('Orientation', 'required'), validate]"
                    required
                    :items="oriens"
                    :item-text="$i18n.locale == 'jp' ? 'text_jp' : 'text_en'"
                    :item-value="'val'"
                    :placeholder="$t('choose_orientation')"
                    v-model="detail_media.orientation"
                    outlined
                    dense
                    class="rounded-8"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-1">
                  <div class="mb-2">
                    <span class="font-weight-medium">{{ $t('unit_of_measurement') }}</span>
                  </div>
                  <v-radio-group
                    :rules="[...rules('Unit of Measurement', 'required'), validate]"
                    required
                    v-model="detail_media.unit_of_measurement.type"
                    row
                  >
                    <v-radio :label="$t('meter')" :value="$t('meter')"></v-radio>
                    <v-radio :label="$t('centimeter')" :value="$t('centimeter')"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <div class="mb-2">
                    <span class="font-weight-medium">{{ $t('height') }}</span>
                    <span class="float-right color-disable text-decoration-none">
                      {{ measurement }}
                    </span>
                  </div>
                  <v-text-field
                    type="number"
                    :rules="[...rules('Height', 'required'), validate]"
                    required
                    v-model="detail_media.unit_of_measurement.height"
                    outlined
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <div class="mb-2">
                    <span class="font-weight-medium">{{ $t('width') }}</span>
                    <span class="float-right color-disable text-decoration-none">
                      {{ measurement }}
                    </span>
                  </div>
                  <v-text-field
                    type="number"
                    :rules="[...rules('Width', 'required'), validate]"
                    required
                    v-model="detail_media.unit_of_measurement.width"
                    outlined
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-1">
                  <div class="mb-2">
                    <span class="font-weight-medium">{{ $t('lighting') }}</span>
                  </div>
                  <v-radio-group
                    :rules="[...rules('Lighting', 'required'), validate]"
                    required
                    v-model="detail_media.is_lighting"
                    row
                  >
                    <v-radio :label="$t('have_lighting')" value="true"></v-radio>
                    <v-radio :label="$t('no_lighting')" value="false"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12">
                  <div class="mb-2">
                    <span class="font-weight-medium">{{ $t('minimum_price_per_month') }}</span>
                  </div>
                  <v-text-field
                    type="number"
                    :rules="[...rules('Minimum Price', 'required'), validate]"
                    required
                    v-model="detail_media.minimum_price"
                    outlined
                    class="rounded-8"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-1">
                  <div class="mb-2">
                    <span class="font-weight-medium">{{ $t('discount') }}</span>
                    <span class="float-right color-disable text-decoration-none"> % </span>
                  </div>
                  <v-text-field
                    type="number"
                    v-model="detail_media.discount"
                    class="rounded-8"
                    placeholder=""
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-1">
                  <div class="mb-2">
                    <span class="font-weight-medium">{{ $t('description') }}</span>
                  </div>
                  <v-textarea
                    :rules="[...rules('Description', 'required'), validate]"
                    required
                    v-model="detail_media.description"
                    class="rounded-8"
                    placeholder=""
                    outlined
                    hide-details="auto"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-1">
                  <div class="mb-2">
                    <span class="font-weight-medium">{{ $t('traffic') }}</span>
                  </div>
                  <v-text-field
                    v-model="detail_media.traffic"
                    class="rounded-8"
                    placeholder=""
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
          </div>
          <div class="row mb-5">
            <div class="col-md-4">
              <p class="fs-20 font-weight-medium mb-2">{{ $t('target_market') }}</p>
              <p class="color-state">{{ $t('targeted_segment_related_to_the_media') }}</p>
            </div>
            <div class="col-md-8">
              <v-row>
                <v-col class="pb-1">
                  <div class="mb-2">
                    <span class="font-weight-medium">{{ $t('target_market') }}</span>
                  </div>
                  <v-autocomplete
                    :rules="[...rules('Target Market', 'required'), validate]"
                    required
                    multiple
                    :items="list_target_market"
                    :item-text="(v) => $tData(v, 'title')"
                    item-value="_id"
                    :placeholder="$t('choose_target_market')"
                    v-model="detail_media.target_market"
                    outlined
                    dense
                    class="rounded-8"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </div>
          </div>
          <div class="row mb-5">
            <div class="col-md-4">
              <p class="fs-20 font-weight-medium mb-2">{{ $t('photo_video') }}</p>
            </div>
            <div class="col-md-8">
              <v-row>
                <v-col class="pb-1">
                  <div class="mb-2">
                    <span class="font-weight-medium">{{ $t('photo') }}</span>
                  </div>

                  <v-row dense class="mb-3">
                    <div
                      class="col-md-6"
                      v-for="(item, index) in detail_media.photo"
                      :key="'A' + index"
                    >
                      <v-card>
                        <v-img
                          :src="item"
                          class="white--text align-end"
                          gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                          height="200px"
                        >
                        </v-img>

                        <v-card-actions>
                          <v-spacer></v-spacer>

                          <v-btn
                            small
                            elevation="0"
                            color="primary"
                            outlined
                            class="rounded-8"
                            @click="delPhoto(index)"
                          >
                            <v-icon class="mr-1">ri-delete-bin-6-line</v-icon>
                            Delete
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                      <!-- <img :src="item" style="width: 400px;height: 200px;object-fit: contain;">
                        <v-btn small elevation="0" color="primary" class="rounded-8">
                          <v-icon>ri-delete-bin-6-line</v-icon>
                        </v-btn> -->
                    </div>
                  </v-row>

                  <file-pond
                    name="photo"
                    v-bind:required="true"
                    credits="false"
                    class-name="my-pond"
                    ref="photo"
                    :label-idle="
                      `<div><div class='text-center mb-2'><i aria-hidden='true' class='v-icon notranslate ri ri-download-cloud-fill theme--light' style='font-size: 40px;'></i></div>` +
                      $t('drag_drop_your_files_or') +
                      ` <span class='primary--text'><span class=''>` +
                      $t('browse') +
                      `</span></span></div>`
                    "
                    v-bind:allow-multiple="true"
                    v-bind:files="photo"
                    v-on:init="handleFilePondInit"
                    accepted-file-types="image/gif,image/jpeg,image/png,image/bmp,image/jpg"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-1">
                  <div class="mb-2">
                    <span class="font-weight-medium">{{ $t('video') }}</span>
                    <span class="float-right color-disable text-decoration-none">
                      {{ $t('link_youtube') }}
                    </span>
                  </div>
                  <v-text-field
                    :rules="[...rules('Video', ''), validate]"
                    required
                    class="rounded-8"
                    v-model="detail_media.video"
                    placeholder=""
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
          </div>
          <div class="row mb-5">
            <div class="col-md-4">
              <p class="fs-20 font-weight-medium mb-2">{{ $t('location') }}</p>
            </div>
            <div class="col-md-8">
              <v-row>
                <v-col class="pb-1">
                  <div class="mb-2">
                    <span class="font-weight-medium">{{ $t('location') }}</span>
                  </div>
                  <gmap-autocomplete
                    style="
                      display: block;
                      line-height: 20px;
                      padding: 14px;
                      max-width: 100%;
                      min-width: 0 px;
                      width: 100%;
                      border: 1px solid rgba(0, 0, 0, 0.38);
                      border-radius: 8px;
                    "
                    :placeholder="$t('search_location')"
                    @place_changed="setPlace"
                    :value="detail_media.location.map_address"
                    :options="{ fields: ['geometry', 'formatted_address', 'address_components'] }"
                  >
                  </gmap-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-1">
                  <div class="mb-2">
                    <span class="font-weight-medium">{{ $t('address') }}</span>
                  </div>
                  <v-text-field
                    class="rounded-8"
                    v-model="detail_media.location.address"
                    placeholder=""
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-1">
                  <div class="mb-2"></div>
                  <div id="map">
                    <GmapMap
                      :center="center"
                      :zoom="10"
                      map-style-id="roadmap"
                      :options="mapOptions"
                      style="width: 100%; height: 50vmin"
                      ref="mapRef"
                      @click="handleMapClick"
                    >
                      <GmapMarker
                        :position="marker.position"
                        :clickable="true"
                        :draggable="true"
                        @drag="handleMarkerDrag"
                        @click="panToMarker"
                      />
                    </GmapMap>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-1">
                  <div class="mb-2">
                    <span class="font-weight-medium">{{ $t('latitude') }}</span>
                  </div>
                  <v-text-field
                    :rules="[...rules('Latitude', 'required'), validate]"
                    required
                    readonly
                    class="rounded-8"
                    v-model="this.detail_media.location.coordinates[1]"
                    placeholder=""
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-1">
                  <div class="mb-2">
                    <span class="font-weight-medium">{{ $t('longitude') }}</span>
                  </div>
                  <v-text-field
                    :rules="[...rules('Longitude', 'required'), validate]"
                    required
                    readonly
                    class="rounded-8"
                    v-model="this.detail_media.location.coordinates[0]"
                    placeholder=""
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-1">
                  <div class="mb-2">
                    <span class="font-weight-medium">{{ $t('document') }}</span>
                  </div>

                  <v-row dense class="mb-3">
                    <div
                      class="col-md-12"
                      v-for="(item, index) in detail_media.document"
                      :key="'B' + index"
                    >
                      <v-card elevation="2" outlined class="pa-2">
                        <v-card-actions>
                          <v-row align="center" justify="space-between" style="width: 100%">
                            <span class="text-body-1 mb-0 text-truncate" style="width: 85%">
                              <a :href="item" target="_blank">{{ item }}</a>
                              <!-- {{item}} -->
                            </span>
                            <v-btn fab small color="primary" @click="delDoc(index)" class="">
                              <v-icon class="">ri-delete-bin-6-line</v-icon>
                            </v-btn>
                          </v-row>
                        </v-card-actions>
                      </v-card>
                    </div>
                  </v-row>

                  <file-pond
                    name="document"
                    credits="false"
                    class-name="my-pond"
                    ref="document"
                    :label-idle="
                      `<div><div class='text-center mb-2'><i aria-hidden='true' class='v-icon notranslate ri ri-download-cloud-fill theme--light' style='font-size: 40px;'></i></div>` +
                      $t('drag_drop_your_files_or') +
                      ` <span class='primary--text'><span class=''>` +
                      $t('browse') +
                      `</span></span></div>`
                    "
                    v-bind:allow-multiple="true"
                    v-bind:files="document"
                    v-on:init="handleFilePondInit"
                    accepted-file-types="application/pdf"
                  />
                </v-col>
              </v-row>
            </div>
          </div>
          <!-- <v-dialog v-model="is_delete.show" max-width="600px">
            <v-card>
              <v-card-title>
                <span> <v-icon>$warning</v-icon> Delete Media </span>
              </v-card-title>
              <v-divider></v-divider>
              <div>
                <v-card-text>
                  Are you sure delete media ?
                </v-card-text>            
              </div>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn outlined color="primary" small elevation="0" @click="is_delete.show=false;">
                  Cancel
                </v-btn>
                <v-btn
                  class="white--text"
                  color="primary"
                  small
                  elevation="0"
                  :loading="submitting"
                  @click="getDelete(is_delete.id)"
                >
                  Yes, Sure!
                </v-btn>            
              </v-card-actions>
            </v-card>
          </v-dialog> -->
          <v-row>
            <v-col cols="12" class="text-end align-self-end mt-4">
              <v-btn to="/list-media" small elevation="0" class="rounded-8 mr-2" outlined>
                {{ $t('cancel') }}
              </v-btn>
              <v-btn
                small
                elevation="0"
                type="submit"
                color="primary"
                :loading="submiting"
                class="rounded-8"
              >
                {{ $t('save') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
        <v-spacer class="py-3"></v-spacer>
      </v-col>
    </v-row>
  </v-col>
  <!-- </v-row> -->
</template>

<script>
import validationMixin from '../validation/valid';

// Import Vue FilePond
import vueFilePond from 'vue-filepond';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';

// Import FilePond plugins
// Please note that you need to install these plugins separately

// Import image preview plugin styles
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';

// Import image preview and file type validation plugins
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';

// Create component
const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview);

export default {
  components: {
    FilePond,
  },
  mixins: [validationMixin],
  name: 'update-media',
  data() {
    return {
      detail_media: {},
      files: [],
      marker: {
        position: {
          lat: 35.652832,
          lng: 139.839478,
        },
      },
      center: {
        lat: 35.652832,
        lng: 139.839478,
      },

      mapOptions: {
        disableDefaultUI: true,
      },
      items: [
        {
          text: 'List Media',
          disabled: false,
          href: '/list-media',
        },
        {
          text: this.$t('update_media'),
          disabled: true,
          href: 'breadcrumbs_link_1',
        },
      ],

      valid: true,
      errors: null,

      submiting: false,
      response: null,
      responsesukses: null,
      media_type: ['Building', 'Station', 'Magazine', 'Influencer', 'Transportation'],
      oriens: [
        { val: 'Horizontal', text_jp: '横', text_en: 'Horizontal' },
        { val: 'Vertical', text_jp: '縦', text_en: 'Vertical' },
      ],
      // oriens: ["Horizontal", "Vertical"],
      cat: ['Best of The Best', 'Popular', 'The Greatest One'],
      tar: ['CEO', 'Family', 'Doctor', 'Student'],
      month: [
        {
          id: '1',
          name: '1 Month',
          value: '1',
        },
        {
          id: '2',
          name: '3 Months',
          value: '3',
        },
        {
          id: '3',
          name: '6 Months',
          value: '6',
        },
        {
          id: '4',
          name: '12 Months',
          value: '12',
        },
      ],

      overlay: 0.1,
      overlay_color: 'secondary',

      orientation: '',
      minimum_price: '',
      traffic: '',
      discount: 0,
      description: '',
      height: '',
      width: '',
      type: '',
      measurement: null,
      lighting: null,
      target: '',
      name: '',
      city: '',
      category: '',
      link_video: '',
      loc: '',
      address: '',
      latitude: 35.652832,
      longitude: 139.839478,
      nearby: '',
      list_media_type: [],
      list_target_market: [],
      data: {},
      data_image: [],
      place: null,
      photo: [],
      document: [],
      media_type_child: [],
      media_type_child_value: [],
      media_type_selected: null,
      title_type: 'title',
      total_photo: {},
      total_doc: {},
      fix_photo: {},
    };
  },
  computed: {
    validate() {
      return !this.errors || this.errors.message;
    },
    file() {
      if (this.data.file instanceof File) {
        const file = this.data.file;
        const type = ['application/pdf'].includes(file.type) ? 'document' : 'file';
        const url = URL.createObjectURL(file);
        return {
          url,
          type,
        };
      } else {
        return this.data.file;
      }
    },
  },
  async created() {
    // this.orien.$set(0, await this.$t('horizontal'))
    // this.orien.$set(1, await this.$t('vertical'))
    /* this.orien[0] = await this.$t('horizontal');
      this.orien[1] = await this.$t('vertical'); */
  },
  async mounted() {
    await this.getMediaType();
    await this.getTargetMarket();
    await this.getDetailMedia(this.$route.params.id);

    this.items[0].text = await this.$t('list_media');
    if (this.$route.params?.id) {
      this.items[1].text = await this.$t('update_media');
    } else {
      this.items[1].text = await this.$t('add_new_media');
    }
    // this.orien.$set(0, await this.$t('horizontal'))
    // this.orien.$set(1, await this.$t('vertical'))
    // this.$refs.form.reset()
    this.$i18n.locale == 'jp' ? (this.title_type = 'title_ja') : (this.title_type = 'title');
  },
  methods: {
    delPhoto(id) {
      let index;

      index = this.detail_media.photo.findIndex((media) => media.id == id);
      if (index == -1) {
        index = id;
      }
      this.detail_media.photo.splice(index, 1);
    },
    delDoc(id) {
      let index;

      index = this.detail_media.document.findIndex((doc) => doc.id == id);
      if (index == -1) {
        index = id;
      }
      this.detail_media.document.splice(index, 1);
    },
    getDetailMedia(id) {
      this.loading = true;
      this.$store
        .dispatch('getDetailMediaOwner', id)
        .then((data) => {
          this.refresh = id;
          this.detail_media = data;
          this.loading = false;
          // this.setPlace(this.detail_media.location.city)
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    orien() {
      return [this.$t('horizontal'), this.$t('vertical')];
    },
    handleFilePondInit: function () {
      // console.log("FilePond has initialized");
      // FilePond instance methods are available on `this.$refs.pond`
    },
    uploadImageSuccess(formData, index, fileList) {
      console.log('data', formData, index, fileList);
      // Upload image api
      // axios.post('http://your-url-upload', formData).then(response => {
      //   console.log(response)
      // })
    },
    beforeRemove(index, done, fileList) {
      console.log('index', index, fileList);
      var r = confirm('remove image');
      if (r == true) {
        done();
      } else {
        console.log('error');
      }
    },
    editImage(formData, index, fileList) {
      console.log('edit data', formData, index, fileList);
    },
    dataChange(data) {
      console.log(data);
    },
    search(nameKey, myArray) {
      for (var i = 0; i < myArray.length; i++) {
        if (myArray[i].types[0] === nameKey) {
          return myArray[i];
        }
      }
    },
    setPlace(place) {
      let resultObject = this.search('locality', place.address_components);
      // console.log('resul',place);
      this.detail_media.location.map_address = place.formatted_address;
      this.detail_media.location.city = resultObject.long_name;
      this.detail_media.location.coordinates[1] = place.geometry.location.lat();
      this.detail_media.location.coordinates[0] = place.geometry.location.lng();
      this.place = place;
      this.marker.position = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      this.panToMarker();
    },
    usePlace() {
      if (this.place) {
        this.markers.push({
          position: {
            lat: this.place.geometry.location.lat(),
            lng: this.place.geometry.location.lng(),
          },
        });
        this.place = null;
      }
    },
    geolocate() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.marker.position = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };

        this.panToMarker();
      });
    },

    //sets the position of marker when dragged
    handleMarkerDrag(e) {
      this.latitude = e.latLng.lat();
      this.longitude = e.latLng.lng();
      this.marker.position = {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      };
    },

    //Moves the map view port to marker
    panToMarker() {
      this.$refs.mapRef.panTo(this.marker.position);
      // this.$refs.mapRef.setZoom(18);
    },

    //Moves the marker to click position on the map
    handleMapClick(e) {
      this.latitude = e.latLng.lat();
      this.longitude = e.latLng.lng();
      this.marker.position = {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      };
      // console.log(e);
    },
    dropFile(event) {
      this.overlay = 0.1;
      this.$set(this.data, 'file', event.dataTransfer.files[0]);
      this.$set(this.data.file, 'src', URL.createObjectURL(this.data.file));
    },
    dropFileImage(event) {
      this.overlay = 0.1;
      event.dataTransfer.files.map((item, index) => {
        this.$set(this.data_image[index], 'file', item);
        this.$set(
          this.data_image[index].file,
          'src',
          URL.createObjectURL(this.data_image[index].file)
        );
      });
    },
    changeFileImage() {
      // console.log(this.$refs.file_image.files);
      this.$refs.file_image.files.forEach((item, index) => {
        // this.data_image[index].file = item
        // this.data_image[index].src = URL.createObjectURL(this.data_image[index].file)
        this.$set(this.data_image, index, {});
        this.$set(this.data_image[index], 'file', item);
        this.$set(
          this.data_image[index].file,
          'src',
          URL.createObjectURL(this.data_image[index].file)
        );
      });
    },
    changeFile() {
      this.$set(this.data, 'file', this.$refs.file.files[0]);
      this.$set(this.data.file, 'src', URL.createObjectURL(this.data.file));
    },
    mediaTypeChild(val) {
      // console.log(`change with value: "${val}"`);
      // this.$refs.form.reset()
      // console.log(this.detail_media.media_type.property);
      // console.log(val)
      this.media_type_child = [];
      this.media_type_child_value = [];
      this.type = val;
      this.list_media_type.map((item) => {
        if (item._id == val) {
          this.media_type_selected = item;
          this.media_type_child = item.property;
        }
      });
      // console.log(this.media_type_child)
    },
    getTargetMarket() {
      this.$store
        .dispatch('getTargetMarketOwner', {})
        .then((data) => {
          this.list_target_market = data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getMediaType() {
      this.$store
        .dispatch('getMediaTypeOwner', {})
        .then((data) => {
          this.list_media_type = data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async upload(files) {
      return await Promise.all(
        files.map(async (file) => {
          let formData = new FormData();
          formData.append('file', file.file);
          return await this.$store.dispatch('upload', formData);
        })
      );
    },
    async submit() {
      if (this.detail_media.location.city == '') {
        this.submiting = false;
        this.response = this.$t('location_is_required');
        window.scrollTo(0, 0);
        return;
      }
      // if (this.photo.length == 0) {
      // if (this.$refs.photo.getFiles().length == 0) {
      //   this.submiting = false;
      //   this.response = this.$t('photo_is_required');
      //   window.scrollTo(0, 0);
      //   return
      // }
      this.responsesukses = null;
      this.response = null;
      this.submiting = true;

      if (this.$refs.form.validate()) {
        this.total_photo = await this.upload(this.$refs.photo.getFiles());
        this.total_doc = await this.upload(this.$refs.document.getFiles());

        if (this.media_type_child.length === 0) {
          this.media_type_selected = this.detail_media.media_type;
        } else {
          this.media_type_child.map((item, index) => {
            this.media_type_child[index].value = this.media_type_child_value[index];
          });
          this.media_type_selected.property = this.media_type_child;
        }

        const data = {
          title: this.detail_media.title,
          media_type_id: this.media_type_selected,
          orientation: this.detail_media.orientation,
          unit_of_measurement: {
            type: this.detail_media.unit_of_measurement.type,
            height: this.detail_media.unit_of_measurement.height,
            width: this.detail_media.unit_of_measurement.width,
          },
          is_lighting: this.detail_media.lighting,
          minimum_price: this.detail_media.minimum_price,
          discount: this.detail_media.discount,
          description: this.detail_media.description,
          traffic: this.detail_media.traffic,
          target_market_id: this.detail_media.target_market,
          photo: [...this.total_photo, ...this.detail_media.photo],
          video: this.detail_media.video,
          location: {
            map_address: this.detail_media.location.map_address,
            address: this.detail_media.location.address,
            coordinates: [
              this.detail_media.location.coordinates[0],
              this.detail_media.location.coordinates[1],
            ],
            city: this.detail_media.location.city,
            type: 'Point',
          },
          document: [...this.total_doc, ...this.detail_media.document],
        };

        this.axios
          .put(`/api/product/${this.$route.params.id}`, data)
          .then((result) => {
            // console.log(result)
            this.responsesukses = result.data.message;
            window.scrollTo(0, 0);
            setTimeout(() => {
              this.$router.push('/list-media');
            }, 5000);
            this.submiting = false;
          })
          .catch((response) => {
            this.response = response.response.data.message
              .replace('body should have ', '')
              .replace('body.', '');
            this.response = this.response.charAt(0).toUpperCase() + this.response.slice(1);
            window.scrollTo(0, 0);
            this.submiting = false;
          });
        this.submiting = false;
      } else {
        this.submiting = false;
        this.response = this.$t('failed');
        window.scrollTo(0, 0);
      }
    },
  },
};
</script>

<style>
/* .filepond--drop-label {
  border: 1px dashed rgba(0, 0, 0, 0.25);
} */
.filepond--file {
  background: rgba(0, 0, 0, 0.7);
}

.filepond--wrapper {
  border: 1px dashed rgba(0, 0, 0, 0.25);
}

.filepond--drop-label {
  background: #fff;
  height: 150px;
}

.filepond--panel-top {
  background: #fff;
}

.filepond--panel-center {
  background: #fff;
}

.filepond--panel-bottom {
  background: #fff;
}

.filepond--panel {
  background: #fff;
}

.my-pond {
  min-height: 150px;
  background: #fff;
  /* border: 1px dashed rgba(0, 0, 0, 0.25); */
}

/* .filepond--credits {
  display: none!important;
} */
.image-container {
  height: 268px !important;
  width: 100% !important;
}

.image-list-container {
  max-width: 100% !important;
  width: 100% !important;
}

.preview-image {
  height: 240px !important;
}

.show-image > img {
  max-height: 200px !important;
  max-width: 100% !important;
}

#my-strictly-unique-vue-upload-multiple-image {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

#my-strictly-unique-vue-upload-multiple-image > div {
  width: 100%;
}

/* #profile .v-text-field__details {
    display: none !important;
  } */

/* .mediaType .v-select__selection,
.v-select__selection--comma,
.v-select.v-text-field input {
  text-transform: uppercase!important;
} */
</style>
