const url = {
  // member/users
  user_login: '/api/auth/login',
  user_register: '/api/auth/register',
  forgot_password: '/api/auth/reset-token',
  reset_password: '/api/auth/reset-password',
  change_password: '/api/user/change-password',
  postcode: '/api/postcode',
  user_update: '/api/user/update',
  user_detail: '/api/profile',
  detail_page: '/api/page',
  faq: '/api/faq',
  homepage: '/api/homepage',
  location: '/api/location',
  category: '/api/category',
  target_market: '/api/targetmarket',
  media_type: '/api/media-type',
  media_type_owner: '/api/mediatype',
  target_market_owner: '/api/targetmarket',
  media: '/api/product',
  media_nearby: '/api/product-nearby',
  media_owner: '/api/product-owner',
  detail_media_owner: '/api/product-owner',
  detail_media: '/api/product',
  update_media: '/api/product',
  create_media: '/api/product',
  upload: '/api/upload',
  request_quotation: '/api/transaction/request-quotation',
  approve_quotation: '/api/transaction/approval-quotation',
  submit_contract: '/api/transaction/upload-contract',
  submit_contract_pay: '/api/transaction/upload-contract',
  submit_payment: '/api/transaction/second-payment',
  upload_media: '/api/transaction/upload-media',
  transaction: '/api/transaction',
  detail_transaction: '/api/transaction',
  inquiry: '/api/inquiry',
  notification: '/api/notification',
  

  // FORNEXT: Hanya untuk keperluan demo
  get_id: () => 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  }),
  success: (data) => ({
    status: 200,
    message: 'success',
    data,
    validation: {}
  }),
  error: (validation) => ({
    response: {
      data: {
        status: 400,
        message: 'error',
        data: {},
        validation
      }
    }
  }),
}

export default url