import { objectToObject } from '.';

const transaction = {
  link_video: 'Link Video',
  media_file_has_been_rejected:
    'Your lastest media has been rejected by admin, Please re-submit your media through the form below',
  payment_proof_history: 'Your Payment Proof History',
  your_signed_contract_history: 'Your Signed Contract History',
  status: {
    contract: {
      approved: 'Contract Approved',
      rejected: 'Contract Rejected',
      pending: 'Waiting Contract Approval',
      expired: 'Contract Expired',
    },
    payment_proof_first: {
      approved: 'First Payment Proof Approved',
      rejected: 'First Payment Proof Rejected',
      pending: 'Waiting First Payment Approval',
      expired: 'First Payment Expired',
    },
    media: {
      approved: 'Media Approved',
      rejected: 'Media Rejected',
      pending: 'Waiting Media Approval',
      expired: 'Media Expired',
    },
    payment_proof_second: {
      approved: 'Second Payment Proof Approved',
      rejected: 'Second Payment Proof Rejected',
      pending: 'Waiting Second Payment Approval',
      expired: 'Second Payment Expired',
    },
    approved: 'Approved',
    rejected: 'Rejected',
    waiting_contract: 'Waiting for Contract',
    waiting_quotation: 'Waiting for Quotation',
    pending_contract: 'Pending Contract',
    contract_rejected: 'Contract Rejected',
    contract_approved: 'Contract Approved',
    pending_quotation: 'Pending Quotation',
    media_approved: 'Media Approved',
    quotation_expired: 'Quotation Expired',
    media_installed: 'Media Installed',
    waiting_activation: 'Waiting for Activation',
    waiting_approval_media: 'Waiting for Approval Media',
    waiting_contract_approval: 'Waiting for Contract Approval',
    waiting_approval: 'Waiting for Approval',
    waiting_client_media: "Waiting for media's client",
    waiting_client_media_subtitle: 'The media will appear here after client uploaded the media',
    waiting_second_payment_proof: 'Waiting for 2nd payment proof',
    waiting_second_payment_proof_subtitle:
      'The payment proof will appear here after client uploaded the file',
    pending: 'Waiting for Approval',
    media_activated: 'Media Activated',
    complete: 'Complete',
    media_activated_subtitle: 'The media has been activated successfully',
    waiting_contract_subtitle: 'Client has been approved the quotation then request contract',
    waiting_quotation_subtitle: 'Please response quotation for {start_date} - {end_date}',
  },
  quotation_valid_until: 'The quotation above will be valid until %date% %keyA%',
  your_signed_contract_rejected:
    'Your signed contract file has been rejected, please re-check your file and re-upload',
  your_payment_rejected:
    'Your payment-proof file has been rejected, please re-check your file and re-upload',
};

export default objectToObject(transaction, 'transaction');
