<template>
  <div class="">
    <section id="filter" class="py-5" style="background:#f9fafa;">
      <!-- <div class=""> -->
        <div class="col-md-11 mx-auto">
          <h2 class="font-weight-bold mb-2">Find deals on advertising space here</h2>
          <p class="fs-14 color-state">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          <!-- <div class="col-md-12"> -->
            <div class="row mt-3">
              <div class="col-md-5 py-0">
                <v-autocomplete
                  :items="location"
                  outlined
                  placeholder="All location"
                  class=""
                  prepend-inner-icon="ri-map-pin-2-fill"
                ></v-autocomplete>
              </div>
              <div class="col-md-5 py-0">
                <v-autocomplete
                  :items="type"
                  outlined
                  placeholder="All type"
                  prepend-inner-icon="ri-picture-in-picture-line"                                                
                ></v-autocomplete>
              </div>              
                <div class="col-md-1 align-center text-center py-0 px-5">
                  <v-btn
                    depressed                  
                    block
                    color="primary"
                    class="rounded-8"
                    style="width: 150px;"
                    :to="{ name: 'serp', params: { id: 123 } }"
                  >
                    Search
                  </v-btn>
                </div>                
            </div>
          <!-- </div> -->
        <!-- </div> -->
      </div>
    </section>

    <!-- browser location -->
    <section class="location white py-5">
      <div class="col-md-11 mx-auto">
        <h2 class="font-weight-bold mb-5">Browse by Location</h2>
        <div class="location-1 mb-2">
          <div class="row">
            <div class="test col-md-6">
              <v-card elevation="0" height="320px" :to="{ name: 'serp', params: { id: 123 } }">
                <!-- <v-card-title>testing</v-card-title>               -->
                <img src="@/assets/images/billboard.jpeg" style="width: 100%;height: 320px;object-fit: cover;">
                <div class="top-left white--text pa-3">
                  <p class="fs-20 font-weight-medium mb-1 ts-title">Yuzawa</p>
                  <p class="">500 media</p>
                </div>
              </v-card>
            </div>
            <div class="test col-md-6">
              <v-card elevation="0" height="320px" :to="{ name: 'serp', params: { id: 123 } }">
                <!-- <v-card-title>testing</v-card-title>               -->
                <img src="@/assets/images/buil-4.jpeg" style="width: 100%;height: 320px;object-fit: cover;">
                <div class="top-left white--text pa-3">
                  <p class="fs-20 font-weight-medium mb-1 ts-title">Karuizawa</p>
                  <p class="">500 media</p>
                </div>
              </v-card>
            </div>
          </div>  
        </div>
        <div class="location-2">
          <div class="col-md-12 px-0">
            <div class="row">
              <div class="test col-md-4">
                <v-card elevation="0" height="320px" :to="{ name: 'serp', params: { id: 123 } }">
                  <!-- <v-card-title>testing</v-card-title> -->              
                  <img src="@/assets/images/buil-1.jpeg" style="width: 100%;height: 320px;object-fit: cover;">
                  <div class="top-left white--text pa-3">
                    <p class="fs-20 font-weight-medium mb-1 ts-title">Yamanouchi</p>
                    <p class="">500 media</p>
                  </div>
                </v-card>
              </div>
              <div class="test col-md-4">
                <v-card elevation="0" height="320px" :to="{ name: 'serp', params: { id: 123 } }">
                  <!-- <v-card-title>testing</v-card-title> -->              
                  <img src="@/assets/images/buil-2.jpeg" style="width: 100%;height: 320px;object-fit: cover;">
                  <div class="top-left white--text pa-3">
                    <p class="fs-20 font-weight-medium mb-1 ts-title">Nagano</p>
                    <p class="">500 media</p>
                  </div>
                </v-card>
              </div>
              <div class="test col-md-4">
                <v-card elevation="0" height="320px" :to="{ name: 'serp', params: { id: 123 } }">
                  <!-- <v-card-title>testing</v-card-title> -->              
                  <img src="@/assets/images/buil-3.jpeg" style="width: 100%;height: 320px;object-fit: cover;">
                  <div class="top-left white--text pa-3">
                    <p class="fs-20 font-weight-medium mb-1 ts-title">Tokyo</p>
                    <p class="">500 media</p>
                  </div>
                </v-card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- category -->
    <section class="category py-5">
      <div class="col-md-11 mx-auto">
        <h2 class="font-weight-bold mb-2">Recommended Category</h2>      
        <div class="slide-category">
          <carousel :responsive="{0:{items:1},425:{items:1},600:{items:3}}" :navText="[`<i class='ri-arrow-left-line'></i>`,`<i class='ri-arrow-right-line'></i>`]" :nav="true" :loop="true">
              <div class="box-slide mx-2">
                <v-card class="bg-transparent" elevation="0" :to="{ name: 'serp', params: { id: 123 } }">
                  <img src="@/assets/images/cat-1.jpg" style="width: 100%;height: 320px;object-fit: cover;">
                  <div class="py-3">
                    <p class="fs-20 font-weight-bold mb-1">Discount Campaign</p>
                    <p class="mb-0">1200 media</p>
                  </div>                              
                </v-card>  
              </div>
              <div class="box-slide mx-2">
                <v-card class="bg-transparent" elevation="0" :to="{ name: 'serp', params: { id: 123 } }">
                  <img src="@/assets/images/cat-2.jpg" style="width: 100%;height: 320px;object-fit: cover;">
                  <div class="py-3">
                    <p class="fs-20 font-weight-bold mb-1">Latest</p>
                    <p class="mb-0">1200 media</p>
                  </div>                              
                </v-card>  
              </div>
              <div class="box-slide mx-2">
                <v-card class="bg-transparent" elevation="0" :to="{ name: 'serp', params: { id: 123 } }">
                  <img src="@/assets/images/cat-3.jpg" style="width: 100%;height: 320px;object-fit: cover;">
                  <div class="py-3">
                    <p class="fs-20 font-weight-bold mb-1">Popular</p>
                    <p class="mb-0">1200 media</p>
                  </div>                              
                </v-card>  
              </div>
              <div class="box-slide mx-2">
                <v-card class="bg-transparent" elevation="0" :to="{ name: 'serp', params: { id: 123 } }">
                  <img src="@/assets/images/cat-2.jpg" style="width: 100%;height: 320px;object-fit: cover;">
                  <div class="py-3">
                    <p class="fs-20 font-weight-bold mb-1">Popular</p>
                    <p class="mb-0">1200 media</p>
                  </div>                              
                </v-card>  
              </div>
          </carousel>
          <!-- <v-slide-group
            v-model="model"
            class="pa-4"
            show-arrows
          >
            <v-slide-item>
              <div class="col-md-3">
                <v-card>
                  <img src="https://cdn.vuetifyjs.com/images/cards/house.jpg" style="width: 100%;height: 320px;object-fit: cover;">
                  <v-card-title>testing</v-card-title>                              
                </v-card>  
              </div>              
            </v-slide-item>
            <v-slide-item>
              <div class="col-md-3">
                <v-card>
                  <img src="https://cdn.vuetifyjs.com/images/cards/house.jpg" style="width: 100%;height: 320px;object-fit: cover;">
                  <v-card-title>testing</v-card-title>                              
                </v-card>  
              </div>              
            </v-slide-item>
            <v-slide-item>
              <div class="col-md-3">
                <v-card>
                  <img src="https://cdn.vuetifyjs.com/images/cards/house.jpg" style="width: 100%;height: 320px;object-fit: cover;">
                  <v-card-title>testing</v-card-title>                              
                </v-card>  
              </div>              
            </v-slide-item>
            <v-slide-item>
              <div class="col-md-3">
                <v-card>
                  <img src="https://cdn.vuetifyjs.com/images/cards/house.jpg" style="width: 100%;height: 320px;object-fit: cover;">
                  <v-card-title>testing</v-card-title>                              
                </v-card>  
              </div>              
            </v-slide-item>
          </v-slide-group> -->
        </div>
      </div>
    </section>

    <!-- type -->
    <section class="type white py-5">
      <div class="col-md-11 mx-auto">
        <h2 class="font-weight-bold mb-2 pt-3">Browse Media Type</h2>      
        <div class="slide-category">
          <carousel :responsive="{0:{items:1},425:{items:1},600:{items:5}}" :navText="[`<i class='ri-arrow-left-line'></i>`,`<i class='ri-arrow-right-line'></i>`]" :nav="true" :loop="true" :dots="true">
            <div class="box-slide mx-2">
                <v-card class="bg-transparent" elevation="0" :to="{ name: 'serp', params: { id: 123 } }">
                  <img src="@/assets/images/img-detail.jpeg" style="width: 100%;height: 200px;object-fit: cover;">
                  <div class="py-3">
                    <p class="fs-20 font-weight-medium">Station</p>
                  </div>                              
                </v-card>  
              </div>
              <div class="box-slide mx-2">
                <v-card class="bg-transparent" elevation="0" :to="{ name: 'serp', params: { id: 123 } }">
                  <img src="@/assets/images/img-detail.jpeg" style="width: 100%;height: 200px;object-fit: cover;">
                  <div class="py-3">
                    <p class="fs-20 font-weight-medium">Building</p>
                  </div>                              
                </v-card>  
              </div>
              <div class="box-slide mx-2">
                <v-card class="bg-transparent" elevation="0" :to="{ name: 'serp', params: { id: 123 } }">
                  <img src="@/assets/images/img-detail.jpeg" style="width: 100%;height: 200px;object-fit: cover;">
                  <div class="py-3">
                    <p class="fs-20 font-weight-medium">Transportation</p>
                  </div>                              
                </v-card>  
              </div>
              <div class="box-slide mx-2">
                <v-card class="bg-transparent" elevation="0" :to="{ name: 'serp', params: { id: 123 } }">
                  <img src="@/assets/images/img-detail.jpeg" style="width: 100%;height: 200px;object-fit: cover;">
                  <div class="py-3">
                    <p class="fs-20 font-weight-medium">Magazine</p>
                  </div>                              
                </v-card>  
              </div>
              <div class="box-slide mx-2">
                <v-card class="bg-transparent" elevation="0" :to="{ name: 'serp', params: { id: 123 } }">
                  <img src="@/assets/images/img-detail.jpeg" style="width: 100%;height: 200px;object-fit: cover;">
                  <div class="py-3">
                    <p class="fs-20 font-weight-medium">Website</p>
                  </div>                              
                </v-card>  
              </div>
              <div class="box-slide mx-2">
                <v-card class="bg-transparent" elevation="0" :to="{ name: 'serp', params: { id: 123 } }">
                  <img src="@/assets/images/img-detail.jpeg" style="width: 100%;height: 200px;object-fit: cover;">
                  <div class="py-3">
                    <p class="fs-20 font-weight-medium">Building</p>
                  </div>                              
                </v-card>  
              </div>
          </carousel>
          <!-- <v-slide-group
            v-model="model"
            class="pa-4"
            show-arrows
          >
            <v-slide-item>
              <div class="col-md-3">
                <v-card>
                  <img src="https://cdn.vuetifyjs.com/images/cards/house.jpg" style="width: 100%;height: 320px;object-fit: cover;">
                  <v-card-title>testing</v-card-title>                              
                </v-card>  
              </div>              
            </v-slide-item>
            <v-slide-item>
              <div class="col-md-3">
                <v-card>
                  <img src="https://cdn.vuetifyjs.com/images/cards/house.jpg" style="width: 100%;height: 320px;object-fit: cover;">
                  <v-card-title>testing</v-card-title>                              
                </v-card>  
              </div>              
            </v-slide-item>
            <v-slide-item>
              <div class="col-md-3">
                <v-card>
                  <img src="https://cdn.vuetifyjs.com/images/cards/house.jpg" style="width: 100%;height: 320px;object-fit: cover;">
                  <v-card-title>testing</v-card-title>                              
                </v-card>  
              </div>              
            </v-slide-item>
            <v-slide-item>
              <div class="col-md-3">
                <v-card>
                  <img src="https://cdn.vuetifyjs.com/images/cards/house.jpg" style="width: 100%;height: 320px;object-fit: cover;">
                  <v-card-title>testing</v-card-title>                              
                </v-card>  
              </div>              
            </v-slide-item>
          </v-slide-group> -->
        </div>
      </div>
    </section> 

    <!-- market -->
    <section class="market white py-5">
      <div class="col-md-11 mx-auto">
        <h2 class="font-weight-bold mb-2">Browse by Target Market</h2>      
        <div class="slide-category">
          <carousel :responsive="{0:{items:1},425:{items:1},600:{items:4}}" :navText="[`<i class='ri-arrow-left-line'></i>`,`<i class='ri-arrow-right-line'></i>`]" :nav="true" :loop="true">
            <div class="box-slide mx-2">
                <v-card class="bg-transparent" elevation="0" :to="{ name: 'serp', params: { id: 123 } }">
                  <img src="@/assets/images/img-detail.jpeg" style="width: 100%;height: 250px;object-fit: cover;">
                  <div class="py-3">
                    <p class="fs-20 font-weight-medium">CEO</p>
                  </div>                              
                </v-card>  
              </div>
              <div class="box-slide mx-2">
                <v-card class="bg-transparent" elevation="0" :to="{ name: 'serp', params: { id: 123 } }">
                  <img src="@/assets/images/img-detail.jpeg" style="width: 100%;height: 250px;object-fit: cover;">
                  <div class="py-3">
                    <p class="fs-20 font-weight-medium">Doctor</p>
                  </div>                              
                </v-card>  
              </div>
              <div class="box-slide mx-2">
                <v-card class="bg-transparent" elevation="0" :to="{ name: 'serp', params: { id: 123 } }">
                  <img src="@/assets/images/img-detail.jpeg" style="width: 100%;height: 250px;object-fit: cover;">
                  <div class="py-3">
                    <p class="fs-20 font-weight-medium">Family</p>
                  </div>                              
                </v-card>  
              </div>
              <div class="box-slide mx-2">
                <v-card class="bg-transparent" elevation="0" :to="{ name: 'serp', params: { id: 123 } }">
                  <img src="@/assets/images/img-detail.jpeg" style="width: 100%;height: 250px;object-fit: cover;">
                  <div class="py-3">
                    <p class="fs-20 font-weight-medium">Student</p>
                  </div>                              
                </v-card>  
              </div>
              <div class="box-slide mx-2">
                <v-card class="bg-transparent" elevation="0" :to="{ name: 'serp', params: { id: 123 } }">
                  <img src="@/assets/images/img-detail.jpeg" style="width: 100%;height: 250px;object-fit: cover;">
                  <div class="py-3">
                    <p class="fs-20 font-weight-medium">Teenager</p>
                  </div>                              
                </v-card>  
              </div>
          </carousel>
        </div>
      </div>
    </section>    
  </div>
</template>

<script>
  import carousel from 'vue-owl-carousel'
  
export default {
  name: "public-beranda",
  components: { carousel },

  data: () => ({
    items:['1','2','3'],
    location:["Kairuzawa","Yamanouchi","Nagano","Tokyo","Yuzawa","Nagoya"],
    type:["Building","Station","Magazine","Influencer","Transportation","Flyer"],
    model:null
  }),
  created() {
  },
  mounted() {
    // console.log(this.$store.getters.isLoggedIn);
  },
  methods: {
    login() {
      this.$router.push("/auth/login");
    },
  },
};
</script>

<style>
.box-slider{
  width: 100%;
  /*max-height: 360px!important;*/
  height: auto!important;
}
#banner .v-carousel__controls > .v-item-group {
  position: absolute;
  left: 11% !important;
}
#banner .v-carousel__controls__item {
  margin: 0;
}
#banner .v-image{
  /*max-height: 360px!important;*/
  height: auto!important;

}
#banner .v-carousel__controls{
  bottom:5%!important;

}
.img-banner{
  width: 100%;
  /*max-height: 360px!important;*/
  height: auto!important;

  /*object-fit: contain;*/
}
.bg-cta-login {
  /*background-image: url("../../assets/images/bg-login.png");*/
  background-repeat: no-repeat;
  background-position: center;
  height: 240px;
  background-size: cover;
}
.img-kategori {
  width: 100%;
}
/*@media (max-width: 768px) {
  #banner .v-image{
    height: auto!important;
  }
}*/
@media (max-width: 425px) {
  .box-data .v-card{
    max-width: 95%!important;
  }
  .box-slider {
    height: auto !important;
  }
  #banner .v-btn--icon.v-size--small {
    height: 15px;
    width: 15px;
  }
  #banner .v-btn__content i {
    font-size: 12px !important;
  }
  #banner .v-carousel__controls {
    bottom: -10px;
  }
  .v-carousel__item {
    height: auto !important;
  }
  .img-banner {
    height: auto !important;
  }
  .bg-cta-login {
    background-position: right !important;
    height: auto !important;
    width: 100%;
  }
  #cta-login .headline {
    font-size: 16px !important;
  }
}
@media (max-width: 375px) {
  .btn-beli {
    font-size: 10px !important;
  }
}
</style>