<template>
  <v-row align-content="center">
    <v-col class="" md="12" style="min-height: 80vh;">
      <v-row>
        <v-col class="bg-transparent mx-auto" md="6">
          <v-spacer class="py-3"></v-spacer>
          <v-spacer class="py-5"></v-spacer>
          <div
            class="px-8 mb-3"            
          >
            <div class="fs-32 font-weight-bold mb-4 text-center">{{ $cookies.get("kinsan_lang") == 'jp' ? '利用規約' : 'Terms of Use' }}</div>
            <!-- <div class="fs-32 font-weight-bold mb-4 text-center">{{detail_page && detail_page.title ? detail_page.title : ''}}</div> -->
            <div v-html="detail_page && detail_page.content ? ($i18n.locale == 'jp' ? detail_page.content_ja : detail_page.content) : ''"></div>
          </div>
          <v-spacer class="py-3"></v-spacer>
          <v-spacer class="py-5"></v-spacer>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: "term-us",
  data() {
    return {
      detail_page: {}
    };
  },
  computed: {
  },
  async mounted() {
    await this.getDetailPage("term-of-service");
  },
  methods: {
    getDetailPage(id) {
      this.$store
        .dispatch("getDetailPage", id)
        .then((data) => {
          this.detail_page = data
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style>

</style>
