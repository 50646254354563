export default {
  activated_feedback_subtitle: 'メディアが正常に登録されました',
  activated_feedback_title: 'メディアが有効となりました',
  activated: '承認済みメディア',
  all_media: '全ての媒体',
  blank_title: 'No Media',
  category_updated_feedback_subtitle: "The media category's has been updated successfully",
  category_updated_feedback_title: "Media Category's Updated",
  deactivated: '非アクティブ化されたメディア',
  deactivated_feedback_subtitle: 'メディアは正常に非アクティブ化されました',
  deactivated_feedback_title: 'メディアの非アクティブ化に成功しました',
  minimum_price: 'Start from {price}',
  pending: '承認待ち',
  reason_rejected: 'ご登録できない理由',
  rejected_feedback_subtitle: 'メディア登録が非承認となりました',
  rejected_feedback_title: 'メディアが非承認となりました',
  rejected: '未承認メディア',
  search: 'Search メディア',
  title: 'メディア',
};
