export default {
  'order.first_payment_expired': 'お支払い{order_number}の期限が切れました',
  'order.new_quotation': '管理者が見積書をお送りしました',
  'order.new_contract': '管理者が契約書をお送りしました',
  'order.contract_rejected': 'ご契約書に不備があります。ご確認いただき、再度、アップロードお願い致します',
  'order.payment_rejected': 'お支払い確認書類に不備があります。ご確認いただき、再度、アップロードお願い致します',
  'order.contract_approved': '管理者が契約書を承認致しました',
  'order.payment_approved': '管理者が支払い確認書類を承認致しました',
  'order.media_rejected': 'メディア登録に不備があります。ご確認いただき、再度、アップロードお願い致します',
  'order.media_approved': '管理者がメディア登録を承認致しました',
  'order.new_activation_proof': '管理者が出稿証明書をお送りしました',
  'order.2nd_payment_approved': '管理者が２回目の支払い確認書類を承認致しました',
  'order.2nd_payment_rejected':
    '２回目の支払い確認書類に不備があります。ご確認いただき、再度、アップロードお願い致します',
  'system.media_expired': 'ご契約して頂いたメディアは既に期限切れです',
  'media.media_approved': '管理者がメディア登録を承認しました',
  'media.media_rejected': 'メディア登録に不備があります。ご確認いただき、再度、アップロードお願い致します',
};
