export default {
  add_new: '新しく登録する',
  approve: '承認',
  cancel: 'キャンセル',
  close: 'クローズ',
  delete: 'Delete',
  download: 'ダウンロード',
  login: 'ログイン',
  logout: 'ログアウト',
  ok: 'OK',
  reject_2: '送信',
  reject: '拒否',
  remove: 'Remove',
  replace: 'Replace',
  save: '作成',
  search: '検索',
  signup: 'サインアップ',
  submit: '承認',
  take_down: '降ろす',
  upload: 'アップロード',
  update: '更新',
  view: '開く',
};
