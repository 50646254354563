<template>
  <v-row align-content="center">
    <v-col class="" md="12" style="min-height: 80vh;">
      <v-row>
        <v-col class="bg-transparent mx-auto" md="6">
          <v-spacer class="py-3"></v-spacer>
          <v-spacer class="py-5"></v-spacer>
          <div
            id="guide"
            class="px-8 mb-3"            
          >
            <div class="fs-32 font-weight-bold mb-4 text-center">FAQ</div>
            <v-expansion-panels accordion>
              <v-expansion-panel class="transparent border-bottom" v-for="(item, index) in list_faq" :key="index">
                <v-expansion-panel-header>{{$cookies.get("kinsan_lang") == 'jp' ? item.question_ja : item.question}}</v-expansion-panel-header>
                <v-expansion-panel-content>
                  {{$cookies.get("kinsan_lang") == 'jp' ? item.answer_ja : item.answer}}
                </v-expansion-panel-content>
              </v-expansion-panel>
              <!-- <v-expansion-panel class="transparent border-bottom">
                <v-expansion-panel-header>How to pay for advertisement?</v-expansion-panel-header>
                <v-expansion-panel-content>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel class="transparent border-bottom">
                <v-expansion-panel-header>What will happen after making an inquiry?</v-expansion-panel-header>
                <v-expansion-panel-content>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel class="transparent border-bottom">
                <v-expansion-panel-header>What happens when i press “See details”?</v-expansion-panel-header>
                <v-expansion-panel-content>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </v-expansion-panel-content>
              </v-expansion-panel> -->
            </v-expansion-panels>
          </div>
          <v-spacer class="py-3"></v-spacer>
          <v-spacer class="py-5"></v-spacer>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: "term-us",
  data() {
    return {
      list_faq: {}
    };
  },
  computed: {
  },
  mounted() {
    this.getFaq();
  },
  methods: {
    getFaq() {
      this.$store
        .dispatch("getFaq", {})
        .then((data) => {
          this.list_faq = data
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style>
#guide .v-expansion-panel::before{
  box-shadow: none;
  border-bottom: 1px solid #e1e4e8;
  border-radius: 0;
}
</style>
