<template>
  <div class="">
    <vue-html2pdf
      ref="sheet"
      :show-layout="false"
      :enable-download="false"
      :preview-modal="true"
      :pdf-quality="2"
      filename="factsheet"
      pdf-orientation="portrait"
      :manual-pagination="true"
      pdf-content-width="100%"
      @hasDownloaded="hasGenerated($event)"
      pdf-format="a4"
    >
      <!-- :html-to-pdf-options="htmlToPdfOptions" -->
      <section slot="pdf-content">
        <section
          class="pdf-item"
          :style="{
            width: `100%`,
            background: `#fff`,
          }"
        >
          <!-- height: `790px`, -->
          <div class="">
            <!-- <div :class="{style1}">           -->
            <div style="text-align: center">
              <img v-if="$i18n.locale === 'en'" width="100%" src="@/assets/images/header-fact.png" alt="" srcset="" />
              <img v-else width="100%" src="@/assets/images/Frame-5.png" alt="" srcset="" />
            </div>
            <div style="margin: auto; width: 90%; margin-top: 20px">
              <img width="100%" height="325" :src="img1" />
              <!-- :src="img1" -->
            </div>
            <div style="text-align: left; width: 90%; margin: auto">
              <p
                style="
                  font-family: Open Sans;
                  font-style: normal;
                  font-weight: 700;
                  font-size: 16px;
                  line-height: 32px;
                  margin-top: 20px;
                  margin-bottom: 30px;
                "
              >
                {{ data.title }}
              </p>
            </div>
            <div style="margin: auto; width: 90%">
              <!-- <img
                width="100%"
                height="220"
                src="@/assets/images/img-detail.jpeg"
              /> -->
              <img
                width="100%"
                height="185"
                :src="
                  `https://maps.google.com/maps/api/staticmap?markers=${
                    map && map.location && map.location.coordinates && map.location.coordinates[1]
                      ? +parseFloat(map.location.coordinates[1])
                      : 0
                  }` +
                  ',' +
                  `${
                    map && map.location && map.location.coordinates && map.location.coordinates[0]
                      ? +parseFloat(map.location.coordinates[0])
                      : 0
                  }&zoom=10&size=1200x220&scale=2&maptype=roadmap&key=AIzaSyBNQK9KCjbq3MyGrJCMdu1_vbLNWy91osY`
                "
              />
              <!-- <GmapMap
                :center="{ lat: map && map.location && map.location.coordinates && map.location.coordinates[1] ? +parseFloat(map.location.coordinates[1]) : 0, lng: map && map.location && map.location.coordinates && map.location.coordinates[0] ? +parseFloat(map.location.coordinates[0]) : 0 }"
                :zoom="10" map-style-id="roadmap" :options="{
                  zoomControl: true,
                  mapTypeControl: false,
                  scaleControl: false,
                  streetViewControl: false,
                  rotateControl: false,
                  fullscreenControl: true,
                  disableDefaultUi: false
                }" style="width: 100%; height: 220px" ref="mapRef">
                <GmapMarker
                  :position="{ lat: map && map.location && map.location.coordinates && map.location.coordinates[1] ? parseFloat(map.location.coordinates[1]) : 0, lng: map && map.location && map.location.coordinates && map.location.coordinates[0]? parseFloat(map.location.coordinates[0]) : 0 }" />
              </GmapMap> -->
            </div>
            <div
              style="
                margin: auto;
                width: 90%;
                display: flex;
                font-family: Open Sans;
                margin-top: 20px;
                margin-bottom: 20px;
                height: 293px;
              "
            >
              <div style="width: 50%; text-align: left">
                <p style="font-weight: 600; margin-bottom: 10px; font-size: 15px">{{ $t('title_scan') }}</p>
                <vue-qrcode
                  :value="`https://kinsan-ad.com/detail/${this.data._id}/${this.data.title}`"
                  level="H"
                  :options="{ width: 200 }"
                  style="margin-left: -15px"
                ></vue-qrcode>
              </div>
              <div style="width: 50%">
                <p style="font-weight: 600; margin-bottom: 20px; font-size: 15px">{{ $t('desc_media') }}</p>
                <table style="width: 100%; font-size: 12px">
                  <tr style="background: #fff">
                    <td
                      style="
                        font-size: 12px;
                        background: #fff;
                        padding-bottom: 15px;
                        width: 50%;
                        font-weight: 500;
                        text-align: left;
                      "
                    >
                      {{ $t('media_type') }}
                    </td>
                    <td
                      style="
                        font-size: 12px;
                        background: #fff;
                        padding-bottom: 15px;
                        width: 50%;
                        color: #8b8c8d;
                        font-weight: 400;
                        text-align: left;
                      "
                    >
                      {{ this.$i18n.locale === 'en' ? media_type.title : media_type.title_ja }}
                    </td>
                  </tr>
                  <tr style="background: #fff">
                    <td
                      style="
                        font-size: 12px;
                        background: #fff;
                        padding-bottom: 15px;
                        width: 50%;
                        font-weight: 500;
                        text-align: left;
                      "
                    >
                      {{ $t('size') }}
                    </td>
                    <td
                      style="
                        font-size: 12px;
                        background: #fff;
                        padding-bottom: 15px;
                        width: 50%;
                        color: #8b8c8d;
                        font-weight: 400;
                        text-align: left;
                      "
                    >
                      {{ size.height }} x {{ size.width }} {{ size.type }}
                    </td>
                  </tr>
                  <tr style="background: #fff">
                    <td
                      style="
                        font-size: 12px;
                        background: #fff;
                        padding-bottom: 15px;
                        width: 50%;
                        font-weight: 500;
                        text-align: left;
                      "
                    >
                      {{ $t('lighting') }}
                    </td>
                    <td
                      style="
                        font-size: 12px;
                        background: #fff;
                        padding-bottom: 15px;
                        width: 50%;
                        color: #8b8c8d;
                        font-weight: 400;
                        text-align: left;
                        text-transform: capitalize;
                      "
                    >
                      {{ detail_media && detail_media.is_lighting == 'true' ? $t('available') : $t('not_available') }}
                    </td>
                  </tr>
                  <tr style="background: #fff">
                    <td
                      style="
                        font-size: 12px;
                        background: #fff;
                        padding-bottom: 15px;
                        width: 50%;
                        font-weight: 500;
                        text-align: left;
                      "
                    >
                      {{ $t('orientation') }}
                    </td>
                    <td
                      style="
                        font-size: 12px;
                        background: #fff;
                        padding-bottom: 15px;
                        width: 50%;
                        color: #8b8c8d;
                        font-weight: 400;
                        text-align: left;
                      "
                    >
                      {{ orientation == 'Vertical' ? $t('vertical') : $t('horizontal') }}
                    </td>
                  </tr>
                  <tr style="background: #fff">
                    <td
                      style="
                        font-size: 12px;
                        background: #fff;
                        padding-bottom: 15px;
                        width: 50%;
                        font-weight: 500;
                        text-align: left;
                      "
                    >
                      {{ $t('price') }}
                    </td>
                    <td
                      style="
                        font-size: 12px;
                        background: #fff;
                        padding-bottom: 15px;
                        width: 50%;
                        color: #8b8c8d;
                        font-weight: 400;
                        text-align: left;
                      "
                    >
                      {{ $t('start_from_amount', { amount: price }) }}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div style="background: #470868; padding: 11px 0">
              <div style="display: flex; color: #fff; margin: auto; width: 90%">
                <div style="padding-right: 15px">
                  <p style="font-size: 14px; font-weight: 600">{{ $t('our_office') }}</p>
                  <p style="margin-bottom: 0px; font-size: 12px; font-weight: 400">Kinsan Global {{ $t('llc') }}</p>
                  <p style="margin-bottom: 0px; font-size: 12px; font-weight: 400">{{ $t('add1') }}</p>
                  <p style="margin-bottom: 0px; font-size: 12px; font-weight: 400">{{ $t('add2') }}</p>
                </div>
                <div style="padding-right: 15px">
                  <p style="font-size: 14px; font-weight: 600">{{ $t('contact_us') }}</p>
                  <p style="font-size: 12px; font-weight: 400">TEL : 03 - 6261 - 0002</p>
                </div>
                <div style="padding-right: 15px">
                  <p style="font-size: 14px; font-weight: 600">{{ $t('business_hour') }}</p>
                  <p style="font-size: 12px; font-weight: 400">{{ $t('weekdays') }} (09:00 - 17:00)</p>
                </div>
              </div>
              <!-- <img width="100%" src="@/assets/images/footer-fact.png" alt="" srcset="" /> -->
            </div>
          </div>
        </section>
      </section>
    </vue-html2pdf>
    <!-- detail content -->
    <section class="detail-content white py-5">
      <div class="col-md-11 mx-auto">
        <div class="row">
          <div class="col-md-12">
            <v-breadcrumbs :items="items" large class="ps-0"></v-breadcrumbs>
          </div>
          <div class="col-md-8" v-if="loading">
            <v-skeleton-loader class="col-md-11 d-inline-block" style="width: 100%; height: auto" type="image">
            </v-skeleton-loader>
            <v-skeleton-loader class="col-md-11 d-inline-block" style="width: 100%; height: auto" type="article">
            </v-skeleton-loader>
            <v-skeleton-loader class="col-md-11 d-inline-block" style="width: 100%; height: auto" type="article">
            </v-skeleton-loader>
            <v-skeleton-loader class="col-md-11 d-inline-block" style="width: 100%; height: auto" type="article">
            </v-skeleton-loader>
          </div>
          <div class="col-md-8" v-else>
            <carousel
              v-if="detail_media && detail_media.photo && detail_media.photo.length > 0 && detail_media.photo[0]"
              :autoplay="true"
              :loop="false"
              :center="true"
              :items="1"
              :nav="false"
              :margin="5"
              class="mb-3"
            >
              <img
                v-for="item in detail_media.photo"
                :key="item"
                :src="item"
                width="100%"
                style="max-height: 520px; object-fit: cover"
              />
            </carousel>
            <!-- <img :src="detail_media.photo[0]" class="mb-3" style="width: 100%;height: auto;object-fit: cover;"> -->
            <p v-if="$i18n.locale === 'en'" class="font-weight-medium fs-20">
              {{
                detail_media && detail_media.media_type && detail_media.media_type.title
                  ? detail_media.media_type.title + ' - '
                  : ''
              }}{{ detail_media && detail_media.title }}
            </p>
            <p v-else class="font-weight-medium fs-20">
              {{
                detail_media && detail_media.media_type && detail_media.media_type.title
                  ? detail_media.media_type.title_ja + ' - '
                  : ''
              }}{{ detail_media && detail_media.title }}
            </p>

            <p class="color-state">{{ detail_media && detail_media.description }}</p>

            <!-- <div class="box-trafic py-3" v-if="detail_media.category && detail_media.category.title">
              <p class="fs-12 font-weight-medium mb-0">Category</p>
              <p class="color-state">{{detail_media.category && detail_media.category.title}}</p>
            </div>

            <div class="box-trafic py-3">
              <p class="fs-12 font-weight-medium mb-0">Traffic</p>
              <p class="color-state">{{detail_media.traffic}}</p>
            </div> -->

            <div class="box-trafic py-3">
              <div class="row">
                <div
                  class="col-3 col-md-2"
                  :title="$t('category')"
                  v-if="detail_media && detail_media.category && detail_media.category.title"
                >
                  <p class="fs-14 font-weight-medium mb-0">{{ $t('category') }}</p>
                  <p class="color-state">{{ detail_media && detail_media.category && detail_media.category.title }}</p>
                </div>
                <div class="col-3 col-md-2" :title="$t('traffic')">
                  <p class="fs-14 font-weight-medium mb-0">{{ $t('traffic') }}</p>
                  <p class="color-state mb-0">{{ detail_media && detail_media.traffic }}</p>
                </div>
                <div class="col-12 col-md-12 mb-2">
                  <p class="fs-14 font-weight-medium mb-0">{{ $t('transaction.link_video') }}</p>
                  <a v-if="detail_media.video != ''" :href="detail_media.video" target="_blank">{{
                    detail_media && detail_media.video
                  }}</a>
                  <p v-else class="text-primary mb-0">-</p>
                </div>
                <!-- <div class="col-3 col-md-2" :title="$t('target_market')">
                  <p class="fs-14 font-weight-medium mb-0">{{ $t('target_market') }}</p>
                  <p class="color-state">
                    {{detail_media && detail_media.target_market && detail_media.target_market.map(function(elem){
                        return elem.title;
                    }).join(", ")}}
                  </p>
                </div> -->
              </div>
            </div>

            <div class="box-details mb-3" v-if="detail_media.media_type && detail_media.media_type.property">
              <div class="row">
                <div v-for="(item, index) in detail_media.media_type.property" :key="index" class="col-3 col-md-2">
                  <!-- <p class="font-weight-bold">{{$t(item.title.toLowerCase().replace(/ /g,'_'))}}</p> -->
                  <p v-if="$i18n.locale === 'en'" class="font-weight-bold">{{ item.title }}</p>
                  <p v-else class="font-weight-bold">{{ item.title_ja }}</p>
                  <p class="font-weight-normal">{{ item.value }}</p>
                </div>
              </div>
            </div>

            <div class="box-details mb-3">
              <div class="row">
                <div class="col-3 col-md-2 text-center" :title="$t('size')">
                  <img src="@/assets/images/size.png" width="64" height="64" class="mb-1" />
                  <p class="font-weight-medium">
                    {{
                      detail_media && detail_media.unit_of_measurement && detail_media.unit_of_measurement.height
                        ? detail_media.unit_of_measurement.height
                        : ''
                    }}
                    x
                    {{
                      detail_media && detail_media.unit_of_measurement && detail_media.unit_of_measurement.width
                        ? detail_media.unit_of_measurement.width
                        : ''
                    }}
                    {{
                      detail_media && detail_media.unit_of_measurement && detail_media.unit_of_measurement.type
                        ? $t(detail_media.unit_of_measurement.type.toLowerCase())
                        : ''
                    }}
                  </p>
                </div>
                <div class="col-3 col-md-2 text-center" :title="$t('orientation')">
                  <img src="@/assets/images/window.png" width="64" height="64" class="mb-1" />
                  <p class="font-weight-medium">
                    {{ detail_media && detail_media.orientation ? $t(detail_media.orientation.toLowerCase()) : '' }}
                  </p>
                </div>
                <div class="col-3 col-md-2 text-center" :title="$t('lighting')">
                  <img src="@/assets/images/sun.png" width="64" height="64" class="mb-1" />
                  <p class="font-weight-medium">
                    {{ detail_media && detail_media.is_lighting == 'true' ? $t('available') : $t('not_available') }}
                  </p>
                </div>
              </div>
            </div>

            <div class="box-map mb-3" v-if="detail_media && detail_media.location">
              <!-- <img src="@/assets/images/maps.png" class="mb-2" style="width: 100%;height: auto;object-fit: cover;"> -->
              <GmapMap
                :center="{
                  lat:
                    detail_media &&
                    detail_media.location &&
                    detail_media.location.coordinates &&
                    detail_media.location.coordinates[1]
                      ? +parseFloat(detail_media.location.coordinates[1])
                      : 0,
                  lng:
                    detail_media &&
                    detail_media.location &&
                    detail_media.location.coordinates &&
                    detail_media.location.coordinates[0]
                      ? +parseFloat(detail_media.location.coordinates[0])
                      : 0,
                }"
                :zoom="10"
                map-style-id="roadmap"
                :options="{
                  zoomControl: true,
                  mapTypeControl: false,
                  scaleControl: false,
                  streetViewControl: false,
                  rotateControl: false,
                  fullscreenControl: true,
                  disableDefaultUi: false,
                }"
                style="width: 100%; height: 50vmin"
                ref="mapRef"
              >
                <GmapMarker
                  :position="{
                    lat:
                      detail_media &&
                      detail_media.location &&
                      detail_media.location.coordinates &&
                      detail_media.location.coordinates[1]
                        ? parseFloat(detail_media.location.coordinates[1])
                        : 0,
                    lng:
                      detail_media &&
                      detail_media.location &&
                      detail_media.location.coordinates &&
                      detail_media.location.coordinates[0]
                        ? parseFloat(detail_media.location.coordinates[0])
                        : 0,
                  }"
                />
              </GmapMap>
              <div class="row mt-2">
                <div class="col-md-10">
                  <p class="color-state">
                    <v-icon>ri-map-pin-2-fill</v-icon>
                    {{
                      detail_media && detail_media.location && detail_media.location.address
                        ? detail_media.location.address
                        : ''
                    }}
                  </p>
                </div>
                <div class="col-md-2 align-self-end text-end">
                  <v-btn
                    :href="
                      detail_media &&
                      detail_media.location &&
                      detail_media.location.coordinates &&
                      detail_media.location.coordinates[0] &&
                      detail_media.location.coordinates[1]
                        ? 'https://www.google.com/maps/place/' +
                          parseFloat(detail_media.location.coordinates[1]) +
                          ',' +
                          parseFloat(detail_media.location.coordinates[0])
                        : ''
                    "
                    target="_blank"
                    outlined
                    class="mb-3 color-state rounded-8"
                    x-small
                  >
                    {{ $t('view_on_map') }}
                  </v-btn>
                </div>
              </div>
            </div>
            <div class="d-lg-none d-md-block mb-5">
              <v-card elevation="0" outlined class="px-3 rounded-md">
                <v-card-actions>
                  <v-list-item class="grow pl-0">
                    <v-list-item-content class="pl-0">
                      <p class="color-oranye font-weight-medium">{{ $t('price_start_from') }}</p>
                    </v-list-item-content>

                    <v-row align="center" justify="end">
                      <p
                        class="fs-20 color-oranye font-weight-medium"
                        v-if="this.detail_media && this.detail_media.minimum_price"
                      >
                        ￥ {{ toCurrency(this.duration * this.detail_media.minimum_price) }}
                      </p>
                    </v-row>
                  </v-list-item>
                </v-card-actions>
                <v-form ref="form" class="px-2">
                  <v-row>
                    <v-col class="pb-1">
                      <div class="mb-2">
                        <span class="font-weight-medium">{{ $t('choose_duration') }}</span>
                      </div>
                      <v-select
                        v-model="duration"
                        :items="month"
                        :placeholder="$t('select_duration')"
                        outlined
                        :item-text="'name'"
                        class="rounded-8"
                        :item-value="'value'"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row class="mt-0">
                    <v-col class="pb-0">
                      <div class="mb-2">
                        <span class="font-weight-medium">{{ $t('choose_start_date') }}</span>
                      </div>
                      <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="date"
                            append-icon="ri-calendar-line"
                            readonly
                            outlined
                            v-bind="attrs"
                            v-on="on"
                            class="rounded-8"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="date"
                          :active-picker.sync="activePicker"
                          :min="
                            new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)
                          "
                          @change="save"
                          class="rounded-8"
                          :locale="locale"
                        ></v-date-picker>
                        <!-- :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)" -->
                      </v-menu>
                    </v-col>
                  </v-row>
                  <div
                    class="box-date py-3 mb-3 rounded-8"
                    style="
                      background: linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), #3e66fb;
                    "
                  >
                    <div class="row">
                      <div class="col-md-6 text-center">
                        <p class="font-weight-medium">{{ $t('start_date') }}</p>
                        <p v-if="this.date != null" class="primary--text">{{ dateStart(date) }}</p>
                      </div>
                      <div class="col-md-6 text-center">
                        <p class="font-weight-medium">{{ $t('end_date') }}</p>
                        <p v-if="this.date != null && this.duration == '1'" class="primary--text">
                          {{ dateMonth(date) }}
                        </p>
                        <p v-else-if="this.date != null && this.duration == '3'" class="primary--text">
                          {{ dateMonth3(date) }}
                        </p>
                        <p v-else-if="this.date != null && this.duration == '6'" class="primary--text">
                          {{ dateMonth6(date) }}
                        </p>
                        <p v-else-if="this.date != null && this.duration == '12'" class="primary--text">
                          {{ dateYear(date) }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <v-btn
                    :loading="loading_quotation"
                    color="primary"
                    class="mb-3 rounded-8"
                    small
                    block
                    @click="reqQuotation()"
                  >
                    {{ $t('quotation_request') }}
                  </v-btn>
                  <v-btn outlined class="mb-3 rounded-8" small block @click="openChat()" :loading="loading_chat">
                    {{ $t('ask_for_enquiries') }}
                  </v-btn>
                  <v-btn text small block color="primary" class="mb-3" :loading="load" @click="getData()">
                    {{ $t('print_factsheet') }}
                  </v-btn>
                </v-form>
              </v-card>
            </div>
            <div class="box-nearby">
              <h2 class="font-weight-bold mb-3">{{ $t('media_nearby') }}</h2>
              <div class="row" :class="media_nearby.length == 0 ? 'justify-center' : ''">
                <div v-if="loading_nearby">
                  <v-skeleton-loader
                    class="col-md-3 d-inline-block"
                    style="width: 350px; height: auto"
                    type="card"
                    v-for="item in 4"
                    :key="item"
                  >
                  </v-skeleton-loader>
                </div>
                <img
                  v-if="media_nearby.length == 0"
                  class="mt-15 mb-15"
                  src="@/assets/images/empty-media.png"
                  style="width: 300px; height: 300px; object-fit: cover"
                />
                <div class="col-md-3" v-for="(item, index) in media_nearby" :key="index">
                  <v-card
                    class=""
                    height="100%"
                    :to="{
                      name: 'detail-page',
                      params: { id: item._id, slug: item.title.replace(/ /g, '-').toLowerCase() },
                    }"
                    target="_blank"
                  >
                    <v-img height="140" :src="item.photo[0]"></v-img>

                    <p class="font-weight-medium fs-18 wb mb-0 pa-2">
                      {{ item.category && item.category.title ? item.category.title + ' - ' : '' }}{{ item.title }}
                    </p>

                    <v-card-text class="pt-0 px-2">
                      <p class="color-oranye mb-0">
                        {{ $t('start_from_amount', { amount: item.minimum_price }) }}
                      </p>
                      <p class="text-dots-2 mb-0">
                        {{ item.location && item.location.address ? item.location.address : '' }}
                      </p>
                    </v-card-text>
                  </v-card>
                </div>
                <!-- <div class="col-md-3">                  
                  <v-card
                    class=""
                  >
                    <v-img
                      height="140"
                      src="@/assets/images/img-detail.jpeg"
                    ></v-img>

                    <p class="font-weight-medium fs-18 wb mb-0 pa-2">Building - Nagoya TV Tower</p>
                    
                    <v-card-text class="pt-0 px-2">
                      <p class="color-oranye mb-0">
                        Start from ￥{{500}}
                      </p>
                      <p class="text-dots-2 mb-0">Nagoya TV Tower Naka Ward, Nagoya, Aichi ...</p>
                    </v-card-text>
                  </v-card>
                </div>
                <div class="col-md-3">                  
                  <v-card
                    class=""
                  >
                    <v-img
                      height="140"
                      src="@/assets/images/img-detail.jpeg"
                    ></v-img>

                    <p class="font-weight-medium fs-18 wb mb-0 pa-2">Building - Nagoya TV Tower</p>
                    
                    <v-card-text class="pt-0 px-2">
                      <p class="color-oranye mb-0">
                        Start from ￥{{500}}
                      </p>
                      <p class="text-dots-2 mb-0">Nagoya TV Tower Naka Ward, Nagoya, Aichi ...</p>
                    </v-card-text>
                  </v-card>
                </div>
                <div class="col-md-3">                  
                  <v-card
                    class=""
                  >
                    <v-img
                      height="140"
                      src="@/assets/images/img-detail.jpeg"
                    ></v-img>

                    <p class="font-weight-medium fs-18 wb mb-0 pa-2">Building - Nagoya TV Tower</p>
                    
                    <v-card-text class="pt-0 px-2">
                      <p class="color-oranye mb-0">
                        Start from ￥{{500}}
                      </p>
                      <p class="text-dots-2 mb-0">Nagoya TV Tower Naka Ward, Nagoya, Aichi ...</p>
                    </v-card-text>
                  </v-card>
                </div>  -->
              </div>
            </div>
          </div>
          <div class="col-md-4 d-none d-lg-block">
            <v-card elevation="0" outlined class="px-3 rounded-md">
              <v-card-actions>
                <v-list-item class="grow pl-0">
                  <v-list-item-content class="pl-0">
                    <p class="color-oranye font-weight-medium">{{ $t('price_start_from') }}</p>
                  </v-list-item-content>

                  <v-row align="center" justify="end">
                    <p>
                      <v-chip
                        v-show="detail_media.discount != 0"
                        class="ma-2 font-weight-bold"
                        color="primary"
                        label
                        text-color="white"
                        small
                      >
                        {{ this.detail_media.discount }}% OFF
                      </v-chip>
                    </p>
                    <p
                      class="fs-20 color-oranye font-weight-medium"
                      v-if="this.detail_media && this.detail_media.minimum_price"
                    >
                      ￥ {{ toCurrency(this.duration * this.detail_media.minimum_price) }}
                    </p>
                  </v-row>
                </v-list-item>
              </v-card-actions>
              <v-form ref="form" class="px-2">
                <v-row>
                  <v-col class="pb-1">
                    <div class="mb-2">
                      <span class="font-weight-medium">{{ $t('choose_duration') }}</span>
                    </div>
                    <v-select
                      v-model="duration"
                      :items="month"
                      :placeholder="$t('select_duration')"
                      outlined
                      :item-text="'name'"
                      class="rounded-8"
                      :item-value="'value'"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row class="mt-0">
                  <v-col class="pb-0">
                    <div class="mb-2">
                      <span class="font-weight-medium">{{ $t('choose_start_date') }}</span>
                    </div>
                    <v-menu
                      ref="menu2"
                      v-model="menu2"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="date2"
                          append-icon="ri-calendar-line"
                          readonly
                          outlined
                          v-bind="attrs"
                          v-on="on"
                          class="rounded-8"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="date2"
                        :active-picker.sync="activePicker2"
                        :min="new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)"
                        @change="save2"
                        class="rounded-8"
                        :locale="locale"
                      ></v-date-picker>
                      <!-- :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)" -->
                    </v-menu>
                  </v-col>
                </v-row>
                <div
                  class="box-date py-3 mb-3 rounded-8"
                  style="
                    background: linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), #3e66fb;
                  "
                >
                  <div class="row">
                    <div class="col-md-6 text-center">
                      <p class="font-weight-medium">{{ $t('start_date') }}</p>
                      <p v-if="this.date2 != null" class="primary--text">{{ dateStart(date2) }}</p>
                    </div>
                    <div class="col-md-6 text-center">
                      <p class="font-weight-medium">{{ $t('end_date') }}</p>
                      <p v-if="this.date2 != null && this.duration == '1'" class="primary--text">
                        {{ dateMonth(date2) }}
                      </p>
                      <p v-else-if="this.date2 != null && this.duration == '3'" class="primary--text">
                        {{ dateMonth3(date2) }}
                      </p>
                      <p v-else-if="this.date2 != null && this.duration == '6'" class="primary--text">
                        {{ dateMonth6(date2) }}
                      </p>
                      <p v-else-if="this.date2 != null && this.duration == '12'" class="primary--text">
                        {{ dateYear(date2) }}
                      </p>
                    </div>
                  </div>
                </div>
                <v-btn
                  :loading="loading_quotation"
                  color="primary"
                  class="mb-3 rounded-8"
                  small
                  block
                  @click="reqQuotation()"
                >
                  {{ $t('quotation_request') }}
                </v-btn>
                <v-btn outlined class="mb-3 rounded-8" small block @click="openChat()" :loading="loading_chat">
                  {{ $t('ask_for_enquiries') }}
                </v-btn>
                <!-- <v-btn text small block color="primary" class="mb-3" target="_blank" :href="$router.resolve({name: 'factsheet'}).href"> -->
                <v-btn text small block color="primary" class="mb-3" :loading="load" @click="getData">
                  {{ $t('print_factsheet') }}
                </v-btn>
              </v-form>
            </v-card>
          </div>
        </div>
      </div>
    </section>
    <v-snackbar top v-model="snackbar.state" :color="snackbar.color" text>
      <!-- :timeout="timeout" -->
      <!-- <v-alert
              type="info"
              text
              dense                            
              >
                
        <div class="color-black font-weight-bold" v-html="snackbar.text"></div>
        <div class="color-black" v-html="snackbar.content"></div>
              </v-alert
            > -->
      <!-- <v-icon>ri-info</v-icon> -->
      <div class="color-black font-weight-bold" v-html="snackbar.text"></div>
      <div class="color-black" v-html="snackbar.content"></div>
      <template v-slot:action="{ attrs }">
        <v-btn small icon color="primary" v-bind="attrs" @click="snackbar.state = false">
          <v-icon>$close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import moment from 'moment';
import carousel from 'vue-owl-carousel';
import VueQrcode from '@chenfengyuan/vue-qrcode';

export default {
  name: 'public-detail',
  components: {
    carousel,
    VueQrcode,
  },
  data: () => ({
    is_open: false,
    loading_chat: false,
    load: false,
    data: {},
    img1: '',
    media_type: '',
    size: {},
    lighting: '',
    orientation: '',
    traffic: '',
    video: '',
    price: '',
    locale: 'en-US',
    map: {},

    items: [
      {
        text: 'Home',
        disabled: false,
        href: '/',
      },
      {
        text: 'Building',
        disabled: true,
        href: 'breadcrumbs_link_1',
      },
    ],
    month: [
      {
        id: '1',
        name: '1 Month',
        value: '1',
        // value: '1 Month'
      },
      {
        id: '2',
        name: '3 Months',
        value: '3',
        // value: '3 Months'
      },
      {
        id: '3',
        name: '6 Months',
        value: '6',
        // value: '6 Months'
      },
      {
        id: '4',
        name: '12 Months',
        value: '12',
        // value: '12 Months'
      },
    ],
    duration: '1',
    activePicker: null,
    date_start: null,
    date_end: null,
    date: null,
    dateEnd: null,
    menu: false,
    snackbar: {
      state: false,
      text: '',
      content: '',
      color: 'success',
    },
    activePicker2: null,
    date2: null,
    dateEnd2: null,
    loading: false,
    loading_nearby: false,
    loading_quotation: false,
    menu2: false,
    detail_media: {},
    media_nearby: [],
  }),
  beforeRouteLeave(to, from, next) {
    this.$drift.api.widget.hide();
    // incase if you want to access `this`
    // const self = this as any;
    next();
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'));
    },
    menu2(val) {
      val && setTimeout(() => (this.activePicker2 = 'YEAR'));
    },
  },
  computed: {
    htmlToPdfOptions() {
      return {
        html2canvas: {
          taintTest: true,
          userCORS: false,
        },
      };
    },
  },
  created() {},
  async mounted() {
    if (this.$cookies.get('kinsan_lang') == 'jp') {
      this.locale = 'ja-US';
    } else {
      this.locale = 'en-US';
    }
    this.month[0].name = (await '1 ') + this.$t('month');
    this.month[1].name = (await '3 ') + this.$t('months');
    this.month[2].name = (await '6 ') + this.$t('months');
    this.month[3].name = (await '12 ') + this.$t('months');
    /* this.$drift.on('ready', async (api) => {
        // hide the widget when it first loads
        // api.widget.hide()
        // show the widget when you receive a message
        this.$drift.on('message', async (e) => {
          if (!e.data.sidebarOpen) {
            api.widget.show()
          }
        })
        // hide the widget when you close the sidebar
        this.$drift.on('sidebarClose', async (e) => {
          console.log(e);
          if (e.data.widgetVisible) {
            // api.widget.hide()
            this.$drift.hide();
          }
        })
      }) */
    // this.$drift.reset();
    /* this.$drift.identify(this.$store.getters.user.id, {
        name: this.$store.getters.user.given_name+' '+this.$store.getters.user.surname,
        email: this.$store.getters.user.email
      }) */
    // this.$drift.show();
    // console.log(this.$store.getters.user);
    // console.log(this.$route.params.id);
    await this.getDetailMedia(this.$route.params.id);
    // console.log(this.$store.getters.isLoggedIn)
    this.items[0].text = await this.$t('home');
    this.items[1].text = await this.$t('building');
    /* if (this.$store.getters.isLoggedIn) {
        // this.$drift.show();
        this.$drift.identify(this.$store.getters.user.id, {
          name: this.$store.getters.user.given_name + ' ' + this.$store.getters.user.surname,
          email: this.$store.getters.user.email
        })
      } */
  },
  methods: {
    toCurrency: function (value) {
      if (typeof value !== 'number') {
        return value;
      }
      // var formatter = new Intl.NumberFormat('en-IN', {
      var formatter = new Intl.NumberFormat('ja-JP', {
        // style: 'currency',
        // currency: 'IDR',
        // minimumFractionDigits: 0
      });
      return formatter.format(value);
    },
    async openChat() {
      this.loading_chat = true;
      if (this.$store.getters.isLoggedIn) {
        this.$drift.on('ready', async (api) => {
          await this.$drift.identify(this.$store.getters.user.id, {
            name: this.$store.getters.user.given_name + ' ' + this.$store.getters.user.surname,
            email: this.$store.getters.user.email,
          });
          if (!this.is_open) {
            // api.widget.show();
            await this.$drift.api.openChat();
            this.is_open = true;
            this.loading_chat = false;
          } else {
            api.widget.hide();
            this.is_open = false;
            this.loading_chat = false;
          }
          // hide the widget when it first loads
          // api.widget.hide()
          // show the widget when you receive a message
          this.$drift.on('message', async (e) => {
            if (!e.data.sidebarOpen) {
              api.widget.show();
              this.loading_chat = false;
            }
          });
          this.$drift.on('chatClose', async () => {
            api.widget.hide();
            this.is_open = false;
            this.loading_chat = false;
            //  this.$drift.hide();
          });
          // hide the widget when you close the sidebar
          /* this.$drift.on('sidebarClose', async (e) => {
              console.log(e);
              if (e.data.widgetVisible) {
                // api.widget.hide()
                this.$drift.hide();
              }
            }) */
        });
        /* await this.$drift.api.toggleChat();
          // await this.$drift.show();
          await this.$drift.identify(this.$store.getters.user.id, {
            name: this.$store.getters.user.given_name + ' ' + this.$store.getters.user.surname,
            email: this.$store.getters.user.email
          }) */
      } else {
        this.loading_chat = false;
        sessionStorage.setItem('kinsan_redirect', window.location.pathname);
        this.$router.push('/login');
      }
    },
    async getData() {
      if (!this.$store.getters.isLoggedIn) {
        sessionStorage.setItem('kinsan_redirect', window.location.pathname);
        this.$router.push('/login');
      } else {
        this.load = true;
        this.axios.get(`api/product/${this.$route.params.id}`).then(async (response) => {
          let res = response.data.data;
          this.data = res;
          this.img1 = `https://api.codetabs.com/v1/proxy/?quest=${res.photo[0]}`;
          this.media_type = res.media_type;
          this.size = res.unit_of_measurement;
          this.orientation = res.orientation;
          this.traffic = res.traffic;
          this.video = res.video;
          this.price = res.minimum_price;
          this.map = res;

          if (res.is_lighting === 'true') {
            this.lighting = 'avaiable';
          } else {
            this.lighting = 'not avaiable';
          }

          this.generateReport();
          this.load = false;
        });
      }
    },
    generateReport() {
      this.$refs.sheet.generatePdf();
    },
    async beforeDownload({ html2pdf, options, pdfContent }) {
      html2pdf()
        .set(options)
        .from(pdfContent)
        .toPdf()
        .output('blob')

        .then((blob) => {
          let formData = new FormData();
          let certif = blob;

          console.log('bb', certif);

          // formData.append("file", certif, "Sertifikat.pdf");
          formData.append('source', 'upload');
          // formData.append("title", "Sertifikat");

          // this.$store
          //   .dispatch("media/create", formData)
          //   .then((res) => {
          //     if (res.status == 200) {
          //       this.id_certif = res.data.id;
          //       this.updateCertif(this.id_certif);
          //       console.log("result", this.id_certif);
          //     }
          //   })
          //   .catch((error) => {
          //     console.log("error", error);
          //   });
        });
    },
    hasGenerated($event) {
      console.log($event);
    },
    reqQuotation() {
      if (!this.$store.getters.isLoggedIn) {
        sessionStorage.setItem('kinsan_redirect', window.location.pathname);
        this.$router.push('/login');
      } else {
        if (!this.date_start || !this.date_end) {
          alert(this.$t('choose_start_date'));
        } else {
          this.loading_quotation = true;
          const data = {
            product_id: this.$route.params.id,
            start_date: this.date_start,
            end_date: this.date_end,
            duration: this.duration + (this.duration == 1 ? ' Month' : ' Months'),
            // "duration": this.duration
          };
          this.$store
            .dispatch('requestQuotation', data)
            .then(() => {
              if (this.$i18n.locale === 'en') {
                this.snackbar.text = 'Request sent';
                this.snackbar.content =
                  "Your request has been accepted. You will be redirected to the transaction page in 5 seconds or click <a href='/transaction'>here</a> to check transaction.";
              } else {
                this.snackbar.text = '見積書を請求しました! ';
                this.snackbar.content =
                  '請求を受け付けました。５秒以内に取引ページに移動します, またはここをクリックして取引を確認してください。';
              }

              this.snackbar.state = true;
              this.snackbar.color = 'success';
              this.loading_quotation = false;
              setTimeout(() => {
                this.$router.push('/transaction');
              }, 5000);
            })
            .catch((error) => {
              console.log(error);
              this.snackbar.text = 'Request failed';
              this.snackbar.content = 'Something error';
              this.snackbar.state = true;
              this.snackbar.color = 'error';
              this.loading_quotation = false;
              // window.location.href = '/'
            });
        }
      }
    },
    getDetailMedia(id) {
      this.loading = true;
      this.$store
        .dispatch('getDetailMedia', id)
        .then((data) => {
          this.detail_media = data;
          if (
            this.detail_media &&
            this.detail_media.location &&
            this.detail_media.location.coordinates[1] &&
            this.detail_media.location.coordinates[0]
          ) {
            this.getMediaNearby(this.detail_media.location.coordinates[1], this.detail_media.location.coordinates[0]);
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          // this.loading = false
          // window.location.href = '/'
        });
    },
    getMediaNearby(latitude, longitude) {
      this.loading_nearby = true;
      this.$store
        .dispatch('getMediaNearby', {
          latitude,
          longitude,
        })
        .then((data) => {
          this.media_nearby = data;
          this.loading_nearby = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading_nearby = false;
        });
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    save2(date) {
      this.$refs.menu2.save(date);
    },
    dateMonth(a) {
      let currentDate = moment(a);
      let test = moment(currentDate).daysInMonth();
      // console.log(test-1)
      let futureYear = moment(currentDate).add(test - 1, 'days');
      this.date_end = moment(futureYear).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
      if (this.$i18n.locale === 'en') {
        return moment(futureYear).format('MMMM D, YYYY');
      } else {
        return moment(futureYear).lang('ja').format('MMMM D, YYYY');
      }
    },
    dateMonth3(a) {
      let currentDate = moment(a);
      // let test=moment(currentDate).daysInMonth();
      // console.log(test-1)
      let futureYears = moment(currentDate).add(3, 'M');
      let futureYear = moment(futureYears).add(-1, 'days');
      this.date_end = moment(futureYear).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
      if (this.$i18n.locale === 'en') {
        return moment(futureYear).format('MMMM D, YYYY');
      } else {
        return moment(futureYear).lang('ja').format('MMMM D, YYYY');
      }
    },
    dateMonth6(a) {
      let currentDate = moment(a);
      let futureYears = moment(currentDate).add(6, 'M');
      let futureYear = moment(futureYears).add(-1, 'days');
      this.date_end = moment(futureYear).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
      if (this.$i18n.locale === 'en') {
        return moment(futureYear).format('MMMM D, YYYY');
      } else {
        return moment(futureYear).lang('ja').format('MMMM D, YYYY');
      }
    },
    dateYear(a) {
      let currentDate = moment(a);
      let futureYears = moment(currentDate).add(1, 'Y');
      let futureYear = moment(futureYears).add(-1, 'days');
      this.date_end = moment(futureYear).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
      if (this.$i18n.locale === 'en') {
        return moment(futureYear).format('MMMM D, YYYY');
      } else {
        return moment(futureYear).lang('ja').format('MMMM D, YYYY');
      }
    },
    dateStart(a) {
      this.date_start = moment(a).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
      if (this.$i18n.locale === 'en') {
        return moment(a).format('MMMM D, YYYY');
      } else {
        return moment(a).lang('ja').format('MMMM D, YYYY');
      }
      // return moment(a).format("MMMM D, YYYY");
    },
  },
};
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Poppins:wght@400;500;700&display=swap');

tr:nth-child(even) {
  background-color: #f9fafc;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row) {
  border-bottom: 0px solid transparent !important;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: 0px solid transparent !important;
  font-size: 14px;
  font-weight: 600;
  color: #8492a6;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr {
  color: #3c4858;
}

.theme--light.v-data-table .v-data-footer {
  border-top: 0px solid transparent !important;
  padding: 16px !important;
}

.v-data-footer__select {
  justify-content: left !important;
  position: absolute;
  left: 20px;
}

.vue-html2pdf .layout-container[data-v-1fd3ad26] {
  background: #fff !important;
}

.vue-html2pdf tr:nth-child(even) {
  background-color: #fff !important;
}

@media print {
  @page {
    size: portrait;
  }
}
</style>
