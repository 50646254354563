<template>
  <div class="">
    <section id="filter" class="py-5" style="background: #f9fafa">
      <!-- <div class=""> -->
      <div class="col-md-11 mx-auto">
        <h2 class="font-weight-bold mb-2">{{ $t('find_deals_on_advertising_space_here') }}</h2>
        <p class="fs-14 color-state">
          {{
            $t(
              'search_your_suitable_advertising_media_compare_the_price_and_book_securely_in_a_few_clicks'
            )
          }}
        </p>
        <!-- <div class="col-md-12"> -->
        <div class="row mt-3">
          <div class="col-md-4 py-0 px-0">
            <v-autocomplete
              clearable
              multiple
              :items="type"
              :no-data-text="$t('no_data')"
              :item-text="title_type"
              item-value="title"
              v-model="getType"
              outlined
              :placeholder="$t('all_type')"
              @change="getLocS()"
            ></v-autocomplete>
          </div>
          <div class="col-md-6 py-0 px-0">
            <v-autocomplete
              clearable
              :items="setName"
              item-text="title"
              return-object
              :item-value="`${name_product._id}, ${name_product.title}`"
              v-model="name_product"
              outlined
              :placeholder="$t('all_location')"
              :no-data-text="$t('no_data')"
              class=""
              @change="getDetailPage()"
            ></v-autocomplete>
          </div>
          <!-- <div class="col-md-5 py-0">
            <v-autocomplete clearable :items="location" item-text="_id" item-value="_id" v-model="loc" outlined
              :placeholder="$t('all_location')" class="" prepend-inner-icon="ri-map-pin-2-fill"></v-autocomplete>
          </div>
          <div class="col-md-5 py-0">
            <v-autocomplete clearable :items="type" :item-text="title_type" item-value="title" v-model="media_type"
              outlined :placeholder="$t('all_type')" prepend-inner-icon="ri-picture-in-picture-line"></v-autocomplete>
          </div> -->
          <div class="col-md-1 align-center text-center py-0 px-5">
            <v-btn
              depressed
              block
              color="primary"
              class="rounded-8"
              style="width: 150px"
              @click="searchBtn()"
            >
              {{ $t('search') }}
            </v-btn>
          </div>
        </div>
        <!-- </div> -->
        <!-- </div> -->
      </div>
    </section>

    <!-- detail content -->
    <section class="detail-content white py-5">
      <div class="col-md-11 mx-auto">
        <div class="row">
          <div class="col-md-2 d-none d-lg-block">
            <h2 class="mb-3">{{ $t('filter_by') }}</h2>
            <div class="box-category mb-5">
              <p class="fs-18 font-weight-medium mb-1">{{ $t('category') }}</p>
              <v-checkbox
                v-for="(item, index) in list_category"
                :key="index"
                v-model="category"
                :label="$i18n.locale == 'jp' ? item.title_ja : item.title"
                :value="item.title"
                @click="searchByCategory"
                hide-details
                class="mt-1"
              ></v-checkbox>
              <!-- <v-checkbox
              v-model="ex"
              label="All"
              value="all"
              hide-details
              class="mt-1"
              ></v-checkbox>
              <v-checkbox
                v-model="ex"
                label="Discount Campaign"
                value="Discount Campaign"
                hide-details
                class="mt-1"
              ></v-checkbox>
              <v-checkbox
                v-model="ex"
                label="Latest Media"
                value="Latest Media"
                hide-details
                class="mt-1"
              ></v-checkbox>
              <v-checkbox
                v-model="ex"
                label="Popular"
                value="Popular"
                hide-details
                class="mt-1"
              ></v-checkbox> -->
            </div>
            <div class="box-market mb-5">
              <p class="fs-18 font-weight-medium mb-1">{{ $t('target_market') }}</p>
              <!-- <v-checkbox
              v-model="ex"
              label="All"
              value="all"
              hide-details
              class="mt-1"
              ></v-checkbox> -->
              <v-checkbox
                v-for="(item, index) in list_target_market"
                :key="index"
                v-model="target_market"
                :label="$i18n.locale == 'jp' ? item.title_ja : item.title"
                :value="item.title"
                @click="targetMarket"
                hide-details
                class="mt-1"
              ></v-checkbox>
              <!-- <v-checkbox
                v-model="ex"
                label="Family"
                value="Family"
                hide-details
                class="mt-1"
              ></v-checkbox>
              <v-checkbox
                v-model="ex"
                label="Student"
                value="Student"
                hide-details
                class="mt-1"
              ></v-checkbox>
              <v-checkbox
                v-model="ex"
                label="Doctor"
                value="Doctor"
                hide-details
                class="mt-1"
              ></v-checkbox> -->
            </div>
            <div class="box-market mb-5">
              <p class="fs-18 font-weight-medium mb-1">{{ $t('price') }}</p>
              <v-row>
                <v-col class="pb-1">
                  <div class="mb-2">
                    <span class="font-weight-medium">{{ $t('min') }}</span>
                  </div>
                  <v-text-field
                    type="number"
                    v-model="min"
                    outlined
                    hide-details="auto"
                    class="rounded-8"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-1">
                  <div class="mb-2">
                    <span class="font-weight-medium">{{ $t('max') }}</span>
                  </div>
                  <v-text-field
                    type="number"
                    v-model="max"
                    outlined
                    hide-details="auto"
                    class="rounded-8"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </div>
            <div class="btn-apply text-end align-self-end">
              <v-btn small depressed color="primary" class="rounded-8" @click="search()">
                {{ $t('apply') }}
              </v-btn>
            </div>
          </div>
          <div class="col-md-10">
            <h2 class="mb-3">{{ $t('search_result') }}</h2>
            <div class="box-search">
              <v-chip
                v-if="this.$route.query.location"
                class="ma-2"
                close
                @click:close="
                  loc = false;
                  search();
                "
              >
                {{ $t('location') }} : {{ this.$route.query.location }}
              </v-chip>
              <v-chip
                v-if="this.$route.query.keyword"
                class="ma-2"
                close
                @click:close="
                  keyword = [];
                  search();
                "
              >
                keyword : {{ this.$route.query.keyword }}
              </v-chip>
              <v-chip
                v-if="this.$route.query.media_type"
                class="ma-2"
                close
                @click:close="
                  media_type = false;
                  search();
                "
              >
                {{ $t('media_type') }} :
                {{
                  $i18n.locale == 'jp'
                    ? type &&
                      type[getKeyByValue(type, 'title', this.$route.query.media_type)] &&
                      type[getKeyByValue(type, 'title', this.$route.query.media_type)].title_ja
                      ? type[getKeyByValue(type, 'title', this.$route.query.media_type)].title_ja
                      : ''
                    : this.$route.query.media_type
                }}
              </v-chip>
              <v-chip
                v-if="target_market.length > 0"
                class="ma-2"
                close
                @click:close="
                  target_market = [];
                  search();
                "
              >
                {{ $t('target_market') }} :
                {{
                  $i18n.locale == 'jp'
                    ? filterArray(target_market, list_target_market).join(',')
                    : target_market.join(',')
                }}
              </v-chip>
              <v-chip
                v-if="category.length > 0"
                class="ma-2"
                close
                @click:close="
                  category = [];
                  search();
                "
              >
                {{ $t('category') }} :
                {{
                  $i18n.locale == 'jp'
                    ? filterArray(category, list_category).join(',')
                    : target_market.join(',')
                }}
              </v-chip>
              <v-chip
                v-if="this.$route.query.min > 0"
                class="ma-2"
                close
                @click:close="
                  min = 0;
                  search();
                "
              >
                {{ $t('price_minimum') }} : {{ this.$route.query.min }}
              </v-chip>
              <v-chip
                v-if="this.$route.query.max > 0"
                class="ma-2"
                close
                @click:close="
                  max = 0;
                  search();
                "
              >
                {{ $t('price_maximum') }} : {{ this.$route.query.max }}
              </v-chip>
            </div>
            <div class="box-data my-3 mt-10">
              <div v-if="loading" class="row mb-3 justify-center" style="min-height: 400px">
                <v-skeleton-loader
                  v-for="index in 10"
                  :key="index"
                  class="mx-auto"
                  width="210"
                  type="card"
                >
                </v-skeleton-loader>
              </div>
              <div v-else class="row mb-3 ml-3 justify-center mx-auto">
                <span class="row mb-3 justify-center" v-if="list_media.length == 0">
                  <img
                    class="mt-15 mb-10"
                    src="@/assets/images/empty-media.png"
                    style="width: 300px; height: 300px; object-fit: cover"
                  />
                  <div
                    class="px-15 mb-15"
                    style="color: #47086880; font-size: 18px; text-align: center; line-height: 38px"
                  >
                    {{
                      $t(
                        'your_search_did_not_match_any_products_please_change_your_search_criteria_and_try_again_if_still_not_finding_anything_relevant_please_click_the_inquiry_button'
                      )
                    }}
                  </div>
                </span>
                <v-card
                  v-else
                  v-for="(item, index) in list_media"
                  :key="index"
                  class="mr-5 mb-5"
                  max-width="220"
                  height="auto"
                  :to="{
                    name: 'detail-page',
                    params: { id: item._id, slug: item.title.replace(/ /g, '-').toLowerCase() },
                  }"
                  target="_blank"
                >
                  <v-img height="140" :src="item.photo[0]"></v-img>

                  <p
                    v-if="$i18n.locale == 'en'"
                    class="font-weight-medium fs-18 wb mb-0 pa-2 text-dots-2"
                    style="min-height: 60px"
                  >
                    {{
                      item.media_type && item.media_type.title ? item.media_type.title + ' - ' : ''
                    }}{{ item.title }}
                  </p>
                  <p
                    v-else
                    class="font-weight-medium fs-18 wb mb-0 pa-2 text-dots-2"
                    style="min-height: 60px"
                  >
                    {{
                      item.media_type && item.media_type.title_ja
                        ? item.media_type.title_ja + ' - '
                        : ''
                    }}{{ item.title }}
                  </p>

                  <v-card-text class="pt-0 px-2">
                    <p v-if="$i18n.locale == 'en'" class="color-oranye mb-0">
                      {{ $t('start_from_amount', { amount: item.minimum_price }) }}
                    </p>
                    <p v-else class="color-oranye mb-0">￥{{ item.minimum_price }} から</p>
                    <p class="text-dots-2 mb-0">
                      {{ item && item.description ? item.description : '' }}
                    </p>
                  </v-card-text>
                </v-card>
              </div>
              <div class="pagination" v-if="list_media.length > 0">
                <v-pagination
                  v-model="page"
                  :length="total_page"
                  :value="page"
                  :total-visible="7"
                  @input="getMedia(page)"
                ></v-pagination>
                <!-- <v-pagination v-model="page" :length="total_page" :value="page" :total-visible="total_pagination" @input="getMedia(page)"></v-pagination> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <v-btn
      fab
      color="grey accent-2"
      class="filter-search d-lg-none d-md-block"
      @click="filter = !filter"
    >
      <v-icon>ri-filter-3-fill</v-icon>
    </v-btn>
    <v-dialog v-model="filter" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-card-title>
          <p class="mb-0">Filter by</p>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="filter = false" color="primary">
            <v-icon>ri-close-fill</v-icon>
          </v-btn>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-card-text class="">
          <div class="box-market mb-5">
            <p class="fs-18 font-weight-medium mb-1">Target Market</p>
            <v-checkbox
              v-for="(item, index) in list_target_market"
              :key="index"
              v-model="target_market"
              :label="$i18n.locale == 'jp' ? item.title_ja : item.title"
              :value="item.title"
              @click="targetMarket"
              hide-details
              class="mt-1"
            ></v-checkbox>
          </div>
          <div class="box-market mb-5">
            <p class="fs-18 font-weight-medium mb-1">Price</p>
            <v-row>
              <v-col class="pb-1">
                <div class="mb-2">
                  <span class="font-weight-medium">Min</span>
                </div>
                <v-text-field
                  type="number"
                  v-model="min"
                  outlined
                  hide-details="auto"
                  class="rounded-8"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pb-1">
                <div class="mb-2">
                  <span class="font-weight-medium">Max</span>
                </div>
                <v-text-field
                  type="number"
                  v-model="max"
                  outlined
                  hide-details="auto"
                  class="rounded-8"
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
          <div class="btn-apply text-end align-self-end">
            <v-btn
              small
              depressed
              color="primary"
              class="rounded-8"
              @click="
                filter = false;
                search();
              "
            >
              Apply
            </v-btn>
          </div>
          <!-- </div> -->
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'public-search',
  data: () => ({
    items: ['1', '2', '3'],
    // location:["Kairuzawa","Yamanouchi","Nagano","Tokyo","Yuzawa","Nagoya"],
    setType: [],
    setName: [],
    search_name: false,
    search_type: [],
    location: [],
    type: [],
    getType: [],
    // type:["Building","Station","Magazine","Influencer","Transportation","Flyer"],
    ex: ['all'],
    keyword: null,
    model: null,
    chip1: true,
    chip2: true,
    loc: false,
    key: false,
    // media_type: false,
    query: {},
    list_media: [],
    target_market: [],
    list_target_market: [],
    category: [],
    list_category: [],
    min: 0,
    max: 0,
    limit: 10,
    page: 1,
    total_pagination: 0,
    total_page: 0,
    filter: false,
    loading: false,
    title: '',
    name_product: {},
  }),
  computed: {
    title_type() {
      if (this.$i18n.locale == 'jp') return 'title_ja';
      return 'title';
    },
    /* loc() {
        return this.$route.query.loc || 1
        // return this.$router.replace({ name: "search-page", query: {loc: this.loc} })
      },
      media_type() {
        return this.$route.query.media_type || 1
        // return this.$router.replace({ name: "search-page", query: {media_type: this.media_type} })
      }, */
  },
  created() {},
  mounted() {
    // console.log(this.$route.query.media_type);
    if (this.$route.query.location) {
      this.loc = this.$route.query.location;
    }
    if (this.$route.query.keyword) {
      this.keyword = this.$route.query.keyword;
    }
    if (this.$route.query.media_type) {
      this.media_type = this.$route.query.media_type;
    }
    if (this.$route.query.target_market) {
      this.target_market = this.$route.query.target_market.split(',');
    }
    if (this.$route.query.category) {
      this.category = this.$route.query.category.split(',');
    }
    if (this.$route.query.min) {
      this.min = this.$route.query.min;
    }
    if (this.$route.query.max) {
      this.max = this.$route.query.max;
    }

    this.search();
    this.getLocation();
    this.getLocS();
    this.getMediatype();
    this.getCategory();
    this.getTargetmarket();
    this.getMedia();
  },
  methods: {
    getLocS() {
      let media = this.getType.join(',');

      let params = {
        page: 1,
        limit: 99,
        media_type: media,
      };
      params = {
        ...params,
      };
      this.$store
        .dispatch('getMedia', params)
        .then((data) => {
          this.setName = data.results;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getKeyByValue(object, keyword, value) {
      return Object.keys(object).find((key) => object[key][keyword] === value);
    },
    filterArray(list, object) {
      return list.map((value) => {
        return object &&
          object[this.getKeyByValue(object, 'title', value)] &&
          object[this.getKeyByValue(object, 'title', value)].title_ja
          ? object[this.getKeyByValue(object, 'title', value)].title_ja
          : '';
      });
    },
    objectToQueryString(obj) {
      var str = [];
      // if (obj.hasOwnProperty(p)) {
      for (var p in obj)
        if (Object.prototype.hasOwnProperty.call(obj, p)) {
          str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
        }
      return str.join('&');
    },
    targetMarket() {
      // console.log(this.query);
      delete this.query.target_market;
      if (this.target_market.length > 0) {
        this.query['target_market'] = this.target_market.join(',');
        /* const path = `/search?${this.objectToQueryString(this.query)}`
          if (this.$route.path !== path){
            //this.$router.push(path)
            this.$router.push({
              name: 'search-page',
              query: this.query
            });
          } */
        this.$router
          .push({
            name: 'search-page',
            query: this.query,
          })
          .catch(() => {});
      } else if (this.target_market.length == 0 && Object.keys(this.query).length > 0) {
        this.$router.push({
          name: 'search-page',
          query: this.query,
        });
      } else {
        this.$router.push({
          name: 'search-page',
        });
      }
      this.getMedia();
    },
    searchByCategory() {
      delete this.query.category;
      if (this.category.length > 0) {
        this.query['category'] = this.category.join(',');
        this.$router
          .push({
            name: 'search-page',
            query: this.query,
          })
          .catch(() => {});
      } else if (this.category.length == 0 && Object.keys(this.query).length > 0) {
        this.$router.push({
          name: 'search-page',
          query: this.query,
        });
      } else {
        this.$router.push({
          name: 'search-page',
        });
      }
      this.getMedia();
    },
    search() {
      // this.query = {}
      delete this.query.category;
      delete this.query.target_market;
      delete this.query.media_type;
      delete this.query.location;
      delete this.query.keyword;
      delete this.query.min;
      delete this.query.max;
      if (this.min != 0 && this.min > 0) {
        this.query['min'] = this.min;
      }
      if (this.max != 0 && this.max > 0) {
        this.query['max'] = this.max;
      }
      if (this.media_type != '') {
        // let media = this.media_type.join(',')
        this.query['media_type'] = this.media_type;
      }
      if (this.target_market.length > 0) {
        this.query['target_market'] = this.target_market.join(',');
      }
      if (this.category.length > 0) {
        this.query['category'] = this.category.join(',');
      }
      if (this.loc != '') {
        this.query['location'] = this.loc;
      }
      if (this.keyword != '') {
        this.query['keyword'] = this.keyword;
      }
      /* const path = `/search?${this.objectToQueryString(this.query)}`
        console.log(path);
        console.log(this.$route.path);
        if (this.$route.path !== path){
          //this.$router.push(path)
          this.$router.push({
            name: 'search-page',
            query: this.query
          });
        } */
      this.$router
        .push({
          name: 'search-page',
          query: this.query,
        })
        .catch(() => {});
      this.getMedia();
    },
    searchBtn() {
      if (this.getType != []) {
        let media = this.getType.join(',');
        this.query['media_type'] = media;
      }

      this.$router
        .push({
          name: 'search-page',
          query: this.query,
        })
        .catch(() => {});
      this.getMedia();
    },
    getDetailPage() {
      // console.log(this.name_product)
      this.$router.push({
        name: 'detail-page',
        params: {
          id: this.name_product._id,
          slug: this.name_product.title.replace(/ /g, '-').toLowerCase(),
        },
      });
    },
    getLocation() {
      this.$store
        .dispatch('getLocation', {})
        .then((data) => {
          this.location = data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getMediatype() {
      this.$store
        .dispatch('getMediatype', {})
        .then((data) => {
          this.type = data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getTargetmarket() {
      this.$store
        .dispatch('getTargetmarket', {})
        .then((data) => {
          this.list_target_market = data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCategory() {
      this.$store
        .dispatch('getCategory', {})
        .then((data) => {
          this.list_category = data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getMedia(page = 1) {
      this.loading = true;
      let params = {
        page: page,
        limit: this.limit,
      };
      params = {
        ...params,
        ...this.query,
      };
      if (this.status != '' && this.status != 'all') {
        params.status = this.status;
      } else {
        delete params.status;
      }
      if (this.keyword != '') {
        params.keyword = this.keyword;
      } else {
        delete params.keyword;
      }
      this.$store
        .dispatch('getMedia', params)
        .then((data) => {
          this.list_media = data.results;
          this.page = data.pagination.current;
          this.total_page = data.pagination.total_page;
          this.total_pagination =
            data.pagination.detail.length == 0 ? 1 : data.pagination.detail.length;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    login() {
      this.$router.push('/auth/login');
    },
  },
};
</script>
<style>
.filter-search {
  right: 5%;
  bottom: 2%;
  position: fixed;
  line-height: inherit;
  z-index: 99;
}
</style>
