<template>
  <div class="">
    <section id="filter" class="py-5" style="background: #f9fafa">
      <!-- <div class=""> -->
      <div class="col-md-11 mx-auto">
        <h1 class="font-weight-bold mb-2 fs-24">
          {{ $t('find_deals_on_advertising_space_here') }}
        </h1>
        <p class="fs-14 color-state">
          {{
            $t(
              'search_your_suitable_advertising_media_compare_the_price_and_book_securely_in_a_few_clicks'
            )
          }}
        </p>
        <!-- <div class="col-md-12"> -->
        <div class="row mt-3">
          <div class="col-md-4 py-0 px-0">
            <v-autocomplete
              clearable
              multiple
              :items="type"
              :no-data-text="$t('no_data')"
              :item-text="title_type"
              item-value="title"
              v-model="media_type"
              outlined
              :placeholder="$t('all_type')"
              @change="getLoc()"
            ></v-autocomplete>
          </div>
          <div class="col-md-6 py-0 px-0">
            <v-autocomplete
              clearable
              :items="location"
              item-text="title"
              return-object
              :item-value="`${loc._id}, ${loc.title}`"
              v-model="loc"
              outlined
              :placeholder="$t('all_location')"
              :no-data-text="$t('no_data')"
              class=""
              @change="getDetailPage()"
            ></v-autocomplete>
          </div>
          <div class="col-md-1 align-center text-center py-0 px-5">
            <v-btn
              depressed
              block
              color="primary"
              class="rounded-8"
              style="width: 150px"
              @click="search()"
            >
              {{ $t('search') }}
            </v-btn>
          </div>
        </div>
        <!-- </div> -->
        <!-- </div> -->
      </div>
    </section>

    <!-- browser location -->
    <section class="location white py-6">
      <div class="col-md-11 mx-auto">
        <h1 class="font-weight-bold mb-6 fs-24">{{ $t('browse_by_location') }}</h1>
        <div class="location-1 mb-3">
          <div class="row">
            <div class="col-md-12" v-if="loading">
              <v-skeleton-loader
                class="col-md-6 d-inline-block"
                type="image"
                v-for="item in 2"
                :key="item"
              >
              </v-skeleton-loader>
            </div>
            <div
              class="test col-md-6"
              v-if="homepage && homepage.browse_by_location && homepage.browse_by_location[0]"
            >
              <v-card
                elevation="0"
                height="320px"
                :to="{ name: 'search-page', query: { location: 'Yuzawa' } }"
                style=""
              >
                <img
                  src="@/assets/images/1.jpeg"
                  class="rounded"
                  style="width: 100%; height: 320px; object-fit: cover"
                />
                <div class="top-left white--text pa-3 rounded">
                  <p class="fs-20 font-weight-medium mb-1 ts-title">Yuzawa</p>
                  <p class="">
                    {{ homepage.browse_by_location[0].count }}
                    {{ $cookies.get('kinsan_lang') == 'jp' ? 'メディア' : 'media' }}
                  </p>
                </div>
              </v-card>
            </div>
            <div
              class="test col-md-6"
              v-if="homepage && homepage.browse_by_location && homepage.browse_by_location[1]"
            >
              <v-card
                elevation="0"
                height="320px"
                :to="{ name: 'search-page', query: { location: 'Karuizawa' } }"
                style=""
              >
                <img
                  src="@/assets/images/2.jpeg"
                  class="rounded"
                  style="width: 100%; height: 320px; object-fit: cover"
                />
                <div class="top-left white--text pa-3 rounded">
                  <p class="fs-20 font-weight-medium mb-1 ts-title">Karuizawa</p>
                  <p class="">
                    {{ homepage.browse_by_location[1].count }}
                    {{ $cookies.get('kinsan_lang') == 'jp' ? 'メディア' : 'media' }}
                  </p>
                </div>
              </v-card>
            </div>
          </div>
        </div>
        <div class="location-2">
          <div class="col-md-12 px-0">
            <div class="row">
              <div class="col-md-12" v-if="loading">
                <v-skeleton-loader
                  class="col-md-4 d-inline-block"
                  type="image"
                  v-for="item in 3"
                  :key="item"
                >
                </v-skeleton-loader>
              </div>
              <div
                class="test col-md-4"
                v-if="homepage && homepage.browse_by_location && homepage.browse_by_location[2]"
              >
                <v-card
                  elevation="0"
                  height="320px"
                  :to="{ name: 'search-page', query: { location: 'Yamanouchi' } }"
                  style=""
                >
                  <img
                    src="@/assets/images/3.jpeg"
                    class="rounded"
                    style="width: 100%; height: 320px; object-fit: cover"
                  />
                  <div class="top-left white--text pa-3 rounded">
                    <p class="fs-20 font-weight-medium mb-1 ts-title">Yamanouchi</p>
                    <p class="">
                      {{ homepage.browse_by_location[2].count }}
                      {{ $cookies.get('kinsan_lang') == 'jp' ? 'メディア' : 'media' }}
                    </p>
                  </div>
                </v-card>
              </div>
              <div
                class="test col-md-4"
                v-if="homepage && homepage.browse_by_location && homepage.browse_by_location[3]"
              >
                <v-card
                  elevation="0"
                  height="320px"
                  :to="{ name: 'search-page', query: { location: 'Nagano' } }"
                  style=""
                >
                  <img
                    src="@/assets/images/4.jpeg"
                    class="rounded"
                    style="width: 100%; height: 320px; object-fit: cover"
                  />
                  <div class="top-left white--text pa-3 rounded">
                    <p class="fs-20 font-weight-medium mb-1 ts-title">Nagano</p>
                    <p class="">
                      {{ homepage.browse_by_location[3].count }}
                      {{ $cookies.get('kinsan_lang') == 'jp' ? 'メディア' : 'media' }}
                    </p>
                  </div>
                </v-card>
              </div>
              <div
                class="test col-md-4"
                v-if="homepage && homepage.browse_by_location && homepage.browse_by_location[4]"
              >
                <v-card
                  elevation="0"
                  height="320px"
                  :to="{ name: 'search-page', query: { location: 'Tokyo' } }"
                  style=""
                >
                  <img
                    src="@/assets/images/5.jpeg"
                    class="rounded"
                    style="width: 100%; height: 320px; object-fit: cover"
                  />
                  <div class="top-left white--text pa-3 rounded">
                    <p class="fs-20 font-weight-medium mb-1 ts-title">Tokyo</p>
                    <p class="">
                      {{ homepage.browse_by_location[4].count }}
                      {{ $cookies.get('kinsan_lang') == 'jp' ? 'メディア' : 'media' }}
                    </p>
                  </div>
                </v-card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- category -->
    <section class="category py-6">
      <div class="col-md-11 mx-auto">
        <h1 class="font-weight-bold mb-6 fs-24">{{ $t('recommended_category') }}</h1>
        <div class="slide-category">
          <div v-if="loading">
            <v-skeleton-loader
              class="col-md-11 d-inline-block"
              style="width: 350px; height: auto"
              type="card"
              v-for="item in 4"
              :key="item"
            >
            </v-skeleton-loader>
          </div>
          <carousel
            v-if="homepage.recommended_category && homepage.recommended_category.length > 0"
            :responsive="{ 0: { items: 1 }, 425: { items: 1 }, 600: { items: 3 } }"
            :navText="[`<i class='ri-arrow-left-line'></i>`, `<i class='ri-arrow-right-line'></i>`]"
            :nav="true"
            :loop="true"
            :autoplay="true"
          >
            <div
              class="box-slide mx-2"
              v-for="(item, index) in homepage.recommended_category"
              :key="index"
            >
              <v-card
                class="bg-transparent"
                elevation="0"
                :to="{ name: 'search-page', query: { category: item.title } }"
              >
                <img
                  :src="item.thumbnail"
                  class="rounded"
                  style="width: 100%; height: 320px; object-fit: cover"
                />
                <div class="py-3">
                  <p class="fs-20 font-weight-medium mb-1">
                    {{ $cookies.get('kinsan_lang') == 'jp' ? item.title_ja : item.title }}
                  </p>
                  <p class="mb-0">
                    {{ item.count }}
                    {{ $cookies.get('kinsan_lang') == 'jp' ? 'メディア' : 'media' }}
                  </p>
                </div>
              </v-card>
            </div>
            <!-- <div class="box-slide mx-2">
                <v-card class="bg-transparent" elevation="0" :to="{ name: 'search-page', query: { id: 123 } }">
                  <img src="@/assets/images/cat-1.jpg" style="width: 100%;height: 320px;object-fit: cover;">
                  <div class="py-3">
                    <p class="fs-20 font-weight-bold mb-1">Discount Campaign</p>
                    <p class="mb-0">1200 media</p>
                  </div>                              
                </v-card>  
              </div>
              <div class="box-slide mx-2">
                <v-card class="bg-transparent" elevation="0" :to="{ name: 'search-page', query: { id: 123 } }">
                  <img src="@/assets/images/cat-2.jpg" style="width: 100%;height: 320px;object-fit: cover;">
                  <div class="py-3">
                    <p class="fs-20 font-weight-bold mb-1">Latest</p>
                    <p class="mb-0">1200 media</p>
                  </div>                              
                </v-card>  
              </div>
              <div class="box-slide mx-2">
                <v-card class="bg-transparent" elevation="0" :to="{ name: 'search-page', query: { id: 123 } }">
                  <img src="@/assets/images/cat-3.jpg" style="width: 100%;height: 320px;object-fit: cover;">
                  <div class="py-3">
                    <p class="fs-20 font-weight-bold mb-1">Popular</p>
                    <p class="mb-0">1200 media</p>
                  </div>                              
                </v-card>  
              </div>
              <div class="box-slide mx-2">
                <v-card class="bg-transparent" elevation="0" :to="{ name: 'search-page', query: { id: 123 } }">
                  <img src="@/assets/images/cat-2.jpg" style="width: 100%;height: 320px;object-fit: cover;">
                  <div class="py-3">
                    <p class="fs-20 font-weight-bold mb-1">Popular</p>
                    <p class="mb-0">1200 media</p>
                  </div>                              
                </v-card>  
              </div> -->
          </carousel>
          <!-- <v-slide-group
            v-model="model"
            class="pa-4"
            show-arrows
          >
            <v-slide-item>
              <div class="col-md-3">
                <v-card>
                  <img src="https://cdn.vuetifyjs.com/images/cards/house.jpg" style="width: 100%;height: 320px;object-fit: cover;">
                  <v-card-title>testing</v-card-title>                              
                </v-card>  
              </div>              
            </v-slide-item>
            <v-slide-item>
              <div class="col-md-3">
                <v-card>
                  <img src="https://cdn.vuetifyjs.com/images/cards/house.jpg" style="width: 100%;height: 320px;object-fit: cover;">
                  <v-card-title>testing</v-card-title>                              
                </v-card>  
              </div>              
            </v-slide-item>
            <v-slide-item>
              <div class="col-md-3">
                <v-card>
                  <img src="https://cdn.vuetifyjs.com/images/cards/house.jpg" style="width: 100%;height: 320px;object-fit: cover;">
                  <v-card-title>testing</v-card-title>                              
                </v-card>  
              </div>              
            </v-slide-item>
            <v-slide-item>
              <div class="col-md-3">
                <v-card>
                  <img src="https://cdn.vuetifyjs.com/images/cards/house.jpg" style="width: 100%;height: 320px;object-fit: cover;">
                  <v-card-title>testing</v-card-title>                              
                </v-card>  
              </div>              
            </v-slide-item>
          </v-slide-group> -->
        </div>
      </div>
    </section>

    <!-- type -->
    <section class="type white py-6">
      <div class="col-md-11 mx-auto">
        <h1 class="font-weight-bold mb-6 pt-3 fs-24">{{ $t('browse_media_type') }}</h1>
        <div class="slide-category">
          <div v-if="loading">
            <v-skeleton-loader
              class="col-md-11 d-inline-block"
              style="width: 350px; height: auto"
              type="card"
              v-for="item in 4"
              :key="item"
            >
            </v-skeleton-loader>
          </div>
          <carousel
            v-if="type && type.length > 0"
            :responsive="{ 0: { items: 1 }, 425: { items: 1 }, 600: { items: 5 } }"
            :navText="[`<i class='ri-arrow-left-line'></i>`, `<i class='ri-arrow-right-line'></i>`]"
            :nav="true"
            :loop="true"
            :autoplay="true"
          >
            <div class="box-slide mx-2" v-for="(item, index) in type" :key="index">
              <v-card
                class="bg-transparent"
                elevation="0"
                :to="{ name: 'search-page', query: { media_type: item.title } }"
              >
                <img
                  :src="item.thumbnail"
                  class="rounded"
                  style="width: 100%; height: 200px; object-fit: cover"
                />
                <div class="py-3">
                  <p class="fs-20 font-weight-medium">
                    {{ $cookies.get('kinsan_lang') == 'jp' ? item.title_ja : item.title }}
                  </p>
                  <p class="mb-0">
                    {{ item.count }}
                    {{ $cookies.get('kinsan_lang') == 'jp' ? 'メディア' : 'Media' }}
                  </p>
                </div>
              </v-card>
            </div>
            <!-- <div class="box-slide mx-2">
                <v-card class="bg-transparent" elevation="0" :to="{ name: 'search-page', query: { id: 123 } }">
                  <img src="@/assets/images/img-detail.jpeg" style="width: 100%;height: 200px;object-fit: cover;">
                  <div class="py-3">
                    <p class="fs-20 font-weight-medium">Building</p>
                  </div>                              
                </v-card>  
              </div>
              <div class="box-slide mx-2">
                <v-card class="bg-transparent" elevation="0" :to="{ name: 'search-page', query: { id: 123 } }">
                  <img src="@/assets/images/img-detail.jpeg" style="width: 100%;height: 200px;object-fit: cover;">
                  <div class="py-3">
                    <p class="fs-20 font-weight-medium">Transportation</p>
                  </div>                              
                </v-card>  
              </div>
              <div class="box-slide mx-2">
                <v-card class="bg-transparent" elevation="0" :to="{ name: 'search-page', query: { id: 123 } }">
                  <img src="@/assets/images/img-detail.jpeg" style="width: 100%;height: 200px;object-fit: cover;">
                  <div class="py-3">
                    <p class="fs-20 font-weight-medium">Magazine</p>
                  </div>                              
                </v-card>  
              </div>
              <div class="box-slide mx-2">
                <v-card class="bg-transparent" elevation="0" :to="{ name: 'search-page', query: { id: 123 } }">
                  <img src="@/assets/images/img-detail.jpeg" style="width: 100%;height: 200px;object-fit: cover;">
                  <div class="py-3">
                    <p class="fs-20 font-weight-medium">Website</p>
                  </div>                              
                </v-card>  
              </div>
              <div class="box-slide mx-2">
                <v-card class="bg-transparent" elevation="0" :to="{ name: 'search-page', query: { id: 123 } }">
                  <img src="@/assets/images/img-detail.jpeg" style="width: 100%;height: 200px;object-fit: cover;">
                  <div class="py-3">
                    <p class="fs-20 font-weight-medium">Building</p>
                  </div>                              
                </v-card>  
              </div> -->
          </carousel>
          <!-- <v-slide-group
            v-model="model"
            class="pa-4"
            show-arrows
          >
            <v-slide-item>
              <div class="col-md-3">
                <v-card>
                  <img src="https://cdn.vuetifyjs.com/images/cards/house.jpg" style="width: 100%;height: 320px;object-fit: cover;">
                  <v-card-title>testing</v-card-title>                              
                </v-card>  
              </div>              
            </v-slide-item>
            <v-slide-item>
              <div class="col-md-3">
                <v-card>
                  <img src="https://cdn.vuetifyjs.com/images/cards/house.jpg" style="width: 100%;height: 320px;object-fit: cover;">
                  <v-card-title>testing</v-card-title>                              
                </v-card>  
              </div>              
            </v-slide-item>
            <v-slide-item>
              <div class="col-md-3">
                <v-card>
                  <img src="https://cdn.vuetifyjs.com/images/cards/house.jpg" style="width: 100%;height: 320px;object-fit: cover;">
                  <v-card-title>testing</v-card-title>                              
                </v-card>  
              </div>              
            </v-slide-item>
            <v-slide-item>
              <div class="col-md-3">
                <v-card>
                  <img src="https://cdn.vuetifyjs.com/images/cards/house.jpg" style="width: 100%;height: 320px;object-fit: cover;">
                  <v-card-title>testing</v-card-title>                              
                </v-card>  
              </div>              
            </v-slide-item>
          </v-slide-group> -->
        </div>
      </div>
    </section>

    <!-- market -->
    <section class="market white py-6">
      <div class="col-md-11 mx-auto">
        <h1 class="font-weight-bold mb-6 fs-24">{{ $t('browse_by_target_market') }}</h1>
        <div class="slide-category">
          <div v-if="loading">
            <v-skeleton-loader
              class="col-md-11 d-inline-block"
              style="width: 350px; height: auto"
              type="card"
              v-for="item in 4"
              :key="item"
            >
            </v-skeleton-loader>
          </div>
          <carousel
            v-if="homepage.browse_by_targetmarket && homepage.browse_by_targetmarket.length > 0"
            :responsive="{ 0: { items: 1 }, 425: { items: 1 }, 600: { items: 4 } }"
            :navText="[`<i class='ri-arrow-left-line'></i>`, `<i class='ri-arrow-right-line'></i>`]"
            :nav="true"
            :loop="true"
            :autoplay="true"
          >
            <div
              class="box-slide mx-2"
              v-for="(item, index) in homepage.browse_by_targetmarket"
              :key="index"
            >
              <v-card
                class="bg-transparent"
                elevation="0"
                :to="{ name: 'search-page', query: { target_market: item.title } }"
              >
                <img
                  :src="item.thumbnail"
                  class="rounded"
                  style="width: 100%; height: 250px; object-fit: cover"
                />
                <div class="py-3">
                  <p class="fs-20 font-weight-medium">
                    {{ $cookies.get('kinsan_lang') == 'jp' ? item.title_ja : item.title }}
                  </p>
                </div>
              </v-card>
            </div>
            <!-- <div class="box-slide mx-2">
                <v-card class="bg-transparent" elevation="0" :to="{ name: 'search-page', query: { id: 123 } }">
                  <img src="@/assets/images/img-detail.jpeg" style="width: 100%;height: 250px;object-fit: cover;">
                  <div class="py-3">
                    <p class="fs-20 font-weight-medium">Doctor</p>
                  </div>                              
                </v-card>  
              </div>
              <div class="box-slide mx-2">
                <v-card class="bg-transparent" elevation="0" :to="{ name: 'search-page', query: { id: 123 } }">
                  <img src="@/assets/images/img-detail.jpeg" style="width: 100%;height: 250px;object-fit: cover;">
                  <div class="py-3">
                    <p class="fs-20 font-weight-medium">Family</p>
                  </div>                              
                </v-card>  
              </div>
              <div class="box-slide mx-2">
                <v-card class="bg-transparent" elevation="0" :to="{ name: 'search-page', query: { id: 123 } }">
                  <img src="@/assets/images/img-detail.jpeg" style="width: 100%;height: 250px;object-fit: cover;">
                  <div class="py-3">
                    <p class="fs-20 font-weight-medium">Student</p>
                  </div>                              
                </v-card>  
              </div>
              <div class="box-slide mx-2">
                <v-card class="bg-transparent" elevation="0" :to="{ name: 'search-page', query: { id: 123 } }">
                  <img src="@/assets/images/img-detail.jpeg" style="width: 100%;height: 250px;object-fit: cover;">
                  <div class="py-3">
                    <p class="fs-20 font-weight-medium">Teenager</p>
                  </div>                              
                </v-card>  
              </div> -->
          </carousel>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import carousel from 'vue-owl-carousel';

export default {
  name: 'public-beranda',
  components: {
    carousel,
  },

  data: () => ({
    homepage: {},
    items: ['1', '2', '3'],
    // location:["Kairuzawa","Yamanouchi","Nagano","Tokyo","Yuzawa","Nagoya"],
    // type:["Building","Station","Magazine","Influencer","Transportation","Flyer"],
    location: [],
    type: [],
    loc: [],
    media_type: [],
    // media_type: [],
    query: {},
    model: null,
    loading: false,
  }),
  computed: {
    title_type() {
      if (this.$i18n.locale == 'jp') return 'title_ja';
      return 'title';
    },
  },
  created() {},
  mounted() {
    // console.log(this.$store.getters.isLoggedIn);
    this.getHomepage();
    // this.getLocation();
    this.getMediatype();
    this.getMedia();
  },
  methods: {
    getMedia() {
      this.loading = true;
      let params = {
        page: 1,
        limit: 99,
      };
      params = {
        ...params,
        ...this.query,
      };

      this.$store
        .dispatch('getMedia', params)
        .then((data) => {
          this.location = data.results;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    getLoc() {
      this.loading = true;
      let media = this.media_type.join(',');

      let params = {
        page: 1,
        limit: 99,
        media_type: media,
      };
      params = {
        ...params,
        // ...this.query
      };
      // console.log(params)
      this.$store
        .dispatch('getMedia', params)
        .then((data) => {
          this.location = data.results;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    getDetailPage() {
      // console.log(this.name_product)
      this.$router.push({
        name: 'detail-page',
        params: { id: this.loc._id, slug: this.loc.title.replace(/ /g, '-').toLowerCase() },
      });
    },
    search() {
      this.query = {};
      if (this.media_type != []) {
        let media = this.media_type.join(',');
        this.query['media_type'] = media;
      }
      if (this.loc != '') {
        this.query['location'] = this.loc;
      }
      this.$router.push({
        name: 'search-page',
        query: this.query,
      });
    },
    // getSearch() {
    //   this.query = {}
    //   if (this.media_type != []) {
    //     let media = this.media_type.join(',')
    //     this.query['media_type'] = media
    //   }
    //   if (this.loc != '') {
    //     this.query['keyword'] = this.loc
    //   }
    //   console.log(this.query)
    //   this.$router.push({
    //     name: 'search-page',
    //     query: this.query
    //   });
    // },
    getHomepage() {
      this.loading = true;
      this.$store
        .dispatch('getHomepage', {})
        .then((data) => {
          this.homepage = data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    getLocation() {
      this.$store
        .dispatch('getLocation', {})
        .then((data) => {
          this.location = data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getMediatype() {
      this.$store
        .dispatch('getMediatype', {})
        .then((data) => {
          this.type = data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    login() {
      this.$router.push('/auth/login');
    },
  },
};
</script>

<style>
.box-slider {
  width: 100%;
  /*max-height: 360px!important;*/
  height: auto !important;
}

#banner .v-carousel__controls > .v-item-group {
  position: absolute;
  left: 11% !important;
}

#banner .v-carousel__controls__item {
  margin: 0;
}

#banner .v-image {
  /*max-height: 360px!important;*/
  height: auto !important;
}

#banner .v-carousel__controls {
  bottom: 5% !important;
}

.img-banner {
  width: 100%;
  /*max-height: 360px!important;*/
  height: auto !important;

  /*object-fit: contain;*/
}

.bg-cta-login {
  /*background-image: url("../../assets/images/bg-login.png");*/
  background-repeat: no-repeat;
  background-position: center;
  height: 240px;
  background-size: cover;
}

.img-kategori {
  width: 100%;
}

/*@media (max-width: 768px) {
  #banner .v-image{
    height: auto!important;
  }
}*/
@media (max-width: 425px) {
  .box-data .v-card {
    max-width: 95% !important;
  }

  .box-slider {
    height: auto !important;
  }

  #banner .v-btn--icon.v-size--small {
    height: 15px;
    width: 15px;
  }

  #banner .v-btn__content i {
    font-size: 12px !important;
  }

  #banner .v-carousel__controls {
    bottom: -10px;
  }

  .v-carousel__item {
    height: auto !important;
  }

  .img-banner {
    height: auto !important;
  }

  .bg-cta-login {
    background-position: right !important;
    height: auto !important;
    width: 100%;
  }

  #cta-login .headline {
    font-size: 16px !important;
  }
}

@media (max-width: 375px) {
  .btn-beli {
    font-size: 10px !important;
  }
}
</style>
