<template>
  <!-- <v-row> -->
  <v-col md="12" class="mx-auto">
    <v-row>
      <v-col cols="12" class="bg-transparent" md="12">
        <div class="row">
          <div class="col-md-12 pb-0">
            <v-breadcrumbs :items="items" large class="px-0 pt-0 "></v-breadcrumbs>
          </div>
          <div class="col-md-12 pt-0">
            <div class="fs-20 font-weight-bold">{{ $t('add_new_media') }}</div>
          </div>
        </div>
      </v-col>
      <v-col cols="12" class="bg-transparent py-0" md="12">
        <v-spacer class="py-3"></v-spacer>

        <v-alert type="error" text dense prominent v-show="response !== null" v-html="response"></v-alert>
        <v-alert type="success" text dense prominent v-show="responsesukses !== null" v-html="responsesukses"></v-alert>
        <v-form id="create_media" lazy-validation class="mb-3" @submit.prevent="submit" ref="form" v-model="valid">
          <div class="row mb-5">
            <div class="col-md-4">
              <p class="fs-20 font-weight-medium mb-2">{{ $t('basic_info') }}</p>
              <p class="color-state">{{ $t('information_related_to_the_specification') }}</p>
            </div>
            <div class="col-md-8">
              <v-row>
                <v-col class="pb-1">
                  <div class="mb-2">
                    <span class="font-weight-medium">{{ $t('media_type') }}</span>
                  </div>
                  <v-autocomplete @change="mediaTypeChild" :rules="[...rules('Media Type', 'required'), validate]" required :items="list_media_type"
                    :item-text="title_type" item-value="_id" :placeholder="$t('choose_media_type')" v-model="type" outlined dense
                    class="rounded-8 mediaType"></v-autocomplete>
                  <v-text-field outlined hide-details="auto" class="rounded-8 mb-5" v-for="(item, index) in media_type_child" v-model="media_type_child_value[index]" :rules="[...rules($i18n.locale == 'jp' ? item.title_ja : item.title, item.is_required?'required':''), validate]" :key="index" :required="item.is_required" :placeholder="$i18n.locale == 'jp' ? item.title_ja : item.title"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-1">
                  <div class="mb-2">
                    <span class="font-weight-medium">{{ $t('media_name') }}</span>
                  </div>
                  <v-text-field :rules="[...rules('Media Name', 'required'), validate]" required class="rounded-8"
                    v-model="name" placeholder="" outlined hide-details="auto"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-1">
                  <div class="mb-2">
                    <span class="font-weight-medium">{{ $t('orientation') }}</span>
                  </div>
                  <v-select :rules="[...rules('Orientation', 'required'), validate]" required :items="oriens" :item-text="$i18n.locale == 'jp' ? 'text_jp' : 'text_en'" :item-value="'val'"
                    :placeholder="$t('choose_orientation')" v-model="orientation" outlined dense class="rounded-8"></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-1">
                  <div class="mb-2">
                    <span class="font-weight-medium">{{ $t('unit_of_measurement') }}</span>
                  </div>
                  <v-radio-group :rules="[...rules('Unit of Measurement', 'required'), validate]" required
                    v-model="measurement" row>
                    <v-radio :label="$t('meter')" :value="$t('meter')"></v-radio>
                    <v-radio :label="$t('centimeter')" :value="$t('centimeter')"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <div class="mb-2">
                    <span class="font-weight-medium">{{ $t('height') }}</span>
                    <span class="float-right color-disable text-decoration-none">
                      {{measurement}}
                    </span>
                  </div>
                  <v-text-field type="number" :rules="[...rules('Height', 'required'), validate]" required v-model="height" outlined>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <div class="mb-2">
                    <span class="font-weight-medium">{{ $t('width') }}</span>
                    <span class="float-right color-disable text-decoration-none">
                      {{measurement}}
                    </span>
                  </div>
                  <v-text-field type="number" :rules="[...rules('Width', 'required'), validate]" required v-model="width" outlined>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-1">
                  <div class="mb-2">
                    <span class="font-weight-medium">{{ $t('lighting') }}</span>
                  </div>
                  <v-radio-group :rules="[...rules('Lighting', 'required'), validate]" required v-model="lighting" row>
                    <v-radio :label="$t('have_lighting')" value="true"></v-radio>
                    <v-radio :label="$t('no_lighting')" value="false"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12">
                  <div class="mb-2">
                    <span class="font-weight-medium">{{ $t('minimum_price_per_month') }}</span>
                  </div>
                  <v-text-field type="number" :rules="[...rules('Minimum Price', 'required'), validate]" required v-model="minimum_price" outlined class="rounded-8" hide-details="auto"></v-text-field>
                </v-col>
                <!-- <v-col
                    cols="12"
                    sm="6"
                  >
                    <div class="mb-2">
                      <span class="font-weight-medium">Duration</span>                      
                    </div>
                    <v-select
                      :items="month"
                      placeholder="Choose duration"                      
                      outlined
                      dense
                      :item-text="'name'"
                      class="rounded-8"
                      :item-value="'value'"
                    ></v-select>
                  </v-col> -->
              </v-row>
              <v-row>
                <v-col class="pb-1">
                  <div class="mb-2">
                    <span class="font-weight-medium">{{ $t('discount') }}</span>
                    <span class="float-right color-disable text-decoration-none">
                      %
                    </span>
                  </div>
                  <v-text-field type="number" v-model="discount"
                    class="rounded-8" placeholder="" outlined hide-details="auto"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-1">
                  <div class="mb-2">
                    <span class="font-weight-medium">{{ $t('description') }}</span>
                  </div>
                  <v-textarea :rules="[...rules('Description', 'required'), validate]" required v-model="description"
                    class="rounded-8" placeholder="" outlined hide-details="auto"></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-1">
                  <div class="mb-2">
                    <span class="font-weight-medium">{{ $t('traffic') }}</span>
                  </div>
                  <v-text-field v-model="traffic"
                    class="rounded-8" placeholder="" outlined hide-details="auto"></v-text-field>
                </v-col>
              </v-row>
            </div>
          </div>
          <div class="row mb-5">
            <div class="col-md-4">
              <p class="fs-20 font-weight-medium mb-2">{{ $t('target_market') }}</p>
              <p class="color-state">{{ $t('targeted_segment_related_to_the_media') }}</p>
            </div>
            <div class="col-md-8">
              <!-- <v-row>
                  <v-col class="pb-1">                    
                    <div class="mb-2">
                      <span class="font-weight-medium">Category</span>
                    </div>
                    <v-autocomplete
                      :items="cat"
                      placeholder="Choose category"                      
                      v-model="category"
                      outlined
                      dense
                      class="rounded-8"
                    ></v-autocomplete>
                  </v-col>
                </v-row> -->
              <v-row>
                <v-col class="pb-1">
                  <div class="mb-2">
                    <span class="font-weight-medium">{{ $t('target_market') }}</span>
                  </div>
                  <v-autocomplete :rules="[...rules('Target Market', 'required'), validate]" required multiple
                    :items="list_target_market" :item-text="title_type" item-value="_id" :placeholder="$t('choose_target_market')"
                    v-model="target" outlined dense class="rounded-8"></v-autocomplete>
                </v-col>
              </v-row>
            </div>
          </div>
          <div class="row mb-5">
            <div class="col-md-4">
              <p class="fs-20 font-weight-medium mb-2">{{ $t('photo_video') }}</p>
            </div>
            <div class="col-md-8">
              <v-row>
                <v-col class="pb-1">
                  <div class="mb-2">
                    <span class="font-weight-medium">{{ $t('photo') }}</span>
                  </div>
                  <file-pond name="photo" v-bind:required="true" credits="false" class-name="my-pond" ref="photo"
                    :label-idle="`<div><div class='text-center mb-2'><i aria-hidden='true' class='v-icon notranslate ri ri-download-cloud-fill theme--light' style='font-size: 40px;'></i></div>`+$t('drag_drop_your_files_or')+` <span class='primary--text'><span class=''>`+$t('browse')+`</span></span></div>`"
                    v-bind:allow-multiple="true" v-bind:files="photo" v-on:init="handleFilePondInit"
                    accepted-file-types="image/gif,image/jpeg,image/png,image/bmp,image/jpg" />
                  <!-- <div id="my-strictly-unique-vue-upload-multiple-image" style="width: 100%;display: flex; justify-content: center;">
                    <vue-upload-multiple-image
      @upload-success="uploadImageSuccess"
      @before-remove="beforeRemove"
      @edit-image="editImage"
      @data-change="dataChange"
      :data-images="images"
      dragText="Drag and Drop Image"
      browseText="Browse Image"
      primaryText="Primary"
      markIsPrimaryText="Set default image"
      popupText="Default image"
      dropText="Drag and drop"
      maxImage="99999999999999"
      accept="image/gif,image/jpeg,image/png,image/bmp,image/jpg"
      width="100%"
      ></vue-upload-multiple-image>
                    </div> -->
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-1">
                  <div class="mb-2">
                    <span class="font-weight-medium">{{ $t('video') }}</span>
                    <span class="float-right color-disable text-decoration-none">
                      {{ $t('link_youtube') }}
                    </span>
                  </div>
                  <v-text-field :rules="[...rules('Video', ''), validate]" required class="rounded-8"
                    v-model="link_video" placeholder="" outlined hide-details="auto"></v-text-field>
                </v-col>
              </v-row>
            </div>
          </div>
          <div class="row mb-5">
            <div class="col-md-4">
              <p class="fs-20 font-weight-medium mb-2">{{ $t('location') }}</p>
            </div>
            <div class="col-md-8">
              <v-row>
                <v-col class="pb-1">
                  <div class="mb-2">
                    <span class="font-weight-medium">{{ $t('location') }}</span>
                  </div>
                  <gmap-autocomplete
                    style="display:block; line-height: 20px; padding: 14px ; max-width: 100%; min-width: 0 px ; width: 100%;border: 1px solid rgba(0, 0, 0, 0.38);border-radius:8px;"
                    :placeholder="$t('search_location')" @place_changed="setPlace"
                    :options="{fields: ['geometry', 'formatted_address', 'address_components']}">
                  </gmap-autocomplete>
                  <!-- <v-text-field
                      class="rounded-8"
                      v-model="loc"
                      placeholder="Search location"
                      append-icon="ri-search-line"                      
                      required
                      outlined
                      hide-details="auto"
                    ></v-text-field> -->
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-1">
                  <div class="mb-2">
                    <span class="font-weight-medium">{{ $t('address') }}</span>
                  </div>
                  <v-text-field class="rounded-8"
                    v-model="address" placeholder="" outlined hide-details="auto"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-1">
                  <div class="mb-2">
                    <!-- <span class="font-weight-medium">Address</span> -->
                  </div>
                  <div id="map">
                    <GmapMap :center="center" :zoom="10" map-style-id="roadmap" :options="mapOptions"
                      style="width: 100%; height: 50vmin" ref="mapRef" @click="handleMapClick">
                      <GmapMarker :position="marker.position" :clickable="true" :draggable="true"
                        @drag="handleMarkerDrag" @click="panToMarker" />
                    </GmapMap>
                    <!-- <button @click="geolocate">Detect Location</button>

  <p>Selected Position: {{ marker.position }}</p> -->
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-1">
                  <div class="mb-2">
                    <span class="font-weight-medium">{{ $t('latitude') }}</span>
                  </div>
                  <v-text-field :rules="[...rules('Latitude', 'required'), validate]" required readonly
                    class="rounded-8" v-model="latitude" placeholder="" outlined hide-details="auto"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-1">
                  <div class="mb-2">
                    <span class="font-weight-medium">{{ $t('longitude') }}</span>
                  </div>
                  <v-text-field :rules="[...rules('Longitude', 'required'), validate]" required readonly
                    class="rounded-8" v-model="longitude" placeholder="" outlined hide-details="auto"></v-text-field>
                </v-col>
              </v-row>
              <!-- <v-row>
                  <v-col class="pb-1">                    
                    <div class="mb-2">
                      <span class="font-weight-medium">Location Nearby</span>
                    </div>
                    <v-text-field
                      :rules="[...rules('Location Nearby', 'required'), validate]"
                      required
                      class="rounded-8"
                      v-model="nearby"
                      placeholder=""
                      outlined
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                </v-row> -->
              <v-row>
                <v-col class="pb-1">
                  <div class="mb-2">
                    <span class="font-weight-medium">{{ $t('document') }}</span>
                  </div>
                  <file-pond name="document" credits="false" class-name="my-pond" ref="document"
                    :label-idle="`<div><div class='text-center mb-2'><i aria-hidden='true' class='v-icon notranslate ri ri-download-cloud-fill theme--light' style='font-size: 40px;'></i></div>`+$t('drag_drop_your_files_or')+` <span class='primary--text'><span class=''>`+$t('browse')+`</span></span></div>`"
                    v-bind:allow-multiple="true" v-bind:files="document" v-on:init="handleFilePondInit"
                    accepted-file-types="application/pdf" />
                </v-col>
              </v-row>
            </div>
          </div>
          <v-row>
            <v-col cols="12" class="text-end align-self-end mt-4">
              <v-btn to="/list-media" small elevation="0" class="rounded-8 mr-2" outlined>
                {{ $t('cancel') }}
              </v-btn>
              <v-btn small elevation="0" type="submit" color="primary" :loading="submiting" class="rounded-8">
                {{ $t('save') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
        <v-spacer class="py-3"></v-spacer>
      </v-col>
    </v-row>
  </v-col>
  <!-- </v-row> -->
</template>

<script>
  import validationMixin from "../validation/valid";
  /* import VueUploadMultipleImage from 'vue-upload-multiple-image'; */

  // Import Vue FilePond
  import vueFilePond from "vue-filepond";

  // Import FilePond styles
  import "filepond/dist/filepond.min.css";

  // Import FilePond plugins
  // Please note that you need to install these plugins separately

  // Import image preview plugin styles
  import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

  // Import image preview and file type validation plugins
  import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
  import FilePondPluginImagePreview from "filepond-plugin-image-preview";

  // Create component
  const FilePond = vueFilePond(
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview
  );

  export default {
    components: {
      /* VueUploadMultipleImage, */
      FilePond,
    },
    mixins: [validationMixin],
    name: "create-media",
    data() {
      return {
        files: [],
        marker: {
          position: {
            lat: 35.652832,
            lng: 139.839478
          }
        },
        center: {
          lat: 35.652832,
          lng: 139.839478
        },

        mapOptions: {
          disableDefaultUI: true,
        },
        items: [{
            text: 'List Media',
            disabled: false,
            href: '/list-media',
          },
          {
            text: 'Add New Media',
            disabled: true,
            href: 'breadcrumbs_link_1',
          },
        ],

        valid: true,
        errors: null,

        submiting: false,
        response: null,
        responsesukses: null,
        media_type: ["Building", "Station", "Magazine", "Influencer", "Transportation"],
        oriens: [
          {val:"Horizontal",text_jp:"横",text_en:"Horizontal"}, 
          {val:"Vertical",text_jp:"縦",text_en:"Vertical"}],
        // oriens: ["Horizontal", "Vertical"],
        cat: ["Best of The Best", "Popular", "The Greatest One"],
        tar: ["CEO", "Family", "Doctor", "Student"],
        month: [{
            id: '1',
            name: '1 Month',
            value: '1'
          },
          {
            id: '2',
            name: '3 Months',
            value: '3'
          },
          {
            id: '3',
            name: '6 Months',
            value: '6'
          },
          {
            id: '4',
            name: '12 Months',
            value: '12'
          }
        ],

        overlay: 0.1,
        overlay_color: "secondary",

        orientation: '',
        minimum_price: '',
        traffic: '',
        discount: 0,
        description: '',
        height: '',
        width: '',
        type: '',
        measurement: null,
        lighting: null,
        target: '',
        name: '',
        city: '',
        category: '',
        link_video: '',
        loc: '',
        address: '',
        latitude: 35.652832,
        longitude: 139.839478,
        nearby: '',
        list_media_type: [],
        list_target_market: [],
        data: {},
        data_image: [],
        place: null,
        photo: [],
        document: [],
        media_type_child: [],
        media_type_child_value: [],
        media_type_selected: null,
      title_type: 'title',
        map:{},
        // type:{},
      };
    },
    computed: {
      validate() {
        return !this.errors || this.errors.message;
      },
      file() {
        if (this.data.file instanceof File) {
          const file = this.data.file;
          const type = ["application/pdf"].includes(file.type) ? "document" : "file";
          const url = URL.createObjectURL(file);
          return {
            url,
            type
          };
        } else {
          return this.data.file;
        }
      },
    },
    async created() {
      // this.orien.$set(0, await this.$t('horizontal'))
      // this.orien.$set(1, await this.$t('vertical'))
      /* this.orien[0] = await this.$t('horizontal');
      this.orien[1] = await this.$t('vertical'); */
    },
    async mounted() {
      await this.getMediaType();
      await this.getTargetMarket();
      this.items[0].text = await this.$t('list_media');
      this.items[1].text = await this.$t('add_new_media');
      // this.orien.$set(0, await this.$t('horizontal'))
      // this.orien.$set(1, await this.$t('vertical'))
      this.$refs.form.reset()
      this.$i18n.locale == 'jp' ? this.title_type = 'title_ja' : this.title_type = 'title';
    },
    methods: {
      orien() {
        return [
          this.$t('horizontal'),
          this.$t('vertical'),
        ]
      },
      handleFilePondInit: function () {
        // console.log("FilePond has initialized");

        // FilePond instance methods are available on `this.$refs.pond`
      },
      uploadImageSuccess(formData, index, fileList) {
        console.log('data', formData, index, fileList)
        // Upload image api
        // axios.post('http://your-url-upload', formData).then(response => {
        //   console.log(response)
        // })
      },
      beforeRemove(index, done, fileList) {
        console.log('index', index, fileList)
        var r = confirm("remove image")
        if (r == true) {
          done()
        } else {
          console.log("error");
        }
      },
      editImage(formData, index, fileList) {
        console.log('edit data', formData, index, fileList)
      },
      dataChange(data) {
        console.log(data)
      },
      search(nameKey, myArray){
      for (var i=0; i < myArray.length; i++) {
          if (myArray[i].types[0] === nameKey) {
              return myArray[i];
          }
        }
      },
      setPlace(place) {
        let resultObject = this.search("locality", place.address_components);
        // console.log('resul',place);
        this.map=place.formatted_address;
        this.city = resultObject.long_name;
        this.latitude = place.geometry.location.lat();
        this.longitude = place.geometry.location.lng();
        this.place = place
        this.marker.position = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        }
        this.panToMarker();
      },
      usePlace() {
        if (this.place) {
          this.markers.push({
            position: {
              lat: this.place.geometry.location.lat(),
              lng: this.place.geometry.location.lng(),
            }
          })
          this.place = null;
        }
      },
      geolocate() {
        navigator.geolocation.getCurrentPosition((position) => {
          this.marker.position = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          this.panToMarker();
        });
      },

      //sets the position of marker when dragged
      handleMarkerDrag(e) {
        this.latitude = e.latLng.lat();
        this.longitude = e.latLng.lng();
        this.marker.position = {
          lat: e.latLng.lat(),
          lng: e.latLng.lng()
        };
      },

      //Moves the map view port to marker
      panToMarker() {
        this.$refs.mapRef.panTo(this.marker.position);
        // this.$refs.mapRef.setZoom(18);
      },

      //Moves the marker to click position on the map
      handleMapClick(e) {
        this.latitude = e.latLng.lat();
        this.longitude = e.latLng.lng();
        this.marker.position = {
          lat: e.latLng.lat(),
          lng: e.latLng.lng()
        };
        // console.log(e);
      },
      dropFile(event) {
        this.overlay = 0.1;
        this.$set(this.data, "file", event.dataTransfer.files[0]);
        this.$set(this.data.file, "src", URL.createObjectURL(this.data.file));
      },
      dropFileImage(event) {
        this.overlay = 0.1;
        event.dataTransfer.files.map((item, index) => {
          this.$set(this.data_image[index], "file", item);
          this.$set(this.data_image[index].file, "src", URL.createObjectURL(this.data_image[index].file));
        })
      },
      changeFileImage() {
        // console.log(this.$refs.file_image.files);
        this.$refs.file_image.files.forEach((item, index) => {
          // this.data_image[index].file = item
          // this.data_image[index].src = URL.createObjectURL(this.data_image[index].file)
          this.$set(this.data_image, index, {});
          this.$set(this.data_image[index], "file", item);
          this.$set(this.data_image[index].file, "src", URL.createObjectURL(this.data_image[index].file));
        })
      },
      changeFile() {
        this.$set(this.data, "file", this.$refs.file.files[0]);
        this.$set(this.data.file, "src", URL.createObjectURL(this.data.file));
      },
      mediaTypeChild(val) {
        // console.log(`change with value: "${val}"`);
        // this.$refs.form.reset()
        this.media_type_child=[];
        this.media_type_child_value=[];
        this.type = val
        this.list_media_type.map(item => {
          if (item._id == val) {
            this.media_type_selected = item
            this.media_type_child = item.property
          }
        })
      },
      getTargetMarket() {
        this.$store
          .dispatch("getTargetMarketOwner", {})
          .then((data) => {
            this.list_target_market = data
          })
          .catch((error) => {
            console.log(error);
          });
      },
      getMediaType() {
        this.$store
          .dispatch("getMediaTypeOwner", {})
          .then((data) => {
            this.list_media_type = data
          })
          .catch((error) => {
            console.log(error);
          });
      },
      async upload(files) {
        return await Promise.all(files.map(async file => {
          let formData = new FormData();
          formData.append('file', file.file);
          return await this.$store.dispatch('upload', formData);
        }));
      },
      async submit() {
        if (this.city == '') {
          this.submiting = false;
          this.response = this.$t('location_is_required');
          window.scrollTo(0, 0);
          return
        }
        if (this.$refs.photo.getFiles().length == 0) {
          this.submiting = false;
          this.response = this.$t('photo_is_required');
          window.scrollTo(0, 0);
          return
        }
        this.responsesukses = null;
        this.response = null;
        this.submiting = true;

        if (this.$refs.form.validate()) {
          /* console.log(this.type);
          console.log(this.name);
          console.log(this.orientation);
          console.log(this.measurement);
          console.log(this.height);
          console.log(this.width);
          console.log(this.lighting);
          console.log(this.minimum_price);
          console.log(this.discount);
          console.log(this.description);
          console.log(this.traffic);
          console.log(this.target);
          console.log(this.link_video);
          console.log(this.address);
          console.log(this.latitude);
          console.log(this.longitude);
          console.log(this.photo);
          console.log(this.document);
          console.log(this.$refs.photo.getFiles());
          console.log(this.$refs.document.getFiles()); */

          this.media_type_child.map((item, index) => {
            this.media_type_child[index].value = this.media_type_child_value[index]
          })
          this.media_type_selected.property = this.media_type_child
          // delete this.media_type_selected._id

          let title = this.name;
          // let media_type_id = this.type;
          let media_type_id = this.media_type_selected;
          let orientation = this.orientation;
          let unit_of_measurement = {
            type: this.measurement,
            height: this.height,
            width: this.width,
          }
          let is_lighting = this.lighting;
          let minimum_price = this.minimum_price;
          // let duration = this.postal_code;
          let discount = this.discount;
          let description = this.description;
          let traffic = this.traffic;
          let target_market_id = this.target;
          let photo = await this.upload(this.$refs.photo.getFiles());
          let video = this.link_video;
          let location = {
            map_address:this.map,
            address: this.address,
            coordinates: [this.longitude, this.latitude],
            city: this.city,
          }
          let document = await this.upload(this.$refs.document.getFiles())

          this.$store
            .dispatch("createMedia", {
              title,
              media_type_id,
              orientation,
              unit_of_measurement,
              is_lighting,
              minimum_price,
              discount,
              description,
              traffic,
              target_market_id,
              photo,
              video,
              location,
              document
            })
            .then(async (result) => {
              if (result == "success") {
                this.$refs.form.reset();
                this.responsesukses =
                  "Success! You will be redirected to the list media page in 5 seconds or click <a href='/list-media'>here</a> to check list media.";
                window.scrollTo(0, 0);
                setTimeout(() => {
                  this.$router.push("/list-media");
                }, 5000);
                this.submiting = false;
              }
            })
            .catch((error) => {
              // this.$refs.form.reset();
              this.response = (error.response.data.message).replace("body should have ", "").replace("body.", "");
              this.response = this.response.charAt(0).toUpperCase() + this.response.slice(1);
              window.scrollTo(0, 0);
              this.submiting = false;
              // console.log(error.response.data);
            });
          this.submiting = false;
        } else {
          this.submiting = false;
          this.response = "Failed";
          window.scrollTo(0, 0);
        }
      },

    },
  };
</script>

<style>
  /* .filepond--drop-label {
  border: 1px dashed rgba(0, 0, 0, 0.25);
} */
  .filepond--file {
    background: rgba(0, 0, 0, 0.7);
  }

  .filepond--wrapper {
    border: 1px dashed rgba(0, 0, 0, 0.25);
  }

  .filepond--drop-label {
    background: #fff;
    height: 150px;
  }

  .filepond--panel-top {
    background: #fff;
  }

  .filepond--panel-center {
    background: #fff;
  }

  .filepond--panel-bottom {
    background: #fff;
  }

  .filepond--panel {
    background: #fff;
  }

  .my-pond {
    min-height: 150px;
    background: #fff;
    /* border: 1px dashed rgba(0, 0, 0, 0.25); */
  }

  /* .filepond--credits {
  display: none!important;
} */
  .image-container {
    height: 268px !important;
    width: 100% !important;
  }

  .image-list-container {
    max-width: 100% !important;
    width: 100% !important;
  }

  .preview-image {
    height: 240px !important;
  }

  .show-image>img {
    max-height: 200px !important;
    max-width: 100% !important;
  }

  #my-strictly-unique-vue-upload-multiple-image {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
  }

  #my-strictly-unique-vue-upload-multiple-image>div {
    width: 100%;
  }

  /* #profile .v-text-field__details {
    display: none !important;
  } */

  /* .mediaType .v-select__selection,
.v-select__selection--comma,
.v-select.v-text-field input {
  text-transform: uppercase!important;
} */
</style>