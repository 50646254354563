<template>
  <v-card elevation="0" class="transparent px-2">
    <vue-html2pdf
      ref="certificate"
      :show-layout="false"
      :enable-download="false"
      :preview-modal="true"
      :pdf-quality="2"
      filename="sertifikat"
      pdf-orientation="portrait"
      :manual-pagination="true"
      :html-to-pdf-options="htmlToPdfOptions"
      pdf-content-width="100%"
      @hasDownloaded="hasGenerated($event)"
      pdf-format="a4"
    >
      <section slot="pdf-content">
        <section
          class="pdf-item"
          :style="{
            width: `100%`,
            background: `#fff`,
          }"
        >
            <!-- height: `790px`, -->
          <div class="">          
          <!-- <div :class="{style1}">           -->
            <div style="text-align: center;">
              <img
                width="100%"
                src="@/assets/images/header-fact.png"
                alt=""
                srcset=""
              />
            </div>
            <div style="margin: auto;width: 90%;margin-top: 20px;">
              <img
                width="100%"
                height="325"
                src="@/assets/images/img-detail.jpeg"
              />            
            </div>
            <div
              style="                
                text-align: left;                
                width: 90%;
                margin:auto;
              "
            >
              <p style="font-family: Open Sans;
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 32px;margin-top: 20px;
                margin-bottom: 30px;"> {{data.title}}</p>
            </div>
            <div style="margin: auto;width: 90%;">
              <!-- <img
                width="100%"
                height="220"
                src="@/assets/images/img-detail.jpeg"
              /> -->
              <GmapMap
                :center="{ lat: map && map.location && map.location.coordinates && map.location.coordinates[1] ? +parseFloat(map.location.coordinates[1]) : 0, lng: map && map.location && map.location.coordinates && map.location.coordinates[0] ? +parseFloat(map.location.coordinates[0]) : 0 }"
                :zoom="10" map-style-id="roadmap" :options="{
                  zoomControl: true,
                  mapTypeControl: false,
                  scaleControl: false,
                  streetViewControl: false,
                  rotateControl: false,
                  fullscreenControl: true,
                  disableDefaultUi: false
                }" style="width: 100%; height: 220px" ref="mapRef">
                <GmapMarker
                  :position="{ lat: map && map.location && map.location.coordinates && map.location.coordinates[1] ? parseFloat(map.location.coordinates[1]) : 0, lng: map && map.location && map.location.coordinates && map.location.coordinates[0]? parseFloat(map.location.coordinates[0]) : 0 }" />
              </GmapMap>            
            </div>          
            <div style="margin: auto;width: 90%;display: flex;font-family: Open Sans;margin-top:20px;margin-bottom: 20px; height: 293px;">
              <div style="width: 50%;text-align: left;">                
                <p style="font-weight: 600;margin-bottom: 10px;font-size: 15px;">Scan Here for Details</p>
                <vue-qrcode :value="`https://kinsan.yureka.dev/detail/${this.data._id}`" level="H" :options="{ width: 200 }" style="margin-left:-15px;"></vue-qrcode>             
              </div>
              <div style="width: 50%;">                
                <p style="font-weight: 600;margin-bottom: 20px;font-size: 15px;">Description Media</p>
                <table style="width: 100%;font-size: 12px;">
                  <tr style="background:#fff;">
                    <td style="font-size:12px;background:#fff;padding-bottom:15px;width: 50%;font-weight:500;text-align: left;">Media Type </td>
                    <td style="font-size:12px;background:#fff;padding-bottom:15px;width: 50%;color:#8b8c8d;font-weight:400;text-align: left;">{{media_type}} </td>
                  </tr>
                  <tr style="background:#fff;">
                    <td style="font-size:12px;background:#fff;padding-bottom:15px;width: 50%;font-weight:500;text-align: left;">Size</td>
                    <td style="font-size:12px;background:#fff;padding-bottom:15px;width: 50%;color:#8b8c8d;font-weight:400;text-align: left;">{{size.width}} x {{size.height}} {{size.type}}</td>
                  </tr>
                  <tr style="background:#fff;">
                    <td style="font-size:12px;background:#fff;padding-bottom:15px;width: 50%;font-weight:500;text-align: left;">Lighting </td>
                    <td style="font-size:12px;background:#fff;padding-bottom:15px;width: 50%;color:#8b8c8d;font-weight:400;text-align: left;text-transform: capitalize;">{{lighting}} </td>
                  </tr>
                  <tr style="background:#fff;">
                    <td style="font-size:12px;background:#fff;padding-bottom:15px;width: 50%;font-weight:500;text-align: left;">Orientation</td>
                    <td style="font-size:12px;background:#fff;padding-bottom:15px;width: 50%;color:#8b8c8d;font-weight:400;text-align: left;">{{orientation}} </td>
                  </tr>
                  <tr style="background:#fff;">
                    <td style="font-size:12px;background:#fff;padding-bottom:15px;width: 50%;font-weight:500;text-align: left;">Traffic</td>
                    <td style="font-size:12px;background:#fff;padding-bottom:15px;width: 50%;color:#8b8c8d;font-weight:400;text-align: left;text-transform: capitalize;">{{traffic}} </td>
                  </tr>
                  <tr style="background:#fff;">
                    <td style="font-size:12px;background:#fff;padding-bottom:15px;width: 50%;font-weight:500;text-align: left;">Price</td>
                    <td style="font-size:12px;background:#fff;padding-bottom:15px;width: 50%;color:#8b8c8d;font-weight:400;text-align: left;">Start from ￥{{price}} </td>
                  </tr>
                </table>             
              </div>
            </div>           
            <div style="background: #470868;">              
              <img
                width="100%"
                src="@/assets/images/footer-fact.png"
                alt=""
                srcset=""
              />
            </div>
          </div>          
        </section>
      </section>
    </vue-html2pdf>
  </v-card>
  
</template>

<script>
import moment from "moment";
import VueHtml2pdf from "vue-html2pdf";
import VueQrcode from '@chenfengyuan/vue-qrcode';
// import { mapGetters } from "vuex";


export default {
  name: "certif-list",
  data() {
    return {
      dialog:true,
      showbtn:false,
      false:false,
      output: null,
      tampil: false,
      data:{},
      img1:'',
      test:'10',
      member:{},
      program:'',
      competence:[],
      dialogCertif: false,
      styleObject: {
        paddingTop: '100px',
        paddingBottom: '180px'
      },
      styleObject2: {
        paddingTop: '100px',
        paddingBottom: '100px'
      },
      styleObject3: {
        paddingTop: '100px',
        paddingBottom: '80px'
      },
      timer: '',
      score:'',
      dateFinish:[],
      tgl:null,

      media_type:'',
      size:{},
      lighting:'',
      orientation:'',
      traffic:'',
      price:'',
      map:{}
    };
  },
  computed: {
    htmlToPdfOptions() {
      return {
        html2canvas: {
          taintTest: true,
          userCORS: false
        }
      }
    }
  },
  components: { VueHtml2pdf , VueQrcode },
  created() {
    this.getData();
          // this.showCertif();

    // this.timer = setInterval(this.getData, 2000)
  },
  mounted(){
      

  },
  methods: {
    getData() {
      this.false=true;
      this.axios
        .get(`api/product/61ad81d6d0550e00145ab201`)
        // .get(`/users/v1/myprogram/list`)
        .then((response) => {
          let res = response.data.data;
          // console.log('title',res.photo[0])
          // let prog = response.data.data.program;
          this.data=res;
          this.img1=res.photo[0];
          this.media_type=res.media_type.title;
          this.size=res.unit_of_measurement;
          this.orientation=res.orientation;
          this.traffic=res.traffic;
          this.price=res.minimum_price;
          this.map=res;
          
          if (res.is_lighting === 'true') {            
            this.lighting='avaiable'
          }else{
            this.lighting='not avaiable'
          }

          this.showCertif();
        });
    },
    getMediaNearby(latitude, longitude) {
        this.loading_nearby = true
        this.$store
          .dispatch("getMediaNearby", {
            latitude,
            longitude
          })
          .then((data) => {
            this.media_nearby = data
            this.loading_nearby = false
          })
          .catch((error) => {
            console.log(error);
            this.loading_nearby = false
          });
      },
    cancelAutoUpdate () { clearInterval(this.timer) },
    reloadPage() {
      window.location.reload();
      this.showbtn=true;
    },
    styleku(){
      console.log('test')
     if(this.test >= 6 ){
         return this.styleObject;
      }
      else if( this.test ===7){
         return this.styleObject2;
      }
      else if( this.test ===8){
         return this.styleObject3;
      }
      else{
           return this.styleObject3;
      } 

   },
    currentDateTime(a) {
      return moment(a).lang("nl").format("L");
    },
    currentDateTimes(a) {
      return moment(a).lang("nl").format("L");
    },
    dateBirthTest(a) {
      return a;
    },
    dateExpired(a) {
      let currentDate = moment(a);
      let futureYear = moment(currentDate).add(1, "Y");
      return moment(futureYear).format("MMMM D, YYYY");
    },
    async beforeDownload({ html2pdf, options, pdfContent }) {
      html2pdf()
        .set(options)
        .from(pdfContent)
        .toPdf()
        .output("blob")

        .then((blob) => {
          let formData = new FormData();
          let certif = blob;

          console.log('bb',certif)


          // formData.append("file", certif, "Sertifikat.pdf");
          formData.append("source", "upload");
          // formData.append("title", "Sertifikat");

          // this.$store
          //   .dispatch("media/create", formData)
          //   .then((res) => {
          //     if (res.status == 200) {
          //       this.id_certif = res.data.id;
          //       this.updateCertif(this.id_certif);
          //       console.log("result", this.id_certif);
          //     }
          //   })
          //   .catch((error) => {
          //     console.log("error", error);
          //   });
        });
    },
    updateCertif(id) {
      let order_id = this.details.id;
      let sertificate_id = id;

      this.axios
        .put(
          `/users/v1/member/order/update-sertificate`,
          { order_id, sertificate_id },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          let res = response.data.data;
          this.loading = false;
          if (response.status == 200) {
            this.loading = false;
            // this.members = res;
            this.getDetailCertif(sertificate_id);
            console.log("berhasil", res);
          }
        });
    },
    hasGenerated($event) {
      console.log($event);
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    getDetail(order) {
      // this.$set(order, 'submiting', true);            
      this.id = order;
      this.axios
        .get(`/users/v1/myprogram/detail/${this.id}`)
        .then((response) => {
          // this.loading = false;
          let res = response.data.data;
          // this.loading = false;
          this.detail=res.program;
          console.log('data detail',this.detail)
          this.showCertif(this.id);
        });
    },
    showCertif() {
      console.log('test')
      this.$refs.certificate.generatePdf();

      // window.location.reload();
    },   
    sortDesc($event) {
      this.dir = $event[0] ? "desc" : "asc";
      this.$emit("refresh-list", this.sort, this.dir);
      console.log("dir", $event);
    },
  },
  beforeDestroy () {
      clearInterval(this.timer)
    }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Poppins:wght@400;500;700&display=swap');
tr:nth-child(even) {
  background-color: #f9fafc;
}
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row) {
  border-bottom: 0px solid transparent !important;
}
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border-bottom: 0px solid transparent !important;
  font-size: 14px;
  font-weight: 600;
  color: #8492a6;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr {
  color: #3c4858;
}
.theme--light.v-data-table .v-data-footer {
  border-top: 0px solid transparent !important;
  padding: 16px !important;
}
.v-data-footer__select {
  justify-content: left !important;
  position: absolute;
  left: 20px;
}
.vue-html2pdf .layout-container[data-v-1fd3ad26] {
  background: #fff !important;
}
.vue-html2pdf tr:nth-child(even) {
  background-color: #fff !important;
}
@media print {
  @page {
    size: portrait;
  }
}
</style>