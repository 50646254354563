import Vue from 'vue'
import Vuex from 'vuex'

import auth from './_auth'
import media from './_media'
import secondary from './_secondary'
import homepage from './_homepage'
import upload from './_upload'
import transaction from './_transaction'
import notification from './_notification'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: { 
		auth,
		media,
		secondary,
		homepage,
		upload,
		transaction,
		notification,
	}
})

