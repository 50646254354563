<template>
  <!-- <v-row> -->
  <v-col md="10" class="mx-auto">
    <v-row>
      <v-col cols="12" class="bg-transparent" md="12">
        <div class="row">
          <div class="col-md-6">
            <div class="fs-32 font-weight-bold mb-4">{{ $t('signup') }}</div>
          </div>
          <div id="account-mob" class="col-md-6">
            <p class="font-weight-medium text-end py-4">
              {{ $t('already_have_an_account') }}
              <router-link
                class="font-weight-medium text-decoration-none"
                tabindex="-1"
                color="primary"
                :to="{ name: 'login' }"
              >
                {{ $t('login') }}
              </router-link>
            </p>
          </div>
        </div>
      </v-col>
      <v-col cols="12" class="bg-transparent" md="12">
        <v-spacer class="py-3"></v-spacer>
        <v-form class="mb-3" @submit.prevent="submit" ref="form" v-model="valid">
          <v-alert
            type="error"
            text
            dense
            prominent
            v-show="response !== null"
            v-html="response"
          ></v-alert>
          <v-alert
            type="success"
            text
            dense
            prominent
            v-show="responsesukses !== null"
            v-html="responsesukses"
          ></v-alert>
          <div class="row">
            <div class="col-md-4">
              <p class="fs-20 font-weight-medium mb-2">{{ $t('account') }}</p>
              <p class="color-state">{{ $t('information_related_to_the_credential') }}</p>
            </div>
            <div class="col-md-8">
              <v-row>
                <v-col class="pb-1">
                  <div class="mb-2">
                    <span class="font-weight-medium">{{ $t('email_address') }}</span>
                  </div>
                  <v-text-field
                    class="rounded-8"
                    v-model="email"
                    placeholder=""
                    :rules="[...rules('Email Address', 'email'), validate]"
                    required
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div class="mb-2">
                    <span class="font-weight-medium">{{ $t('password') }}</span>
                  </div>
                  <v-text-field
                    class="rounded-8"
                    v-model="password"
                    :rules="pswRules"
                    outlined
                    hide-details="auto"
                    required
                    :type="show ? 'text' : 'password'"
                    :append-icon="show ? 'ri-eye-fill' : 'ri-eye-off-fill'"
                    @click:append="show = !show"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-1">
                  <div class="mb-1">
                    <span class="font-weight-medium">{{ $t('user_category') }}</span>
                  </div>
                  <v-radio-group
                    v-model="user_type"
                    row
                    :rules="[...rules('User category', 'required'), validate]"
                  >
                    <v-radio :label="$t('client')" value="client"></v-radio>
                    <v-radio :label="$t('owner')" value="owner"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <p class="fs-20 font-weight-medium mb-2">{{ $t('profile') }}</p>
              <p class="color-state">{{ $t('information_related_to_the_personal_info') }}</p>
            </div>
            <div class="col-md-8">
              <v-row>
                <v-col class="pb-1">
                  <div class="mb-2">
                    <span class="font-weight-medium">{{ $t('given_name') }}</span>
                  </div>
                  <v-text-field
                    class="rounded-8"
                    v-model="name"
                    placeholder=""
                    :rules="[...rules('Given name', 'required'), validate]"
                    required
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col class="pb-1">
                  <div class="mb-2">
                    <span class="font-weight-medium">{{ $t('surname') }}</span>
                  </div>
                  <v-text-field
                    class="rounded-8"
                    v-model="surname"
                    placeholder=""
                    :rules="[...rules('Surname', 'required'), validate]"
                    required
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-1">
                  <div class="mb-2">
                    <span class="font-weight-medium">{{ $t('company_name') }}</span>
                  </div>
                  <v-text-field
                    class="rounded-8"
                    v-model="company_name"
                    placeholder=""
                    :rules="[...rules('Company name', 'required'), validate]"
                    required
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-1">
                  <div class="mb-2">
                    <span class="font-weight-medium">{{ $t('postal_code') }}</span>
                  </div>
                  <v-text-field
                    class="rounded-8 mb-4"
                    v-model="postal_code"
                    :placeholder="$t('ex') + ': 1920000'"
                    :rules="[...rules('Postal Code', 'required'), validate]"
                    type="number"
                    required
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                  <v-alert
                    type="error"
                    text
                    dense
                    prominent
                    v-show="responsePostcodeFail !== null"
                    >{{ $t('postal_code_not_available') }}</v-alert
                  >
                  <v-alert
                    type="success"
                    text
                    dense
                    prominent
                    v-show="responsePostcodeSuccess !== null"
                    >{{ $t('postal_code_available') }}</v-alert
                  >
                  <v-btn
                    @click="checkPostCode()"
                    small
                    elevation="0"
                    type="button"
                    color="primary"
                    :loading="check_postal_code"
                    class="rounded"
                  >
                    {{ $t('check') }}
                  </v-btn>
                  <!-- <v-autocomplete
                      :items="postal"
                      v-model="postal_code"
                      outlined
                      dense
                    ></v-autocomplete> -->
                  <!-- <v-text-field
                      class="rounded-8"
                      v-model="postal_code"
                      placeholder=""
                      :rules="[...rules('Postal code', 'required'), validate]"                      
                      required
                      outlined
                      hide-details="auto"
                    ></v-text-field> -->
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-1">
                  <div class="mb-2">
                    <span class="font-weight-medium">{{ $t('prefecture') }}</span>
                  </div>
                  <v-text-field
                    class="rounded-8"
                    v-model="prefecture"
                    :readonly="true"
                    :placeholder="$t('auto_fill_from_postal_code')"
                    :rules="[...rules('Prefecture', 'required'), validate]"
                    required
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                  <!-- <v-select
                      outlined
                      required
                      :items="location"
                      v-model="prefecture"
                      :rules="[...rules('Prefecture', 'required'), validate]"                                            
                    ></v-select> -->
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-1">
                  <div class="mb-2">
                    <span class="font-weight-medium">{{ $t('city') }}</span>
                  </div>
                  <v-text-field
                    class="rounded-8"
                    v-model="city"
                    :readonly="true"
                    :placeholder="$t('auto_fill_from_postal_code')"
                    :rules="[...rules('City', 'required'), validate]"
                    required
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                  <!-- <v-select
                      outlined
                      required
                      :items="location"
                      v-model="city"
                      :rules="[...rules('City', 'required'), validate]"                                            
                    ></v-select> -->
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-1">
                  <div class="mb-2">
                    <span class="font-weight-medium">{{ $t('townarea') }}</span>
                  </div>
                  <v-text-field
                    class="rounded-8"
                    v-model="townarea"
                    :readonly="true"
                    :placeholder="$t('auto_fill_from_postal_code')"
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                  <!-- <v-select
                      outlined
                      required
                      :items="location"
                      v-model="townarea"
                      :rules="[...rules('Townarea', 'required'), validate]"                                            
                    ></v-select> -->
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-1">
                  <div class="mb-2">
                    <span class="font-weight-medium">{{ $t('building_address') }}</span>
                  </div>
                  <v-textarea
                    class="rounded-8"
                    v-model="building_address"
                    placeholder=""
                    :rules="[...rules('Building address', 'required'), validate]"
                    required
                    outlined
                    hide-details="auto"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-1">
                  <div class="mb-2">
                    <span class="font-weight-medium">{{ $t('phone') }}</span>
                  </div>
                  <v-text-field
                    class="rounded-8"
                    v-model="phone"
                    placeholder=""
                    :rules="[...rules('Phone', 'required'), validate]"
                    required
                    outlined
                    type="number"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-1">
                  <div class="mb-2">
                    <span class="font-weight-medium">{{ $t('contact_person') }}</span>
                  </div>
                  <v-text-field
                    class="rounded-8"
                    v-model="contact_person"
                    placeholder=""
                    :rules="[...rules('Contact person', 'required'), validate]"
                    required
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
          </div>
          <v-row>
            <v-col cols="12" class="text-end align-self-end">
              <v-btn
                small
                elevation="0"
                type="submit"
                color="primary"
                :loading="submiting"
                class="rounded block"
              >
                {{ $t('signup') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
        <v-spacer class="py-3"></v-spacer>
      </v-col>
    </v-row>
  </v-col>
  <!-- </v-row> -->
</template>

<script>
import validationMixin from '../validation/valid';

export default {
  mixins: [validationMixin],
  name: 'login',
  data() {
    return {
      show: false,
      valid: true,
      errors: null,

      emailRules: [
        (v) => !!v || this.$t('email_is_required'),
        (v) => /.+@.+\..+/.test(v) || this.$t('email_must_be_valid'),
      ],
      pswRules: [
        (v) => !!v || this.$t('password_is_required'),
        (v) =>
          (v &&
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#^$!%*?&])[A-Za-z\d@#^$!%*?&]{8,}$/.test(v)) ||
          this.$t(
            'password_must_has_at_least_8_characters_that_include_at_least_1_lowercase_character_1_uppercase_character_1_number_and_1_special_character'
          ),
      ],
      submiting: false,
      check_postal_code: false,
      response: null,
      responsesukses: null,
      responsePostcodeFail: null,
      responsePostcodeSuccess: null,
      location: ['Kairuzawa', 'Yamanouchi', 'Nagano', 'Tokyo', 'Yuzawa', 'Nagoya'],
      postal: ['1200000', '1900100', '1960000', '1160000', '1120000', '1000000'],

      user_type: '',
      email: '',
      password: '',
      name: '',
      surname: '',
      company_name: '',
      postal_code: '',
      prefecture: '',
      city: '',
      townarea: '',
      building_address: '',
      phone: '',
      contact_person: '',
      lang: null,
    };
  },
  computed: {
    validate() {
      return !this.errors || this.errors.message;
    },
  },
  mounted() {
    this.$refs.form.reset();
    this.checkPostCode();
  },
  methods: {
    checkPostCode() {
      if (this.postal_code && this.postal_code != '') {
        this.responsePostcodeFail = null;
        this.responsePostcodeSuccess = null;
        if (this.$i18n.locale === 'en') {
          this.lang = 'eng';
        } else {
          this.lang = null;
        }
        let language = this.lang;
        let zipcode = this.postal_code;
        this.check_postal_code = true;
        this.$store
          .dispatch('postCode', { zipcode, language })
          .then(async (result) => {
            this.check_postal_code = false;
            if (result) {
              if (result.data[0]) {
                this.city = result.data[0].Value;
              }
              if (result.data[2]) {
                if (result.data[2].Value == 'If not listed below') {
                  this.townarea = '';
                } else {
                  this.townarea = result.data[2].Value;
                }
              }
              if (result.data[3]) {
                this.prefecture = result.data[3].Value;
              }
              this.responsePostcodeSuccess = 'Success';
              setTimeout(() => {
                this.responsePostcodeSuccess = null;
              }, 2000);
            }
          })
          .catch(() => {
            this.city = '';
            this.townarea = '';
            this.prefecture = '';
            this.check_postal_code = false;
            this.responsePostcodeFail = 'Failed';
            setTimeout(() => {
              this.responsePostcodeFail = null;
            }, 2000);
          });
      }
    },
    submit() {
      this.responsesukses = null;
      this.response = null;
      this.submiting = true;
      if (this.$refs.form.validate()) {
        let email = this.email;
        let password = this.password;
        let type = this.user_type;
        let given_name = this.name;
        let surname = this.surname;
        let company = this.company_name;
        let postal_code = this.postal_code;
        let prefecture = this.prefecture;
        let city = this.city;
        let townarea = this.townarea;
        let building_address = this.building_address;
        let phone = this.phone;
        let contact_person = this.contact_person;

        this.$store
          .dispatch('register', {
            email,
            password,
            type,
            given_name,
            surname,
            company,
            postal_code,
            prefecture,
            city,
            townarea,
            building_address,
            phone,
            contact_person,
          })
          .then(async (result) => {
            this.submiting = false;
            if (result == 'success') {
              this.$refs.form.reset();
              this.responsesukses =
                "Success! You will be redirected to the login page in 3 seconds or click <a href='/login'>here</a> to login.";
              this.responsePostcodeFail = null;
              this.responsePostcodeSuccess = null;
              window.scrollTo(0, 0);
              setTimeout(() => {
                this.$router.push('/login');
              }, 3000);
            }
            // this.$router.push("/login");
            // await this.$store.dispatch('get_user')
            // this.redirectAfterLogin();
          })
          .catch((error) => {
            this.submiting = false;
            // this.$refs.form.reset();
            this.response = error.response.data.message
              .replace('body should have ', '')
              .replace('body.', '');
            this.response = this.$t('email_already_registered');
            window.scrollTo(0, 0);
            // console.log(error.response.data);
          });
      } else {
        this.submiting = false;
        // this.response = "Nomor Whatsapp dan password tidak boleh kosong";
        // this.$refs.form.reset();
      }
    },
    redirectAfterLogin() {
      if (localStorage.getItem('kinsan_redirect') != null) {
        this.$router.push(localStorage.getItem('kinsan_redirect'));
        localStorage.removeItem('kinsan_redirect');
        return;
      }
      if (this.user.is_updated_profile) {
        return this.$router.push('/');
      }
      return this.$router.push('/');
    },
  },
};
</script>

<style>
.google-signin-button {
  color: white;
  background-color: red;
  height: 50px;
  font-size: 16px;
  border-radius: 10px;
  padding: 10px 20px 25px 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
#line {
  width: 100%;
  height: max-content;
  text-align: center;
  position: relative;
}

#line:after {
  content: '';
  width: 100%;
  border-bottom: solid 1px #e0e6ed;
  position: absolute;
  left: 0;
  top: 50%;
  z-index: 1;
}

#line span {
  width: auto;
  background: #fff;
  display: inline-block;
  z-index: 3;
  padding: 0 20px 0 20px;
  position: relative;
  margin: 0;
}
</style>
