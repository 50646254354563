function objectToObject(obj, prefix = '') {
  const object = {};
  Object.keys(obj).forEach((key) => {
    const val = obj[key];

    if (typeof val == 'object') {
      Object.assign(object, objectToObject(val, prefix + '.' + key));
    } else {
      object[prefix + '.' + key] = val;
    }
  });
  return object;
}

import transaction from './transaction';
import notifications from './notifications';
import media from './media';
import action from './action';

export { objectToObject };

export default {
  ...transaction,
  ...objectToObject(notifications, 'notification'),
  ...objectToObject(media, 'media'),
  ...objectToObject(action, 'action'),
};
