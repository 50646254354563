<template>
  <!-- <v-row> -->
    <v-col md="12" class="mx-auto">
      <v-row>
        <v-col cols="12" class="bg-transparent" md="12">
          <div class="row">
            <div class="col-md-6">
              <div class="fs-20 font-weight-bold">{{ $t('profile') }}</div>              
            </div>            
          </div>
        </v-col>
        <v-col cols="12" class="bg-transparent py-0" md="12">
          <v-spacer class="py-3"></v-spacer>

            <v-alert
              type="error"
              text
              dense
              prominent
              v-show="response !== null"
              >{{ response }}</v-alert
            >
            <v-alert
              type="success"
              text
              dense
              prominent
              v-show="responsesukses !== null"
              >{{ responsesukses }}</v-alert
            >
            <v-form
            class="mb-3"
            @submit.prevent="changePassword"
            ref="form_password"
            v-model="valid"
          >
            <div class="row">
              <div class="col-md-4">
                <p class="fs-20 font-weight-medium mb-2">{{ $t('account') }}</p>
                <p class="color-state">{{ $t('information_related_to_the_credential') }}</p>
              </div>
              <div class="col-md-8">
                <v-row>
                  <v-col class="pb-1">
                    <v-chip
                      class="pa-1 text-capitalize mb-3"
                      color="primary"                      
                      outlined
                      label
                      small
                      style="background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #3E66FB!important;"
                    >{{ $t('owner') }}</v-chip>
                    <div class="mb-2">
                      <span class="font-weight-medium">{{ $t('email_address') }}</span>
                    </div>
                    <v-text-field
                      class="rounded-8"
                      v-model="email"
                      placeholder=""
                      readonly
                      required
                      outlined
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <div v-show="changePass" class="mt-2">                  
                  <v-row>
                    <v-col>
                      <div class="mb-2">
                        <span class="font-weight-medium">{{ $t('current_password') }}</span>              
                      </div>
                      <v-text-field
                        class="rounded-8"
                        v-model="current_password"
                        :rules="pswRules"
                        outlined
                        hide-details="auto"
                        required
                        :type="show ? 'text' : 'password'"
                        :append-icon="show ? 'ri-eye-fill' : 'ri-eye-off-fill'"
                        @click:append="show = !show"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <div class="mb-2">
                        <span class="font-weight-medium">{{ $t('new_password') }}</span>              
                      </div>
                      <v-text-field
                        class="rounded-8"
                        v-model="new_password"
                        :rules="newpswRules"
                        outlined
                        hide-details="auto"
                        required
                        :type="show2 ? 'text' : 'password'"
                        :append-icon="show2 ? 'ri-eye-fill' : 'ri-eye-off-fill'"
                        @click:append="show2 = !show2"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <div class="mb-2">
                        <span class="font-weight-medium">{{ $t('confirm_password') }}</span>              
                      </div>
                      <v-text-field
                        class="rounded-8"
                        v-model="confirm_password"
                        :rules="conpswRules"
                        outlined
                        hide-details="auto"
                        required
                        :type="show3 ? 'text' : 'password'"
                        :append-icon="show3 ? 'ri-eye-fill' : 'ri-eye-off-fill'"
                        @click:append="show3 = !show3"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pb-1 text-end">                    
                      <v-btn
                        small
                        elevation="0"
                        class="rounded-8 mr-2"
                        outlined 
                        color="primary"
                        @click="changePass=false;showChange=true"                     
                      >
                        {{ $t('cancel') }}
                      </v-btn>
                      <v-btn
                        small
                        elevation="0"
                        class="rounded-8"                      
                        color="primary"
                        @click="changePassword()"                      
                      >
                        {{ $t('save') }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
                <v-row v-show="showChange">
                  <v-col class="pb-1 text-end mt-3">                    
                    <v-btn
                      small
                      elevation="0"
                      class="rounded"
                      outlined
                      @click="changePass=true;showChange=false"                     
                    >
                      {{ $t('change_password') }}
                    </v-btn>
                  </v-col>
                </v-row>                
              </div>
            </div>
            </v-form>
            <v-spacer class="py-5"></v-spacer>
          <v-form
            class="mb-3"
            @submit.prevent="submit"
            ref="form"
            v-model="valid"
          >
            <div class="row">
              <div class="col-md-4">
                <p class="fs-20 font-weight-medium mb-2">{{ $t('profile') }}</p>
                <p class="color-state">{{ $t('information_related_to_the_personal_info') }}</p>
              </div>
              <div class="col-md-8">
                <v-row>
                  <v-col class="pb-1">
                    <div class="mb-2">
                      <span class="font-weight-medium">{{ $t('given_name') }}</span>
                    </div>
                    <v-text-field
                      class="rounded-8"
                      v-model="name"
                      placeholder=""
                      :rules="[...rules('Given name', 'required'), validate]"                      
                      required
                      outlined
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col class="pb-1">
                    <div class="mb-2">
                      <span class="font-weight-medium">{{ $t('surname') }}</span>
                    </div>
                    <v-text-field
                      class="rounded-8"
                      v-model="surname"
                      placeholder=""
                      :rules="[...rules('Surname', 'required'), validate]"                      
                      required
                      outlined
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pb-1">
                    <div class="mb-2">
                      <span class="font-weight-medium">{{ $t('company_name') }}</span>
                    </div>
                    <v-text-field
                      class="rounded-8"
                      v-model="company_name"
                      placeholder=""
                      :rules="[...rules('Company name', 'required'), validate]"                      
                      required
                      outlined
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pb-1">
                    <div class="mb-2">
                      <span class="font-weight-medium">{{ $t('postal_code') }}</span>
                    </div>
                    <v-text-field
                      class="rounded-8"
                      v-model="postal_code"
                      :placeholder="$t('ex')+': 1920000'"
                      :rules="[...rules('Postal Code', 'required'), validate]"       
                      type="number"               
                      required
                      outlined
                      hide-details="auto"
                    ></v-text-field>
                    <v-alert
                      type="error"
                      text
                      dense
                      prominent
                      v-show="responsePostcodeFail !== null"
                      >{{ $t('postal_code_not_available') }}</v-alert
                    >
                    <v-alert
                      type="success"
                      text
                      dense
                      prominent
                      v-show="responsePostcodeSuccess !== null"
                      >{{ $t('postal_code_available') }}</v-alert
                    >
                    <v-btn
                      @click="checkPostCode()"
                      small
                      elevation="0"
                      type="button"
                      color="primary"
                      :loading="check_postal_code"
                      class="rounded mt-3"
                    >
                      {{ $t('check') }}
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pb-1">
                    <div class="mb-2">
                      <span class="font-weight-medium">{{ $t('prefecture') }}</span>
                    </div>
                    <v-text-field
                      class="rounded-8"
                      v-model="prefecture"
                      :readonly="true"
                      :placeholder="$t('auto_fill_from_postal_code')"
                      :rules="[...rules('Prefecture', 'required'), validate]"                      
                      required
                      outlined
                      hide-details="auto"
                    ></v-text-field>
                    <!-- <v-select
                      outlined
                      required
                      :items="location"
                      v-model="prefecture"
                      :rules="[...rules('Prefecture', 'required'), validate]"                                            
                    ></v-select> -->
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pb-1">
                    <div class="mb-2">
                      <span class="font-weight-medium">{{ $t('city') }}</span>
                    </div>
                    <v-text-field
                      class="rounded-8"
                      v-model="city"
                      :readonly="true"
                      :placeholder="$t('auto_fill_from_postal_code')"
                      :rules="[...rules('City', 'required'), validate]"                      
                      required
                      outlined
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pb-1">
                    <div class="mb-2">
                      <span class="font-weight-medium">{{ $t('townarea') }}</span>
                    </div>
                    <v-text-field
                      class="rounded-8"
                      v-model="townarea"
                      :readonly="true"
                      :placeholder="$t('auto_fill_from_postal_code')"
                      outlined
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pb-1">
                    <div class="mb-2">
                      <span class="font-weight-medium">{{ $t('building_address') }}</span>
                    </div>
                    <v-textarea
                      class="rounded-8"
                      v-model="building_address"
                      placeholder=""
                      :rules="[...rules('Building address', 'required'), validate]"                      
                      required
                      outlined
                      hide-details="auto"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pb-1">
                    <div class="mb-2">
                      <span class="font-weight-medium">{{ $t('phone') }}</span>
                    </div>
                    <v-text-field
                      class="rounded-8"
                      v-model="phone"
                      placeholder=""
                      :rules="[...rules('Phone', 'required'), validate]"                      
                      required
                      outlined
                      type="number"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                </v-row> 
                <v-row>
                  <v-col class="pb-1">
                    <div class="mb-2">
                      <span class="font-weight-medium">{{ $t('contact_person') }}</span>
                    </div>
                    <v-text-field
                      class="rounded-8"
                      v-model="contact_person"
                      placeholder=""
                      :rules="[...rules('Contact person', 'required'), validate]"                      
                      required
                      outlined
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                </v-row>               
              </div>
            </div>
            <v-row>
                  <v-col cols="12" class="text-end align-self-end mt-4">
                    <v-btn
                      small
                      elevation="0"
                      type="submit"
                      color="primary"
                      :loading="submiting"
                      class="rounded"
                      
                    >
                      {{ $t('save') }}
                    </v-btn>
                  </v-col>
                </v-row>
          </v-form>          
          <v-spacer class="py-3"></v-spacer>
        </v-col>
      </v-row>
    </v-col>
  <!-- </v-row> -->
</template>

<script>
import validationMixin from "../validation/valid";


export default {
  mixins: [validationMixin],
  name: "profile",
  data() {
    return {
      show: false,
      show2: false,
      show3: false,
      valid: true,
      errors: null,
      
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      pswRules: [
        (v) => !!v || "Password is required",
        // (v) =>  (v && /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#^$!%*?&])[A-Za-z\d@#^$!%*?&]{8,}$/.test(v)) || "Password must has at least 8 characters that include at least 1 lowercase character, 1 uppercase character, 1 number, and 1 special character in (!@#$%^&*)",
      ],
      newpswRules: [
        (v) => !!v || "New Password is required",
        (v) =>  (v && /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#^$!%*?&])[A-Za-z\d@#^$!%*?&]{8,}$/.test(v)) || "Password must has at least 8 characters that include at least 1 lowercase character, 1 uppercase character, 1 number, and 1 special character in (!@#$%^&*)",
      ],
      submiting: false,
      check_postal_code: false,
      response: null,
      responsesukses: null,
      responsePostcodeFail: null,
      responsePostcodeSuccess: null,
      location:["Kairuzawa","Yamanouchi","Nagano","Tokyo","Yuzawa","Nagoya"],
      postal:["120-0000","190-0100","196-0000","116-0000","112-0000","100-0000"],

      user_category:'',
      email: "",
      current_password: "",
      new_password: "",
      confirm_password: "",
      name:"",
      surname:"",
      company_name:"",
      postal_code:"",
      prefecture:"",
      city:"",
      townarea:"",
      building_address:'',
      phone:"",
      contact_person:'',
      user: {},
      changePass:false,
      showChange:true,
      lang:null,
    };
  },
  computed: {
    validate() {
      return !this.errors || this.errors.message;
    },
    conpswRules() {
      return [
        () =>
          this.new_password === this.confirm_password ||
          "Confirmation password does not match",
        (v) => !!v || "Confirmation Password is required",
      ];
    },
  },
  async mounted() {
    await this.$store.dispatch('get_user')
    if (localStorage.getItem("ytc_kinsan_auth") != null) {
      this.user = JSON.parse(localStorage.getItem("ytc_kinsan_auth"));
      this.user_category = this.user.user_category
      this.email = this.user.email
      this.name = this.user.given_name
      this.surname = this.user.surname
      this.company_name = this.user.company
      this.postal_code = this.user.postal_code
      this.prefecture = this.user.prefecture
      this.city = this.user.city
      this.townarea = this.user.townarea
      this.building_address = this.user.building_address
      this.phone = this.user.phone
      this.contact_person = this.user.contact_person
    }
    this.checkPostCode()
  },
  methods: {
    checkPostCode() {
      if (this.postal_code && this.postal_code != '') {
        this.responsePostcodeFail = null;
        this.responsePostcodeSuccess = null;
        if (this.$i18n.locale === 'en') {
          this.lang = 'eng';
        }else{
          this.lang = null;
        }
        let zipcode = this.postal_code;
        let language = this.lang;
        this.check_postal_code = true;
        this.$store
          .dispatch("postCode", { zipcode,language })
          .then(async (result) => {
            this.check_postal_code = false;
            if (result) {
              if (result.data[0]) {
                this.city = result.data[0].Value
              }
              if (result.data[2]) {
                if (result.data[2].Value == 'If not listed below') {
                  this.townarea = ''
                } else {
                  this.townarea = result.data[2].Value
                }
              }
              if (result.data[3]) {
                this.prefecture = result.data[3].Value
              }
              this.responsePostcodeSuccess = "Success";
              setTimeout(() => {this.responsePostcodeSuccess=null;},2000);

            }
          })
          .catch(() => {
            this.city = ""
            this.townarea = ""
            this.prefecture = ""
            this.check_postal_code = false;
            this.responsePostcodeFail = "Failed";
              setTimeout(() => {this.responsePostcodeFail=null;},2000);
            
          });
      }
    },
    submit() {
      this.responsesukses = null;
      this.response = null;
      this.submiting = true;
      if (this.$refs.form.validate()) {

        // let phone = this.email;
        // let password = this.password;

        // this.$store
        //   .dispatch("login", { phone, password })
        //   .then(async () => {
            this.submiting = false;
            // this.$router.push("/login");
        //     await this.$store.dispatch('get_user')
        //     this.redirectAfterLogin();
        //   })
        //   .catch((error) => {
        //     this.submiting = false;
        //     this.$refs.form.reset();
        //     this.response = "Nomor Telepon dan Password tidak cocok";
        //     console.error(error);
        //   });

        let given_name = this.name;
        let surname = this.surname;
        let company =  this.company_name;
        let postal_code = this.postal_code;
        let prefecture = this.prefecture;
        let city = this.city;
        let townarea = this.townarea;
        let building_address = this.building_address;
        let phone = this.phone;
        let contact_person = this.contact_person;

        this.$store
          .dispatch("updateProfile", { given_name, surname, company, postal_code, prefecture, city, townarea, building_address, phone, contact_person })
          .then(async (result) => {
            this.submiting = false;
            if (result == "success") {
              // this.$refs.form.reset();
              this.responsesukses = "Update profile success!";
              this.responsePostcodeFail = null;
              this.responsePostcodeSuccess = null;
              window.scrollTo(0,0);
            }
            // this.$router.push("/login");
            // await this.$store.dispatch('get_user')
            // this.redirectAfterLogin();
          })
          .catch((error) => {
            this.submiting = false;
            // this.$refs.form.reset();
            this.response = (error.response.data.message).replace("body should have ", "").replace("body.", "");
            this.response = this.response.charAt(0).toUpperCase() + this.response.slice(1);
            window.scrollTo(0,0);
            // console.log(error.response.data);
          });
      }
      else {
        this.submiting = false;
        // this.response = "Nomor Whatsapp dan password tidak boleh kosong";
        // this.$refs.form.reset();
      }
    },
    async changePassword() {
      this.responsesukses = null;
      this.response = null;
      this.submiting = true;
      if (this.$refs.form_password.validate()) {

        // let phone = this.email;
        // let password = this.password;

        // this.$store
        //   .dispatch("login", { phone, password })
        //   .then(async () => {
            this.submiting = false;
            // this.$router.push("/login");
        //     await this.$store.dispatch('get_user')
        //     this.redirectAfterLogin();
        //   })
        //   .catch((error) => {
        //     this.submiting = false;
        //     this.$refs.form.reset();
        //     this.response = "Nomor Telepon dan Password tidak cocok";
        //     console.error(error);
        //   });

        let old_password = this.current_password;
        let new_password = this.new_password;

        this.$store
          .dispatch("changePassword", { old_password, new_password })
          .then(async (result) => {
            this.submiting = false;
            if (result == "success") {
              await this.$refs.form_password.reset();
              this.email = this.user.email
              this.responsesukses = "Change password success!";
              this.responsePostcodeFail = null;
              this.responsePostcodeSuccess = null;
              window.scrollTo(0,0);
            }
            // this.$router.push("/login");
            // await this.$store.dispatch('get_user')
            // this.redirectAfterLogin();
          })
          .catch((error) => {
            this.submiting = false;
            // this.$refs.form.reset();
            this.response = (error.response.data.message).replace("body should have ", "").replace("body.", "");
            this.response = this.response.charAt(0).toUpperCase() + this.response.slice(1);
            window.scrollTo(0,0);
            // console.log(error.response.data);
          });
      }
      else {
        this.submiting = false;
        // this.response = "Nomor Whatsapp dan password tidak boleh kosong";
        // this.$refs.form.reset();
      }
    },
    
  },
};
</script>

<style>
/* #profile .v-text-field__details{
  display: none!important;
} */
</style>
