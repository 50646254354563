<template>
  <v-row align-content="center">
    <v-col class="" md="12">
      <v-row>
        <v-col class="bg-transparent mx-auto" md="4">
          <v-spacer class="py-3"></v-spacer>
          <v-spacer class="py-5"></v-spacer>
          <v-form
            class="px-8 mb-3"
            @submit.prevent="submit"
            lazy-validation
            ref="form"
            v-model="valid"
          >
            <div class="fs-32 font-weight-bold mb-4 text-center">{{ $t('login') }}</div>

            <v-alert type="error" text dense prominent v-show="response !== null">{{
              response
            }}</v-alert>
            <v-alert type="success" text dense prominent v-show="responsesukses !== null">{{
              responsesukses
            }}</v-alert>
            <v-row>
              <v-col class="pb-1">
                <div class="mb-2">
                  <span class="font-weight-bold">{{ $t('email_address') }}</span>
                </div>
                <v-text-field
                  v-model="email"
                  placeholder=""
                  prepend-inner-icon="ri-mail-fill"
                  :rules="emailRules"
                  class="rounded-8"
                  required
                  outlined
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="mb-2">
                  <span class="font-weight-bold">{{ $t('password') }}</span>
                  <!-- <router-link
                    :to="{ name: 'forgot-password' }"
                    class="float-right color-disable text-decoration-none"
                    tabindex="-1"
                  >
                    Forgot Password?
                  </router-link> -->
                  <router-link
                    :to="{ name: 'forgot-password' }"
                    class="float-right color-disable text-decoration-none"
                    tabindex="-1"
                  >
                    {{ $t('forgot_password') }}
                  </router-link>
                </div>
                <v-text-field
                  v-model="password"
                  :rules="pswRules"
                  class="rounded-8"
                  outlined
                  hide-details="auto"
                  prepend-inner-icon="ri-key-fill"
                  required
                  :type="show ? 'text' : 'password'"
                  :append-icon="show ? 'ri-eye-fill' : 'ri-eye-off-fill'"
                  @click:append="show = !show"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="">
                <v-btn
                  large
                  elevation="0"
                  type="submit"
                  color="primary"
                  :loading="submiting"
                  class="rounded-8"
                  block
                >
                  {{ $t('login') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
          <p class="font-weight-medium text-center py-4">
            {{ $t('dont_have_an_account_yet') }}
            <router-link
              class="font-weight-medium text-decoration-none"
              tabindex="-1"
              color="primary"
              :to="{ name: 'register' }"
            >
              {{ $t('signup') }}
            </router-link>
          </p>
          <v-spacer class="py-3"></v-spacer>
          <v-spacer class="py-5"></v-spacer>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'login',
  data() {
    return {
      show: false,
      errors: [],
      valid: true,
      email: '',
      password: '',
      emailRules: [
        (v) => !!v || this.$t('email_is_required'),
        (v) => /.+@.+\..+/.test(v) || this.$t('email_must_be_valid'),
      ],
      pswRules: [
        (v) => !!v || this.$t('password_is_required'),
        // (v) =>  (v && /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#^$!%*?&])[A-Za-z\d@#^$!%*?&]{8,}$/.test(v)) || this.$t('password_must_has_at_least_8_characters_that_include_at_least_1_lowercase_character_1_uppercase_character_1_number_and_1_special_character'),
      ],
      submiting: false,
      response: null,
      responsesukses: null,
    };
  },
  computed: {
    ...mapGetters(['user']),
  },
  mounted() {
    // console.log('user',this.user.status)
  },
  methods: {
    submit() {
      this.submiting = true;
      if (this.$refs.form.validate()) {
        let email = this.email;
        let password = this.password;

        this.$store
          .dispatch('login', { email, password })
          .then(() => {
            this.submiting = false;

            if (sessionStorage.getItem('kinsan_redirect') != null) {
              this.$router.push(sessionStorage.getItem('kinsan_redirect'));
              sessionStorage.removeItem('kinsan_redirect');
            } else {
              if (this.user.type === 'client') {
                this.$router.push('/');
              } else if (this.user.type === 'owner') {
                this.$router.push('/list-media');
              }
            }
          })
          .catch(() => {
            this.submiting = false;
            this.response = this.$t('incorrect_email_or_password');
            this.$refs.form.reset();
            setTimeout(() => {
              this.response = null;
            }, 4000);
          });
      } else {
        this.submiting = false;
        // this.response = "Nomor Whatsapp dan password tidak boleh kosong";
        // this.$refs.form.reset();
      }
    },
    redirectAfterLogin() {
      if (localStorage.getItem('kinsan_redirect') != null) {
        this.$router.push(localStorage.getItem('kinsan_redirect'));
        localStorage.removeItem('kinsan_redirect');
        return;
      }
      if (this.user.is_updated_profile) {
        return this.$router.push('/');
      }
      return this.$router.push('/');
    },
  },
};
</script>

<style>
.google-signin-button {
  color: white;
  background-color: red;
  height: 50px;
  font-size: 16px;
  border-radius: 10px;
  padding: 10px 20px 25px 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
#line {
  width: 100%;
  height: max-content;
  text-align: center;
  position: relative;
}

#line:after {
  content: '';
  width: 100%;
  border-bottom: solid 1px #e0e6ed;
  position: absolute;
  left: 0;
  top: 50%;
  z-index: 1;
}

#line span {
  width: auto;
  background: #fff;
  display: inline-block;
  z-index: 3;
  padding: 0 20px 0 20px;
  position: relative;
  margin: 0;
}
</style>
