export default {
  'order.first_payment_expired': 'First payment of {order_number} has expired.',
  'order.new_quotation': 'The Administrator send you a quote.',
  'order.new_contract': 'The Administrator send you a contract.',
  'order.contract_rejected': 'The Administrator reject your signed contract.',
  'order.payment_rejected': 'The Administrator reject your payment confirmation document.',
  'order.contract_approved': 'The Administrator approve your signed contract.',
  'order.payment_approved': 'The Administrator approve your payment confirmation document.',
  'order.media_rejected': 'The Administrator reject your media.',
  'order.media_approved': 'The Administrator approve your media.',
  'order.new_activation_proof': 'The Administrator send the activation proof.',
  'order.2nd_payment_approved': 'The Administrator approve your second payment confirmation document.',
  'order.2nd_payment_rejected': 'The Administrator reject your second payment confirmation document.',
  'system.media_expired': 'Your purchased media has expired',
  'media.media_approved': 'The Administrator approve your media.',
  'media.media_rejected': 'The Administrator reject your media.',
};
