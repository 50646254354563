<template>
  <v-data-table
    :loading="isLoading"
    :headers="headers"
    :search="search"
    :items="item"
    :items-per-page.sync="data.limit"
    :page.sync="data.page"
    :item-key="'id'"
    :mobile-breakpoint="0"
    :server-items-length="data.total"
    loading-text="Loading... Please wait"
    :footer-props="{
      'items-per-page-text': $t('row_per_page'),
    }"
  >
    <template slot="item" slot-scope="{ item }">
      <tr class="flat-b-1 rounded-lg">
        <td class="white text-capitalize">
          <router-link
            class="color--text font-weight-bold text-capitalize"
            :to="{ name: 'detail-transaction', params: { id: item._id } }"
            v-text="item.order_number"
          >
          </router-link>
        </td>
        <td
          v-if="$i18n.locale === 'en'"
          class="white text-capitalize text-truncate"
          v-text="
            item && item && item.product && item.product.title
              ? item.product.media_type.title + ' - ' + item.product.title
              : ''
          "
          style="max-width: 150px"
        ></td>
        <td
          v-else
          class="white text-capitalize text-truncate"
          v-text="
            item && item && item.product && item.product.title
              ? item.product.media_type.title_ja + ' - ' + item.product.title
              : ''
          "
          style="max-width: 150px"
        ></td>
        <td class="white text-capitalize">
          <!-- <v-chip
            style="background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #3E66FB!important;"
            :ripple="false" class="pa-1 text-capitalize" color="primary" outlined label small
            v-text="item.status == 'waiting_quotation' ? 'Waiting for Quotation' : item.status == 'waiting_contract' ? 'Waiting for Contract' : item.status.replace(/_/g,' ')"></v-chip> -->
          <v-chip
            style="
              background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)),
                #3e66fb !important;
            "
            :ripple="false"
            class="pa-1 text-capitalize"
            color="primary"
            outlined
            label
            small
            v-text="getStatusOrder(item)"
          ></v-chip>
        </td>
        <td class="white text-capitalize">
          <!-- <v-chip
            style="background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #3E66FB!important;"
            :ripple="false" class="pa-1 text-capitalize" color="primary" outlined label small
            v-text="item && item.approval && item.approval.payment_proof_first && item.approval.payment_proof_first.status ? item.approval.payment_proof_first.status : '-'">
          </v-chip> -->
          <v-chip
            v-if="item.status_payment"
            style="
              background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)),
                #3e66fb !important;
            "
            :ripple="false"
            class="pa-1 text-capitalize"
            color="primary"
            outlined
            label
            small
            v-text="$t('transaction.status.' + item.status_payment)"
          >
          </v-chip>
          <span v-else>-</span>
        </td>
        <!-- <td class="white text-capitalize" v-text="item && item.duration ? item.duration : ''"></td> -->
        <td
          class="white text-capitalize"
          v-text="
            item.duration == '1 Month'
              ? $t('one_month')
              : item.duration == '3 Months'
              ? $t('three_month')
              : item.duration == '6 Months'
              ? $t('six_month')
              : item.duration == '12 Months'
              ? $t('one_year')
              : item.duration
          "
        ></td>
      </tr>
    </template>
  </v-data-table>
</template>
<script>
export default {
  name: 'table-trans',
  props: {
    // data: { required: true, type: Object, default: () => ({}) },
    data: {
      type: Object,
      default: () => ({
        limit: 5,
      }),
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
    search: {
      type: String,
      default: () => '',
    },
  },
  data: () => ({
    headers: [
      {
        text: 'ID Order',
        class: 'text-no-wrap',
        value: 'order_number',
      },
      {
        text: 'Media',
        class: 'text-no-wrap',
        filterable: true,
        value: 'product.title',
      },
      {
        text: 'Status Order',
        class: 'text-no-wrap',
        value: 'status',
      },
      {
        text: 'Status Payment',
        class: 'text-no-wrap',
        value: 'status_payment',
      },
      {
        text: 'Duration',
        class: 'text-no-wrap',
        value: 'duration',
      },
    ],
    item: [
      /* {
          id:'16AE41332TY90',
          media:'Building - Nagoya TV Tower Naka Ward, Nagoya, Aichi 460-0003',
          order:'Waiting for quotation',
          payment:'-',
          duration:'1 Month'
        },
        {
          id:'16AE41332TY91',
          media:'Building - Nagoya TV Tower Naka Ward, Nagoya, Aichi 460-0003',
          order:'Media Activated',
          payment:'2nd Payment Approved',
          duration:'1 Month'
        },   */
    ],
    table_loading: false,
  }),
  computed: {
    isLoading() {
      return this.loading || this.table_loading;
    },
    /* isSearch() {
        return this.search;
      }, */
    /* item: {
        get () {
          return this.item
        },
        set (val) {
          this.$emit('update:item', val)
        }
      } */
  },
  async mounted() {
    await this.getTransaction();
    this.headers[0].text = await this.$t('id_order');
    this.headers[1].text = await this.$t('media');
    this.headers[2].text = await this.$t('status_order');
    this.headers[3].text = await this.$t('status_payment');
    this.headers[4].text = await this.$t('duration');
  },
  methods: {
    getStatusOrder(item) {
      let status = item.status;
      if (item.approval?.contract_signed?.status == 'pending') {
        status = 'waiting_contract_approval';
      }
      return this.$t(`transaction.status.${status}`);
    },
    getTransaction() {
      this.table_loading = true;
      this.$store
        .dispatch('getTransaction', {})
        .then((data) => {
          this.item = data;
          this.table_loading = false;
        })
        .catch((error) => {
          this.table_loading = false;
          console.log(error);
        });
    },
    paginate: (current, total) => {
      if (current > total) {
        throw new Error('Current page is larger than total');
      }
      let pages = [];
      if (total > 5) {
        pages = new Array(5);
        pages = pages.fill(0).map((val, i) => {
          if (current > 3 && current < total - 2) {
            switch (i) {
              case 0:
                return current > 3 ? '...' : i + current - 2;
              case 4:
                return current < total - 2 ? '...' : i + current - 2;
              default:
                return i + current - 2;
            }
          }

          if (current <= 3) {
            return i == 4 ? '...' : i + 1;
          }

          if (current >= total - 2) {
            return i == 0 ? '...' : i + total - 4;
          }
        });
      } else {
        pages = new Array(total);
        pages = pages.fill(0).map((val, i) => i + 1);
      }

      return pages;
    },
  },
  watch: {
    'data.limit': function () {
      this.$emit('refresh-list');
    },
    'data.page': function () {
      this.$emit('refresh-list');
    },
  },
};
</script>
<style>
#dt-program .v-data-table > .v-data-table__wrapper > table {
  border-spacing: 0 10px !important;
}

/*#dt-program .v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
    height: 80px !important;
  }*/
.theme--light
  #dt-program
  .v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border: 0px solid !important;
}

.theme--light #dt-program .v-data-table .v-data-footer {
  border: 0px solid !important;
}

#dt-program .v-data-table-header {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #c2c9d1;
  color: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
}

#dt-program .v-data-table-header th {
  font-size: 14px;
  font-weight: 600;
}

.v-application--is-ltr #dt-program .v-data-footer__select .v-select {
  margin: 13px 0 13px 0px;
}
</style>
