import Vue from 'vue'
import VueRouter from 'vue-router'
import Views from '../views'
import store from '../store'


Vue.use(VueRouter)

let routes = {
  auth: [
    { path: '/dashboard', name: 'dashboard', component: Views.User.dashboard },

    //member
    { path: '/profile', name: 'profile', component: Views.User.profile },
    { path: '/transaction', name: 'transaction', component: Views.User.transaction },
    { path: '/detail-transaction/:id', name: 'detail-transaction', component: Views.User.detailTrans },

    //owner
    { path: '/owner-profile', name: 'owner-profile', component: Views.Owner.OwnerProfile },
    { path: '/list-media', name: 'list-media', component: Views.Owner.listMedia },
    { path: '/create-media', name: 'create-media', component: Views.Owner.CreateMedia },
    { path: '/update-media/:id', name: 'update-media', component: Views.Owner.UpdateMedia },


    //public
    { path: '/user/index', name: 'index', component: Views.indexUser.UserBeranda },
    { path: '/user/search-page/:id', name: 'serp', component: Views.indexUser.UserSearch },
    { path: '/user/detail-page/:id', name: 'pdp', component: Views.indexUser.UserDetail },

    /* { path: '/', name: 'beranda', component: Views.Public.PublicBeranda },
    { path: '/search', name: 'search-page', component: Views.Public.PublicSearch },
    { path: '/detail/:id', name: 'detail-page', component: Views.Public.PublicDetail }, */
  ],
  guest: [
    { path: '/login', name: 'login', component: Views.Auth.Login },
    { path: '/register', name: 'register', component: Views.Auth.Register },
    { path: '/forgot-password', name: 'forgot-password', component: Views.Auth.forgotPassword },
    { path: '/reset-password', name: 'reset-password', component: Views.Auth.resetPassword },
    /* { path: '/', name: 'beranda', component: Views.Public.PublicBeranda },
    { path: '/search', name: 'search-page', component: Views.Public.PublicSearch },
    { path: '/detail/:id', name: 'detail-page', component: Views.Public.PublicDetail }, */
    
  ],
  default: [
    // { path: '/user/index', name: 'index', component: Views.indexUser.UserBeranda },
  
    { path: '*', name: '404 Not Found', component: Views.Errors.NotFound },
    { path: '/terms-of-use', name: 'terms-of-use', component: Views.Secondary.term },
    { path: '/company-profile', name: 'company-profile', component: Views.Secondary.company },
    { path: '/media-owner', name: 'media-owner', component: Views.Secondary.media },
    { path: '/privacy-policy', name: 'privacy-policy', component: Views.Secondary.privacy },
    { path: '/faq', name: 'faq', component: Views.Secondary.guide },

    { path: '/', name: 'beranda', component: Views.Public.PublicBeranda },
    { path: '/search', name: 'search-page', component: Views.Public.PublicSearch },
    { path: '/detail/:id/:slug', name: 'detail-page', component: Views.Public.PublicDetail},
    { path: '/factsheet', name: 'factsheet', component: Views.Public.Viewer },

    // { path: '/detail-kelas/:id', name: 'detail-kelas', component: Views.Public.PublicDetail },
  ]
}

routes.auth.forEach(route => { route.meta = { ...route.meta, auth: true } });
routes.guest.forEach(route => { route.meta = { ...route.meta, guest: true } });
routes = [
  ...routes.auth,
  ...routes.guest,
  ...routes.default,
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.path == '/' && store.getters.isLoggedIn) {
    const user = await store.getters.user;
    if (user.type == 'owner') {
      return next('/profile')
    }
  }
  if (to.meta.auth && !store.getters.isLoggedIn) {
    sessionStorage.setItem('kinsan_redirect', location.pathname)
    return next('/')
  }
  /* if (to.meta.guest && store.getters.isLoggedIn) {
    return next('/profile')
  } */
  return next()
})

export default router
