<template>
  <transition name="fade">
    <v-app v-if="loaded">
      <!-- <div class=""> -->
      <v-main class="" style="background: #fcfcfd">
        <v-app-bar id="menu-bar" class="white" v-show="show_sidebar" flat fixed elevation="1">
          <v-toolbar-title>
            <router-link class="my-1 text-left primary--text font-weight-bold" :to="{ name: 'beranda'}">
              <img src="@/assets/images/logo.jpg" style="width: 95px;" class="img-logo-head">
              
            </router-link>
          </v-toolbar-title>

          <v-spacer></v-spacer>

          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <div class="ml-6 mx-3 rounded-pill" v-ripple v-bind="attrs" v-on="on">

                <span class="fs-16 font-weight-medium primary--text mr-1">{{ $cookies.get("kinsan_lang") && $cookies.get("kinsan_lang") == 'jp' ? '日本' : 'English'}}</span>
                <v-icon small>$dropdown</v-icon>

              </div>
            </template>

            <v-list dense>
              <v-list-item link @click="changeLang('en')">
                <v-list-item-title v-text="'English'"></v-list-item-title>
              </v-list-item>
              <v-list-item link @click="changeLang('jp')">
                <v-list-item-title v-text="'日本'"></v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-btn @click="signIn()" class="me-2 d-none d-md-block rounded-lg border-state" x-small outlined
            elevation="0">
            {{ $t('login') }}
          </v-btn>

          <v-btn @click="signUp()" color="primary" class="d-none d-md-block rounded-lg" x-small elevation="0">
            {{ $t('register') }}
          </v-btn>
          <v-app-bar-nav-icon v-if="!$vuetify.breakpoint.mdAndUp" @click="dialog.menu = true">
          </v-app-bar-nav-icon>
          <v-dialog v-model="dialog.menu" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card>
              <v-toolbar>
                <v-toolbar-title>
                  <div class="my-1 text-left">
                    <!-- <img
                        src="@/assets/images/logo-ytc.png"
                        alt="Avatar"
                        class="img-yec-test"
                      /> -->
                    <router-link class="my-1 text-left primary--text font-weight-bold" :to="{ name: 'beranda'}">
                      Kinsan
                    </router-link>
                  </div>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-btn icon dark @click="dialog.menu = false" color="primary">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>
              <v-spacer></v-spacer>
              <v-card-text class="pa-5">
                <v-btn @click="signIn();dialog.menu = false" class="rounded-8 mb-4" block elevation="0" outlined>
                  Login
                </v-btn>
                <v-btn @click="signUp();dialog.menu = false" color="primary" class="rounded-8" block elevation="0">
                  Register
                </v-btn>
                <!-- <v-btn @click="prakerja();dialog.menu = false" class=" transparent primary--text" block elevation="0" >
                    Kelas Prakerja
                  </v-btn> -->
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-app-bar>

        <v-container fluid style="margin-top: 64px;padding: 0px;">
          <transition name="fade">
            <router-view v-if="loaded"></router-view>
          </transition>
        </v-container>

        <div id="footer" class="bg-footer py-4" v-show="show_sidebar">
          <div class="container">
            <div class="row">
              <div class="col-md-10 mx-auto">
                <div class="contact">
                  <div class="row">
                    <div class="col-md-8 mb-3 box-flex">
                      <p class="white--text me-2">TEL : 03 - 6261 - 0002</p>
                      <p class="white--text mb-0">({{ $t('operating_hours') }} : {{ $t('weekdays') }} 09:00 - 17:00)</p>
                    </div>
                    <div class="col-md-4 mb-3 box-inquiry">
                      <!-- <v-btn depressed x-small class="rounded-lg"> -->
                      <v-btn depressed x-small class="rounded-lg" @click="inquiry=true">
                        {{ $t('inquiry') }}
                      </v-btn>
                    </div>
                    <div class="col-md-12 mb-3">
                      <div class="row">
                        <div class="col-md-2 mx-auto text-center">
                          <a href="#" class="white--text text-center fs-14 font-weight-medium">{{ $t('for_medias_owners') }}</a>
                        </div>
                        <div class="col-md-2 mx-auto text-center">
                          <a href="#" class="white--text text-center fs-14 font-weight-medium">{{ $t('company_profile') }}</a>
                        </div>
                        <div class="col-md-2 mx-auto text-center">
                          <router-link :to="{ name: 'privacy-policy'}" class="white--text text-center fs-14 font-weight-medium">{{ $t('privacy_statement') }}</router-link>
                          <!-- <router-link :to="{ name: 'index'}" class="white--text text-center fs-14 font-weight-medium">Privacy Statement</router-link> -->
                        </div>
                        <div class="col-md-2 mx-auto text-center">
                          <router-link :to="{ name: 'terms-of-use'}" class="white--text text-center fs-14 font-weight-medium">{{ $t('terms_conditions') }}</router-link>
                          <!-- <router-link :to="{ name: 'index'}" class="white--text text-center fs-14 font-weight-medium">Terms & Condition</router-link> -->
                        </div>
                        <div class="col-md-2 mx-auto text-center">
                          <router-link :to="{ name: 'faq'}" class="white--text text-center fs-14 font-weight-medium">{{ $t('faq_how_to_use') }}</router-link>
                          <!-- <router-link :to="{ name: 'index'}" class="white--text text-center fs-14 font-weight-medium">FAQ (How to Use)</router-link> -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 mb-2 px-0">
                    <v-divider style="border-color: #a383b3;"></v-divider>
                  </div>
                  <div class="logo-footer white--text col-md-5 py-0 text-center mb-2 mx-auto">
                    <v-list-item class="d-dekstop">
                      <img src="@/assets/images/logo-footer.png" style="width: 150px;height: 100px;" class="">
                      <v-list-item-content>
                        <v-list-item-title class="white--text">by KINSAN GLOBAL, LLC</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <div class="d-mobile">
                      <img src="@/assets/images/logo-footer.png" style="width: 150px;height: 100px;" class=""><br>
                      <span>by KINSAN GLOBAL, LLC</span>                   
                    </div>
                    <!-- <span class="font-weight-bold fs-32 mr-3">Kinsan Advertising </span>
                    <span>by KINSAN GLOBAL, LLC</span> -->
                  </div>
                  <div class="text-footer white--text">
                    <p class="mb-0 fs-14">{{ $t('copyright') }} © {{year}} Kinsan.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <v-dialog v-model="inquiry" width="700" persistent>
            <v-form lazy-validation @submit.prevent="submit" ref="form" class="mb-3">
              <v-card>
                <v-card-title class="text-h5">
                  <v-alert width="100%" v-if="response_success" text outlined color="success" class="rounded-8">
                    <p class="color-black fs-18 font-weight-medium mb-0">Success</p>
                    <p class="color-black mb-0">{{response_success}}</p>
                  </v-alert>
                  <v-alert width="100%" v-if="response_fail" text outlined color="error" class="rounded-8">
                    <p class="color-black fs-18 font-weight-medium mb-0">Failed</p>
                    <p class="color-black mb-0">{{response_fail}}</p>
                  </v-alert>
                  {{ $t('inquiry') }}
                </v-card-title>

                <v-card-text>
                  <v-row>
                    <v-col class="pb-1">
                      <div class="mb-2">
                        <span class="font-weight-medium color-black">{{ $t('company_name') }}</span>
                      </div>
                      <v-text-field :rules="[...rules('Company Name', 'required'), validate]" v-model="company_name"
                        class="rounded-8" required outlined hide-details="auto"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pb-1">
                      <div class="mb-2">
                        <span class="font-weight-medium color-black">{{ $t('phone') }}</span>
                      </div>
                      <v-text-field type="number" :rules="[...rules('Phone', 'required'), validate]" v-model="contact"
                        class="rounded-8" required outlined hide-details="auto"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pb-1">
                      <div class="mb-2">
                        <span class="font-weight-medium color-black">{{ $t('email') }}</span>
                      </div>
                      <v-text-field :rules="[...rules('Email Address', 'email'), validate]" v-model="email" class="rounded-8"
                        required outlined hide-details="auto"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pb-1">
                      <div class="mb-2">
                        <span class="font-weight-medium color-black">{{ $t('message') }}</span>
                      </div>
                      <v-textarea :rules="[...rules('Message', 'required'), validate]" v-model="message"
                        class="rounded-8" required outlined hide-details="auto" rows="2"></v-textarea>
                    </v-col>
                  </v-row>
                </v-card-text>

                <!-- <v-divider></v-divider> -->

                <v-card-actions class="pb-4">
                  <v-spacer></v-spacer>
                  <v-btn outlined @click="inquiry = false" color="#9e9e9e" small class="rounded-8 px-4">
                    {{ $t('cancel') }}
                  </v-btn>
                  <v-btn :loading="loading" color="primary" type="submit" small class="rounded-8 px-4 me-2">
                    {{ $t('submit') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-dialog>
        </div>
      </v-main>
      <!-- </div> -->
    </v-app>
  </transition>
</template>
<script>
  import validationMixin from "../validation/valid";
  import moment from "moment"

  import {
    mapGetters
  } from "vuex";

  export default {
    mixins: [validationMixin],
    data() {
      return {
        breadcrumbs: [],
        loaded: true,
        year:null,
        drawer: false,
        group: null,
        notifications: [1],
        pageTitle: "",
        // title:false,
        inquiry: false,
        response_success: null,
        response_fail: null,
        loading: false,
        pathnya: "",
        company_name: "",
        email: "",
        contact: "",
        message: "",
        dialog: {
          menu: false,
        },
        bhs: ['English', 'Japan'],
        language: 'English'
      };
    },
    computed: {
      ...mapGetters(["user"]),

      show_sidebar() {
        return !/\/auth\/.\.*/.test(this.$route.path);
      },
      validate() {
        return !this.errors || this.errors.message;
      },
    },
    created() {
      // this.disableRight();
      this.$root.$on("setPageTitle", (pageTitle) => {
        this.pageTitle = pageTitle;
        // console.log(pageTitle);
      });

      // let path = "/" + this.$route.path.split("/")[1];
      this.pathnya = this.$route.path;
      // console.log("pathnya", this.pathnya);
      // let index = this._.findIndex(this.items, { link: path });
      // if (index != -1) {
      //   this.pageTitle = this.items[index].title;
      //   this.breadcrumbs.push({
      //     text: this.items[index].title,
      //     disabled: true,
      //     href: path,
      //   });
      // }
      this.year=moment().format('YYYY');
    },
    methods: {
      async changeLang(language) {
          this.$cookies.set("kinsan_lang", language, -1);
          this.$router.go(0);
      },
      async submit() {
        this.response_success = null;
        this.response_fail = null;
        this.loading = true;

        if (this.$refs.form.validate()) {

          let company_name = this.company_name
          let contact = this.contact
          let email = this.email
          let message = this.message

          this.$store
            .dispatch("inquiry", {
              company_name,
              contact,
              email,
              message
            })
            .then(async (result) => {
              if (result == "success") {
                this.$refs.form.reset();
                this.response_success =
                  "Your message has been sent.";
                // window.scrollTo(0, 0);
                setTimeout(() => {
                  this.inquiry = false
                }, 5000);
                this.loading = false;
              }
            })
            .catch((error) => {
              // this.$refs.form.reset();
              this.response_fail = (error.response.data.message).replace("body should have ", "").replace("body.",
                "");
              this.response_fail = this.response && this.response.slice(1) ? this.response_fail.charAt(0).toUpperCase() + this.response.slice(1) : this.response_fail;
              // window.scrollTo(0, 0);
              this.loading = false;
              // console.log(error.response.data);
            });
        } else {
          this.loading = false;
          // this.response_fail = "Failed";
          // window.scrollTo(0, 0);
        }
      },
      menuItems() {
        return this.menu;
      },
      logout() {
        this.$store.dispatch("logout").then(() => {
          this.$router.push("/");
          window.location.reload(true);
        });
      },
      signUp() {
        this.$router.push("/register");
      },
      signIn() {
        this.$router.push("/login");
      },
      prakerja() {
        this.$router.push("/kelas-prakerja");
      },
      // menu() {
      //   this.$router.push("/");
      // },
      updateLocation(item) {
        // this.pageChanged(0, item);
        this.pageTitle = item.title;
      },
      pageChanged(segment, location = null) {
        // console.log(segment);
        // console.log(location);
        this.pathnya = this.$route.path;
        // console.log("pathpage", this.pathnya);
        this.breadcrumbs.splice(segment);
        if (location != null) {
          this.pageTitle = location.title;
          this.breadcrumbs.map((item) => (item.disabled = false));
          this.breadcrumbs.push({
            text: location.title,
            disabled: true,
            href: location.link,
          });
        }
        // window.location.reload(true);
        document.title =
          this.breadcrumbs.map((val) => val.text).join(" -> ") +
          " - " +
          process.env.VUE_APP_TITLE;
      },
    },
  };
</script>