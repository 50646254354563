import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from './en';
import jp from './jp';

Vue.use(VueI18n);

const messages = {
  en: {
    update_media: 'Update Media',
    email_already_registered: 'Email is already registered!',
    your_media_history: 'Your Media History',
    incorrect_email_or_password: 'Incorrect email or password!',
    postal_code_available: 'Postal code available',
    postal_code_not_available: 'Postal code not available',
    start_from_amount: 'Start From ￥{amount}',
    success_update_product: 'Success update product',
    failed: 'Failed',
    requiest_quotation: 'Request Quotation',
    quotation_no_longer_valid: 'The quotation above is no longer valid',
    ...en,
    find_deals_on_advertising_space_here: 'Find deals on advertising space here',
    login: 'Login',
    register: 'Register',
    all_location: 'Find your media here',
    all_type: 'All type',
    search: 'Search',
    browse_by_location: 'Browse by Location',
    recommended_category: 'Recommended Category',
    browse_media_type: 'Browse Media Type',
    browse_by_target_market: 'Browse by Target Market',
    footer: 'Footer',
    operating_hours: 'Operating hours',
    for_medias_owners: 'For Media’s Owners',
    company_profile: 'Company Profile',
    privacy_statement: 'Privacy Policy',
    terms_conditions: 'Terms of Use',
    faq_how_to_use: 'FAQ',
    inquiry: 'Inquiry',
    filter_by: 'Filter by',
    search_result: 'Search Result',
    category: 'Category',
    target: 'Target',
    price: 'Price',
    apply: 'Apply',
    min: 'Min',
    max: 'Max',
    price_start_from: 'Price Start From',
    choose_duration: 'Choose Duration',
    choose_start_date: 'Choose Start Date',
    start_date: 'Start Date',
    end_date: 'End Date',
    quotation_request: 'Quotation Request',
    ask_for_enquiries: 'Ask For Enquiries',
    print_factsheet: 'Print Factsheet',
    email_address: 'Email Address',
    password: 'Password',
    forgot_password: 'Forgot Password',
    dont_have_an_account_yet: 'Don’t have an account yet?',
    already_have_an_account: 'Already have an account?',
    account: 'Account',
    information_related_to_the_credential: 'Information Related to the Credential',
    user_category: 'User Category',
    client: 'Client',
    owner: 'Owner',
    profile: 'Profile',
    given_name: 'Given Name',
    surname: 'Surname',
    company_name: 'Company Name',
    postal_code: 'Postal Code',
    prefecture: 'Prefecture',
    city: 'City',
    building_address: 'Building Address',
    phone: 'Phone',
    contact_person: 'Contact Person',
    transaction: 'Transaction',
    quotation: 'Quotation',
    contract_payment: 'Contract & Payment',
    upload: 'Upload',
    activation: 'Activation',
    finish: 'Finish',
    waiting_for_quotation: 'Waiting for Quotation',
    pending_quotation: 'Pending Quotation',
    waiting_for_contract: 'Waiting for Contract',
    pending_contract: 'Pending Contract',
    approved: 'Approved',
    contract_approved: 'Contract Approved',
    contract_rejected: 'Contract Rejected',
    media_approved: 'Media Approved',
    media_pending: 'Media Pending',
    media_rejected: 'Media Rejected',
    media_activated: 'Media Activated',
    second_payment_proof_approved: 'Second Payment Proof Approved',
    first_payment_proof_approved: 'First Payment Proof Approved',
    second_payment_proof_rejected: 'Second payment proof rejected',
    first_payment_proof_rejected: 'First payment proof rejected',
    your_request_has_been_accepted_admin_will_send_you_quotation_here_please_waiting:
      'Your request has been accepted, admin will send you quotation here, please waiting',
    please_download_the_pdf_file_above_then_give_authorized_manually_after_that_upload_the_contract_payment_receipt:
      'Please download the pdf file above then give authorized manually, after that upload the contract & payment receipt',
    contract_authorized: 'Signed Contract',
    payment_receipt: 'Payment Proof',
    'drag_n_drop_file,_or_browse_computer': 'Drag n drop file, or browse computer',
    waiting_for_approval: 'Waiting for Approval',
    please_attend_the_rule_before_you_upload_the_media:
      'Please attend the rule before you upload the media',
    id_order: 'ID Order',
    media: 'Media',
    status_order: 'Status Order',
    status_payment: 'Status Payment',
    duration: 'Duration',
    change_password: 'Change Password',
    profile_updated: 'Profile Updated',
    your_profile_has_been_updated_succesfully: 'Your profile has been updated succesfully',
    email: 'Email',
    message: 'Message',
    reset_password: 'Reset Password',
    email_sent: 'Email Sent',
    please_check_your_email_and_open_link_we_sent_to_continue:
      'Please check your email and open link we sent to continue',
    new_password: 'New Password',
    enter_new_password_to_continue: 'Enter new password to continue',
    password_reset: 'Password Reset',
    password_has_been_reset_successfully: 'Password has been reset successfully',
    login_now: 'Login Now',
    no_media_added: 'No media added',
    add_your_media_by_press_the_button_below: 'Add your media by press the button below',
    add_new_media: 'Add new media',
    basic_info: 'Basic Info',
    media_type: 'Media Type',
    media_name: 'Media Name',
    orientation: 'Orientation',
    unit_of_measurement: 'Unit of Measurement',
    height: 'Height',
    width: 'Width',
    lighting: 'Lighting',
    available: 'Available',
    not_available: 'Not Available',
    minimum_price: 'Minimum Price',
    discount: 'Discount',
    description: 'Description',
    traffic: 'Traffic',
    target_market: 'Target Market',
    photo: 'Photo',
    video: 'Video',
    location: 'Location',
    address: 'Address',
    latitude: 'Latitude',
    longitude: 'Longitude',
    document: 'Document',
    vertical: 'Vertical',
    horizontal: 'Horizontal',
    size: 'Size',
    meter: 'Meter',
    centimeter: 'Milimeter',
    media_nearby: 'Media Nearby',
    view_on_map: 'View on map',
    signup: 'Signup',
    information_related_to_the_personal_info: 'Information related to the personal info',
    search_transaction: 'Search Transaction',
    row_per_page: 'Row per page : ',
    all: 'All',
    logout: 'Logout',
    user: 'User',
    current_password: 'Current Password',
    confirm_password: 'Confirm Password',
    save: 'Save',
    cancel: 'Cancel',
    check: 'Check',
    all_media: 'All Media',
    active_media: 'Active Media',
    activated_media: 'Activated Media',
    waiting_for_review: 'Waiting for Review',
    rejected: 'Rejected',
    rejected_media: 'Rejected Media',
    search_media: 'Search Media',
    information_related_to_the_specification: 'Information related to the specification',
    minimum_price_per_month: 'Minimum Price (Per Month)',
    targeted_segment_related_to_the_media: 'targeted segment related to the media',
    photo_video: 'Photo & Video',
    link_youtube: 'Link Youtube',
    search_location: 'Search location',
    choose_media_type: 'Choose media type',
    choose_orientation: 'Choose orientation',
    choose_target_market: 'Choose target market',
    have_lighting: 'Have Lighting',
    no_lighting: 'No Lighting',
    list_media: 'List Media',
    drag_drop_your_files_or: 'Drag & Drop your files or',
    browse: 'Browse',
    by: 'by',
    copyright: 'Copyright',
    weekdays: 'Weekdays',
    select_duration: 'Select Duration',
    home: 'Home',
    building: 'Building',
    download: 'Download',
    renewal: 'Renewal',
    contract_unsigned: 'Contract Unsigned',
    submit: 'Submit',
    approve: 'Approve',
    // 'waiting_for_contract': 'Waiting for Contract',
    admin_will_send_you_contract_here_please_waiting:
      'Waiting for Contract. Admin will send you contract here, please waiting',
    waiting_for_approval_contract_and_payment: 'Waiting for approval contract and payment',
    upload_media_will_be_available_if_contract_and_payment_approved:
      'Upload media will be available if contract and payment approved',
    contact: 'Contact',
    enter_your_email_address_and_we_ll_send_you_a_link_to_reset_your_password:
      'Enter your email address and we’ll send you a link to reset your password',
    send_password_reset_link: 'Send password reset link',
    is_required: ' is required',
    must_be_valid: ' must be valid',
    location_is_required: 'Location is required',
    photo_is_required: 'Photo is required',
    email_is_required: 'E-mail is required',
    email_must_be_valid: 'E-mail must be valid',
    password_is_required: 'Password is required',
    password_must_has_at_least_8_characters_that_include_at_least_1_lowercase_character_1_uppercase_character_1_number_and_1_special_character:
      'Password must has at least 8 characters that include at least 1 lowercase character, 1 uppercase character, 1 number, and 1 special character in (!@#$%^&*)',
    the_administrator_send_you_a_quote: 'The Administrator send you a quote.',
    the_administrator_send_you_a_contract: 'The Administrator send you a contract.',
    the_administrator_reject_your_signed_contract: 'The Administrator reject your signed contract.',
    the_administrator_reject_your_payment_confirmation_document:
      'The Administrator reject your payment confirmation document.',
    the_administrator_approve_your_signed_contract:
      'The Administrator approve your signed contract.',
    the_administrator_approve_your_payment_confirmation_document:
      'The Administrator approve your payment confirmation document.',
    the_administrator_reject_your_media: 'The Administrator reject your media.',
    the_administrator_approve_your_media: 'The Administrator approve your media.',
    the_administrator_send_the_activation_proof: 'The Administrator send the activation proof.',
    the_administrator_approve_your_second_payment_confirmation_document:
      'The Administrator approve your second payment confirmation document.',
    the_administrator_reject_your_second_payment_confirmation_document:
      'The Administrator reject your second payment confirmation document.',
    your_purchased_media_has_expired: 'Your purchased media has expired',
    request_media_approval: 'Request media approval.',
    request_a_quote: 'Request a quote.',
    request_a_contract: 'Request a contract.',
    upload_signed_contract: 'Upload signed contract.',
    upload_payment_confirmation_document: 'Upload payment confirmation document.',
    upload_media: 'Upload Media.',
    upload_second_payment_confirmation_document: 'Upload second payment confirmation document.',
    location_name: 'Location Name',
    station_name: 'Station Name',
    building_name: 'Building Name',
    building_number: 'Building Number',
    airport_name: 'Airport Name',
    gate_number: 'Gate Number',
    townarea: 'Town Area',
    month: 'Month',
    months: 'Months',
    auto_fill_from_postal_code: 'Auto fill from postal code',
    ex: 'Ex',
    price_minimum: 'Price Minimum',
    price_maximum: 'Price Maximum',
    our_office: 'Our Office',
    start_from: 'Start From',
    title_scan: 'Scan Here for Details',
    contact_us: 'Contact Us',
    business_hour: 'Business Hour',
    search_your_suitable_advertising_media_compare_the_price_and_book_securely_in_a_few_clicks:
      'Search your suitable advertising media, compare the price, and book securely in a few clicks',
    your_search_did_not_match_any_products_please_change_your_search_criteria_and_try_again_if_still_not_finding_anything_relevant_please_click_the_inquiry_button:
      'Your search did not match any products. Please change your search criteria and try again. If still not finding anything relevant, please click the “inquiry” button.',
    no_data: 'No data avaiable',
    desc_media: 'Description Media',
    notifications: 'Notifications',
    one_month: '1 Month',
    three_month: '3 Months',
    six_month: '6 Months',
    one_year: '12 Months',
    homepage: 'Homepage',
    waiting_media_activation: 'Waiting for Media Activation',
    payment_proof_second_: 'Second Payment Proof Approved',
    second_payment_receipt: '2nd Payment Receipt',
    llc: 'LLC',
    add1: 'Sakura Building 5F, 4-2-2 Kudankita, Chiyoda-ku',
    add2: 'Tokyo 102-0073, Japan',
    waiting_approval: 'Waiting for Media Approval',
    waiting_approval2: 'Waiting Approval',
  },
  jp: {
    update_media: 'メディアをアップデートする',
    email_already_registered: 'このメールアドレスはすでに登録されています！',
    your_media_history: 'メディア履歴',
    incorrect_email_or_password: 'メールアドレスまたはパスワードが正しくありません！',
    postal_code_available: 'この郵便番号は利用可能です',
    postal_code_not_available: 'この郵便番号は利用できません',
    start_from_amount: '￥{amount}から',
    success_update_product: '製品を正常に更新しました',
    failed: '失敗',
    requiest_quotation: '見積依頼',
    quotation_no_longer_valid: 'お見積書の期限が切れました',
    ...jp,
    find_deals_on_advertising_space_here: '見つけたい、使いたい広告をここから チェック',
    login: 'ログイン',
    register: '登録',
    all_location: '場所',
    all_type: '広告枠の種類',
    search: '検索',
    browse_by_location: '目的地で検索',
    recommended_category: 'おすすめ媒体',
    browse_media_type: ' 媒体タイプで検索',
    browse_by_target_market: 'ターゲット検索',
    footer: 'フッター',
    operating_hours: '受付時間',
    for_medias_owners: 'メディアオーナー様へ',
    company_profile: '運営会社',
    privacy_statement: 'プライバシーポリシー',
    terms_conditions: '利用規約',
    faq_how_to_use: 'FAQ（使い方）',
    inquiry: 'お問い合わせ',
    filter_by: '絞り込み検索',
    search_result: '検索結果',
    category: 'おすすめ媒体',
    target: '目標',
    price: '広告掲出料',
    apply: '検索',
    min: '下限',
    max: '上限',
    price_start_from: '広告掲出料',
    choose_duration: '掲出期間',
    choose_start_date: '開始日を選択',
    start_date: '開始日',
    end_date: '終了日',
    quotation_request: '見積書請求',
    ask_for_enquiries: 'お問い合わせください',
    print_factsheet: '企画書をコピー',
    email_address: 'メールアドレス',
    password: 'パスワード',
    forgot_password: 'パスワードをお忘れですか？',
    dont_have_an_account_yet: 'アカウントをお持ちですか？',
    already_have_an_account: '既にアカウントをお持ちになっている方はこちらから',
    account: 'アカウント',
    information_related_to_the_credential: 'アカウントに関するインフォメーション',
    user_category: 'ユーザーカテゴリー',
    client: 'クライアント',
    owner: '媒体オーナー',
    profile: 'プロフィール',
    given_name: '名',
    surname: '姓',
    company_name: '会社名',
    postal_code: '郵便番号',
    prefecture: '都道府県',
    city: '市区町村名',
    building_address: '番地・ビル名',
    phone: '電話番号',
    contact_person: 'ご連絡先の方のお名前',
    transaction: '取引状況',
    quotation: '見積もり',
    contract_payment: '契約書＆支払い',
    upload: 'アップロード',
    activation: '制作・取付',
    finish: '完了',
    waiting_for_quotation: 'お見積書待ちです',
    pending_quotation: '承認待ちのお見積書です',
    waiting_for_contract: 'ご契約書待ちです',
    pending_contract: '期限切れのご契約書',
    approved: '承認済み',
    contract_approved: '契約が承認されました',
    contract_rejected: '契約を拒否しました',
    media_approved: 'メディアは承認されました',
    media_pending: 'メディア登録承認待ち',
    media_rejected: 'メディア登録を拒否しました',
    media_activated: 'メディアは出稿されました',
    second_payment_proof_approved: '２回目のお支払い証明が確認されました',
    first_payment_proof_approved: '１回目のお支払い証明が確認されました',
    second_payment_proof_rejected: '２回目のお支払い証明を拒否しました',
    first_payment_proof_rejected: '１回目のお支払い証明を拒否しました',
    your_request_has_been_accepted_admin_will_send_you_quotation_here_please_waiting:
      'リクエスト受け付けました。お見積書が出来上がり次第、こちらにお送りします。お待ちください。',
    please_download_the_pdf_file_above_then_give_authorized_manually_after_that_upload_the_contract_payment_receipt:
      '上から契約書PDFをダウンロードしてください。その後、サイン済みの契約書とお支払い確認書類を下にアップロードしてください。',
    contract_authorized: 'サイン済み契約書',
    payment_receipt: 'お支払い確認書類',
    'drag_n_drop_file,_or_browse_computer':
      'ここにドラッグ＆ドロップしてください。もしくわコンピュータのブラウザから選択してください。',
    waiting_for_approval: '承認待ち',
    please_attend_the_rule_before_you_upload_the_media:
      'デザインデータをアップロードしてください。',
    id_order: 'オーダーID',
    media: 'メディア',
    status_order: 'オーダー状況',
    status_payment: 'お支払い状況',
    duration: '期間',
    change_password: 'パスワード変更',
    profile_updated: 'プロファイルアップデート',
    your_profile_has_been_updated_succesfully: 'あなたのプロファイルが正常にアップデートされました',
    email: 'メールアドレス',
    message: 'お問い合わせ内容',
    reset_password: 'パスワードの変更',
    email_sent: 'メール送信済み',
    please_check_your_email_and_open_link_we_sent_to_continue:
      '登録したメールアドレスを確認し、再度、パスワード登録を行ってください。',
    new_password: '新しいパスワード',
    enter_new_password_to_continue: '新しいパスワードを登録してください。',
    password_reset: '新しいパスワードを登録しました。',
    password_has_been_reset_successfully: 'パスワードは問題なく登録されました',
    login_now: '今すぐログインする',
    no_media_added: '登録メディアなし',
    add_your_media_by_press_the_button_below: '下のボタンからメディア登録してください。',
    add_new_media: 'メディア登録',
    basic_info: '基本情報',
    media_type: '媒体種類',
    media_name: '媒体名',
    orientation: '縦横',
    unit_of_measurement: '測定の単位',
    height: '縦',
    width: '横',
    lighting: '照明',
    available: '利用可能',
    not_available: '利用不可',
    minimum_price: '最低価格',
    discount: '割引率',
    description: '媒体詳細',
    traffic: '効果',
    target_market: 'ターゲット',
    photo: '写真',
    video: 'ビデオ',
    location: '場所',
    address: '住所',
    latitude: '緯度',
    longitude: '経度',
    document: '書類',
    vertical: '縦',
    horizontal: '横',
    size: 'サイズ',
    meter: 'Meter',
    centimeter: 'Milimeter',
    media_nearby: '近くのメディア',
    view_on_map: '地図上に表示',
    signup: '登録',
    information_related_to_the_personal_info: 'プロフィールに関するインフォメーション',
    search_transaction: '検索',
    row_per_page: '1ページあたりの行数 ：',
    all: '全て',
    logout: 'ログアウト',
    user: 'ユーザー',
    current_password: '現在のパスワード',
    confirm_password: 'パスワードを認証する',
    save: '保存する',
    cancel: 'キャンセル',
    check: 'チェック',
    all_media: ' 全ての媒体',
    active_media: 'アクティブメディア',
    activated_media: '承認済みメディア',
    waiting_for_review: '承認待ち',
    rejected: '拒否',
    rejected_media: '未承認メディア',
    search_media: 'メディアを検索',
    information_related_to_the_specification: '仕様に関する情報',
    minimum_price_per_month: '最低価格（月額）',
    targeted_segment_related_to_the_media: 'メディアに関連するターゲットセグメント',
    photo_video: '写真とビデオ',
    link_youtube: 'リンクYoutube',
    search_location: '検索場所',
    choose_media_type: '媒体種類を選択',
    choose_orientation: '向きを選択してください',
    choose_target_market: 'ターゲット市場を選択する',
    have_lighting: '照明あり',
    no_lighting: '照明なし',
    list_media: 'リストメディア',
    drag_drop_your_files_or: 'ファイルをドラッグアンドドロップするか、',
    browse: 'ブラウズ',
    by: '沿って',
    copyright: '著作権',
    weekdays: '平日',
    select_duration: '期間を選択',
    home: 'ホームページ',
    building: '建物',
    download: 'ダウンロード',
    renewal: 'リニューアル',
    contract_unsigned: '未署名の契約',
    submit: '送信',
    approve: '承認',
    // 'waiting_for_contract': 'ご契約書待ちです',
    admin_will_send_you_contract_here_please_waiting:
      '管理者がこちらに契約書をお送りします。 しばらく、お待ちください',
    waiting_for_approval_contract_and_payment: '承認契約と支払いを待っています',
    upload_media_will_be_available_if_contract_and_payment_approved:
      '契約と支払いが承認された場合、アップロードメディアが利用可能になります',
    contact: 'コンタクト',
    enter_your_email_address_and_we_ll_send_you_a_link_to_reset_your_password:
      'メールアドレスを入力すると、パスワードをリセットするためのリンクが送信されます',
    send_password_reset_link: 'パスワードリセットリンクを送信する',
    is_required: ' を入力してください',
    must_be_valid: ' 有効である必要があります',
    location_is_required: '場所が必要です',
    photo_is_required: '写真が必要です',
    email_is_required: 'メールアドレスを入力してください',
    email_must_be_valid: '有効なアドレスを入力してください',
    password_is_required: 'パスワードが必要です',
    password_must_has_at_least_8_characters_that_include_at_least_1_lowercase_character_1_uppercase_character_1_number_and_1_special_character:
      'パスワードには、少なくとも1つの小文字、1つの大文字、1つの数字、および1つの特殊文字（！@＃$％^＆*)を含む8文字以上が必要です',
    the_administrator_send_you_a_quote: '管理者が見積書をお送りしました',
    the_administrator_send_you_a_contract: '管理者が契約書をお送りしました',
    the_administrator_reject_your_signed_contract:
      'ご契約書に不備があります。ご確認いただき、再度、アップロードお願い致します',
    the_administrator_reject_your_payment_confirmation_document:
      'お支払い確認書類に不備があります。ご確認いただき、再度、アップロードお願い致します',
    the_administrator_approve_your_signed_contract: '管理者が契約書を承認致しました',
    the_administrator_approve_your_payment_confirmation_document:
      '管理者が支払い確認書類を承認致しました',
    the_administrator_reject_your_media:
      'メディア登録に不備があります。ご確認いただき、再度、アップロードお願い致します',
    the_administrator_approve_your_media: '管理者がメディア登録を承認致しました',
    the_administrator_send_the_activation_proof: '管理者が出稿証明書をお送りしました',
    the_administrator_approve_your_second_payment_confirmation_document:
      '管理者が２回目の支払い確認書類を承認致しました',
    the_administrator_reject_your_second_payment_confirmation_document:
      '２回目の支払い確認書類に不備があります。ご確認いただき、再度、アップロードお願い致します',
    your_purchased_media_has_expired: 'ご契約して頂いたメディアは既に期限切れです',
    request_media_approval: 'からメディア登録申請があります',
    request_a_quote: 'から見積もり要請があります',
    request_a_contract: 'から契約書の要請があります',
    upload_signed_contract: 'から締結契約書のアップロードがありました',
    upload_payment_confirmation_document: 'から支払い確認書類のアップデートがありました',
    upload_media: 'からメディアのアップロードがありました',
    upload_second_payment_confirmation_document:
      'から２回目の支払い確認書類のアップロードがありました',
    location_name: '場所の名前',
    station_name: '駅名',
    building_name: 'ビル名',
    building_number: 'ビル番号',
    airport_name: '空港名',
    gate_number: 'ゲート番号',
    townarea: 'タウンエリア',
    month: 'ヶ月',
    months: 'ヶ月',
    auto_fill_from_postal_code: '郵便番号からの自動入力',
    ex: '例',
    price_minimum: '最低価格',
    price_maximum: '最大価格',
    our_office: '住所',
    start_from: 'から',
    title_scan: 'QRコードからもチェック',
    contact_us: '電話番号',
    business_hour: '営業時間',
    search_your_suitable_advertising_media_compare_the_price_and_book_securely_in_a_few_clicks:
      '屋外広告、駅広告、ビジョン広告など様々な媒体を今すぐチェック',
    your_search_did_not_match_any_products_please_change_your_search_criteria_and_try_again_if_still_not_finding_anything_relevant_please_click_the_inquiry_button:
      '該当する商品が見つかりませんでした。絞り込み内容を変更してください。お探しの広告につきましては、問い合わせフォームからお問い合わせ可能です。ご利用ください。',
    no_data: '有効な検索ワードを入力してください',
    desc_media: '媒体詳細',
    notifications: '通知',
    one_month: '1 ヶ月',
    three_month: '3 ヶ月',
    six_month: '6 ヶ月',
    one_year: '12 ヶ月',
    homepage: 'ホームページ',
    waiting_media_activation: '出稿までお待ちください',
    second_payment_receipt: '２回目のお支払いお支払い確認書類',
    payment_proof_second_: '2回目のお支払い証明が確認されました',
    llc: '合同会社',
    add1: '東京都千代田区九段北4-2-2',
    add2: '桜ビル５F 〒102-0073',
    waiting_approval: 'メディア承認待ち',
    waiting_approval2: '承認をお待ちください',
  },
};

export default new VueI18n({
  messages,
});
