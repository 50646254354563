import { objectToObject } from '.';

const transaction = {
  link_video: 'リンクビデオ',
  media_file_has_been_rejected:
    'デザインが管理者から不承認となりました。再度、管理者にデザインをお送りください。',
  payment_proof_history: '支払歴',
  your_signed_contract_history: 'サイン済みご契約書履歴',
  status: {
    contract: {
      approved: '契約が確認されました',
      rejected: '契約を拒否しました',
      pending: '契約承認待ち',
    },
    payment_proof_first: {
      approved: '１回目のお支払い証明が確認されました',
      rejected: '１回目のお支払い証明を拒否しました',
      pending: '１回目のお支払い承認待ち',
      expired: 'お支払いの期限が切れました',
    },
    media: {
      approved: 'メディアは承認されました',
      rejected: 'メディア登録を拒否しました',
      pending: 'メディア登録承認待ち',
      expired: 'ご契約が拒否されました',
    },
    payment_proof_second: {
      approved: '２回目のお支払い証明が確認されました',
      rejected: '２回目のお支払い証明を拒否しました',
      pending: '２回目のお支払い承認待ち',
      expired: 'お支払いの期限が切れました',
    },
    quotation_expired: 'お見積書の期限が切れました',
    media_installed: 'メディア出稿完了',
    approved: '承認済み',
    complete: 'Complete',
    contract_rejected: 'ご契約を拒否しました',
    contract_approved: '契約が確認されました',
    media_activated_subtitle: 'メディアは出稿されました',
    media_activated: 'メディアは出稿されました',
    media_approved: 'メディアは承認されました',
    pending_contract: '期限切れのご契約書',
    pending_quotation: '承認待ちのお見積書です',
    pending: '承認待ち',
    rejected: '拒否',
    waiting_activation: '出稿までお待ちください',
    waiting_approval_media: 'メディア承認待ち',
    waiting_contract_approval: 'ご契約書の承認待ちです',
    waiting_approval: '承認待ち',
    waiting_client_media_subtitle:
      'クライアントがメディアをアップロードした後、メディアがここに表示されます',
    waiting_client_media: 'メディアクライアント承認待ち',
    waiting_contract_subtitle: 'クライアントは見積書もりを承認しましたので、契約書を送ってください',
    waiting_contract: 'ご契約書待ちです',
    waiting_quotation_subtitle: ({ values }) => {
      const numbers = ['０', '１', '２', '３', '４', '５', '６', '７', '８', '９'];
      let date = new Date(values.start_date);
      let year = String(date.getFullYear())
        .split('')
        .map((v) => numbers[v])
        .join('');
      let month = String(date.getMonth() + 1)
        .split('')
        .map((v) => numbers[v])
        .join('');
      let day = String(date.getDate())
        .split('')
        .map((v) => numbers[v])
        .join('');
      const start_date = `${year}年${month}月${day}日`;

      date = new Date(values.end_date);
      year = String(date.getFullYear())
        .split('')
        .map((v) => numbers[v])
        .join('');
      month = String(date.getMonth() + 1)
        .split('')
        .map((v) => numbers[v])
        .join('');
      day = String(date.getDate())
        .split('')
        .map((v) => numbers[v])
        .join('');
      const end_date = `${year}年${month}月${day}日`;
      return `${start_date}～${end_date}のお見積書作成お願いします`;
    },
    waiting_quotation: 'お見積書待ちです',
    waiting_second_payment_proof_subtitle:
      'クライアントがファイルをアップロードした後、支払証明書がここに表示されます',
    waiting_second_payment_proof: '２回目のお支払い証明を待っています',
  },
  your_signed_contract_rejected:
    'サイン済みご契約書は拒否されました。申し訳ございませんが、再度確認して頂き、再アップロードをしてください。',
  your_payment_rejected:
    '支払証明書 は拒否されました。申し訳ございませんが、再度確認して頂き、再アップロードをしてください。',
};

export default objectToObject(transaction, 'transaction');
