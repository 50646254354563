export default { 
  Auth: require('./auth').default,
  Public: require('./public').default,
  User: require('./user').default,
  Owner: require('./owner').default,
  indexUser: require('./indexUser').default,
  Secondary: require('./secondary').default,
  Errors: require('./errors').default,
}

