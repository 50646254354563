<template>
  <div class="">
    <h4>testing dashboard</h4>  
  </div>
</template>

<script>
  
export default {
  name: "dashboard",

  data: () => ({
    
  }),
  created() {
  },
  mounted() {
  },
  methods: {
    
  },
};
</script>
