import axios from "axios";
import url from "./api";

// const offline = false

const state = () => ({
  // user: null,
  status: "",
});

const mutations = {
  /* SET_USER(state, user) {
    state.user = user;
  },
  RESET_USER(state) {
    state.user = null;
  }, */
  SET_STATUS(state, status) {
    state.status = status;
  },
};

const actions = {

  async readNotification({ commit }, data) {
    let user = JSON.parse(localStorage.getItem("ytc_kinsan_auth"));

    if (!user) {
      localStorage.removeItem("ytc_kinsan_auth");
      return;
    }

    let token = user.token;
    // let bearer = `Bearer ${token}`;

    axios.defaults.headers.common["Authorization"] = token;
    axios.defaults.headers.common["Content-Type"] = 'multipart/form-data';

    return axios
      .post(url.notification, data)
      .then((res) => res.data)
      .then((res) => {
        commit("SET_STATUS", "success");
        return res.data;
      }) 
  },

  async getNotification({ commit }, data) {
    let user = JSON.parse(localStorage.getItem("ytc_kinsan_auth"));

    if (!user) {
      localStorage.removeItem("ytc_kinsan_auth");
      return;
    }

    let token = user.token;
    // let bearer = `Bearer ${token}`;

    axios.defaults.headers.common["Authorization"] = token;
    axios.defaults.headers.common["Content-Type"] = 'multipart/form-data';

    return axios
      .get(url.notification, data)
      .then((res) => res.data)
      .then((res) => {
        commit("SET_STATUS", "success");
        return res.data;
      }) 
  },

};

const getters = {
  /* user: (state) => state.user,
  isLoggedIn: (state) => !!state.user && !!state.user.token, */
};

export default { state, mutations, actions, getters };
