import firebase from 'firebase/app'
import 'firebase/firebase-messaging'

var firebaseConfig = {
    apiKey: 'AIzaSyBDwtWvXaXbsd1PykfneTGWf0i7WrDE47Y',
    authDomain: 'dev-kinsan.firebaseapp.com',
    projectId: 'dev-kinsan',
    storageBucket: 'dev-kinsan.appspot.com',
    messagingSenderId: '862688215183',
    appId: '1:862688215183:web:a001377f89980099f530fa',
    measurementId: 'G-5N18YZRCGD',
}

firebase.initializeApp(firebaseConfig)

// export default firebase.messaging()
export default firebase