import axios from "axios";
import url from "./api";

// const offline = false

const state = () => ({
  // user: null,
  status: "",
});

const mutations = {
  /* SET_USER(state, user) {
    state.user = user;
  },
  RESET_USER(state) {
    state.user = null;
  }, */
  SET_STATUS(state, status) {
    state.status = status;
  },
};

const actions = {
  async requestQuotation({ commit }, data) {
    let user = JSON.parse(localStorage.getItem("ytc_kinsan_auth"));

    if (!user) {
      localStorage.removeItem("ytc_kinsan_auth");
      return;
    }

    let token = user.token;
    // let bearer = `Bearer ${token}`;

    axios.defaults.headers.common["Authorization"] = token;
    axios.defaults.headers.common["Content-Type"] = 'multipart/form-data';

    return axios
      .post(url.request_quotation, data)
      .then((res) => res.data)
      .then((res) => {
        commit("SET_STATUS", "success");
        return res.data;
      }) 
  },

  async approveQuotation({ commit }, data) {
    let user = JSON.parse(localStorage.getItem("ytc_kinsan_auth"));

    if (!user) {
      localStorage.removeItem("ytc_kinsan_auth");
      return;
    }

    let token = user.token;
    // let bearer = `Bearer ${token}`;

    axios.defaults.headers.common["Authorization"] = token;
    axios.defaults.headers.common["Content-Type"] = 'multipart/form-data';

    return axios
      .post(url.approve_quotation, data)
      .then((res) => res.data)
      .then((res) => {
        commit("SET_STATUS", "success");
        return res.data;
      }) 
  },

  async submitContract({ commit }, data) {
    let user = JSON.parse(localStorage.getItem("ytc_kinsan_auth"));

    if (!user) {
      localStorage.removeItem("ytc_kinsan_auth");
      return;
    }

    let token = user.token;
    // let bearer = `Bearer ${token}`;

    axios.defaults.headers.common["Authorization"] = token;
    axios.defaults.headers.common["Content-Type"] = 'multipart/form-data';

    return axios
      .post(url.submit_contract, data)
      .then((res) => res.data)
      .then((res) => {
        commit("SET_STATUS", "success");
        return res.data;
      }) 
  },

  async submitContractPay({ commit }, data) {
    let user = JSON.parse(localStorage.getItem("ytc_kinsan_auth"));

    if (!user) {
      localStorage.removeItem("ytc_kinsan_auth");
      return;
    }

    let token = user.token;
    // let bearer = `Bearer ${token}`;

    axios.defaults.headers.common["Authorization"] = token;
    axios.defaults.headers.common["Content-Type"] = 'multipart/form-data';

    return axios
      .post(url.submit_contract_pay, data)
      .then((res) => res.data)
      .then((res) => {
        commit("SET_STATUS", "success");
        return res.data;
      }) 
  },

  async submitPayment({ commit }, data) {
    let user = JSON.parse(localStorage.getItem("ytc_kinsan_auth"));

    if (!user) {
      localStorage.removeItem("ytc_kinsan_auth");
      return;
    }

    let token = user.token;
    // let bearer = `Bearer ${token}`;

    axios.defaults.headers.common["Authorization"] = token;
    axios.defaults.headers.common["Content-Type"] = 'multipart/form-data';

    return axios
      .post(url.submit_payment, data)
      .then((res) => res.data)
      .then((res) => {
        commit("SET_STATUS", "success");
        return res.data;
      }) 
  },

  async uploadMedia({ commit }, data) {
    let user = JSON.parse(localStorage.getItem("ytc_kinsan_auth"));

    if (!user) {
      localStorage.removeItem("ytc_kinsan_auth");
      return;
    }

    let token = user.token;
    // let bearer = `Bearer ${token}`;

    axios.defaults.headers.common["Authorization"] = token;
    axios.defaults.headers.common["Content-Type"] = 'multipart/form-data';

    return axios
      .post(url.upload_media, data)
      .then((res) => res.data)
      .then((res) => {
        commit("SET_STATUS", "success");
        return res.data;
      }) 
  },

  async getTransaction({ commit }, data) {
    let user = JSON.parse(localStorage.getItem("ytc_kinsan_auth"));

    if (!user) {
      localStorage.removeItem("ytc_kinsan_auth");
      return;
    }

    let token = user.token;
    // let bearer = `Bearer ${token}`;

    axios.defaults.headers.common["Authorization"] = token;
    axios.defaults.headers.common["Content-Type"] = 'multipart/form-data';

    return axios
      .get(url.transaction, data)
      .then((res) => res.data)
      .then((res) => {
        commit("SET_STATUS", "success");
        return res.data;
      }) 
  },

  async getDetailTransaction({ commit }, data) {
    return axios
      .get(url.detail_transaction+"/"+data, {})
      .then((res) => res.data)
      .then((res) => {
        commit("SET_STATUS", "success");
        return res.data
      })
      .catch((error) => {
        console.error(error);
        // this.$router.push("/");
        window.location.href = '/'
      });
  },
};

const getters = {
  /* user: (state) => state.user,
  isLoggedIn: (state) => !!state.user && !!state.user.token, */
};

export default { state, mutations, actions, getters };
