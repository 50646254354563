<template>
  <div class="">
    <section id="filter" class="py-5" style="background:#f9fafa;">
      <!-- <div class=""> -->
        <div class="col-md-11 mx-auto">
          <h2 class="font-weight-bold mb-2">Find deals on advertising space here</h2>
          <p class="fs-14 color-state">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          <!-- <div class="col-md-12"> -->
            <div class="row mt-3">
              <div class="col-md-5 py-0">
                <v-autocomplete
                  :items="location"
                  outlined
                  placeholder="All location"
                  class=""
                  prepend-inner-icon="ri-map-pin-2-fill"
                ></v-autocomplete>
              </div>
              <div class="col-md-5 py-0 ">
                <v-autocomplete
                  :items="type"
                  outlined
                  placeholder="All type"
                  prepend-inner-icon="ri-picture-in-picture-line"                                                
                ></v-autocomplete>
              </div>              
                <div class="col-md-1 align-center text-center py-0 px-5">
                  <v-btn
                    depressed                  
                    block
                    color="primary"
                    class="rounded-8"
                    style="width: 150px;"
                    
                  >
                    Search
                  </v-btn>
                </div>                
            </div>
          <!-- </div> -->
        <!-- </div> -->
      </div>
    </section>

    <!-- detail content -->
    <section class="detail-content white py-5">
      <div class="col-md-11 mx-auto">
        <div class="row">
          <div class="col-md-2 d-none d-lg-block">
            <h2 class="mb-3">Filter by</h2>
            <div class="box-category mb-5">
              <p class="fs-18 font-weight-medium mb-1">Category</p>
              <v-checkbox
              v-model="ex"
              label="All"
              value="all"
              hide-details
              class="mt-1"
              ></v-checkbox>
              <v-checkbox
                v-model="ex"
                label="Discount Campaign"
                value="Discount Campaign"
                hide-details
                class="mt-1"
              ></v-checkbox>
              <v-checkbox
                v-model="ex"
                label="Latest Media"
                value="Latest Media"
                hide-details
                class="mt-1"
              ></v-checkbox>
              <v-checkbox
                v-model="ex"
                label="Popular"
                value="Popular"
                hide-details
                class="mt-1"
              ></v-checkbox>
            </div>
            <div class="box-market mb-5">
              <p class="fs-18 font-weight-medium mb-1">Target Market</p>
              <v-checkbox
              v-model="ex"
              label="All"
              value="all"
              hide-details
              class="mt-1"
              ></v-checkbox>
              <v-checkbox
                v-model="ex"
                label="CEO"
                value="CEO"
                hide-details
                class="mt-1"
              ></v-checkbox>
              <v-checkbox
                v-model="ex"
                label="Family"
                value="Family"
                hide-details
                class="mt-1"
              ></v-checkbox>
              <v-checkbox
                v-model="ex"
                label="Student"
                value="Student"
                hide-details
                class="mt-1"
              ></v-checkbox>
              <v-checkbox
                v-model="ex"
                label="Doctor"
                value="Doctor"
                hide-details
                class="mt-1"
              ></v-checkbox>
            </div>
            <div class="box-market mb-5">
              <p class="fs-18 font-weight-medium mb-1">Price</p>
              <v-row>
                <v-col class="pb-1">
                  <div class="mb-2">
                    <span class="font-weight-medium">Min</span>
                  </div>
                  <v-text-field
                    outlined
                    hide-details="auto"
                    class="rounded-8"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-1">
                  <div class="mb-2">
                    <span class="font-weight-medium">Max</span>
                  </div>
                  <v-text-field                    
                    outlined
                    hide-details="auto"
                    class="rounded-8"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
            <div class="btn-apply text-end align-self-end">
              <v-btn
                small
                  depressed
                  color="primary"
                  class="rounded-8"
                >
                  Apply
                </v-btn>
            </div>
          </div>
          <div class="col-md-10">
            <h2 class="mb-3">Search Result</h2>
            <div class="box-search">
              <v-chip
                v-if="chip1"
                class="ma-2"
                close
                @click:close="chip1 = false"
              >
                Yamanouchi
              </v-chip>
              <v-chip
                v-if="chip1"
                class="ma-2"
                close
                @click:close="chip1 = false"
              >
                Building
              </v-chip>
            </div>
            <div class="box-data my-3">
              <div class="row mb-3">                
                <v-card
                  class="mx-2 my-2"
                  max-width="230"
                  :to="{ name: 'pdp', params: { id: 123 } }"
                >
                  <v-img
                    height="140"
                    src="@/assets/images/img-detail.jpeg"
                  ></v-img>

                  <p class="font-weight-medium fs-18 wb mb-0 pa-2">Building - Nagoya TV Tower</p>
                  
                  <v-card-text class="pt-0 px-2">
                    <p class="color-oranye mb-0">
                      Start from ￥500
                    </p>
                    <p class="text-dots-2 mb-0">Nagoya TV Tower Naka Ward, Nagoya, Aichi ...</p>
                  </v-card-text>
                </v-card>
                <v-card
                  class="mx-2 my-2"
                  max-width="230"
                  :to="{ name: 'pdp', params: { id: 123 } }"
                >
                  <v-img
                    height="140"
                    src="@/assets/images/img-detail.jpeg"
                  ></v-img>

                  <p class="font-weight-medium fs-18 wb mb-0 pa-2">Building - Nagoya TV Tower</p>
                  
                  <v-card-text class="pt-0 px-2">
                    <p class="color-oranye mb-0">
                      Start from ￥500
                    </p>
                    <p class="text-dots-2 mb-0">Nagoya TV Tower Naka Ward, Nagoya, Aichi ...</p>
                  </v-card-text>
                </v-card>
                <v-card
                  class="mx-2 my-2"
                  max-width="230"
                  :to="{ name: 'pdp', params: { id: 123 } }"
                >
                  <v-img
                    height="140"
                    src="@/assets/images/img-detail.jpeg"
                  ></v-img>

                  <p class="font-weight-medium fs-18 wb mb-0 pa-2">Building - Nagoya TV Tower</p>
                  
                  <v-card-text class="pt-0 px-2">
                    <p class="color-oranye mb-0">
                      Start from ￥500
                    </p>
                    <p class="text-dots-2 mb-0">Nagoya TV Tower Naka Ward, Nagoya, Aichi ...</p>
                  </v-card-text>
                </v-card>
                <v-card
                  class="mx-2 my-2"
                  max-width="230"
                  :to="{ name: 'pdp', params: { id: 123 } }"
                >
                  <v-img
                    height="140"
                    src="@/assets/images/img-detail.jpeg"
                  ></v-img>

                  <p class="font-weight-medium fs-18 wb mb-0 pa-2">Building - Nagoya TV Tower</p>
                  
                  <v-card-text class="pt-0 px-2">
                    <p class="color-oranye mb-0">
                      Start from ￥500
                    </p>
                    <p class="text-dots-2 mb-0">Nagoya TV Tower Naka Ward, Nagoya, Aichi ...</p>
                  </v-card-text>
                </v-card>
                <v-card
                  class="mx-2 my-2"
                  max-width="230"
                  :to="{ name: 'pdp', params: { id: 123 } }"
                >
                  <v-img
                    height="140"
                    src="@/assets/images/img-detail.jpeg"
                  ></v-img>

                  <p class="font-weight-medium fs-18 wb mb-0 pa-2">Building - Nagoya TV Tower</p>
                  
                  <v-card-text class="pt-0 px-2">
                    <p class="color-oranye mb-0">
                      Start from ￥500
                    </p>
                    <p class="text-dots-2 mb-0">Nagoya TV Tower Naka Ward, Nagoya, Aichi ...</p>
                  </v-card-text>
                </v-card>
                <v-card
                  class="mx-2 my-2"
                  max-width="230"
                  :to="{ name: 'pdp', params: { id: 123 } }"
                >
                  <v-img
                    height="140"
                    src="@/assets/images/img-detail.jpeg"
                  ></v-img>

                  <p class="font-weight-medium fs-18 wb mb-0 pa-2">Building - Nagoya TV Tower</p>
                  
                  <v-card-text class="pt-0 px-2">
                    <p class="color-oranye mb-0">
                      Start from ￥500
                    </p>
                    <p class="text-dots-2 mb-0">Nagoya TV Tower Naka Ward, Nagoya, Aichi ...</p>
                  </v-card-text>
                </v-card>
                <v-card
                  class="mx-2 my-2"
                  max-width="230"
                  :to="{ name: 'pdp', params: { id: 123 } }"
                >
                  <v-img
                    height="140"
                    src="@/assets/images/img-detail.jpeg"
                  ></v-img>

                  <p class="font-weight-medium fs-18 wb mb-0 pa-2">Building - Nagoya TV Tower</p>
                  
                  <v-card-text class="pt-0 px-2">
                    <p class="color-oranye mb-0">
                      Start from ￥500
                    </p>
                    <p class="text-dots-2 mb-0">Nagoya TV Tower Naka Ward, Nagoya, Aichi ...</p>
                  </v-card-text>
                </v-card>
                <v-card
                  class="mx-2 my-2"
                  max-width="230"
                  :to="{ name: 'pdp', params: { id: 123 } }"
                >
                  <v-img
                    height="140"
                    src="@/assets/images/img-detail.jpeg"
                  ></v-img>

                  <p class="font-weight-medium fs-18 wb mb-0 pa-2">Building - Nagoya TV Tower</p>
                  
                  <v-card-text class="pt-0 px-2">
                    <p class="color-oranye mb-0">
                      Start from ￥500
                    </p>
                    <p class="text-dots-2 mb-0">Nagoya TV Tower Naka Ward, Nagoya, Aichi ...</p>
                  </v-card-text>
                </v-card>
                <v-card
                  class="mx-2 my-2"
                  max-width="230"
                  :to="{ name: 'pdp', params: { id: 123 } }"
                >
                  <v-img
                    height="140"
                    src="@/assets/images/img-detail.jpeg"
                  ></v-img>

                  <p class="font-weight-medium fs-18 wb mb-0 pa-2">Building - Nagoya TV Tower</p>
                  
                  <v-card-text class="pt-0 px-2">
                    <p class="color-oranye mb-0">
                      Start from ￥500
                    </p>
                    <p class="text-dots-2 mb-0">Nagoya TV Tower Naka Ward, Nagoya, Aichi ...</p>
                  </v-card-text>
                </v-card>
                <v-card
                  class="mx-2 my-2"
                  max-width="230"
                  :to="{ name: 'pdp', params: { id: 123 } }"
                >
                  <v-img
                    height="140"
                    src="@/assets/images/img-detail.jpeg"
                  ></v-img>

                  <p class="font-weight-medium fs-18 wb mb-0 pa-2">Building - Nagoya TV Tower</p>
                  
                  <v-card-text class="pt-0 px-2">
                    <p class="color-oranye mb-0">
                      Start from ￥500
                    </p>
                    <p class="text-dots-2 mb-0">Nagoya TV Tower Naka Ward, Nagoya, Aichi ...</p>
                  </v-card-text>
                </v-card>
                <v-card
                  class="mx-2 my-2"
                  max-width="230"
                  :to="{ name: 'pdp', params: { id: 123 } }"
                >
                  <v-img
                    height="140"
                    src="@/assets/images/img-detail.jpeg"
                  ></v-img>

                  <p class="font-weight-medium fs-18 wb mb-0 pa-2">Building - Nagoya TV Tower</p>
                  
                  <v-card-text class="pt-0 px-2">
                    <p class="color-oranye mb-0">
                      Start from ￥500
                    </p>
                    <p class="text-dots-2 mb-0">Nagoya TV Tower Naka Ward, Nagoya, Aichi ...</p>
                  </v-card-text>
                </v-card>
                <v-card
                  class="mx-2 my-2"
                  max-width="230"
                  :to="{ name: 'pdp', params: { id: 123 } }"
                >
                  <v-img
                    height="140"
                    src="@/assets/images/img-detail.jpeg"
                  ></v-img>

                  <p class="font-weight-medium fs-18 wb mb-0 pa-2">Building - Nagoya TV Tower</p>
                  
                  <v-card-text class="pt-0 px-2">
                    <p class="color-oranye mb-0">
                      Start from ￥500
                    </p>
                    <p class="text-dots-2 mb-0">Nagoya TV Tower Naka Ward, Nagoya, Aichi ...</p>
                  </v-card-text>
                </v-card>
                <v-card
                  class="mx-2 my-2"
                  max-width="230"
                  :to="{ name: 'pdp', params: { id: 123 } }"
                >
                  <v-img
                    height="140"
                    src="@/assets/images/img-detail.jpeg"
                  ></v-img>

                  <p class="font-weight-medium fs-18 wb mb-0 pa-2">Building - Nagoya TV Tower</p>
                  
                  <v-card-text class="pt-0 px-2">
                    <p class="color-oranye mb-0">
                      Start from ￥500
                    </p>
                    <p class="text-dots-2 mb-0">Nagoya TV Tower Naka Ward, Nagoya, Aichi ...</p>
                  </v-card-text>
                </v-card>
                <v-card
                  class="mx-2 my-2"
                  max-width="230"
                  :to="{ name: 'pdp', params: { id: 123 } }"
                >
                  <v-img
                    height="140"
                    src="@/assets/images/img-detail.jpeg"
                  ></v-img>

                  <p class="font-weight-medium fs-18 wb mb-0 pa-2">Building - Nagoya TV Tower</p>
                  
                  <v-card-text class="pt-0 px-2">
                    <p class="color-oranye mb-0">
                      Start from ￥500
                    </p>
                    <p class="text-dots-2 mb-0">Nagoya TV Tower Naka Ward, Nagoya, Aichi ...</p>
                  </v-card-text>
                </v-card>
                <v-card
                  class="mx-2 my-2"
                  max-width="230"
                  :to="{ name: 'pdp', params: { id: 123 } }"
                >
                  <v-img
                    height="140"
                    src="@/assets/images/img-detail.jpeg"
                  ></v-img>

                  <p class="font-weight-medium fs-18 wb mb-0 pa-2">Building - Nagoya TV Tower</p>
                  
                  <v-card-text class="pt-0 px-2">
                    <p class="color-oranye mb-0">
                      Start from ￥500
                    </p>
                    <p class="text-dots-2 mb-0">Nagoya TV Tower Naka Ward, Nagoya, Aichi ...</p>
                  </v-card-text>
                </v-card>
              </div>
              <div class="pagination">
                <v-pagination
                  v-model="page"
                  :length="4"
                ></v-pagination>
              </div>                  
            </div>
          </div>
        </div>
      </div>
    </section>

    <v-btn
      fab
      color="grey accent-2"
      class="filter-search d-lg-none d-md-block"
      @click="filter = !filter"
    >
      <v-icon>ri-filter-3-fill</v-icon>
    </v-btn>
    <v-dialog
      v-model="filter"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title>
          <p class="mb-0">Filter by</p>          
          <v-spacer></v-spacer>
            <v-btn
              icon
              dark
              @click="filter = false"
              color="primary"
            >
              <v-icon>ri-close-fill</v-icon>
            </v-btn>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-card-text class="">
          <!-- <div class="col-md-2"> -->
            <h2 class="mb-3"></h2>
            <div class="box-category mb-5">
              <p class="fs-18 font-weight-medium mb-1">Category</p>
              <v-checkbox
              v-model="ex"
              label="All"
              value="all"
              hide-details
              class="mt-1"
              ></v-checkbox>
              <v-checkbox
                v-model="ex"
                label="Discount Campaign"
                value="Discount Campaign"
                hide-details
                class="mt-1"
              ></v-checkbox>
              <v-checkbox
                v-model="ex"
                label="Latest Media"
                value="Latest Media"
                hide-details
                class="mt-1"
              ></v-checkbox>
              <v-checkbox
                v-model="ex"
                label="Popular"
                value="Popular"
                hide-details
                class="mt-1"
              ></v-checkbox>
            </div>
            <div class="box-market mb-5">
              <p class="fs-18 font-weight-medium mb-1">Target Market</p>
              <v-checkbox
              v-model="ex"
              label="All"
              value="all"
              hide-details
              class="mt-1"
              ></v-checkbox>
              <v-checkbox
                v-model="ex"
                label="CEO"
                value="CEO"
                hide-details
                class="mt-1"
              ></v-checkbox>
              <v-checkbox
                v-model="ex"
                label="Family"
                value="Family"
                hide-details
                class="mt-1"
              ></v-checkbox>
              <v-checkbox
                v-model="ex"
                label="Student"
                value="Student"
                hide-details
                class="mt-1"
              ></v-checkbox>
              <v-checkbox
                v-model="ex"
                label="Doctor"
                value="Doctor"
                hide-details
                class="mt-1"
              ></v-checkbox>
            </div>
            <div class="box-market mb-5">
              <p class="fs-18 font-weight-medium mb-1">Price</p>
              <v-row>
                <v-col class="pb-1">
                  <div class="mb-2">
                    <span class="font-weight-medium">Min</span>
                  </div>
                  <v-text-field
                    outlined
                    hide-details="auto"
                    class="rounded-8"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-1">
                  <div class="mb-2">
                    <span class="font-weight-medium">Max</span>
                  </div>
                  <v-text-field                    
                    outlined
                    hide-details="auto"
                    class="rounded-8"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
            <div class="btn-apply text-end align-self-end">
              <v-btn
                small
                  depressed
                  color="primary"
                  class="rounded-8"
                  @click="filter = false"

                >
                  Apply
                </v-btn>
            </div>
          <!-- </div> -->
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  name: "public-search",
  data: () => ({
    items:['1','2','3'],
    location:["Kairuzawa","Yamanouchi","Nagano","Tokyo","Yuzawa","Nagoya"],
    type:["Building","Station","Magazine","Influencer","Transportation","Flyer"],
    ex:['all'],
    model:null,
    chip1:true,
    chip2:true,
    page:1,
    filter:false
  }),
  created() {
  },
  mounted() {
    // console.log(this.$store.getters.isLoggedIn);
  },
  methods: {
    login() {
      this.$router.push("/auth/login");
    },
  },
};
</script>
<style>
  .filter-search{
    right: 5%;
    bottom: 2%;
    position: fixed;
    line-height: inherit;
    z-index: 99;
}
</style>