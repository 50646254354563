<style scoped>
.v-menu__content {
  max-width: 300px !important;
  top: 64px !important;
}
</style>
<template>
  <transition name="fade">
    <v-app v-if="loaded">
      <!-- Sidenav / Navigation drawer -->
      <!-- <div> -->
      <!-- Topnav / App bar -->
      <v-main class="" style="background: #fcfcfd">
        <v-app-bar id="menu-bar" class="white" flat fixed elevation="1">
          <v-toolbar-title :to="{ name: 'beranda' }">
            <!-- <router-link v-if="this.user.type ==='client'" class="my-1 text-left primary--text font-weight-bold" :to="{ name: 'index'}"> -->
            <router-link
              v-if="this.user.type === 'client'"
              class="my-1 text-left primary--text font-weight-bold"
              :to="{ name: 'beranda' }"
            >
              <img src="@/assets/images/logo.jpg" style="width: 95px" class="img-logo-head" />
            </router-link>
            <div v-else-if="this.user.type === 'owner'" class="my-1 text-left primary--text font-weight-bold">
              <img src="@/assets/images/logo.jpg" style="width: 95px" class="img-logo-head" />
            </div>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn rounded text small v-bind="attrs" v-on="on">
                <span class="fs-16 font-weight-medium primary--text mr-1">{{
                  $i18n.locale && $i18n.locale == 'jp' ? '日本' : 'English'
                }}</span>
                <v-icon right small>$dropdown</v-icon>
              </v-btn>
            </template>

            <v-list dense>
              <v-list-item link @click="changeLang('en')">
                <v-list-item-title v-text="'English'"></v-list-item-title>
              </v-list-item>
              <v-list-item link @click="changeLang('jp')">
                <v-list-item-title v-text="'日本'"></v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <!-- <v-badge class="mr-3" :dot="notifications.length == 0" top :color="notifications.length > 0 ? 'red' :''"
            :content="notifications.length" right overlap :value="notifications.length" offset-x="24" offset-y="23">
            <v-btn :disabled="notifications.length == 0" @click="openNotificationsMenu" depressed icon :ripple="false">
              <v-icon :color="notifications.length > 0 ? 'blue' :''">
                ri-notification-3-fill
              </v-icon>
            </v-btn>
          </v-badge> -->

          <v-menu
            offset-x
            v-model="showMenu"
            :position-x="menuPosition.x"
            :position-y="menuPosition.y"
            style="max-width: 300px"
            class="notif"
          >
            <template v-slot:activator="{ on }">
              <!-- <v-icon v-on="on" @click="menu = true">mdi-bell</v-icon> -->
              <v-badge
                v-on="on"
                class="mr-3"
                :dot="notifications.unread == 0"
                top
                :color="notifications.unread > 0 ? 'red' : ''"
                :content="notifications.unread > 99 ? '99+' : notifications.unread"
                right
                overlap
                :value="notifications.unread > 99 ? '99+' : notifications.unread"
                offset-x="24"
                offset-y="23"
              >
                <v-btn @click="openNotificationsMenu" depressed icon :ripple="false">
                  <!-- <v-icon :color="notifications.unread > 0 ? 'blue' :''"> -->
                  <v-icon> ri-notification-3-fill </v-icon>
                </v-btn>
              </v-badge>
            </template>
            <v-card scrollable>
              <v-card-title class="pb-2 text-body-1 font-weight-bold">
                {{ $t('notifications') }}
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text class="pa-0" style="height: 280px; overflow-y: scroll" @scroll="infiniteScrolling">
                <v-list>
                  <v-list-item-group>
                    <v-list-item
                      two-line
                      v-for="(item, index) in notifications_data"
                      :key="index"
                      :style="!item.red_at ? 'background: #ff999920' : ''"
                    >
                      <v-list-item-avatar style="height: 32px; min-width: 32px; width: 32px; align-self: start">
                        <v-img src="@/assets/images/no-pict.png" width="20"></v-img>
                      </v-list-item-avatar>
                      <v-list-item-content @click="readNotification(item)">
                        <!-- <v-list-item-title style="font-size: 12px;" class="text-capitalize text-wrap"
                          v-html="notif_template[item.code].from ? item.from.given_name+' '+item.from.surname+' '+$t(notif_template[item.code].template).replace('The Administrator', `<b>The Administrator</b>`) : $t(notif_template[item.code].template).replace('The Administrator', `<b>The Administrator</b>`)">
                        </v-list-item-title> -->

                        <v-list-item-title class="text-wrap" style="font-size: 12px">
                          {{ $t(`notification.${item.type}.${item.code}`, item[item.type]) }}
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 10px; color: rgba(0, 0, 0, 0.5)" class="text-wrap py-1">
                          <!-- {{ item.created_at | moment("dddd, MMMM Do YYYY HH:mm") }}                           -->
                          {{ item.created_at | moment('MMMM Do YYYY') }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle style="font-size: 12px; color: rgba(0, 0, 0, 0.5)" class="text-wrap">
                          <!-- {{item.type}} -->
                          ID Order
                          {{ item.order && item.order.order_number ? item.order.order_number : '' }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <!-- <v-list-item-icon v-show="!item.red_at">
                        <v-icon class="ri ri-checkbox-blank-circle-fill" x-small color="primary"></v-icon>
                      </v-list-item-icon> -->
                    </v-list-item>
                    <!-- <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Order</v-list-item-title>
                    <v-list-item-subtitle>The Administrator approve your media.</v-list-item-subtitle>
                    <v-list-item-subtitle>28 Dec 2021 12.12</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Order</v-list-item-title>
                    <v-list-item-subtitle>The Administrator approve your media.</v-list-item-subtitle>
                    <v-list-item-subtitle>28 Dec 2021 12.12</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item> -->
                  </v-list-item-group>
                </v-list>
                <div class="text-center" v-if="loading_notification">
                  <v-progress-circular indeterminate color="primary"> </v-progress-circular>
                </div>
              </v-card-text>
              <!-- <v-card-actions>
                <v-btn text @click="menu = false">Cancel</v-btn>
                <v-btn color="primary" text @click="menu = false">Read all</v-btn>
              </v-card-actions> -->
            </v-card>
          </v-menu>
          <v-menu offset-y class="d-none d-md-block transparent">
            <template v-slot:activator="{ on, attrs }" class="d-none d-md-block transparent">
              <v-btn
                class="d-none d-md-block px-2 font-weight-regular"
                v-bind="attrs"
                v-on="on"
                elevation="0"
                rounded
                color="transparent"
              >
                <img
                  v-if="user && user.photo_profile && user.photo_profile != 'https://'"
                  :src="user.photo_profile"
                  alt="Avatar"
                  class="mr-2"
                />
                <v-avatar v-else class="mr-2" color="green" size="40">
                  <span class="white--text text-h5">{{ user.surname.charAt(0) }}</span>
                </v-avatar>
                <!-- {{ $t('user') }} -->
                {{ user.surname }}
                <v-icon class="mx-2" small>$dropdown</v-icon>
              </v-btn>
            </template>

            <v-list dense v-if="this.user.type === 'client'">
              <v-list-item link @click="home">
                <v-list-item-title> <v-icon>ri-home-line</v-icon> {{ $t('homepage') }} </v-list-item-title>
              </v-list-item>
              <v-list-item link @click="prof">
                <v-list-item-title> <v-icon>ri-user-line</v-icon> {{ $t('profile') }} </v-list-item-title>
              </v-list-item>
              <v-list-item link @click="trans">
                <v-list-item-title> <v-icon>ri-file-text-line</v-icon> {{ $t('transaction') }} </v-list-item-title>
              </v-list-item>
              <v-list-item link @click="logout">
                <v-list-item-title> <v-icon>ri-logout-circle-line</v-icon> {{ $t('logout') }} </v-list-item-title>
              </v-list-item>
            </v-list>
            <v-list dense v-else-if="this.user.type === 'owner'">
              <v-list-item link @click="profOwner">
                <v-list-item-title> <v-icon>ri-user-line</v-icon> {{ $t('profile') }} </v-list-item-title>
              </v-list-item>
              <v-list-item link @click="medias">
                <v-list-item-title> <v-icon>ri-movie-line</v-icon> {{ $t('media') }} </v-list-item-title>
              </v-list-item>
              <v-list-item link @click="logout">
                <v-list-item-title> <v-icon>ri-logout-circle-line</v-icon> {{ $t('logout') }} </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-app-bar-nav-icon v-if="!$vuetify.breakpoint.mdAndUp" @click="dialog.menuAuth = true"></v-app-bar-nav-icon>
          <v-dialog v-model="dialog.menuAuth" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card>
              <v-toolbar>
                <v-toolbar-title>
                  <div class="my-1 text-left">
                    <router-link
                      v-if="this.user.type === 'client'"
                      class="my-1 text-left primary--text font-weight-bold"
                      :to="{ name: 'index' }"
                    >
                      Kinsan
                    </router-link>
                    <div v-else-if="this.user.type === 'owner'" class="my-1 text-left primary--text font-weight-bold">
                      Kinsan
                    </div>
                  </div>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-btn icon dark @click="dialog.menuAuth = false" color="primary">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>
              <v-spacer></v-spacer>
              <v-card-text class="pa-5">
                <v-list dense id="list-menu-user">
                  <!-- <v-list-item link @click="Beranda">
                      <v-list-item-title>
                        <v-icon>ri-home-3-line</v-icon>
                        Beranda</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item link @click="Prakerja">
                      <v-list-item-title>
                        <v-icon>ri-community-line</v-icon> Kelas Prakerja
                        </v-list-item-title
                      >
                    </v-list-item> -->
                  <v-list-item link>
                    <v-list-item-title>
                      <v-icon>ri-notification-3-fill</v-icon>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item class="pa-0">
                    <v-list-item-title class="">
                      <v-list-group :value="false" class="p-0" v-if="this.user.type === 'client'">
                        <template v-slot:activator class="">
                          <v-list-item-content class="">
                            <v-list-item-title class="mb-2" style="display: contents">
                              <img src="@/assets/images/Avatar.png" alt="Avatar" class="mr-2" />
                              <span style="align-items: center">{{ $t('user') }}</span>
                            </v-list-item-title>
                          </v-list-item-content>
                        </template>
                        <v-list-item link @click="home">
                          <v-list-item-title> <v-icon>ri-home-line</v-icon> {{ $t('homepage') }} </v-list-item-title>
                        </v-list-item>
                        <v-list-item link @click="prof">
                          <v-list-item-title> <v-icon>ri-user-line</v-icon> {{ $t('profile') }} </v-list-item-title>
                        </v-list-item>
                        <v-list-item link @click="trans">
                          <v-list-item-title>
                            <v-icon>ri-file-text-line</v-icon> {{ $t('transaction') }}
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item link @click="logout">
                          <v-list-item-title>
                            <v-icon>ri-logout-circle-line</v-icon> {{ $t('logout') }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list-group>
                      <v-list-group :value="false" class="p-0" v-else-if="this.user.type === 'owner'">
                        <template v-slot:activator class="">
                          <v-list-item-content class="">
                            <v-list-item-title class="mb-2" style="display: contents">
                              <img src="@/assets/images/Avatar.png" alt="Avatar" class="mr-2" />
                              <span style="align-items: center">{{ $t('user') }}</span>
                            </v-list-item-title>
                          </v-list-item-content>
                        </template>
                        <v-list-item link @click="profOwner">
                          <v-list-item-title> <v-icon>ri-user-line</v-icon> {{ $t('profile') }} </v-list-item-title>
                        </v-list-item>
                        <v-list-item link @click="medias">
                          <v-list-item-title> <v-icon>ri-movie-line</v-icon> {{ $t('media') }} </v-list-item-title>
                        </v-list-item>
                        <v-list-item link @click="logout">
                          <v-list-item-title>
                            <v-icon>ri-logout-circle-line</v-icon> {{ $t('logout') }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list-group>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-app-bar>

        <div class="col-md-11 mx-auto" style="margin-top: 64px; min-height: 80vh" v-if="show_sidebar">
          <div class="row">
            <div class="col-md-2 d-none d-lg-flex" v-if="show_secondary">
              <v-list flat class="transparent" style="width: 100%" v-if="this.user.type === 'client'">
                <template v-for="item in menus">
                  <v-list-item
                    active-class="menu-active"
                    color="primary"
                    v-if="item.items == undefined && !item.disabled"
                    class="font-weight-medium"
                    :key="item.title"
                    :to="item.link"
                    @click="pageChanged(0, item)"
                  >
                    <!-- active-class="menu-active" -->
                    <v-list-item-icon>
                      <!-- <v-icon v-text="item.icon"></v-icon> -->
                      <img v-if="item.title === 'profile'" src="@/assets/images/icon-prof.svg" class="img-filter" />
                      <img v-else src="@/assets/images/icon-trans.svg" class="img-filter" />
                    </v-list-item-icon>

                    <v-list-item-content class="font-weight-medium">
                      <v-list-item-title>
                        {{ $t(item.title) }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-group
                    v-else-if="!item.disabled"
                    :key="item.title"
                    :prepend-icon="item.icon"
                    active-class="menu-active"
                    :color="item.disabled ? 'primary' : 'primary'"
                    class="font-weight-medium"
                  >
                    <template slot="activator">
                      <v-list-item-title>
                        {{ $t(item.title) }}
                      </v-list-item-title>
                    </template>

                    <v-list-item
                      class="background"
                      color="primary"
                      v-for="item in item.items"
                      :key="item.title"
                      :to="item.link"
                    >
                      <v-list-item-icon>
                        <v-icon v-text="''"></v-icon>
                      </v-list-item-icon>

                      <v-list-item-title class="font-weight-medium">
                        {{ $t(item.title) }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list-group>
                </template>
              </v-list>
              <v-list flat class="transparent" style="width: 100%" v-else-if="this.user.type === 'owner'">
                <template v-for="item in menuOwner">
                  <v-list-item
                    active-class="menu-active"
                    color="primary"
                    v-if="item.items == undefined && !item.disabled"
                    class=""
                    :key="item.title"
                    :to="item.link"
                    @click="pageChanged(0, item)"
                  >
                    <!-- active-class="menu-active" -->
                    <v-list-item-icon>
                      <!-- <v-icon v-text="item.icon"></v-icon> -->
                      <img v-if="item.title === 'profile'" src="@/assets/images/icon-prof.svg" class="img-filter" />
                      <img v-else src="@/assets/images/icon-media.svg" class="img-filter" />
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title class="font-weight-medium">
                        {{ $t(item.title) }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-group
                    v-else-if="!item.disabled"
                    :key="item.title"
                    :prepend-icon="item.icon"
                    active-class="menu-active"
                    :color="item.disabled ? 'primary' : 'primary'"
                  >
                    <template slot="activator">
                      <v-list-item-title class="font-weight-medium">
                        {{ $t(item.title) }}
                      </v-list-item-title>
                    </template>

                    <v-list-item
                      class="background"
                      color="primary"
                      v-for="item in item.items"
                      :key="item.title"
                      :to="item.link"
                    >
                      <v-list-item-icon>
                        <v-icon v-text="''"></v-icon>
                      </v-list-item-icon>

                      <v-list-item-title class="font-weight-medium">
                        {{ $t(item.title) }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list-group>
                </template>
              </v-list>
            </div>
            <div class="col-md-10 mx-auto">
              <!-- <v-breadcrumbs class="px-0 pt-0" :items="breadcrumbs">
                  <template v-slot:item="{ item }">
                    <v-breadcrumbs-item>
                      <span v-if="item.disabled">
                        {{ item.text }}
                      </span>
                      <router-link class="text-decoration-none" v-else :to="item.href">
                        {{ item.text }}
                      </router-link>
                    </v-breadcrumbs-item>
                  </template>
                </v-breadcrumbs> -->
              <!-- <transition name="fade-up"> -->
              <router-view v-if="loaded"></router-view>
              <!-- </transition> -->
            </div>
          </div>
        </div>
        <div class="col-md-12 mx-auto" style="margin-top: 64px; min-height: 80vh" v-else>
          <div class="row">
            <div class="col-md-12">
              <!-- <v-breadcrumbs class="px-0 pt-0" :items="breadcrumbs">
                  <template v-slot:item="{ item }">
                    <v-breadcrumbs-item>
                      <span v-if="item.disabled">
                        {{ item.text }}
                      </span>
                      <router-link class="text-decoration-none" v-else :to="item.href">
                        {{ item.text }}
                      </router-link>
                    </v-breadcrumbs-item>
                  </template>
                </v-breadcrumbs> -->
              <!-- <transition name="fade-up"> -->
              <router-view v-if="loaded"></router-view>
              <!-- </transition> -->
            </div>
          </div>
        </div>
        <div id="footer" class="bg-footer py-4">
          <div class="container">
            <div class="row">
              <div class="col-md-10 mx-auto">
                <div class="contact">
                  <div class="row">
                    <div class="col-md-8 mb-3 box-flex">
                      <p class="white--text me-2">TEL : 03 - 6261 - 0002</p>
                      <p class="white--text mb-0">({{ $t('operating_hours') }} : {{ $t('weekdays') }} 09:00 - 17:00)</p>
                    </div>
                    <div class="col-md-4 mb-3 box-inquiry">
                      <!-- <v-btn depressed x-small class="rounded-lg"> -->
                      <v-btn depressed x-small class="rounded-lg" @click="inquiry = true">
                        {{ $t('inquiry') }}
                      </v-btn>
                    </div>
                    <div class="col-md-12 mb-3">
                      <div class="row">
                        <div class="col-md-2 mx-auto text-center">
                          <a href="#" class="white--text fs-14 font-weight-medium">{{ $t('for_medias_owners') }}</a>
                        </div>
                        <div class="col-md-2 mx-auto text-center">
                          <a href="#" class="white--text fs-14 font-weight-medium">{{ $t('company_profile') }}</a>
                        </div>
                        <div class="col-md-2 mx-auto text-center">
                          <router-link :to="{ name: 'privacy-policy' }" class="white--text fs-14 font-weight-medium">
                            {{ $t('privacy_statement') }}</router-link
                          >
                          <!-- <router-link :to="{ name: 'index'}" class="white--text fs-14 font-weight-medium">Privacy Statement</router-link> -->
                        </div>
                        <div class="col-md-2 mx-auto text-center">
                          <router-link :to="{ name: 'terms-of-use' }" class="white--text fs-14 font-weight-medium">
                            {{ $t('terms_conditions') }}</router-link
                          >
                          <!-- <router-link :to="{ name: 'index'}" class="white--text fs-14 font-weight-medium">Terms & Condition</router-link> -->
                        </div>
                        <div class="col-md-2 mx-auto text-center">
                          <router-link :to="{ name: 'faq' }" class="white--text fs-14 font-weight-medium">
                            {{ $t('faq_how_to_use') }}</router-link
                          >
                          <!-- <router-link :to="{ name: 'index'}" class="white--text fs-14 font-weight-medium">FAQ (How to Use)</router-link> -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 mb-2 px-0">
                    <v-divider style="border-color: #a383b3"></v-divider>
                  </div>
                  <div class="logo-footer white--text col-md-5 py-0 text-center mb-2 mx-auto">
                    <v-list-item class="d-dekstop">
                      <img src="@/assets/images/logo-footer.png" style="width: 150px; height: 100px" class="" />
                      <v-list-item-content>
                        <v-list-item-title class="white--text">by KINSAN GLOBAL, LLC</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <div class="d-mobile">
                      <img src="@/assets/images/logo-footer.png" style="width: 150px; height: 100px" class="" /><br />
                      <span>by KINSAN GLOBAL, LLC</span>
                    </div>
                    <!-- <span class="font-weight-bold fs-32 mr-3">Kinsan Advertising</span> -->
                    <!-- <img src="@/assets/images/logo-footer.png" style="width: 95px;" class="img-logo-head">
                    <span>by KINSAN GLOBAL, LLC</span> -->
                  </div>
                  <div class="text-footer white--text">
                    <p class="mb-0 fs-14">{{ $t('copyright') }} © {{ year }} Kinsan.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <v-dialog v-model="inquiry" width="700" persistent>
            <v-form lazy-validation @submit.prevent="submit" ref="form" class="mb-3">
              <v-card>
                <v-card-title class="text-h5">
                  <v-alert width="100%" v-if="response_success" text outlined color="success" class="rounded-8">
                    <p class="color-black fs-18 font-weight-medium mb-0">Success</p>
                    <p class="color-black mb-0">{{ response_success }}</p>
                  </v-alert>
                  <v-alert width="100%" v-if="response_fail" text outlined color="error" class="rounded-8">
                    <p class="color-black fs-18 font-weight-medium mb-0">Failed</p>
                    <p class="color-black mb-0">{{ response_fail }}</p>
                  </v-alert>
                  {{ $t('inquiry') }}
                </v-card-title>

                <v-card-text>
                  <v-row>
                    <v-col class="pb-1">
                      <div class="mb-2">
                        <span class="font-weight-medium">{{ $t('company_name') }}</span>
                      </div>
                      <v-text-field
                        :rules="[...rules('Company Name', 'required'), validate]"
                        v-model="company_name"
                        class="rounded-8"
                        required
                        outlined
                        hide-details="auto"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pb-1">
                      <div class="mb-2">
                        <span class="font-weight-medium">{{ $t('phone') }}</span>
                      </div>
                      <v-text-field
                        type="number"
                        :rules="[...rules('Phone', 'required'), validate]"
                        v-model="contact"
                        class="rounded-8"
                        required
                        outlined
                        hide-details="auto"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pb-1">
                      <div class="mb-2">
                        <span class="font-weight-medium">{{ $t('email') }}</span>
                      </div>
                      <v-text-field
                        :rules="[...rules('Email Address', 'email'), validate]"
                        v-model="email"
                        class="rounded-8"
                        required
                        outlined
                        hide-details="auto"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pb-1">
                      <div class="mb-2">
                        <span class="font-weight-medium">{{ $t('message') }}</span>
                      </div>
                      <v-textarea
                        :rules="[...rules('Message', 'required'), validate]"
                        v-model="message"
                        class="rounded-8"
                        required
                        outlined
                        hide-details="auto"
                        rows="2"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-card-text>

                <!-- <v-divider></v-divider> -->

                <v-card-actions class="pb-4">
                  <v-spacer></v-spacer>
                  <v-btn outlined @click="inquiry = false" small class="rounded-8 px-4">
                    {{ $t('cancel') }}
                  </v-btn>
                  <v-btn :loading="loading" color="primary" type="submit" small class="rounded-8 px-4 me-2">
                    {{ $t('submit') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-dialog>
        </div>
      </v-main>
      <!-- </div> -->
    </v-app>
  </transition>
</template>
<script>
import validationMixin from '../validation/valid';
import moment from 'moment';

import { mapGetters } from 'vuex';
import menu from './menu';
import menuOw from './menuOwner';

export default {
  mixins: [validationMixin],
  data() {
    return {
      notif_template: {
        new_quotation: {
          from: false,
          to: false,
          template: 'the_administrator_send_you_a_quote',
        },
        new_contract: {
          from: false,
          to: false,
          template: 'the_administrator_send_you_a_contract',
        },
        contract_rejected: {
          from: false,
          to: false,
          template: 'the_administrator_reject_your_signed_contract',
        },
        payment_rejected: {
          from: false,
          to: false,
          template: 'the_administrator_reject_your_payment_confirmation_document',
        },
        contract_approved: {
          from: false,
          to: false,
          template: 'the_administrator_approve_your_signed_contract',
        },
        payment_approved: {
          from: false,
          to: false,
          template: 'the_administrator_approve_your_payment_confirmation_document',
        },
        media_rejected: {
          from: false,
          to: false,
          template: 'the_administrator_reject_your_media',
        },
        media_approved: {
          from: false,
          to: false,
          template: 'the_administrator_approve_your_media',
        },
        new_activation_proof: {
          from: false,
          to: false,
          template: 'the_administrator_send_the_activation_proof',
        },
        '2nd_payment_approved': {
          from: false,
          to: false,
          template: 'the_administrator_approve_your_second_payment_confirmation_document',
        },
        '2nd_payment_rejected': {
          from: false,
          to: false,
          template: 'the_administrator_reject_your_second_payment_confirmation_document',
        },
        media_expired: {
          from: false,
          to: false,
          template: 'your_purchased_media_has_expired',
        },
        request_media_approval: {
          from: true,
          to: false,
          template: 'request_media_approval',
        },
        request_quotation: {
          from: true,
          to: false,
          template: 'request_a_quote',
        },
        request_contract: {
          from: true,
          to: false,
          template: 'request_a_contract',
        },
        request_contract_approval: {
          from: true,
          to: false,
          template: 'upload_signed_contract',
        },
        request_payment_approval: {
          from: true,
          to: false,
          template: 'upload_payment_confirmation_document',
        },
        request_upload_media: {
          from: true,
          to: false,
          template: 'upload_media',
        },
        request_2nd_payment_approval: {
          from: true,
          to: false,
          template: 'upload_second_payment_confirmation_document',
        },
      },
      year: null,
      showMenu: false,
      menuPosition: {
        x: 0,
        y: 0,
      },
      breadcrumbs: [],
      next: 0,
      count: 0,
      data: [],
      busy: false,
      loaded: true,
      drawer: null,
      notifications: [],
      notifications_data: [],
      pageTitle: '',
      // title:false,
      inquiry: false,
      response_success: null,
      response_fail: null,
      loading: false,
      loading_notification: false,
      pathnya: '',
      company_name: '',
      email: '',
      contact: '',
      message: '',
      dialog: {
        menuAuth: false,
      },
      items: [
        // { title: "Beranda", icon: "$beranda", link: "/beranda" },
        // { title: "Kelas Prakerja", icon: "ri-community-line", link: "/prakerja" },
        {
          title: '',
          icon: 'ri-notification-3-fill',
          link: '/',
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['user']),
    menus() {
      return menu;
    },
    menuOwner() {
      return menuOw;
    },
    show_sidebar() {
      // return !/\/user\/.\.*/.test(this.$route.path);
      // console.log(this.$route);
      return (
        this.$route.name == 'create-media' ||
        this.$route.name == 'update-media' ||
        this.$route.name == 'profile' ||
        this.$route.name == 'detail-transaction' ||
        this.$route.name == 'owner-profile' ||
        this.$route.name == 'transaction' ||
        this.$route.name == 'list-media'
      );
    },
    show_secondary() {
      return !/\/secondary\/.\.*/.test(this.$route.path);
    },
    validate() {
      return !this.errors || this.errors.message;
    },
  },
  created() {
    this.$root.$on('setPageTitle', (pageTitle) => {
      this.pageTitle = pageTitle;
      // console.log(pageTitle);
    });

    // let path = "/" + this.$route.path.split("/")[1];
    this.pathnya = this.$route.path;
    // console.log("pathnya", this.pathnya);
    // let index = this._.findIndex(this.items, { link: path });
    // if (index != -1) {
    //   this.pageTitle = this.items[index].title;
    //   this.breadcrumbs.push({
    //     text: this.items[index].title,
    //     disabled: true,
    //     href: path,
    //   });
    // }
    this.year = moment().format('YYYY');
  },
  async mounted() {
    document.addEventListener(
      'visibilitychange',
      async () => {
        // console.log(document.hidden, document.visibilityState);
        if (!document.hidden) {
          await this.getNotification();
        }
      },
      false
    );
    await this.getNotification();
    if (this.user.id) {
      if (this.$firebaseIsSupported) {
        await this.fcmToken().then(() => {});
        // await this.$messaging.onTokenRefresh(this.fcmToken());
        await this.$messaging.onTokenRefresh((data) => {
          console.log('onTokenRefresh...', data);
          this.fcmToken();
        });
      }
    }
  },
  methods: {
    async infiniteScrolling(event) {
      if (this.notifications.pagination.next != 0) {
        this.loading_notification = true;
      }
      // const d = document.documentElement;
      // const offset = event.target.scrollTop + event.target.innerHeight;
      const offset = event.target.scrollTop + event.target.clientHeight;
      // const height = event.target.offsetHeight - 200;
      const height = event.target.scrollHeight - 20;
      if (
        !this.busy &&
        offset >= height &&
        this.notifications.pagination.current != this.notifications.pagination.next &&
        this.notifications.pagination.next != 0
      ) {
        this.busy = true;
        await this.getNotification(this.notifications.pagination.next);
        this.busy = false;
      }
      /* setTimeout(async () => {
          if (!this.busy && offset >= height && this.notifications.pagination.current != this.notifications
            .pagination.next && this.notifications.pagination.next != 0) {
            this.busy = true
            await this.getNotification(this.notifications.pagination.next);
            this.busy = false
          }
        }, 500); */
    },
    async fcmToken() {
      // console.log('test', localStorage.getItem("kinsan_token_fcm"));
      // console.log(this.user);
      // if (localStorage.getItem("kinsan_token_fcm") != null) {
      //   if (this.user && this.user.id && this.user.id != '') {
      //     await this.axios
      //       .post(`api/subscribe`, {
      //         token: localStorage.getItem("kinsan_token_fcm"),
      //         topic: this.user.id,
      //       })
      //       .then(async () => {
      //         await this.getNotification();
      //       })
      //       .catch((error) => {
      //         console.log(error);
      //       });
      //     await this.$messaging.onMessage(async (payload) => {
      //       await this.getNotification();
      //       console.log("payload ", payload);
      //       // const notificationTitle = payload.notification.title;
      //       const notificationTitle = payload.data.title;
      //       const notificationOptions = {
      //         // body: payload.notification.body,
      //         body: payload.data.body,
      //         icon: "https://i.postimg.cc/vTFgKvRZ/favicon.png",
      //       };
      //       /* return navigator.serviceWorker.showNotification(
      //         notificationTitle,
      //         notificationOptions
      //       ) */
      //       navigator.serviceWorker.getRegistration().then(registration => {
      //         console.log(registration);
      //         if (registration) {
      //           // if (registration.length > 0) {
      //           return registration.showNotification(
      //             notificationTitle,
      //             notificationOptions
      //           )
      //         }
      //         /* else {
      //                                navigator.serviceWorker
      //                                  .register("/firebase-messaging-sw.js")
      //                                  .then(async (registration) => {
      //                                    return registration.showNotification(
      //                                      notificationTitle,
      //                                      notificationOptions
      //                                    )
      //                                  });
      //                              } */
      //       });
      //     });
      //   }
      // }
      await this.$messaging
        .requestPermission()
        .then(async () => {
          await this.$messaging
            .getToken()
            .then(async (token) => {
              // console.log("token ", token);
              if (token && token != '') {
                await this.axios
                  .post(`api/subscribe`, {
                    token: token,
                    topic: this.user.id,
                  })
                  .then(async () => {
                    await this.getNotification();
                  })
                  .catch((error) => {
                    console.log(error);
                  });
                /* await this.$messaging.onBackgroundMessage(async (payload) => {
                    console.log("payload onBackgroundMessage", payload);
                    await this.getNotification();
                  }) */
                await this.$messaging.onMessage(async (payload) => {
                  await this.getNotification();
                  // console.log("payload ", payload);
                  // const notificationTitle = payload.notification.title;
                  const notificationTitle = payload.data.title;
                  const notificationOptions = {
                    // body: payload.notification.body,
                    body: payload.data.body,
                    icon: 'https://i.postimg.cc/vTFgKvRZ/favicon.png',
                  };
                  /* return navigator.serviceWorker.showNotification(
                      notificationTitle,
                      notificationOptions
                    ) */
                  navigator.serviceWorker.getRegistration().then((registration) => {
                    // console.log(registration);
                    if (registration) {
                      // if (registration.length > 0) {
                      return registration.showNotification(notificationTitle, notificationOptions);
                    }
                    /* else {
                                             navigator.serviceWorker
                                               .register("/firebase-messaging-sw.js")
                                               .then(async (registration) => {
                                                 return registration.showNotification(
                                                   notificationTitle,
                                                   notificationOptions
                                                 )
                                               });
                                           } */
                  });
                });
              }
            })
            .catch((err) => {
              console.log('Err ', err);
            });
        })
        .catch((err) => {
          console.log('Unable to get token ', err);
        });
    },
    async readNotification(data) {
      // this.loading_notification = true;
      this.axios
        .post(`api/notification`, {
          id: data._id,
        })
        .then(async () => {
          // this.loading_notification = false;
          this.getNotification();
          if (data.type == 'order') {
            // const path = `/detail-transaction/${data.order.id}`
            // if (this.$route.path !== path) this.$router.push(path)
            // this.$router.push(`/detail-transaction/${data.order.id}`);
            window.location = `/detail-transaction/${data.order.id}`;
          } else if (data.type == 'media') {
            this.$router.push('/list-media');
          } else if (data.type == 'system') {
            // const path = `/detail-transaction/${data.order.id}`
            // if (this.$route.path !== path) this.$router.push(path)
            // this.$router.push(`/detail-transaction/${data.order.id}`);
            window.location = `/detail-transaction/${data.order.id}`;
          }
        })
        .catch((error) => {
          console.log(error);
          // this.loading_notification = false
        });
    },
    async getNotification(page = 1) {
      this.loading_notification = true;
      await this.axios
        .get(`api/notification?page=${page}`)
        .then(async (response) => {
          this.notifications = response.data.data;
          this.next = response.data.data.pagination.next;
          if (response.data.data.pagination.current == 1) {
            this.notifications_data = response.data.data.results;
          } else {
            this.notifications_data = [...this.notifications_data, ...response.data.data.results];
            // this.notifications_data.push(response.data.data.results);
          }
          this.loading_notification = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading_notification = false;
        });
    },
    openNotificationsMenu(e) {
      this.getNotification();
      this.showMenu = true;
      this.menuPosition.x = e.clientX - 40;
      if (e.clientY < 60) {
        this.menuPosition.y = 60;
      } else {
        this.menuPosition.y = e.clientY;
      }
    },
    async changeLang(language) {
      this.$cookies.set('kinsan_lang', language, -1);
      this.$router.go(0);
    },
    async submit() {
      this.response_success = null;
      this.response_fail = null;
      this.loading = true;

      if (this.$refs.form.validate()) {
        let company_name = this.company_name;
        let contact = this.contact;
        let email = this.email;
        let message = this.message;

        this.$store
          .dispatch('inquiry', {
            company_name,
            contact,
            email,
            message,
          })
          .then(async (result) => {
            if (result == 'success') {
              this.$refs.form.reset();
              this.response_success = 'Your message has been sent.';
              // window.scrollTo(0, 0);
              setTimeout(() => {
                this.inquiry = false;
              }, 5000);
              this.loading = false;
            }
          })
          .catch((error) => {
            // this.$refs.form.reset();
            this.response_fail = error.response.data.message.replace('body should have ', '').replace('body.', '');
            this.response_fail =
              this.response && this.response.slice(1)
                ? this.response_fail.charAt(0).toUpperCase() + this.response.slice(1)
                : this.response_fail;
            // window.scrollTo(0, 0);
            this.loading = false;
            // console.log(error.response.data);
          });
      } else {
        this.loading = false;
        // this.response_fail = "Failed";
        // window.scrollTo(0, 0);
      }
    },
    logout() {
      this.$store.dispatch('logout').then(() => {
        this.$router.push('/login');
      });
      this.dialog.menuAuth = false;
    },
    home() {
      this.$router.push('/');
      this.dialog.menuAuth = false;
    },
    prof() {
      this.$router.push('/profile');
      this.dialog.menuAuth = false;
    },
    profOwner() {
      this.$router.push('/owner-profile');
      this.dialog.menuAuth = false;
    },
    medias() {
      this.$router.push('/list-media');
      this.dialog.menuAuth = false;
    },
    trans() {
      this.$router.push('/transaction');
      // window.location.reload(true);
      // this.$router.push({ path: '/transaction'})
      this.dialog.menuAuth = false;
    },
    Setting() {
      this.$router.push('/account');
      this.dialog.menuAuth = false;
    },
    Beranda() {
      this.$router.push('/beranda');
      this.dialog.menuAuth = false;
    },
    Kelas() {
      this.$router.push('/kelas');
      this.dialog.menuAuth = false;
    },
    Prakerja() {
      this.$router.push('/prakerja');
      this.dialog.menuAuth = false;
    },
    Sertifikat() {
      this.$router.push('/sertifikat');
      window.location.reload(true);

      this.dialog.menuAuth = false;
    },
    Tele() {
      window.open('https://t.me/joinchat/sI_iXQ5WSwA5MTY9', '_blank');
    },
    menu() {
      this.$router.push('/');
    },
    pageChanged(segment, location = null) {
      // console.log(segment);
      // console.log(location);
      this.pathnya = this.$route.path;
      // console.log("pathpage", this.pathnya);
      this.breadcrumbs.splice(segment);
      if (location != null) {
        this.pageTitle = location.title;
        this.breadcrumbs.map((item) => (item.disabled = false));
        this.breadcrumbs.push({
          text: location.title,
          disabled: true,
          href: location.link,
        });
      }
      // window.location.reload(true);
      document.title = this.breadcrumbs.map((val) => val.text).join(' -> ') + ' - ' + process.env.VUE_APP_TITLE;
    },
  },
};
</script>

<style>
/* .v-list {
    max-height: 300px;
    overflow-y: auto
  } */

/* .v-menu__content {
    max-width: 300px !important;
    top: 64px !important;
  } */

/*.v-list-item__content {
    border-bottom: 1px solid #dadada50;
  }*/

.content-wrapper {
  position: relative;
}

.menu-active {
  color: #470868 !important;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #470868 !important;
  border-radius: 5px;
}

.menu-active .img-filter {
  filter: unset;
}

.img-filter {
  filter: grayscale(1);
}

/*.img-yec {
  height: 60px;
  width: 100%;
  object-fit: contain;
}*/
/*.img-yec:hover {
  cursor: pointer;
}*/
/*.img-yec-test {
  height: 60px;
  width: 100%;
  object-fit: revert;
}*/
#list-menu-user .v-list-item {
  padding: 8px 16px;
}

/*.img-yec-test:hover {
  cursor: pointer;
}*/
</style>
