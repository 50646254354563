<template>
  <guest v-if="!$store.getters.isLoggedIn" />
  <auth v-else-if="$store.getters.isLoggedIn" />
  <!-- <guest v-if="!$store.getters.isLoggedIn" />
  <auth v-else /> -->
</template>

<script>
/* import firebase from "firebase/app";
  import "firebase/messaging"; */
export default {
  metaInfo: {
    title: 'YTC-LMS',
  },
  components: {
    auth: require('./views/template/Auth').default,
    guest: require('./views/template/Guest').default,
  },
  created() {},
  methods: {},
  computed: {
    language() {
      // this.$translate.setLang(this.$cookies.get("kinsan_lang"));
      return this.$cookies.get('kinsan_lang');
    },
  },
  async mounted() {
    // await this.$drift.hide();
    this.$drift.on('ready', async (api) => {
      api.widget.hide();
    });
    // this.$drift.show();
    // console.log('Firebase cloud messaging object', this.$messaging)
    // console.log(this.$registration);
    this.$i18n.locale = this.language || 'en';
    // this.$messaging.getToken()
    // this.$messaging
    //   .requestPermission()
    //   .then(async () => {
    //     this.$messaging.onTokenRefresh((data) => {
    //       console.log("onTokenRefresh", data);
    //     });
    //     this.$messaging
    //       .getToken()
    //       .then(async (token) => {
    //         localStorage.setItem("kinsan_token_fcm", token)
    //         console.log(token);
    //       }).catch((err) => {
    //         console.log("Unable to get token ", err);
    //       });
    //   }).catch((err) => {
    //     console.log("Request Permission Failed : ", err);
    //     alert("Please allow notifications")
    //     /* Notification.requestPermission().then(function (permission) {
    //       console.log(permission);
    //     }); */
    //   });
  },
};
</script>

<style lang="scss">
.theme--light.v-application {
  background-color: var(--v-background-base);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@keyframes fade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade-up {
  from {
    width: 100%;
    position: absolute;
    opacity: 0;
    transform: translate3d(0, 50%, 0);
    left: 0;
    right: 0;
  }

  to {
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fade-enter-active {
  animation: fade 0.3s;
}

.fade-up-leave-active {
  animation: fade-up 0.3s reverse;
}

.fade-up-enter-active {
  animation: fade-up 0.3s;
}

a {
  text-decoration: none;
}
</style>
