import axios from "axios";
import url from "./api";

const state = () => ({
  status: "",
});

const mutations = {
  SET_STATUS(state, status) {
    state.status = status;
  },
};

const actions = {

  async getFaq({ commit }, data) {

    return axios
      .get(url.faq, {params: data})
      .then((res) => res.data)
      .then((res) => {
        commit("SET_STATUS", "success");
        return res.data
      })
      .catch((error) => {
        console.error(error);
      });
  },

  async getDetailPage({ commit }, data) {

    return axios
      .get(url.detail_page+"/"+data, {})
      .then((res) => res.data)
      .then((res) => {
        commit("SET_STATUS", "success");
        return res.data
      })
      .catch((error) => {
        console.error(error);
      });
  },
};

const getters = {
  /* user: (state) => state.user,
  isLoggedIn: (state) => !!state.user && !!state.user.token, */
};

export default { state, mutations, actions, getters };
