<template>
  <!-- <v-row> -->
  <v-col md="12" class="mx-auto">
    <v-row>
      <v-col cols="12" class="bg-transparent mb-0" md="12">
        <div class="row">
          <v-col lg="9" class="">
            <h2>{{ $t('media') }}</h2>
          </v-col>
          <v-col lg="3" class="align-end text-end">
            <v-btn color="primary" elevation="0" small @click="create">
              <v-icon>ri-add-line</v-icon> {{ $t('add_new_media') }}
            </v-btn>
          </v-col>
        </div>
      </v-col>
      <v-col cols="12" class="bg-transparent py-0" md="12">
        <v-row align="center">
          <v-col cols="12" lg="8">
            <v-tabs class="badged-tabs" color="primary" show-arrows="mobile">
              <v-tab
                class="text-capitalize"
                @click="
                  status = 'all';
                  getMedia();
                "
              >
                {{ $t('all_media') }}
              </v-tab>
              <v-tab
                class="text-capitalize"
                @click="
                  status = 'activated';
                  getMedia();
                "
              >
                {{ $t('activated_media') }}
              </v-tab>
              <v-tab
                class="text-capitalize"
                @click="
                  status = 'deactivated';
                  getMedia();
                "
              >
                {{ $t('media.deactivated') }}
              </v-tab>
              <v-tab
                class="text-capitalize"
                @click="
                  status = 'pending';
                  getMedia();
                "
              >
                {{ $t('waiting_for_review') }}
              </v-tab>
              <v-tab
                class="text-capitalize"
                @click="
                  status = 'rejected';
                  getMedia();
                "
              >
                {{ $t('rejected_media') }}
              </v-tab>
            </v-tabs>
          </v-col>

          <v-col id="search-media" cols="12" lg="4" class="align-end text-end">
            <v-text-field
              clearable
              @click:clear="onClearClicked"
              @keyup="
                keyword = $event.target.value;
                getMedia();
              "
              outlined
              append-icon="ri-search-line"
              class="search-media pt-0 mt-7 float-right rounded-8"
              :placeholder="$t('search_media')"
              solo
              flat
              style="width: 250px"
            >
              <!-- <template v-slot:append v-if="name">
                  <v-icon @click="name = ''">ri-close-line</v-icon>
                </template> -->
            </v-text-field>
          </v-col>
        </v-row>

        <v-row style="margin: 0">
          <v-col>
            <!-- <v-tabs-items id="dt-program" v-model="tabPaket" class="transparent">
                <v-tab-item> -->
            <div class="box-data my-3">
              <div v-if="loading" class="row mb-3 justify-center" style="min-height: 400px">
                <v-skeleton-loader v-for="index in 10" :key="index" class="mx-auto" width="210" type="card">
                </v-skeleton-loader>
              </div>
              <div class="row mb-3 justify-center">
                <!-- <v-img style="width: 200px;height: 200px;object-fit: cover;" src="@/assets/images/empty-media.png"></v-img> -->
                <img
                  v-if="list_media.length == 0"
                  class="mt-15 mb-15"
                  src="@/assets/images/empty-media.png"
                  style="width: 300px; height: 300px; object-fit: cover"
                />
                <v-card
                  v-else
                  v-for="(item, index) in list_media"
                  :key="index"
                  class="mr-5 mb-5"
                  max-width="210"
                  height="auto"
                  @click="
                    getDetailMedia(item._id);
                    dialog = true;
                  "
                >
                  <v-img height="140" :src="item.photo[0]"></v-img>

                  <p class="font-weight-medium fs-18 wb mb-0 pa-2 text-dots-2" style="min-height: 60px">
                    {{ item.media_type ? $tData(item.media_type, 'title') : '' }} - {{ item.title }}
                  </p>

                  <v-card-text class="pt-0 px-2">
                    <p class="color-oranye mb-0">
                      {{ $t('start_from_amount', { amount: item.minimum_price }) }}
                    </p>
                    <p class="text-dots-2 mb-0">{{ item && item.description ? item.description : '' }}</p>
                  </v-card-text>
                </v-card>
              </div>
              <div class="pagination" v-if="list_media.length > 0">
                <v-pagination
                  v-model="page"
                  :length="total_page"
                  :value="page"
                  :total-visible="total_pagination"
                  @input="getMedia(page)"
                ></v-pagination>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-dialog v-model="dialog" scrollable max-width="900">
        <v-card>
          <v-toolbar class="transparent" fixed elevation="0">
            <v-toolbar-title>
              <v-chip
                :ripple="false"
                class="pa-1 ma-2 mb-0 text-capitalize"
                :color="
                  detail_media.status && detail_media.status == 'activated'
                    ? 'green'
                    : detail_media.status && detail_media.status == 'rejected'
                    ? 'red'
                    : 'primary'
                "
                outlined
                label
                small
                :style="
                  detail_media.status && detail_media.status == 'activated'
                    ? 'background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), green!important;'
                    : detail_media.status && detail_media.status == 'rejected'
                    ? 'background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), red!important;'
                    : 'background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #3E66FB!important;'
                "
              >
                {{ $t('media.' + detail_media.status) }}
              </v-chip>
            </v-toolbar-title>
            <!-- <span v-if="detail_media.note && detail_media.note != ''"
              :class="detail_media.status && detail_media.status == 'activated' ? 'green--text' : detail_media.status && detail_media.status == 'rejected' ? 'red--text' : 'color-disable'"
              style="font-size: 12px;display:block;max-width: 70%;" class="pt-2">
              {{ "(Note : "+detail_media.note+")" }}
            </span> -->
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn icon @click="dialog = false">
                <v-icon>ri-close-line</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-card-text>
            <carousel
              v-if="detail_media.photo && detail_media.photo.length > 0 && detail_media.photo[0]"
              :key="refresh"
              :autoplay="true"
              :loop="false"
              :center="true"
              :items="1"
              :nav="false"
              :adjustableHeight="true"
              :margin="5"
            >
              <img v-for="item in detail_media.photo" :key="item" :src="item" class="img-detail-media" />
            </carousel>
            <!-- <carousel v-if="detail_media.photo && detail_media.photo.length > 0" :nav="true" :autoplay="true">
                <img v-for="(item, index) in detail_media.photo" :key="index" :src="item" class="mb-3" style="width: 100%;height: auto;object-fit: cover;">
              </carousel> -->
            <p class="font-weight-medium fs-20 mt-3">
              {{ detail_media.media_type ? $tData(detail_media.media_type, 'title') : '' }} - {{ detail_media.title }}
            </p>

            <v-alert
              v-if="detail_media.reason && detail_media.reason != ''"
              text
              outlined
              :class="
                detail_media.status && detail_media.status == 'activated'
                  ? 'green--text'
                  : detail_media.status && detail_media.status == 'rejected'
                  ? 'red--text'
                  : 'color-disable'
              "
              class="rounded-8"
            >
              <p
                v-if="detail_media.status && detail_media.status == 'rejected'"
                class="color-black fs-18 font-weight-medium mb-0"
              >
                Reason for rejected
              </p>
              <p class="color-black mb-0">{{ detail_media.reason }}</p>
            </v-alert>

            <p class="color-state" v-html="detail_media.description"></p>

            <div class="box-trafic py-3">
              <div class="row">
                <div
                  class="col-3 col-md-2"
                  :title="$t('category')"
                  v-if="detail_media && detail_media.category && detail_media.category.title"
                >
                  <p class="fs-14 font-weight-medium mb-0">{{ $t('category') }}</p>
                  <p class="color-state">{{ detail_media && detail_media.category && detail_media.category.title }}</p>
                </div>
                <div class="col-3 col-md-2" :title="$t('traffic')">
                  <p class="fs-14 font-weight-medium mb-0">{{ $t('traffic') }}</p>
                  <p class="color-state mb-0">{{ detail_media && detail_media.traffic }}</p>
                </div>
                <div class="col-12 col-md-12 mb-2">
                  <p class="fs-14 font-weight-medium mb-0">{{ $t('transaction.link_video') }}</p>
                  <a v-if="detail_media.video != ''" :href="detail_media.video" target="_blank">{{
                    detail_media && detail_media.video
                  }}</a>
                  <p v-else class="text-primary mb-0">-</p>
                </div>
                <!-- <div class="col-3 col-md-2" :title="$t('target_market')">
                  <p class="fs-14 font-weight-medium mb-0">{{ $t('target_market') }}</p>
                  <p class="color-state">
                    {{detail_media && detail_media.target_market && detail_media.target_market.map(function(elem){
                        return elem.title;
                    }).join(", ")}}
                  </p>
                </div> -->
              </div>
            </div>

            <div class="box-details mb-3" v-if="detail_media.media_type && detail_media.media_type.property">
              <div class="row">
                <div v-for="(item, index) in detail_media.media_type.property" :key="index" class="col-3 col-md-2">
                  <!-- <p class="font-weight-bold">{{$t(item.title.toLowerCase().replace(/ /g,'_'))}}</p> -->
                  <p class="font-weight-bold">{{ $tData(item, 'title') }}</p>
                  <p class="font-weight-normal">{{ $tData(item, 'value') }}</p>
                </div>
              </div>
            </div>

            <div class="box-details mb-3">
              <div class="row">
                <div class="col-3 col-md-2 text-center" :title="$t('size')">
                  <img src="@/assets/images/size.png" width="64" height="64" class="mb-1" />
                  <p class="font-weight-medium">
                    {{
                      detail_media.unit_of_measurement && detail_media.unit_of_measurement.width
                        ? detail_media.unit_of_measurement.width
                        : ''
                    }}
                    x
                    {{
                      detail_media.unit_of_measurement && detail_media.unit_of_measurement.height
                        ? detail_media.unit_of_measurement.height
                        : ''
                    }}
                    {{
                      detail_media.unit_of_measurement && detail_media.unit_of_measurement.type
                        ? $t(detail_media.unit_of_measurement.type.toLowerCase())
                        : ''
                    }}
                  </p>
                </div>
                <div class="col-3 col-md-2 text-center" :title="$t('orientation')">
                  <img src="@/assets/images/window.png" width="64" height="64" class="mb-1" />
                  <p class="font-weight-medium">
                    {{ $t(detail_media && detail_media.orientation ? detail_media.orientation.toLowerCase() : '') }}
                  </p>
                </div>
                <div class="col-3 col-md-2 text-center" :title="$t('lighting')">
                  <img src="@/assets/images/sun.png" width="64" height="64" class="mb-1" />
                  <p class="font-weight-medium">
                    {{ detail_media.is_lighting === 'true' ? $t('available') : $t('not_available') }}
                  </p>
                </div>
              </div>
            </div>

            <div class="box-map mb-3">
              <GmapMap
                :center="{
                  lat:
                    detail_media && detail_media.location && detail_media.location.coordinates
                      ? detail_media.location.coordinates[1]
                      : '',
                  lng:
                    detail_media && detail_media.location && detail_media.location.coordinates
                      ? detail_media.location.coordinates[0]
                      : '',
                }"
                :zoom="10"
                map-style-id="roadmap"
                :options="{
                  zoomControl: true,
                  mapTypeControl: false,
                  scaleControl: false,
                  streetViewControl: false,
                  rotateControl: false,
                  fullscreenControl: true,
                  disableDefaultUi: false,
                }"
                style="width: 100%; height: 50vmin"
                ref="mapRef"
              >
                <GmapMarker
                  :position="{
                    lat:
                      detail_media && detail_media.location && detail_media.location.coordinates
                        ? detail_media.location.coordinates[1]
                        : '',
                    lng:
                      detail_media && detail_media.location && detail_media.location.coordinates
                        ? detail_media.location.coordinates[0]
                        : '',
                  }"
                />
              </GmapMap>
              <div class="row mt-2">
                <div class="col-md-10">
                  <p class="color-state">
                    <v-icon>ri-map-pin-2-fill</v-icon>
                    {{ detail_media.location && detail_media.location.address ? detail_media.location.address : '' }}
                  </p>
                </div>
                <div class="col-md-2 align-self-end text-end">
                  <v-btn
                    :href="
                      detail_media.location && detail_media.location.coordinates
                        ? 'https://www.google.com/maps/place/' +
                          detail_media.location.coordinates[1] +
                          ',' +
                          detail_media.location.coordinates[0]
                        : ''
                    "
                    target="_blank"
                    outlined
                    class="mb-3 color-state rounded-8"
                    x-small
                  >
                    {{ $t('view_on_map') }}
                  </v-btn>
                </div>
              </div>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              elevation="0"
              small
              :to="{ name: 'update-media', params: { id: detail_media._id } }"
              :disabled="detail_media.status === 'pending'"
            >
              {{ $t('action.update') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-col>
  <!-- </v-row> -->
</template>

<script>
import validationMixin from '../validation/valid';
import carousel from 'vue-owl-carousel';

export default {
  mixins: [validationMixin],
  components: {
    carousel,
  },
  name: 'list-media',
  data() {
    return {
      loading: false,
      status: 'all',
      keyword: '',
      tabPaket: null,
      refresh: 0,
      page: 1,
      limit: 10,
      total_pagination: 0,
      total_page: 0,
      dialog: false,
      detail_media: {},
      list_media: [],
    };
  },
  computed: {},
  mounted() {
    this.getMedia();
  },
  methods: {
    onClearClicked() {
      this.keyword = '';
      this.getMedia();
    },
    getMedia(page = 1) {
      this.loading = true;
      let params = {
        page: page,
        limit: this.limit,
      };
      if (this.status != '' && this.status != 'all') {
        params.status = this.status;
      } else {
        delete params.status;
      }
      if (this.keyword != '') {
        params.keyword = this.keyword;
      } else {
        delete params.keyword;
      }
      this.$store
        .dispatch('getMediaOwner', params)
        .then((data) => {
          this.list_media = data.results;
          this.page = data.pagination.current;
          this.total_page = data.pagination.total_page;
          this.total_pagination = data.pagination.detail.length == 0 ? 1 : data.pagination.detail.length;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    getDetailMedia(id) {
      this.loading = true;
      this.$store
        .dispatch('getDetailMediaOwner', id)
        .then((data) => {
          this.refresh = id;
          this.detail_media = data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    create() {
      this.$router.push('/create-media');
    },
  },
};
</script>

<style>
/* .owl-stage {
  width: 100%!important;
}
.owl-item {
  width: 100%!important;
} */
.img-detail-media {
  width: 100%;
  max-height: 400px;
  object-fit: cover;
}

#search-media > .v-text-field--enclosed .v-input__append-inner {
  margin-top: 0 !important;
}

.v-tab--active {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #470868 !important;
  border-radius: 8px !important;
}

.v-tabs-slider-wrapper {
  display: none !important;
}
</style>
