var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"loading":_vm.isLoading,"headers":_vm.headers,"search":_vm.search,"items":_vm.item,"items-per-page":_vm.data.limit,"page":_vm.data.page,"item-key":'id',"mobile-breakpoint":0,"server-items-length":_vm.data.total,"loading-text":"Loading... Please wait","footer-props":{
    'items-per-page-text': _vm.$t('row_per_page'),
  }},on:{"update:itemsPerPage":function($event){return _vm.$set(_vm.data, "limit", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.data, "limit", $event)},"update:page":function($event){return _vm.$set(_vm.data, "page", $event)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
  var item = ref.item;
return [_c('tr',{staticClass:"flat-b-1 rounded-lg"},[_c('td',{staticClass:"white text-capitalize"},[_c('router-link',{staticClass:"color--text font-weight-bold text-capitalize",attrs:{"to":{ name: 'detail-transaction', params: { id: item._id } }},domProps:{"textContent":_vm._s(item.order_number)}})],1),(_vm.$i18n.locale === 'en')?_c('td',{staticClass:"white text-capitalize text-truncate",staticStyle:{"max-width":"150px"},domProps:{"textContent":_vm._s(
          item && item && item.product && item.product.title
            ? item.product.media_type.title + ' - ' + item.product.title
            : ''
        )}}):_c('td',{staticClass:"white text-capitalize text-truncate",staticStyle:{"max-width":"150px"},domProps:{"textContent":_vm._s(
          item && item && item.product && item.product.title
            ? item.product.media_type.title_ja + ' - ' + item.product.title
            : ''
        )}}),_c('td',{staticClass:"white text-capitalize"},[_c('v-chip',{staticClass:"pa-1 text-capitalize",staticStyle:{"background":"linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)),"},attrs:{"ripple":false,"color":"primary","outlined":"","label":"","small":""},domProps:{"textContent":_vm._s(_vm.getStatusOrder(item))}})],1),_c('td',{staticClass:"white text-capitalize"},[(item.status_payment)?_c('v-chip',{staticClass:"pa-1 text-capitalize",staticStyle:{"background":"linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)),"},attrs:{"ripple":false,"color":"primary","outlined":"","label":"","small":""},domProps:{"textContent":_vm._s(_vm.$t('transaction.status.' + item.status_payment))}}):_c('span',[_vm._v("-")])],1),_c('td',{staticClass:"white text-capitalize",domProps:{"textContent":_vm._s(
          item.duration == '1 Month'
            ? _vm.$t('one_month')
            : item.duration == '3 Months'
            ? _vm.$t('three_month')
            : item.duration == '6 Months'
            ? _vm.$t('six_month')
            : item.duration == '12 Months'
            ? _vm.$t('one_year')
            : item.duration
        )}})])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }