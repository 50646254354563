<template>
  <v-row justify="center" class="py-3">
    
    <v-col v-if="isPaketEmpty" cols="4" c>
      <v-row class="flex-column">
        <!-- TRANSACTION -->
      </v-row>
    </v-col>

    <v-col v-else>
      <v-row>
      <v-col cols="12" md="9" class="">                  
        <h2>{{ $t('transaction') }}</h2>        
      </v-col>
      <v-col cols="12" id="search-trans" md="3" class="align-end text-end"> 
        <v-text-field
          outlined
          append-icon="ri-search-line"
          class="pt-0 mt-0 float-right rounded-8"
          :placeholder="$t('search_transaction')"
          v-model="search"
          solo
          flat
          style="width: 300px;"
        >
          <template v-slot:append v-if="name">
            <v-icon @click="name = ''">ri-close-line</v-icon>
          </template>    
        </v-text-field>          
      </v-col>      
    </v-row>
      <v-row>
        <v-col id="dt-program" >          
          <trans
              :search="search"
              :loading="table_loading"
              class="elevation-1 px-2"
            />            
        </v-col>
      </v-row>

      <v-snackbar 
        top 
        v-model="snackbar.state"
        color="primary"
        outlined
        :timeout="timeout"
      >
        <div v-html="snackbar.text"></div>
        <template v-slot:action="{ attrs }">
          <v-btn
            small
            icon
            color="error"
            v-bind="attrs"
            @click="snackbar.state = false"
          >
            <v-icon>$close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-col>
  </v-row>
</template>

<script>
import Trans from "./components/tableTrans";
// import { mapGetters} from 'vuex'


export default {
  name: "user-list",
  components: { Trans},
  data: () => ({
    isPaketEmpty:false,
    snackbar: {
        state: false,
        text: "",
      },
    newPaket: "",
    table_loading: false,    
    loading:false,
    all: {},
    member:{},
    topics:[],
    idMember:'',
    tabPaket: null,
    submitting:false,
    timeout:5000,
    new_status: "",
    new_items:"",
    prog:{}, 
    name:"",
    search:"",

    menu: false,
    date: null,
    age:'',
    respon:false,
    btnRespon:true,
    feedback:'',
    detailVoucher:{},
    url_:''
  }),
  computed: {
    
  },
  mounted() {
    this.$emit("page-changed", 1, {
      title: "Transaction",
      link: this.$route.path,
    });
  },
  created(){
  },
  methods: {
  },
  watch: {
  },
};
</script>
<style>
#search-trans .v-text-field__details{
  display: none!important;
}
#search-trans .v-input__append-inner{
  margin-top: 0px!important;
}
</style>