<template>
  <v-alert colored-border elevation="0" style="min-height: 100vh;">
    <p class="text-center py-16">
      <!-- <img src="@/assets/images/data_empty.png" /> -->
    </p>
    <h1 class="text-center">{{ message }}</h1>
  </v-alert>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: "Error 404 - Page not found"
    }
  }
}
</script>